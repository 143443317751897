import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Switch,
  FormControlLabel,
  CardHeader,
  TextField,
  MenuItem,
} from '@mui/material';
import CreatorDetailsCard from './creatorDetailsCard';
import { platformRatesListFromCreator } from '../../../../Utils/constants';

const CreatorDetails = ({
  selectedCreators,
  creatorsData,
  setCreatorsData,
  isBlitzFeeOnCreators,
  setIsBlitzFeeOnCreators,
  onNext,
  onBack,
  onRemove,
  data

}) => {
  const [triedNext, setTriedNext] = useState(false);
  const [allPromotion, setAllPromotion] = useState('');

  useEffect(() => {
    setAllPromotion('');
    setCreatorsData(prev => [...selectedCreators].map((creatorId) => {
      const existingCreator = prev.find((existing) => existing.creator == creatorId);
      return existingCreator || {
        ...data.find((creator) => creator.creator == creatorId),
        rateKey: '',
        basePrice: 0,
        crossPost: false,
        usageRights: false,
        adjustedPrice: 0,
      }
    })
    );
  }, [selectedCreators]);

  const handlePriceChange = (index, newPrice) => {
    setCreatorsData((prev) => {
      const updated = [...prev];
      updated[index].basePrice = parseFloat(newPrice) || 0;
      updated[index].adjustedPrice = calculateAdjustedPrice(
        updated[index].basePrice,
        updated[index].crossPost,
        updated[index].usageRights
      );
      return updated;
    });
  };

  const setGeneralPromotions = (event) => {
    if (event.target.value) {
      setAllPromotion(event.target.value);
      setCreatorsData((prev) => prev.map((creator) => ({
        ...creator,
        rateKey: event.target.value,
        basePrice: creator[event.target.value] || 0,
        adjustedPrice: calculateAdjustedPrice(
          creator[event.target.value],
          creator.crossPost,
          creator.usageRights)
      })
      ))
    }
  }

  const handleOptionChange = (index, option, value) => {
    if (option === 'rateKey')
      setAllPromotion('');
    setCreatorsData((prev) => {
      const updated = [...prev];
      updated[index][option] = value;
      updated[index].basePrice = updated[index][updated[index].rateKey] || 0
      updated[index].adjustedPrice = calculateAdjustedPrice(
        updated[index].basePrice,
        updated[index].crossPost,
        updated[index].usageRights
      );
      return updated;
    });
  };

  const generalRates = useMemo(() => {
    if (creatorsData.length === 0) return [];

    // Step 1: Get the list of rate keys for each creator
    const creatorRatesList = creatorsData.map(platformRatesListFromCreator);

    // Step 2: Find the intersection of rate keys across all creators
    const commonRateKeys = creatorRatesList
      .map((rates) => rates.map((rate) => rate.rateKey)) // Extract rate keys
      .reduce((commonKeys, rateKeys) =>
        commonKeys.filter((key) => rateKeys.includes(key))
      );

    // Step 3: Filter each creator's rates to include only those with common rate keys
    return creatorRatesList[0]
      .filter((rate) => commonRateKeys.includes(rate.rateKey))
      .map(({ rateKey, label }) => ({
        label,
        rate: -1, // Retrieve the rate from the first creator as an example
        rateKey
      }));
  }, [creatorsData])

  const calculateAdjustedPrice = (basePrice, crossPost, usageRights) => {
    let adjustedPrice = basePrice;
    if (crossPost) {
      adjustedPrice *= 1.4;
    }
    if (usageRights) {
      adjustedPrice *= 1.1;
    }
    return adjustedPrice;
  };

  const projectSum = creatorsData.reduce(
    (sum, creator) => sum + creator.adjustedPrice,
    0
  );

  const blitzFee = isBlitzFeeOnCreators ? 0 : projectSum * 0.05;

  const finalCost = projectSum + blitzFee;

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>
            Selected Creators
          </Typography>
          {<TextField
            value={allPromotion}
            onChange={setGeneralPromotions}
            label='Set every Promotion Type'
            select
            fullWidth
            sx={{ marginBlockEnd: 2 }}
          >
            <MenuItem value=''>None</MenuItem>
            {generalRates.map((promo) => (<MenuItem key={`general-${promo.rateKey}`} value={promo.rateKey}>{promo.label}</MenuItem>))}
          </TextField>}
          <Grid container spacing={2}>
            {creatorsData.map((creator, index) => (
              <CreatorDetailsCard
                creator={creator}
                index={index}
                onRemove={onRemove}
                handleOptionChange={handleOptionChange}
                handlePriceChange={handlePriceChange}
                triedNext={triedNext}
                key={creator.creator}
              >
              </CreatorDetailsCard>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={theme => ({ position: { xs: 'static', md: 'sticky' }, top: theme.spacing(2) })}>
            <CardHeader title='Project Summary'></CardHeader>
            <CardContent>
              {false && <FormControlLabel
                control={
                  <Switch
                    checked={isBlitzFeeOnCreators}
                    onChange={(e) => setIsBlitzFeeOnCreators(e.target.checked)}
                    color="primary"
                  />
                }
                label="Blitz Fee Paid by Creators"
              />}
              {false && <Typography variant="body1">
                Project Sum: ${projectSum.toFixed(2)}
              </Typography>}
              {false && <Typography variant="body1">
                Blitz Fee: ${blitzFee.toFixed(2)}
              </Typography>}
              <Typography variant="body1">
                Final Cost: ${finalCost.toFixed(2)}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Button variant="contained" onClick={onBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const anyBadData = creatorsData.some((creator) => !creator.basePrice || creator.basePrice == '0' || !creator.rateKey);
                    setTriedNext(true);
                    if (!anyBadData)
                      onNext();

                  }}
                  disabled={creatorsData.length === 0}
                >
                  Next
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreatorDetails;
