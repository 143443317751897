import React from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, Box } from '@mui/material';
import { ResponsiveContainer, BarChart, LineChart, PieChart, XAxis, YAxis, Tooltip, Legend, Bar, Line, Pie, Cell } from 'recharts';

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#A83731",
  "#5A30B5",
  "#34A853",
];

const CampaignReportingPanel = ({ costPerCreatorData, viewsByDayData, reachData }) => {
  return (
    <>
      <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: 'center', marginBottom: 4 }}>
        Reporting
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {/* Cost Per Creator Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader title="Cost Per Creator" sx={{ textAlign: 'center', backgroundColor: '#646AFF', color: '#FFF' }} />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={costPerCreatorData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="price" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Views by Day Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader title="Total Views by Day" sx={{ textAlign: 'center', backgroundColor: '#646AFF', color: '#FFF' }} />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={viewsByDayData}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="views" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Reach Pie Chart */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardHeader title="Total Reach by Creator" sx={{ textAlign: 'center', backgroundColor: '#646AFF', color: '#FFF' }} />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={reachData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                  >
                    {reachData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignReportingPanel;