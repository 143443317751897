import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Link,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Container,
  AppBar,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import client from "../../../../API";
import CreatorCampaignConversation from "./conversation";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import CreatorTimeline from "./creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";
import { TimelineStatus } from "../../../../Utils/constants";
import PageTitle from "../../../../Components/PageTitle";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`creator-tabpanel-${index}`}
      aria-labelledby={`creator-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const isValidURL = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const CreatorSpecificCampaign = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const editedEvents = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { creatorToken } = useCreatorAuth();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  const [liveLink, setLiveLink] = useState("");
  const [boostCode, setBoostCode] = useState("");

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        let eventData = { events: [] };

        try {
          eventData = await client.campaigns.timeline_by_creator(
            campaignId,
            creatorToken.creator_user.username
          );
        } catch (err) {
          if (err.response && err.response.status === 404) {
            console.warn(
              "No timeline found for this campaign. Proceeding without it."
            );
          } else {
            throw err;
          }
        }

        editedEvents.current = false;
        setCampaign(data);
        setTimelineEvents(
          eventData.events.map((event) => ({
            ...event,
            projected_date: new Date(event.projected_date),
            ogStatus: event.status,
          }))
        );
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching campaign data:", err);
      }
    };
    fetchCampaignData();
  }, [campaignId, creatorToken.creator_user.username]);

  const OnEditTimelineField = (targetIndex, target, value) => {
    editedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        } else return event;
      })
    );
  };

  const { mutate: updateTimeline, isLoading: isUpdatingTimeline } = useMutation(
    ({ input }) =>
      client.creatorConnect.campaignUpdateTimeline(
        campaignId,
        creatorToken.creator_user.username,
        input
      ),
    {
      onSuccess: () => {
        openAlert("Success", "Timeline Updated", closeDialog, null, "Ok", null);
      },
      onError: (error) => {
        const errorMsg =
          error.response && error.response.data
            ? error.response.data.error
            : error.message;
        openAlert(
          "Error",
          `Error when updating timeline: ${errorMsg}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      },
    }
  );

  const onSaveChanges = () => {
    if (editedEvents.current) {
      const foundSetComplete = timelineEvents.some(
        (event) =>
          event.ogStatus !== event.status && event.status === TimelineStatus[2]
      );
      if (foundSetComplete) {
        openAlert(
          "Completed Objective",
          "You're setting an objective as complete. This will notify the manager of this campaign, and won't allow you to do any further updates to this objective. Continue?",
          () => {
            closeDialog();
            updateTimeline({ input: timelineEvents });
          },
          closeDialog,
          "Continue",
          "Cancel"
        );
      } else updateTimeline({ input: timelineEvents });
    }
  };

  const creator = useMemo(() => {
    if (!campaign) {
      return undefined;
    }
    const creators = campaign.creators;
    return creators.find(
      (creator) => creator.id === creatorToken.creator_user.username
    );
  }, [campaign, creatorToken.creator_user.username]);

  useEffect(() => {
    if (creator) {
      setLiveLink(creator.liveLink || "");
      setBoostCode(creator.boostCode || "");
    }
  }, [creator]);

  const canEdit =
    campaign?.campaign_status === "Launched" &&
    creator?.status !== undefined &&
    creator.status !== "Declined" &&
    creator.status !== "Dropped" &&
    creator.status !== "Pitched";

  const handleLiveLinkChange = (event) => {
    setLiveLink(event.target.value);
  };

  const handleBoostCodeChange = (event) => {
    setBoostCode(event.target.value);
  };

  // Mutation for updating fields
  const { mutate: updateFields, isLoading: isUpdatingFields } = useMutation(
    (input) =>
      client.creatorConnect.updateCreatorFields(campaignId, input),
    {
      onSuccess: () => {
        openAlert("Success", "Fields updated.", closeDialog, null, "Ok", null);
      },
      onError: (error) => {
        const errorMsg =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : error.message;
        openAlert(
          "Error",
          `Error when updating fields: ${errorMsg}`,
          closeDialog,
          null,
          "Ok",
          null
        );
      },
    }
  );

  const handleSaveLiveLinkAndBoostCode = () => {
    updateFields({ liveLink, boostCode });
  };

  const handleAssetsSent = () => {
    updateFields({ assetsSent: true });
  };

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Container maxWidth="xl">
        <PageTitle title={`Campaign - ${campaign.name} - Blitz`} />

        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="creator campaign tabs"
          >
            <Tab label="Overview" />
            <Tab label="To Do List" />
            <Tab label="Chat with Brand" />
          </Tabs>
        </AppBar>

        <TabPanel value={tabIndex} index={0}>
          <StyledPaper elevation={2}>
            <Typography variant="h4" gutterBottom>
              {campaign.name}
            </Typography>
            <Typography variant="body1">
              <strong>Ideal Due Date:</strong>{" "}
              {formatIdealDueDate(campaign.ideal_duedate)}
            </Typography>
            <Typography variant="body1">
              <strong>Brief:</strong> {campaign.brief}
            </Typography>
            <Typography variant="body1">
              <strong>Status:</strong> {campaign.campaign_status}
            </Typography>
          </StyledPaper>

          {creator && (
            <StyledPaper elevation={2}>
              <Typography variant="h6" gutterBottom>
              <strong>Rate:</strong>{" "}
                    {creator.agencyRate
                      ? `$${creator.agencyRate}`
                      : creator.price
                      ? `$${creator.price}`
                      : "N/A"}              
              </Typography>
              <Grid container spacing={2}>
                
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={!!creator.promotionPlatform} disabled />
                    }
                    label={`Platform: ${
                      creator?.promotionPlatform || "Not specified"
                    }`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={!!creator.promotionType} disabled />
                    }
                    label={`Promotion Type: ${
                      creator?.promotionType || "Not specified"
                    }`}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <Typography variant="body1" gutterBottom>
                <strong>Creator Brief:</strong>
              </Typography>
              {isValidURL(creator.creatorBrief) ? (
                <Link
                  href={creator.creatorBrief}
                  target="_blank"
                  rel="noopener"
                  variant="body1"
                >
                  Open Creator Brief
                </Link>
              ) : (
                <Typography variant="body1">
                  {creator?.creatorBrief || ""}
                </Typography>
              )}

              <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                <strong>Posting Instructions:</strong>
              </Typography>
              <Typography variant="body1">
                {creator?.postingInstructions || ""}
              </Typography>

              {/* File Upload Area */}
              {canEdit && (
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      border: "2px dashed grey",
                      padding: 4,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (creator.submissionLink) {
                        window.open(creator.submissionLink, "_blank");
                      } else {
                        alert("No submission link available.");
                      }
                    }}
                  >
                    <Typography variant="body1">
                      Click here to upload your files
                    </Typography>
                  </Box>
                  {!creator.assetsSent && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleAssetsSent}
                      sx={{ mt: 2 }}
                      disabled={isUpdatingFields}
                    >
                      Mark Files as Sent
                    </Button>
                  )}
                  {creator.assetsSent && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      Assets have been marked as sent.
                    </Typography>
                  )}
                </Box>
              )}

              {/* Accordion for Live Link and Boost Code */}
              <Accordion sx={{ mt: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="live-link-content"
                  id="live-link-header"
                >
                  <Typography variant="body1">
                    <strong>Live Link and Boost Code</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" gutterBottom>
                    <strong>
                      {canEdit ? "Insert live link here" : "Live Link:"}
                    </strong>
                  </Typography>
                  <TextField
                    fullWidth
                    value={liveLink}
                    onChange={handleLiveLinkChange}
                    disabled={isUpdatingFields || !canEdit}
                    placeholder="Enter the live link here"
                  />

                  {liveLink && (
                    <>
                      <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                        <strong>
                          {canEdit ? "Insert Boost Code here" : "Boost Code:"}
                        </strong>
                      </Typography>
                      <TextField
                        fullWidth
                        value={boostCode}
                        onChange={handleBoostCodeChange}
                        disabled={isUpdatingFields || !canEdit}
                        placeholder="Enter the boost code here"
                      />
                    </>
                  )}

                  {canEdit && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveLiveLinkAndBoostCode}
                      disabled={isUpdatingFields}
                      sx={{ mt: 2 }}
                    >
                      {isUpdatingFields ? "Saving..." : "Save Changes"}
                    </Button>
                  )}
                </AccordionDetails>
              </Accordion>
            </StyledPaper>
          )}
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <StyledPaper elevation={2}>
            <CreatorTimeline
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onSaveChanges={onSaveChanges}
              isSavingChanges={isUpdatingTimeline}
            />
          </StyledPaper>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <StyledPaper elevation={2}>
            <Typography variant="h6" gutterBottom>
              Chat with Brand
            </Typography>
            <CreatorCampaignConversation campaignId={campaign.id} />
          </StyledPaper>
        </TabPanel>
      </Container>
      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default CreatorSpecificCampaign;
