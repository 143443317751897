import { Box, IconButton, TextField } from '@mui/material';
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import React from 'react'
import MuiDateField from '../../../../Components/MuiDateField';


function formatDateToYYYYMMDD(dateTime) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(
        dateTime
    );
    return formattedDate.replace(/\//g, '-');
}

function PickerToLocal(dateObject) {
    const localDate = new Date(dateObject+"T00:00");
    return localDate;
}

export default function CampaignTimelineEntry({ event, onEditField, onDelete }) {

    const todayDate = new Date();

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginBottom: 1,
            }}
        >
            <TextField
                label="Objective"
                value={event.objective}
                onChange={(e) => onEditField('objective', e.target.value)}
                sx={{ marginRight: 1, flex: '1 1 200px', marginBottom: 1 }}
            />
            <TextField
                label="Notes"
                value={event.notes}
                onChange={(e) => onEditField('notes', e.target.value)}
                sx={{ marginRight: 1, flex: '1 1 200px', marginBottom: 1 }}
            />
            <TextField
                label="Manager Notes"
                value={event.manager_notes}
                onChange={(e) => onEditField('manager_notes', e.target.value)}
                sx={{ marginRight: 1, flex: '1 1 200px', marginBottom: 1 }}
            />
            <MuiDateField
                label="Projected Date"
                type="date"
                value={formatDateToYYYYMMDD(event.projected_date)}
                inputProps={{
                    min:formatDateToYYYYMMDD(todayDate)
                }}
                onChange={(e) =>
                    onEditField('projected_date', PickerToLocal(e.target.value))
                }
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{ marginRight: 1, flex: '1 1 200px', marginBottom: 1 }}
            />
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </Box>
    )
}
