import React from 'react';
import { Box, Typography, Container, Toolbar, AppBar, Tabs, Tab } from '@mui/material';
import HomeHeader from '../../Components/HomeHeader';
import { globalStyles } from '../../Utils/Styles';
import HomeFooter from '../../Components/HomeFooter';
import { StyledDivider as Divider } from '../../Components/LightDivider';
import { styled } from '@mui/material/styles'; // Import styled

const helpTopics = [
  {
    label: "Dashboard",
    video: 'https://www.youtube.com/embed/JtMUVsqadUI?si=fMtBoNZKFuwe6Jq2',
    text: 'View and manage your dashboard.',
  },
  {
    label: "Marketplace",
    video: 'https://www.youtube.com/embed/ck_YtUrSaTQ?si=q__CJn3jO6VCvJlf',
    text: 'Explore our creator selection and make projects from there.',
  },
  {
    label: "Campaigns",
    video: 'https://www.youtube.com/embed/vBEEWHIv3ms?si=EprBNuvQnmELE4Og',
    text: 'Manage your ongoing campaigns.',
  },
  {
    label: "Partnerships",
    video: 'https://www.youtube.com/embed/xwSKrV88u0U?si=YF92kmgSR158LHhM',
    text: 'Manage partnerships and collaborations.',
  },
  {
    label: "Blitz Pay",
    video: 'https://www.youtube.com/embed/mm1Xqrr_eng?si=D4vAtn_E9ZVDiVk7',
    text: 'Make instant payments through Blitz Pay.',
  },
 
  {
    label: "Trackers",
    video: 'https://www.youtube.com/embed/Qsr5Jv5LaaM?si=ZdUtU2yzxwW8R7Lf',
    text: 'View and manage trackers.',
  },
  {
    label: "Creator Management & Agency Feature",
    video: 'https://www.youtube.com/embed/3ErrbUj0j6Q?si=ahkKvqkXCQs2VVBn',
    text: 'View and manage creators.',
  },
  
];

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontSize: '0.875rem',
  textTransform: 'none',
}));

const HelpPage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const currentTopic = helpTopics[selectedTab];

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", width: '100%', color: '#000' }}>
      <HomeHeader />
      <Toolbar />
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          color: "#000",
          marginBlockStart: 4,
        }}
      >
        {/* Help Page Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ textAlign: "center", marginBlockEnd: 2 }}>
            Help Center
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 4 }}>
            Pay - Create - Accelerate
          </Typography>
        </Box>

        <Divider />

        {/* Tabs */}
        <AppBar
          position="static"
          sx={{
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: 'none',
            mt: 4,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="standard"
            indicatorColor="primary"
            textColor="inherit"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {helpTopics.map((topic, index) => (
              <StyledTab key={index} label={topic.label} />
            ))}
          </Tabs>
        </AppBar>

        {/* Content */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            {currentTopic.label} Help
          </Typography>
          <Box mb={2}>
            {currentTopic.video ? (
              <iframe
                width="100%"
                height="400"
                src={currentTopic.video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={`${currentTopic.label} Help Video`}
              ></iframe>
            ) : (
              <Typography>No video available for this section.</Typography>
            )}
          </Box>
          <Typography variant="body1">
            {currentTopic.text || 'No explanation available for this section.'}
          </Typography>
        </Box>
      </Container>
      <HomeFooter />
    </Box>
  );
};

export default HelpPage;
