import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import client from '../../../../../API';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer, Button, Typography } from '@mui/material';

const PartnerKpis = ({ setPartnerKpi }) => {
    const [partnerData, setPartnerData] = useState([]);

    const { mutate: fetchData } = useMutation(client.creators.listKPI, {
        onSuccess: (data) => {
            const totalPartners = Object.values(data).reduce((acc, item) => acc + item.total_partner, 0);
            setPartnerKpi({ progress: totalPartners });
            setPartnerData(Object.entries(data));
        },
        onError: (error) => {
            console.error('Failed to fetch data:', error);
        },
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleExport = async (row) => {
        try {
            const response = await fetch('http://blitz-backend-nine.vercel.app/export-to-sheets', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(row),
            });

            const result = await response.json();
            if (response.ok) {
                alert('Data exported successfully');
            } else {
                alert(`Failed to export data: ${result.message}`);
            }
        } catch (error) {
            console.error('Failed to export data:', error);
            alert('Failed to export data');
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Week Starting</TableCell>
                        <TableCell>Creators</TableCell>
                        <TableCell>Emails</TableCell>
                        <TableCell>Total Partners Added</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partnerData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography variant="body1">No data available</Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        partnerData.map(([date, details]) => (
                            <TableRow key={date}>
                                <TableCell>{date}</TableCell>
                                <TableCell>
                                    {details.creators ? details.creators.join(', ') : 'N/A'}
                                </TableCell>
                                <TableCell>{details.emails ? details.emails.join(', ') : 'N/A'}</TableCell>
                                <TableCell>{details.total_partner || 0}</TableCell>
                               
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PartnerKpis;
