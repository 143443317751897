import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Tabs,
  Tab,
  Pagination,
  Backdrop,
  styled,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import client from '../../../../API';
import { getHeadersFromKeys } from '../../../../Utils/constants';
import { globalStyles } from '../../../../Utils/Styles';

const ShinyButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)',
  backgroundSize: '400% 400%',
  color: '#FFF',
  fontWeight: '700',
  height: '46px',
  animation: 'rainbow 6s ease infinite',
  '@keyframes rainbow': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));

const rowsPerPage = 12;

const CreatorCastingCalls = () => {
  const [currentFilter, setCurrentFilter] = useState('active');
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const { data: castingCalls, isFetching: loadingCalls, refetch: fetchCastingCalls, error: errorCalls } = useQuery({
    queryKey: ['creator', 'castingCalls', 'list', { page, currentFilter }],
    queryFn: () => client.castingCalls.creatorFetch({ page, rows: rowsPerPage, status: currentFilter }),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (castingCalls) {
      setRowCount(castingCalls.total);
    }
  }, [castingCalls]);


  // Apply for a casting call
  const { mutate: applyToCastingCall, isLoading: loadingApply } = useMutation((castingCallId) =>
    client.castingCalls.creatorApply(castingCallId), {
    onSuccess: () => {
      alert('Application successful');
      fetchCastingCalls();
    },
    onError: (error) => {
      console.error('Error applying to casting call:', error);
      alert('An error occurred while applying');
    },
  }
  );

  useEffect(() => {
    if (errorCalls) {
      alert(`Error while getting available casting calls: ${errorCalls?.response?.data?.error || errorCalls.message}`);
      console.log(errorCalls);
    }
  }, [errorCalls])

  const handleApply = (castingCallId) => {
    applyToCastingCall(castingCallId); // Pass just the ID
  };


  return (
    <Box sx={{ ...globalStyles.minHeightMinusTop, width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={loadingCalls||loadingApply}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={2} square>
        <Tabs value={currentFilter} onChange={(e, val) => setCurrentFilter(val)} centered>
          <Tab label="Active" value="active" />
          <Tab label="Applied" value="applied" />
          <Tab label="Completed" value="completed" />
        </Tabs>
      </Paper>
      <Typography variant="h6" sx={{ p: 2 }}>
        Available Casting Calls
      </Typography>

      {castingCalls !== undefined && castingCalls.casting_calls.length > 0 ?
        (<><Grid container spacing={2} sx={{ padding: 2, flex: 1, alignContent:'flex-start' }}>
          {castingCalls.casting_calls.map((castingCall) => (
            <Grid item xs={12} sm={6} md={4} key={castingCall.id}>
              <Card sx={{position:'relative'}}>
                {castingCall.invited_creators.length > 0 &&
                  <ShinyButton sx={{position:'absolute', right:'-5rem', top:'-4rem', rotate:'45deg', width:'10rem', height:'8rem', paddingBlockStart:'6rem', margin:0, boxShadow: 2}}>
                    Invited!
                  </ShinyButton>
                }
                <CardHeader
                  title={`Casting Call #${castingCall.id} (${castingCall.status})`}
                  subheader={`Budget per creator: $${castingCall.budget_min} - $${castingCall.budget_max}`}
                />
                <CardContent>
                  <Typography variant="body2">
                    Platforms: {castingCall.platforms?.join(', ') || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    Promotion Types: {!!(castingCall?.promotion_types) ? getHeadersFromKeys(castingCall.promotion_types).join(", ") : "N/A"}
                  </Typography>
                  <Typography variant="body2">Brief: {castingCall.brief || 'No details provided.'}</Typography>
                </CardContent>
                <CardActions>
                  {currentFilter == 'active' && <Button variant="contained" color="primary" onClick={() => handleApply(castingCall.id)}>
                    Apply
                  </Button>}
                </CardActions>
              </Card>
            </Grid>
          ))}</Grid>
          <Paper sx={{ paddingInline: 4, paddingBlock: 1, marginInline: 'auto' }} elevation={2}>
            <Pagination
              count={rowCount === 0 ? 0 : Math.ceil(rowCount / rowsPerPage)}
              page={page + 1}
              onChange={(event, value) => setPage(value - 1)}>
            </Pagination>
          </Paper>
        </>
        ) : (
          <>{castingCalls !== undefined && <Typography sx={{ marginInline: 2 }}>No casting calls available under the selected filter.</Typography>}</>
        )}

    </Box>
  );
};

export default CreatorCastingCalls;
