import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import SelectPopup from '../../../../Components/SelectPopup';
import FilterPopup from '../../../../Components/FilterPopup';
import { indexToPromoFilter } from '../../../../Utils/constants';

export default function PromoPlatFilter({ value, onChangeValue }) {
    const [internalSelect, setInternalSelect] = useState(0);

    const filterValue = indexToPromoFilter(value);

    const onOpen = () => {
        setInternalSelect(value);
    }

    return (
        <SelectPopup value={`${filterValue.platform} - ${filterValue.promo}`} label='Promotion Type' inputStyle={{ width: '13rem' }} onOpenPopup={onOpen} >
            <FilterPopup boxStyle={{ padding: 2, maxWidth:'80vw' }} onApply={() => onChangeValue(internalSelect)}>
                <Box sx={{height: 'max-content'}}>
                    <FormControl>
                        <RadioGroup
                            defaultValue={0}
                            value={internalSelect}
                            onChange={(event) => setInternalSelect(parseInt(event.target.value))}
                        >
                            <FormLabel focused={false}>TikTok</FormLabel>
                            <FormControlLabel value={0} control={<Radio />} label="Sound Promotion" />
                            <FormControlLabel value={1} control={<Radio />} label="Brand Promotion" />

                            <FormLabel focused={false}>Instagram</FormLabel>
                            <FormControlLabel value={2} control={<Radio />} label="Sound Promotion (Reel)" />
                            <FormControlLabel value={3} control={<Radio />} label="Brand Promotion (Reel)" />
                            <FormControlLabel value={4} control={<Radio />} label="Feed Promotion" />
                            <FormControlLabel value={5} control={<Radio />} label="Story Promotion" />

                            <FormLabel focused={false}>Youtube</FormLabel>
                            <FormControlLabel value={6} control={<Radio />} label="Integrated Promotion" />
                            <FormControlLabel value={7} control={<Radio />} label="Shorts" />
                            <FormControlLabel value={8} control={<Radio />} label="Dedicated Video" />

                            {/* New Platforms */}
                            <FormLabel focused={false}>Podcasts</FormLabel>
                            <FormControlLabel value={9} control={<Radio />} label="Pre-roll ad" />
                            <FormControlLabel value={10} control={<Radio />} label="Mid-roll ad" />
                            <FormControlLabel value={11} control={<Radio />} label="Post-roll ad" />
                            <FormControlLabel value={12} control={<Radio />} label="Full episode Sponsorship" />

                            <FormLabel focused={false}>Twitch</FormLabel>
                            <FormControlLabel value={13} control={<Radio />} label="Product Placement" />

                            <FormLabel focused={false}>Kick</FormLabel>
                            <FormControlLabel value={14} control={<Radio />} label="Product Placement" />

                            <FormLabel focused={false}>X (Twitter)</FormLabel>
                            <FormControlLabel value={15} control={<Radio />} label="Feed Post" />
                            <FormControlLabel value={16} control={<Radio />} label="Repost" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </FilterPopup>
        </SelectPopup>
    );
}
