import React from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Link,
  useTheme,
} from "@mui/material";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo

const CampaignsContainers = ({ creators, handleInviteClick }) => {
  const theme = useTheme();

  // Function to calculate total reach (aggregated following counts)
  const calculateTotalReach = (creator) => {
    const youtube = parseInt(creator.youtube) || 0;
    const instagram = parseInt(creator.instagram) || 0;
    const tiktok = parseInt(creator.tiktok) || 0;
    return youtube + instagram + tiktok;
  };

  return (
    <Grid container spacing={2}>
      {creators.map((creator, index) => {
        const totalReach = calculateTotalReach(creator);

        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              elevation={3}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.05)' },
                padding: theme.spacing(2),
              }}
            >
              <CardMedia
                component="img"
                height="350"
                image={creator.pfphref || profilePhoto}
                alt={creator.creator}
                sx={{ objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1, padding: theme.spacing(2, 0) }}>
                <Typography variant="h6" gutterBottom>
                  <Link
                    href={`https://www.useblitz.co/creators/${creator.creator}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    @{creator.creator}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Total Reach: {totalReach.toLocaleString()}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: theme.spacing(1),
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  href={`https://www.useblitz.co/creators/${creator.creator}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ flex: 1 }}
                >
                  View Media Kit
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleInviteClick(creator.creator)}
                  sx={{ flex: 1 }}
                >
                  Invite to Partnership
                </Button>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CampaignsContainers;
