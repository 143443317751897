import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'


export default function AgeCard({ data, fill }) {


    return (
        <Card variant='outlined'>
            <CardHeader subheader='Audience Age Groups'></CardHeader>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data || []} layout="vertical">
                    <YAxis dataKey="name" type="category" />
                    <XAxis dataKey="percent" type="number" unit="%" />
                    <Tooltip labelStyle={{ color: '#000' }} />
                    <Bar layout="vertical" dataKey="percent" name="Percentage" unit="%">
                        {(data || []).map((entry, index) => (
                            <Cell key={index} fill={fill[index]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    )
}
