import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import { StyledDivider as Divider } from "../../../Components/LightDivider";

const StreamlineCampaigns = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            Streamline Your Influencer Campaigns with AI-Powered Blitz
          </Typography>

          {/* Introduction */}
          <Typography variant="body1" paragraph>
            Welcome to the future of influencer marketing! Blitz leverages the power of AI to
            revolutionize how brands and creators work together. From campaign management to
            payments, Blitz simplifies every step, saving time and money.
          </Typography>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <img
              src="https://storage.googleapis.com/blitzccmc.appspot.com/1/New%20Project%282%29.jpg"
              alt="AI-powered campaigns"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Box>

          <Divider />

          {/* AI Manager */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              The Power of an All-in-One AI Manager
            </Typography>
            <Typography variant="body1" paragraph>
              Forget traditional human managers with high fees. Blitz’s AI manager takes care
              of everything—from finding creators to negotiating deals. With AI-powered tools,
              brands and creators can enjoy unmatched efficiency and cost savings.
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Automated deal negotiations and campaign management</li>
              <li>Real-time insights and data-driven decisions</li>
              <li>Significant cost savings compared to traditional management fees</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/IMG_3610.jpg"
                alt="AI manager efficiency"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* BlitzPay */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              How Blitz Revolutionizes Creator Payments
            </Typography>
            <Typography variant="body1" paragraph>
              BlitzPay makes delayed payments a thing of the past. With our automated payout
              system, creators worldwide can access their earnings within 24 hours. This
              ensures financial flexibility and trust.
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Global payment accessibility</li>
              <li>Fast, reliable, and secure payouts</li>
              <li>Transparent invoicing for peace of mind</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/73A08416-6CDA-4615-82FD-89D49C6F42E9.jpg"
                alt="BlitzPay system"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* Global Marketplace */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Access Over 500 Top Creators with Blitz’s Global Marketplace
            </Typography>
            <Typography variant="body1" paragraph>
              Discover a curated selection of over 500 vetted creators from around the globe.
              Blitz makes it easy to find the perfect match for your brand and launch campaigns
              with minimal effort.
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Search creators by category, region, or engagement</li>
              <li>Connect with top talent to amplify your campaigns</li>
              <li>Easy-to-use interface for seamless collaboration</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/image%20%282%29.jpg"
                alt="Global creator marketplace"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* Call to Action */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Transform Your Influencer Campaigns Today
            </Typography>
            <Typography variant="body1" paragraph>
              It’s time to say goodbye to inefficiencies and hello to AI-powered influencer
              marketing. Whether you’re a brand or a creator, Blitz is your ultimate partner in
              success. Sign up now to unlock the future of campaigns.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/250e8855-1ce8-429c-8370-74afff7cf398.jfif"
                alt="Sign up for Blitz"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default StreamlineCampaigns;
