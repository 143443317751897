// CreatorHeader.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  useMediaQuery,
  styled,
  ThemeProvider,
  CssBaseline,
  Typography,
  Divider,
  Tooltip,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import blitzLogo from './globalAssets/logo_placeholder.png';
import routes from "../Config/routes";
import { lightTheme } from "../Utils/baseTheme";
import ListItemLink from './ListItemLink';
import { MonetizationOnOutlined } from "@mui/icons-material";
import API from "../API"; // Adjust the import path as needed
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../Hooks/creator-use-auth';

// Define the width of the Drawer when open
const drawerWidth = 240;

// Styled IconButton for consistent styling
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

// Define the navigation links, excluding "Edit Account"
const links = [
  { text: 'Dashboard', route: routes.creatorConnectStart, icon: <DashboardIcon /> },
  { text: 'Money', route: routes.creatorConnectPayoutsInvoicing, icon: <AttachMoneyIcon /> },
  { text: 'Deals', route: routes.creatorCollabs, icon: <WorkIcon /> },
  { text: 'Pitch', route: routes.creatorConnectPitch, icon: <SendIcon /> },
  { text: 'Casting Calls', route: routes.creatorCastingCalls, icon: <CampaignIcon /> },
  { text: 'Inbound', route: routes.creatorConnectDealsEmails, icon: <EmailIcon /> },

];

const CreatorHeader = ({ children }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { creatorToken } = useCreatorAuth();

  // Toggle the Drawer open state
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle navigation and close the Drawer
  const handleNavigation = (route) => {
    navigate(route);
    setDrawerOpen(false);
  };
  
  // Fetch creator's details from the backend
  const { data: creatorData, isLoading, error } = useQuery(
    ['creatorDetails', creatorToken.creator_user.username],
    () => API.creators.fetchDetails(creatorToken.creator_user.username), // Adjusted to use username
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );
  // Handle settings navigation
  const handleSettings = () => {
    navigate(routes.creatorConnectEdit); // Adjust the route as necessary
  };

  // Handle logout (Assuming there's a logout function in your API)
  const handleLogout = () => {
    API.auth.logout(); // Implement this function in your API module
    navigate(routes.login); // Adjust the route as necessary
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {/* AppBar Header */}
      <AppBar position="fixed" id="CreatorHeader" sx={{ bgcolor: 'primary.main' }}>
        <Toolbar>
          {/* Menu Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Blitz Logo */}
          <IconButton
            color="inherit"
            aria-label="logo"
            onClick={() => navigate(routes.creatorConnectStart)}
            sx={{ p: 0, mr: 2 }}
          >
            <img
              src={blitzLogo}
              alt="Blitz Logo"
              style={{ height: "40px" }}
            />
          </IconButton>

          {/* Spacer to push the following items to the right */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Greeting and Settings */}
          {isLoading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : error ? (
            <Typography variant="body1" color="error">
              Error loading user info
            </Typography>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }}>
               Welcome, {creatorToken.creator_user.username}!
              </Typography>
              <Tooltip title="Account Settings">
                <IconButton onClick={handleSettings} color="inherit">
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {/* Placeholder Toolbar to offset the fixed AppBar */}
      <Toolbar />

      {/* Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          {/* Top Section: Logo and Navigation Links */}
          <Box>
            {/* Logo in Drawer (optional) */}
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
              <img src={blitzLogo} alt="Blitz Logo" style={{ height: '40px' }} />
            </Box>

            {/* Navigation Links */}
            <List>
              {links.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton onClick={() => handleNavigation(item.route)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Bottom Section: User Info and Help */}
          <Box>
            {/* Divider */}
            <Divider />

            {/* User Info */}
            <Box sx={{ padding: '16px' }}>
              {isLoading ? (
                <Typography variant="body1">Loading...</Typography>
              ) : error ? (
                <Typography variant="body1" color="error">
                  Error loading user info
                </Typography>
              ) : (
                <>
                 <Typography variant="h6">
                 {creatorToken.creator_user.username}!
                 </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <Tooltip title="Account Settings">
                      <IconButton onClick={handleSettings} color="inherit">
                        <SettingsOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    
                  </Box>
                </>
              )}
            </Box>

            {/* Help Link */}
            <Box sx={{ padding: '16px' }}>
              <ListItem button onClick={() => navigate(routes.help)}>
                <ListItemIcon>
                  <LiveHelpOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Help" />
              </ListItem>
            </Box>
          </Box>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        id='TopPageContainer'
        sx={{
          minWidth: '100%',
          padding: '16px', // Adjust as needed
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default CreatorHeader;
