import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import { StyledDivider as Divider } from "../../../Components/LightDivider";

const HottestNewCreatorSoftware = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            The Hottest New Free Creator Software
          </Typography>

          {/* Introduction */}
          <Typography variant="body1" paragraph>
            Are you tired of juggling multiple tools to manage your influencer campaigns? Say
            hello to Blitz—the revolutionary free software that simplifies your workflow. From
            sourcing creators to managing campaigns and processing payments, Blitz does it all
            in one place.
          </Typography>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <img
              src="https://storage.googleapis.com/blitzccmc.appspot.com/1/Screenshot%202024-09-11%20121328.png"
              alt="Blitz introduction"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Box>

          <Divider />

          {/* Key Features */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Why Choose Blitz?
            </Typography>
            <Typography variant="body1" paragraph>
              Blitz is packed with powerful features designed to make influencer management
              easier than ever:
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Access to over 500 vetted creators worldwide</li>
              <li>AI-powered campaign management that saves time and money</li>
              <li>Automated payments through BlitzPay for fast, reliable transactions</li>
              <li>Real-time insights to optimize campaign performance</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/New%20Project%282%29.jpg"
                alt="Blitz key features"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* For Brands */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              For Brands: Elevate Your Campaigns
            </Typography>
            <Typography variant="body1" paragraph>
              Blitz offers a seamless way to discover top creators, automate outreach, and
              monitor every aspect of your campaigns. No more manual spreadsheets or costly
              human managers—Blitz’s AI manager has you covered.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/bdcc6562-ee65-4f49-a5af-f5ce28674732.jfif"
                alt="Brands using Blitz"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* For Creators */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              For Creators: Unlock Your Potential
            </Typography>
            <Typography variant="body1" paragraph>
              As a creator, Blitz helps you connect with leading brands, manage your deals, and
              grow your revenue—all at no cost. Plus, BlitzPay ensures you get paid faster than
              ever before.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/IMG_131748_0.jpeg"
                alt="Creators using Blitz"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* How to Get Started */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              How to Get Started
            </Typography>
            <Typography variant="body1" paragraph>
              Ready to transform your influencer marketing? Getting started with Blitz is easy:
            </Typography>
            <Typography variant="body1" component="ol" sx={{ pl: 2 }}>
              <li>Create a free account on our platform</li>
              <li>Explore our global marketplace of creators</li>
              <li>Launch your first AI-powered campaign</li>
              <li>Track your results in real-time and enjoy hassle-free payments</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://via.placeholder.com/800x400"
                alt="Getting started with Blitz"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* Call to Action */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Join the Blitz Revolution
            </Typography>
            <Typography variant="body1" paragraph>
              Don’t miss out on the hottest new free software for creators and brands. Sign up
              today and experience the power of Blitz firsthand. Your journey to streamlined
              influencer campaigns starts now.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://via.placeholder.com/800x400"
                alt="Call to action"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default HottestNewCreatorSoftware;
