import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Link,
} from "@mui/material";
import client from "../../../API"; // Ensure this is the correct path
import CreatorCampaignConversation from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/conversation";
import { useCreatorAuth } from "../../../Hooks/creator-use-auth";
import AssetsTab from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/campaignAssets";
import CreatorTimeline from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { TimelineStatus } from "../../../Utils/constants";
import useIsDesktop from "../../../Hooks/useIsDesktop";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const AgencyCampaignDialog = ({
  openDialog,
  handleCloseDialog,
  campaignId,
  username
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const editedEvents = useRef(false)
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useIsDesktop();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        if (openDialog) {
          editedEvents.current = false;
          console.log(data);
          setCampaign(data);
          setIsLoading(false);
        }
      } catch (err) {
        handleCloseDialog();
      }
    };
    if (openDialog) {
      setIsLoading(true);
      fetchCampaignData();
    }
    else
      setCampaign(null);

  }, [openDialog]);

  const creator = useMemo(() => {
    if (!campaign) {
      return undefined;
    }
    const creators = campaign.creators;
    return creators.find(
      (creator) => creator.id === username
    );
  }, [campaign]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={isLoading ? "sm" : "xl"}
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {isLoading && (
          <DialogContent style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <CircularProgress />
          </DialogContent>
        )}

        {!isLoading && !!campaign && (
          <>
            <DialogTitle sx={{ padding: 0, overflowY: 'clip' }}>
              <Paper elevation={2} square>
                <Grid container alignItems={'end'} spacing={2} padding={0} margin={0} width={'100%'}>
                  <Grid item xs={14} md={3} padding={2}>
                    <Typography variant="h6">
                      Campaign Name: {campaign.name}
                    </Typography>
                    <Typography variant="h6">
                      Ideal Due Date: {formatIdealDueDate(campaign.ideal_duedate)}
                    </Typography>
                    <Typography variant="h6">
                      Brief: <Typography component={'span'} variant="body1">{campaign.brief}</Typography>
                    </Typography>
                    <Typography variant="h6">
                      Status: {campaign.campaign_status}
                    </Typography>

                    <Typography>
                      {false && (
                        <Link
                          href={"dialogContent.drive_link"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Campaign Assets
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} padding={0} margin={0}>
                    <Paper elevation={4}>
                      <Tabs
                        value={tabIndex}
                        onChange={handleChangeTab}
                        aria-label="campaign details tabs"
                      >
                        <Tab label="Overview" />
                        <Tab label="Assets" />
                      </Tabs>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </DialogTitle>
            <DialogContent dividers={true}>
              <TabPanel value={tabIndex} index={0}>
                <>
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={2}
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      {creator && (
                        <>
                          <Typography variant="h6">
                            Creator Brief: <Typography variant="body1">{creator.creatorBrief || "N/A"}</Typography>
                          </Typography>
                          <Typography variant="h6">
                            Rate: {creator.agencyRate ? `$${creator.agencyRate}` : creator.price ? `$${creator.price}` : "N/A"}
                          </Typography>

                        </>
                      )}
                      <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
                        Campaign Manager:
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Name:</strong> {campaign.campaign_manager.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Email:</strong> {campaign.campaign_manager.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2">
                            <strong>Phone:</strong> {campaign.campaign_manager.phone_number}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*<Typography variant="h6">Timeline</Typography>
                  <CreatorTimeline timelineEvents={timelineEvents} onEditField={OnEditTimelineField} onSaveChanges={onSaveChanges} isSavingChanges={isUpdatingTimeline}></CreatorTimeline>*/}
                </>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <AssetsTab campaignDetails={campaign} creatorEntry={creator} />
              </TabPanel>
            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="text">Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState}></AlertDialog>
    </>
  );
};

export default AgencyCampaignDialog;
