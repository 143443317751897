import { Box, Checkbox, Divider, FormControlLabel, FormGroup, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useCallback } from 'react'
import { RateField } from '../RateField';

const ratesStyle = {
    marginInlineStart: '4rem'
}

const formatNumber = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "0" : number.toString();
}

export default function CreatorPlatforms({
    platforms,
    setPlatforms,
    instagramLink, setInstagramLink,
    instagramFollowerCount, setInstagramFollowerCount,
    instagramBrandRate, setInstagramBrandRate,
    instagramSongRate, setInstagramSongRate,
    instagramFeedRate, setInstagramFeedRate,
    instagramStoryRate, setInstagramStoryRate,
    tikTokLink, setTikTokLink,
    tikTokFollowerCount, setTikTokFollowerCount,
    tikTokBrandRate, setTikTokBrandRate,
    tikTokSongRate, setTikTokSongRate,
    youtubeLink, setYoutubeLink,
    youtubeFollowerCount, setYoutubeFollowerCount,
    youtubeIntegrationRate, setYoutubeIntegrationRate,
    youtubeShortsRate, setYoutubeShortsRate,
    youtubeDedicatedRate, setYoutubeDedicatedRate,
    xLink, setXLink,
    xFollowerCount, setXFollowerCount,
    xFeedPost, setXFeedPost,
    xRepost, setXRepost,
    podcastLink, setPodcastLink,
    podcastFollowerCount, setPodcastFollowerCount,
    podcastPreroll, setPodcastPreroll,
    podcastMidroll, setPodcastMidroll,
    podcastPostroll, setPodcastPostroll,
    podcastFull, setPodcastFull,
    kickLink, setKickLink,
    kickFollowerCount, setKickFollowerCount,
    kickPlacement, setKickPlacement,
    twitchLink, setTwitchLink,
    twitchFollowerCount, setTwitchFollowerCount,
    twitchPlacement, setTwitchPlacement,
}) {

    const handlePlatformChange = useCallback((event) => {
        setPlatforms((prevPlatforms) => ({
            ...prevPlatforms,
            [event.target.name]: event.target.checked
        }));
    }, [setPlatforms]);

    const handleFollowerCountChange = useCallback((setter) => (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        const formattedValue = formatNumber(rawValue);
        setter(formattedValue);
    }, []);

    return (
        <>
            <FormGroup row>
                {Object.keys(platforms).map((platform) => (
                    <FormControlLabel
                        key={platform}
                        control={
                            <Checkbox
                                checked={platforms[platform]}
                                onChange={handlePlatformChange}
                                name={platform}
                            />
                        }
                        label={platform}
                    />
                ))}
            </FormGroup>
            <Divider></Divider>
            {platforms.Instagram && (
                <>
                    <Typography variant='subtitle'><b>Instagram</b></Typography>
                    <TextField
                        margin="dense"
                        label="Instagram Username"
                        type="text"
                        fullWidth
                        value={instagramLink}
                        onChange={(e) => setInstagramLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    instagram.com/
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{ marginInlineStart: '4rem' }}>
                        <TextField
                            margin="dense"
                            label="Instagram Follower Count"
                            type="text"
                            fullWidth
                            value={instagramFollowerCount}
                            onChange={handleFollowerCountChange(setInstagramFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="Instagram Brand Rate"
                            fullWidth
                            value={instagramBrandRate}
                            onChange={setInstagramBrandRate}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Instagram Song Rate"
                            fullWidth
                            value={instagramSongRate}
                            onChange={setInstagramSongRate}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Instagram Feed post Rate"
                            fullWidth
                            value={instagramFeedRate}
                            onChange={setInstagramFeedRate}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Instagram Story Rate"
                            fullWidth
                            value={instagramStoryRate}
                            onChange={setInstagramStoryRate}
                            variant="outlined"
                            minValue={20}
                        />
                    </Box>
                </>
            )}
            {platforms.TikTok && (
                <>
                    <Typography variant='subtitle'><b>Tiktok</b></Typography>
                    <TextField
                        margin="dense"
                        label="TikTok Username"
                        type="text"
                        fullWidth
                        value={tikTokLink}
                        onChange={(e) => setTikTokLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    tiktok.com/@
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="TikTok Follower Count"
                            type='number'
                            fullWidth
                            value={tikTokFollowerCount}
                            onChange={handleFollowerCountChange(setTikTokFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="TikTok Brand Rate"
                            fullWidth
                            value={tikTokBrandRate}
                            onChange={setTikTokBrandRate}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="TikTok Song Rate"
                            fullWidth
                            value={tikTokSongRate}
                            onChange={setTikTokSongRate}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
            {platforms.YouTube && (
                <>
                    <Typography variant='subtitle'><b>Youtube</b></Typography>
                    <TextField
                        margin="dense"
                        label="Youtube Username"
                        type="text"
                        fullWidth
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    youtube.com/
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="YouTube Follower Count"
                            type='number'
                            fullWidth
                            value={youtubeFollowerCount}
                            onChange={handleFollowerCountChange(setYoutubeFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="YouTube Brand Integration Rate"
                            fullWidth
                            value={youtubeIntegrationRate}
                            onChange={(value) => setYoutubeIntegrationRate(value)}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="YouTube Shorts Rate"
                            fullWidth
                            value={youtubeShortsRate}
                            onChange={setYoutubeShortsRate}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="YouTube Dedicated video Rate"
                            fullWidth
                            value={youtubeDedicatedRate}
                            onChange={setYoutubeDedicatedRate}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
            {platforms.X && (
                <>
                    <Typography variant='subtitle'><b>X</b></Typography>

                    <TextField
                        margin="dense"
                        label="X Username"
                        type="text"
                        fullWidth
                        value={xLink}
                        onChange={(e) => setXLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    x.com/
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="X Follower Count"
                            type='number'
                            fullWidth
                            value={xFollowerCount}
                            onChange={handleFollowerCountChange(setXFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="X Feed Post"
                            fullWidth
                            value={xFeedPost}
                            onChange={setXFeedPost}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="X Repost"
                            fullWidth
                            value={xRepost}
                            onChange={setXRepost}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
            {platforms.Podcast && (
                <>
                    <Typography variant='subtitle'><b>Podcast</b></Typography>

                    <TextField
                        margin="dense"
                        label="Podcast Link"
                        type="text"
                        fullWidth
                        value={podcastLink}
                        onChange={(e) => setPodcastLink(e.target.value)}
                        variant="outlined"
                        required
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="Podcast Follower Count"
                            type='number'
                            fullWidth
                            value={podcastFollowerCount}
                            onChange={handleFollowerCountChange(setPodcastFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="Podcast Preroll Ad rate"
                            fullWidth
                            value={podcastPreroll}
                            onChange={setPodcastPreroll}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Podcast Midroll Ad rate"
                            fullWidth
                            value={podcastMidroll}
                            onChange={setPodcastMidroll}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Podcast Postroll Ad rate"
                            fullWidth
                            value={podcastPostroll}
                            onChange={setPodcastPostroll}
                            variant="outlined"
                            minValue={100}
                        />
                        <RateField
                            margin="dense"
                            label="Podcast Full Episode Sponsorship rate"
                            fullWidth
                            value={podcastFull}
                            onChange={setPodcastFull}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
            {platforms.Kick && (
                <>
                    <Typography variant='subtitle'><b>Kick</b></Typography>
                    <TextField
                        margin="dense"
                        label="Kick Link"
                        type="text"
                        fullWidth
                        value={kickLink}
                        onChange={(e) => setKickLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    kick.com/
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="Kick Follower Count"
                            type='number'
                            fullWidth
                            value={kickFollowerCount}
                            onChange={handleFollowerCountChange(setKickFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="Kick Product Placement rate"
                            fullWidth
                            value={kickPlacement}
                            onChange={setKickPlacement}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
            {platforms.Twitch && (
                <>
                    <Typography variant='subtitle'><b>Twitch</b></Typography>
                    <TextField
                        margin="dense"
                        label="Twitch Link"
                        type="text"
                        fullWidth
                        value={twitchLink}
                        onChange={(e) => setTwitchLink(e.target.value)}
                        variant="outlined"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    twitch.tv/
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={ratesStyle}>
                        <TextField
                            margin="dense"
                            label="Twitch Follower Count"
                            type='number'
                            fullWidth
                            value={twitchFollowerCount}
                            onChange={handleFollowerCountChange(setTwitchFollowerCount)}
                            variant="outlined"
                            required
                        />
                        <RateField
                            margin="dense"
                            label="Twitch Product Placement rate"
                            fullWidth
                            value={twitchPlacement}
                            onChange={setTwitchPlacement}
                            variant="outlined"
                            minValue={100}
                        />
                    </Box>
                </>
            )}
        </>
    )
}
