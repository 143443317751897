import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import client from "../../../../API"; // Ensure this is the correct path
import { useMutation, useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  AppBar,
  Toolbar,
  Button,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import BlitzHeader from "../../../../Components/BlitzHeader.js";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignReportingPanel from "./campaignTabs/campaignReporting.js";
import CampaignDetailsPanel from "./campaignTabs/campaignDetails.js";
import CampaignProjectionsPanel from "./campaignTabs/campaignProjections.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import PageTitle from "../../../../Components/PageTitle.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import { parseLocalFloat } from "../../../../Utils/constants.js";
import HomeHeader from "../../../../Components/HomeHeader.js";

const CampaignDetailsPage = () => {
  const { campaignId } = useParams();
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewMode, setViewMode] = useState("pretty");
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const isDesktop = useIsDesktop();
  const hasShownMessage = useRef(false);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeView = (event, newValue) => {
    if (newValue === null) newValue = viewMode == 'pretty' ? 'list' : 'pretty';
    setViewMode(newValue);
  };

  const { data, error, isLoading, refetch } = useQuery(
    ['campaignDetails', campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      onSuccess: (data) => {
        try {
          const creators = data.creators;
          setCampaignDetails({ ...data, creators });
          if (data.campaign_status === "Draft") {
            if (!hasShownMessage.current) {
              setShowInstructions(true);
              hasShownMessage.current = true;
            }
          }
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }
      },
      onError: (error) => {
        console.error("Failed to fetch campaign details:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    if (campaignDetails) {
      if (
        campaignDetails.campaign_status !== "Launched" &&
        campaignDetails.campaign_status !== "Completed"
      ) {
        setViewMode("pretty");
      } else {
        setViewMode("list");
      }
    }
  }, [campaignDetails]);

  const handleCloseCRMDialog = () => {
    setShowCRMDialog(false);
  };
  const { mutate: updateCreatorStats } = useMutation(
    (payload) => client.campaigns.updateCreatorStats(payload),
    {
      onSuccess: (data, variables) => {
        refetch();
      },
      onError: (error) => {
        alert((error?.response?.data?.error) ? error.response.data.error : error.message)
        console.error("Failed to update status:", error);
      },
    }
  );

  const handleStatusChange = (creatorId, status) => {
    const payload = {
      campaignId,
      creators: [{ id: creatorId, status }],
    };
    updateCreatorStats(payload, { creatorId, status });
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `campaign_details_${campaignId}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({ 
        campaignId, 
        campaignDetails 
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error("Error exporting to Google Sheets:", response.error);
      }
    } catch (error) {
      console.error("Failed to export to Google Sheets:", error?.response?.data?.error ? error.response.data.error : error.message);
    }
  };
  


  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Promotion Platform",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.name ?? "",
      creator.following ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      creator.promotionType ?? "",
      creator.promotionPlatform ?? "",
      creator.likes ?? 0,
      creator.comments ?? 0,
      creator.totalViews ?? 0,
      creator.postDate ?? "",
      creator.platformLink ?? "",
      creator.liveLink ?? "",
    ]);

    const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
    return csvContent;
  };

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

 
  const costPerCreatorData = Array.isArray(campaignDetails.creators) ? campaignDetails.creators.map((creator) => ({
    name: creator.name ?? "",
    price: creator.price
      ? parseLocalFloat(creator.price.toString().replace(/[^0-9.-]+/g, ""), 10)
      : 0, // Assuming the price is a string like "$6,000"
  })) : [];

  const reachData = Array.isArray(campaignDetails.creators) ? campaignDetails.creators.map((creator) => ({
    name: creator.name ?? "",
    value: creator.following
      ? parseInt(creator.following.replace(/,/g, ""), 10)
      : 0,
  })) : [];

  // Prepare data for the "Total Views by Day" chart
  const viewsByDayData = Array.isArray(campaignDetails.creators)
    ? campaignDetails.creators
      .reduce((acc, creator) => {
        const date = creator.postDate ?? Date();
        const views = creator.totalViews ? parseInt(creator.totalViews, 10) : 0;
        const existingEntry = acc.find((entry) => entry.date === date);
        if (existingEntry) {
          existingEntry.views += views;
        } else {
          acc.push({ date, views });
        }
        return acc;
      }, [])
      .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date for better chart display
    : [];

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  const isCampaignActive =
    campaignDetails.campaign_status !== "Launched" &&
    campaignDetails.campaign_status !== "Completed";

  const renderOptionalDetail = (label, value) => {
    return value ? (
      <Typography variant="body1">{`${label}: ${value}`}</Typography>
    ) : null;
  };

  return (
    <>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>
      <PageTitle title={`${campaignDetails.name} - Blitz`} />

      <Box
        sx={{
          backgroundImage: 'linear-gradient(#646AFF, #7B2A2A)',
          color: '#FFF',
          textAlign: 'center',
          padding: 5,
          marginBottom: 4,
        }}
      >
        <Typography variant="h3" gutterBottom>
          {campaignDetails.name}
        </Typography>
        <Typography variant="h6">
          {campaignDetails.brief}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: 3 }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleChangeView}
            aria-label="creator-view"
          >
            <ToggleButton value="list">
              See in List
            </ToggleButton>
            <ToggleButton value="pretty">
              Make it Pretty
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            color="success"
            variant="outlined"
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={exportToSheets}
          >
            Export to Google Sheets
          </Button>
        </Box>
      </Box>
      <Box sx={{ margin: { xs: 0, md: 2 }, textAlign: 'center' }}>
        <Paper
          elevation={1}
          square={!isDesktop}
          sx={{ width: { xs: '100%', md: 'fit-content' }, paddingInline: 1, marginInline: 'auto' }}
        >
          <Tabs
            centered
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Campaign" />
            <Tab label="Projections" />
            <Tab label="Reporting" value={2} />
          </Tabs>
        </Paper>
        <Divider sx={{ marginBlockStart: 2 }} />
        <Box sx={{ paddingInline: { xs: 1, md: 4 }, marginBlockStart: { xs: 1, md: 2 } }} >
          <TabPanel value={selectedTab} index={0}>
            <CampaignDetailsPanel
              campaignDetails={campaignDetails}
              viewMode={viewMode}
              isCampaignActive={isCampaignActive}
              handleStatusChange={handleStatusChange}

            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <CampaignProjectionsPanel campaignDetails={campaignDetails} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <CampaignReportingPanel
               viewsByDayData={viewsByDayData}
               costPerCreatorData={costPerCreatorData}
               reachData={reachData}
            />
          </TabPanel>
        </Box>
      </Box>
      {showCRMDialog && (
        <CRMDialog
          isOpen={showCRMDialog}
          handleClose={handleCloseCRMDialog}
          origin={`${campaignDetails.name} - campaign`}
        />
      )}
      <Dialog open={showInstructions} onClose={() => setShowInstructions(false)}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <Typography>
            Hey! New To Blitz? This is our proposal sheet - a list of creators
            curated just for you by our team. Each creator listed has a
            recommended price for the deliverables you requested, with their
            media kit attached. In order to interact with this page, you are
            able to "Approve," "Drop," and "Negotiate" for each creator listed
            and we will continue forward with your interest! If you would like,
            you may also download this list as a CSV for your personal keeping.
            Once we launch our campaign together, this link will turn into a
            tracking sheet to show current creator activity, with which creators
            have created a draft, which has posted, and their analytics for your
            reporting! Let's Win!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstructions(false)}>Got it!</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetailsPage;