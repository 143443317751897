import React, { useState, useCallback, useEffect, useRef } from "react";
import client from "../../../../API";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  TextField,
  Checkbox,
  Avatar,
  ListItemAvatar,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import GoogleIcon from "@mui/icons-material/Google";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png";
import TabPanel from "../../../../Components/TabPanel.js";
import CampaignProjectionsPanel from "../individualcampaigns/campaignTabs/campaignProjections.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import useAuth from "../../../../Hooks/use-auth.js";
import SelectAssetDialog from "../../../../Components/tools/selectfile";
import Tools from "./tools.js";
import TimelineTab from "./timeline";
import PageTitle from "../../../../Components/PageTitle";
import {
  CreatorCampaignStatus,
  parseLocalFloat,
  TrackLinks,
  wait,
} from "../../../../Utils/constants.js";
import { TimelineStatus } from "../../../../Utils/constants";
import { RateField } from "../../../../Components/RateField.js";

const RainbowButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)",
  backgroundSize: "400% 400%",
  animation: "rainbow 5s ease infinite",
  color: "white",
  "@keyframes rainbow": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  "&:hover": {
    backgroundColor: "darkgreen",
  },
}));

const ActionsButton = ({
  isDraft,
  isLaunched,
  handleAddCreatorsClick,
  handleConfirmCompleteCampaign,
  handleDownloadCSV,
  handleExportToGoogleSheets,
  isTcc,
  isLoading,
  isEditable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="flex-end" gap={2}>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Button
            onClick={handleMoreActionsClick}
            startIcon={<MoreVertIcon />}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreActionsClose}
          >
            {isDraft && (
              <MenuItem onClick={handleAddCreatorsClick}>
                <ListItemIcon>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Creators</ListItemText>
              </MenuItem>
            )}
            {isLaunched && isTcc && (
              <MenuItem onClick={handleConfirmCompleteCampaign}>
                <ListItemIcon>
                  <CheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Complete Campaign</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleDownloadCSV}>
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download as CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleExportToGoogleSheets}>
              <ListItemIcon>
                <GoogleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export to Google Sheets</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

const EditCampaignPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isDesktop = useIsDesktop();
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";

  const [campaignDetails, setCampaignDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEditingName, setEditingName] = useState(false);
  const [isEditingBrief, setEditingBrief] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedBrief, setEditedBrief] = useState("");
  const [creatorsToRemove, setCreatorsToRemove] = useState([]);
  const [showLaunchConfirmDialog, setShowLaunchConfirmDialog] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [expandedCreator, setExpandedCreator] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const changedEvents = useRef(false);

  // State for assets functionality
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(null);

  const { isFetching, data, refetch: refetchCampaign } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: files, refetch: refetchFiles } = useQuery("files", () =>
    client.files.fetch("")
  );

  const { data: projectionsData } = useQuery(
    ["campaignProjections", campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const InvalidateCampaign = () => {
    queryClient.invalidateQueries(["campaign", campaignId]);
  };

  const handleRefreshStats = async () => {
    if (unsavedChanges) {
      alert("Please save all changes before tracking");
      return;
    }
    const foundLiveLinks = campaignDetails.creators.reduce(
      (found, currentCreator) => found || !!currentCreator.liveLink,
      false
    );
    if (!foundLiveLinks) {
      alert("None of the creators in this campaigns has any Live Links to track");
      return;
    }

    setIsTracking(true);
    const engagement = await TrackLinks(campaignDetails.creators);
    setProgress(100);
    const foundError = Object.values(engagement).reduce(
      (found, currentCreator) =>
        found || (currentCreator ? !!currentCreator.error : false),
      false
    );
    if (foundError) alert("Failed tracking one or more links. Please try again");
    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        comments: engagement[creator.id]?.comments || creator.comments,
        totalViews: engagement[creator.id]?.view_count || creator.totalViews,
        likes: engagement[creator.id]?.like_count || creator.likes,
        postDate: engagement[creator.id]?.upload_date || creator.postDate,
      })),
    };
    try {
      await updateCreatorSpecsAsync(payload);
    } catch (error) {
      alert(error);
    }
    setIsTracking(false);
    await wait(1000);
    setProgress(0);
  };

  useEffect(() => {
    if (data) {
      setCampaignDetails({
        ...data,
        creators: data.creators.map((creator) => ({
          ...creator,
          price:
            typeof creator?.price === "number"
              ? creator.price.toFixed(2)
              : "",
          agencyRate:
            typeof creator?.agencyRate === "number"
              ? creator.agencyRate.toFixed(2)
              : "",
        })),
      });
      setEditedName(data.name || "");
      setEditedBrief(data.brief || "");
      setUnsavedChanges(false);
      setTimelineEvents(
        data.timelineEvents.map((event) => ({
          ...event,
          projected_date: new Date(event.projected_date),
          will_delete: false,
        }))
      );
    }
  }, [data]);

  const AddTimelineEvent = (creator) => {
    changedEvents.current = true;
    setTimelineEvents((prev) => [
      ...prev,
      {
        campaign_id: campaignId,
        id: null,
        last_updated: null,
        manager_completed: false,
        objective: "",
        projected_date: new Date(Date.now()),
        status: TimelineStatus[0],
        notes: "",
        manager_notes: "",
        username: creator,
        will_delete: false,
      },
    ]);
  };

  const OnEditTimelineField = (targetIndex, target, value) => {
    changedEvents.current = true;
    setTimelineEvents(
      timelineEvents.map((event, index) => {
        if (index === targetIndex) {
          return { ...event, [target]: value };
        } else return event;
      })
    );
  };

  const { mutateAsync: updateTimeline, isLoading: isUpdatingTimeline } =
    useMutation(
      ({ id, input }) => client.campaigns.timeline_edit(id, input),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["campaign", campaignDetails.id]);
          alert("Timeline updated successfully.");
        },
        onError: (error) => {
          if (error.response && error.response.data && error.response.data.error)
            alert(`Error: ${error.response.data.error}`);
          else alert(`Error: ${error.message}`);
        },
      }
    );

  const estimatedTime = campaignDetails?.creators?.length * 4000 || 1000;
  const intervalDuration = 1000; // 1000 milliseconds

  useEffect(() => {
    if (!isTracking) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        const newValue =
          prev + 100 / (estimatedTime / intervalDuration);
        if (newValue >= 100) {
          clearInterval(interval);
          return 100;
        }
        return newValue;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [isTracking, estimatedTime]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFieldChange = (creatorId, field, value) => {
    const updatedCreators = campaignDetails.creators.map((creator) =>
      creator.id === creatorId ? { ...creator, [field]: value } : creator
    );
    setCampaignDetails((prevState) => ({
      ...prevState,
      creators: updatedCreators,
    }));
    setUnsavedChanges(true);
  };

  const saveChanges = () => {
    if (changedEvents.current === true) {
      updateTimeline({
        id: campaignDetails.id,
        input: timelineEvents
          .filter((event) => event.will_delete === false)
          .map(({ will_delete, ...event }) => ({
            ...event,
            projected_date: event.projected_date.toUTCString(),
          })),
      });
      changedEvents.current = false;
    }
    const payload = {
      campaignId,
      creators: campaignDetails.creators.map((creator) => ({
        id: creator.id,
        status: creatorsToRemove.includes(creator.id)
          ? "Dropped"
          : creator.status,
        price: parseLocalFloat(creator.price),
        agencyRate: parseLocalFloat(creator.agencyRate),
        assetsSent: creator.assetsSent,
        assetsApproved: creator.assetsApproved,
        likes: creator.likes,
        comments: creator.comments,
        totalViews: creator.totalViews,
        postDate: creator.postDate,
        liveLink: creator.liveLink,
        skipPayout: creator.skipPayout,
        promotionPlatform: creator.promotionPlatform,
        promotionType: creator.promotionType,
        creatorBrief: creator.creatorBrief,
        postingInstructions: creator.postingInstructions,
        boostCode: creator.boostCode,
      })),
    };

    updateCampaignCreatorSpecs(payload);
    setUnsavedChanges(false);
  };

  const saveMetaData = () => {
    const payload = {
      id: campaignId,
      name: editedName,
      brief: editedBrief,
      campaign_sum: totalCampaignSum,
    };
    updateMetaData(payload);
    setUnsavedChanges(false);
  };

  const handleSaveCampaignDetails = () => {
    saveMetaData();
    setEditingName(false);
    setEditingBrief(false);
  };

  const totalCampaignSum = campaignDetails?.creators.reduce((acc, creator) => {
    const price = parseFloat(
      (creator.price || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + price;
  }, 0);

  const showSkipPayment = campaignDetails?.creators.some(
    (creator) => creator.skipPayout === true
  );

  const totalCreatorSum = campaignDetails?.creators.reduce((acc, creator) => {
    const cost = parseFloat(
      (creator.agencyRate || "0").toString().replace(/[^\d.]/g, "")
    );
    return acc + cost;
  }, 0);

  const {
    mutate: updateCampaignCreatorSpecs,
    mutateAsync: updateCreatorSpecsAsync,
    isLoading: isLoadingUpateSpecs,
  } = useMutation(
    (payload) => client.campaigns.updateCampaignCreatorSpecs(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        refetchCampaign();
        alert("Creator details updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const { mutate: updateMetaData, isLoading: isLoadingUpdateMetadata } =
    useMutation((payload) => client.campaigns.updateMetaData(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign metadata updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const { mutate: launchCampaign, isLoading: isLoadingLaunch } = useMutation(
    (payload) => client.campaigns.launch(payload),
    {
      onSuccess: () => {
        alert("Campaign launched successfully!");
        queryClient.invalidateQueries(["campaign", campaignId]);
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    }
  );

  const handleLaunchCampaign = () => {
    setShowLaunchConfirmDialog(true);
  };

  const handleConfirmLaunch = async () => {
    setIsLaunching(true);
    setShowLaunchConfirmDialog(false);

    const creatorNames =
      campaignDetails?.creators?.map((creator) => creator.name) || [];
    const creatorPromotionTypes =
      campaignDetails?.creators?.map((creator) => ({
        name: creator.name,
        promotionType: creator.promotionType,
      })) || [];
    const creatorPrices =
      campaignDetails?.creators?.reduce((acc, creator) => {
        acc[creator.name] = parseLocalFloat(creator.price);
        return acc;
      }, {}) || {};

    const agencyRates =
      campaignDetails?.creators?.reduce((acc, creator) => {
        acc[creator.name] = parseLocalFloat(creator.agencyRate || "0");
        return acc;
      }, {}) || {};

    const payload = {
      campaignId: campaignDetails?.id,
      campaignName: campaignDetails?.name,
      campaignBrief: campaignDetails?.brief,
      creatorNames,
      creatorPromotionTypes,
      creatorPrices,
      agencyRates,
      blitzautocampaign: true,
      idealduedate: campaignDetails?.ideal_duedate,
      userEmail: campaignDetails?.user_email,
      sharedWithEmail: campaignDetails?.shared_with_email,
    };

    try {
      launchCampaign(payload);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleCancelLaunch = () => {
    setShowLaunchConfirmDialog(false);
  };

  const { mutate: completeCampaign, isLoading: isLoadingComplete } =
    useMutation((input) => client.campaigns.complete(input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Campaign completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const { mutate: completeCreator, isLoading: isLoadingCompleteCreator } =
    useMutation((input) => client.campaigns.completeCreator(campaignId, input), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaignId]);
        alert("Creator completed successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    });

  const handleConfirmCompleteCampaign = async () => {
    completeCampaign({ index: campaignId, params: {} });
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(campaignDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `campaign_details_${campaignId}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.campaigns.exportSheet({
        campaignId,
        campaignDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error(
          "Error exporting to Google Sheets:",
          response.error
        );
      }
    } catch (error) {
      console.error(
        "Failed to export to Google Sheets:",
        error.response?.data?.error || error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Status",
      "Price",
      "Offer Sent",
      "Assets Sent",
      "Assets Approved",
      "Promotion Type",
      "Likes",
      "Comments",
      "Total Views",
      "Post Date",
      "Platform Link",
      "Live Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.name ?? "",
      creator.following ?? "",
      creator.status ?? "",
      creator.price ?? "",
      creator.offerSent ? "Yes" : "No",
      creator.assetsSent ? "Yes" : "No",
      creator.assetsApproved ? "Yes" : "No",
      creator.promotionType ?? "",
      creator.likes ?? 0,
      creator.comments ?? 0,
      creator.totalViews ?? 0,
      creator.postDate ?? "",
      creator.platformLink ?? "",
      creator.liveLink ?? "",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");
    return csvContent;
  };

  const handleExpandClick = (creatorId) => {
    setExpandedCreator(expandedCreator === creatorId ? null : creatorId);
  };

  // Mutations and functions for starting conversations
  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data, variables) => {
      if (data.exists) {
        navigateToConversation(variables.creatorName);
      } else {
        createConversation.mutate(variables);
      }
    },
    onError: (error) => {
      console.error("Check response error:", error);
    },
  });

  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data, variables) => {
      navigateToConversation(variables.creatorName);
    },
    onError: (error) => {
      console.error("Create response error:", error);
    },
  });

  const startConversation = (creatorId, creatorName) => {
    const payload = {
      campaign_id: campaignId,
      creator_id: creatorId,
      creatorName: creatorName,
    };
    checkConversation.mutate(payload);
  };

  const navigateToConversation = (creatorName) => {
    navigate(`/campaigns/convo/${campaignId}/${creatorName}`);
  };

  const renderOptionalDetail = (
    label,
    value,
    isEditing,
    onEditClick,
    onChange
  ) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "flex-end",
        }}
      >
        {isEditing ? (
          <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        ) : (
          <Typography variant="body1">{`${label}: ${value}`}</Typography>
        )}
        {isEditable && !isEditing && (
          <IconButton size="small" onClick={onEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {isEditing && (
          <IconButton size="small" onClick={handleSaveCampaignDetails}>
            <CheckIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  const renderSummary = (creator) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 2,
          padding: 2,
          flexWrap: "wrap",
        }}
      >
        {creator.price ? (
          <Typography variant="body2">
            <strong>Price:</strong> {creator.price}
          </Typography>
        ) : null}
        {creator.totalViews ? (
          <Typography variant="body2">
            <strong>Total Views:</strong> {creator.totalViews}
          </Typography>
        ) : null}
        {creator.comments ? (
          <Typography variant="body2">
            <strong>Comments:</strong> {creator.comments}
          </Typography>
        ) : null}
        {creator.shares ? (
          <Typography variant="body2">
            <strong>Shares:</strong> {creator.shares}
          </Typography>
        ) : null}
        {creator.likes ? (
          <Typography variant="body2">
            <strong>Likes:</strong> {creator.likes}
          </Typography>
        ) : null}
        {creator.postDate ? (
          <Typography variant="body2">
            <strong>Post Date:</strong> {creator.postDate}
          </Typography>
        ) : null}
      </Box>
    );
  };

  // Functions for assets management
  const handleDialogOpen = (index) => {
    setCurrentCreatorIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed && selectedAsset) {
      const updatedCreators = campaignDetails.creators.map((creator, idx) => {
        if (idx === currentCreatorIndex) {
          return {
            ...creator,
            creatorBrief: `${creator.creatorBrief}\n${selectedAsset.file_url}`,
          };
        }
        return creator;
      });

      const updatedDetails = { ...campaignDetails, creators: updatedCreators };
      setCampaignDetails(updatedDetails);

      const payload = {
        campaignId: updatedDetails.id,
        creators: updatedDetails.creators.map((creator) => ({
          id: creator.id,
          creatorBrief: creator.creatorBrief,
        })),
      };

      updateCampaignCreatorSpecs(payload);
    }
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  if (!campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  const isDraft = campaignDetails?.campaign_status === "Draft";
  const isEditable = isTcc || (isDraft && isDesktop);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={
          isFetching ||
          isLaunching ||
          isLoadingComplete ||
          isLoadingCompleteCreator ||
          isLoadingLaunch ||
          isLoadingUpateSpecs ||
          isLoadingUpdateMetadata
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isTracking}
      >
        <Box sx={{ width: "75%" }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={progress}
          />
        </Box>
      </Backdrop>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ width: "100%" }}
        >
          <Tab label="Campaign" />
          <Tab label="Tools" />
          <Tab label="Automations" />
          <Tab label="Projections" />
        </Tabs>
      </AppBar>

      <Container maxWidth="xl">
        <Box sx={{ margin: 2 }}>
          <Stepper
            activeStep={
              campaignDetails.campaign_status === "Completed"
                ? 2
                : campaignDetails.campaign_status === "Launched"
                ? 1
                : 0
            }
          >
            <Step>
              <StepLabel>Draft</StepLabel>
            </Step>
            <Step>
              <StepLabel>Launched</StepLabel>
            </Step>
            <Step>
              <StepLabel>Completed</StepLabel>
            </Step>
          </Stepper>
        </Box>

        <Box
          sx={{
            margin: { xs: 0, md: 2 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 0 },
            maxWidth: "100%",
          }}
        >
          <PageTitle title={`Edit Campaign - ${campaignDetails.name} - Blitz`} />

          <Box sx={{ maxWidth: "100%", overflowX: "clip" }}>
            <Typography variant="h5" gutterBottom>
              {isEditingName ? (
                <TextField
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              ) : (
                `Campaign: ${campaignDetails.name}`
              )}
              {isEditable && !isEditingName && (
                <IconButton size="small" onClick={() => setEditingName(true)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
              {isEditingName && (
                <IconButton size="small" onClick={handleSaveCampaignDetails}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              )}
            </Typography>
            {renderOptionalDetail(
              "Brief",
              editedBrief,
              isEditingBrief,
              () => setEditingBrief(true),
              setEditedBrief
            )}
            {renderOptionalDetail("Campaign Sum", `$${totalCampaignSum}`)}
            <Box sx={{ mt: 1 }}>
              {campaignDetails.drive_link ? (
                <GreenButton
                  variant="contained"
                  href={campaignDetails.drive_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open Deliverables Drive
                </GreenButton>
              ) : (
                <Typography variant="body1">
                  Deliverables Drive: Campaign Not Launched
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "flex-start", md: "flex-end" },
            }}
          >
            {isDraft && (
              <RainbowButton
                variant="contained"
                onClick={handleLaunchCampaign}
                sx={{ mb: 2 }}
              >
                Launch Campaign
              </RainbowButton>
            )}
            <ActionsButton
              isDraft={isDraft}
              isLaunched={campaignDetails.campaign_status === "Launched"}
              handleAddCreatorsClick={() =>
                navigate(`/add-creators/${campaignId}`)
              }
              handleConfirmCompleteCampaign={handleConfirmCompleteCampaign}
              handleDownloadCSV={downloadCSV}
              handleExportToGoogleSheets={exportToSheets}
              isTcc={isTcc}
              isLoading={isLaunching}
              isEditable={isEditable}
            />
            {isEditable && (
              <Button
                variant="contained"
                color="primary"
                onClick={saveChanges}
                sx={{ mt: 1 }}
              >
                Save Changes
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/campaigns/${campaignId}`)}
              sx={{ mt: 2 }}
            >
              Share Campaign
            </Button>
            {unsavedChanges && (
              <Typography variant="subtitle1" color="error" sx={{ mt: 1 }}>
                You have unsaved changes. Please save them before leaving the page.
              </Typography>
            )}
            {campaignDetails?.creators.some((creator) => creator.liveLink) &&
              (campaignDetails.campaign_status === "Launched" ||
                campaignDetails.campaign_status === "Completed") && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRefreshStats}
                  sx={{ mt: 1 }}
                >
                  Refresh Campaign Stats
                </Button>
              )}
          </Box>
        </Box>

        <Divider sx={{ marginBlockStart: 2 }} />
        <Box
          sx={{
            paddingInline: { xs: 1, md: 4 },
            marginBlockStart: { xs: 1, md: 2 },
          }}
        >
          <TabPanel value={selectedTab} index={0}>
            <Typography variant="h6">Creators</Typography>
            <List sx={{ width: { xs: "100%", md: "auto" } }}>
              {Array.isArray(campaignDetails.creators) &&
                campaignDetails.creators.map((creator, index) => (
                  <Paper
                    elevation={1}
                    key={index}
                    sx={{
                      borderRadius: expandedCreator === creator.id ? 4 : 6,
                      marginBlockEnd: 1,
                      width: { xs: "100%", md: "auto" },
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={creator.pfphref || profilePhoto}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={creator.name ?? ""}
                        secondary={`Following: ${creator.following ?? 0}`}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "auto",
                          gap: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            startConversation(creator.id, creator.name)
                          }
                        >
                          Start Conversation
                        </Button>
                        <IconButton
                          onClick={() => handleExpandClick(creator.id)}
                        >
                          {expandedCreator === creator.id ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                    </ListItem>

                    {expandedCreator === creator.id ? (
                      <>
                        {/* Creator Submission Portal */}
                        {creator.submissionLink && (
                          <Box sx={{ padding: 2 }}>
                            <Card
                              sx={{
                                cursor: "pointer",
                                border: "2px solid blue",
                              }}
                              onClick={() => {
                                if (creator.submissionLink) {
                                  window.open(creator.submissionLink, "_blank");
                                }
                              }}
                            >
                              <CardContent>
                                <Typography variant="h6">
                                  {creator.name}'s Submission
                                </Typography>
                                <Typography variant="body2">
                                  Click to view submission
                                </Typography>
                              </CardContent>
                            </Card>
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 2,
                            paddingInline: 2,
                            width: "100%",
                            marginInlineStart: 2,
                            borderLeft: "2px solid #ddd",
                            minHeight: "8em",
                            alignItems: "flex-start",
                          }}
                        >
                          {isEditable && (
                            <>
                              <RateField
                                label="Price"
                                value={creator.price ?? 0}
                                onChange={(value) =>
                                  handleFieldChange(creator.id, "price", value)
                                }
                                sx={{ width: "10em" }}
                                variant="outlined"
                                size="small"
                              />
                              {isTcc && (
                                <RateField
                                  label="Agency Rate"
                                  value={creator.agencyRate ?? 0}
                                  onChange={(value) =>
                                    handleFieldChange(
                                      creator.id,
                                      "agencyRate",
                                      value
                                    )
                                  }
                                  sx={{ width: "10em" }}
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                              <TextField
                                label="Status"
                                select
                                value={creator.status || "Pitched"}
                                onChange={(event) =>
                                  handleFieldChange(
                                    creator.id,
                                    "status",
                                    event.target.value
                                  )
                                }
                                sx={{ width: "10em" }}
                                variant="outlined"
                                size="small"
                              >
                                {CreatorCampaignStatus.map((status) => (
                                  <MenuItem value={status} key={status}>
                                    {status === "Dropped" && (
                                      <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                      </ListItemIcon>
                                    )}
                                    {status === "Dropped" ? "Drop Creator" : status}
                                  </MenuItem>
                                ))}
                              </TextField>

                              <FormControlLabel
                                label="Deliverables Sent"
                                checked={creator.assetsSent}
                                onChange={() =>
                                  handleFieldChange(
                                    creator.id,
                                    "assetsSent",
                                    !creator.assetsSent
                                  )
                                }
                                color="primary"
                                control={<Checkbox></Checkbox>}
                              />

                              {creator.assetsSent && (
                                <>
                                  <FormControlLabel
                                    label="Deliverables Approved"
                                    checked={creator.assetsApproved}
                                    onChange={() =>
                                      handleFieldChange(
                                        creator.id,
                                        "assetsApproved",
                                        !creator.assetsApproved
                                      )
                                    }
                                    color="primary"
                                    control={<Checkbox></Checkbox>}
                                  />

                                  {creator.assetsApproved && (
                                    <>
                                      <TextField
                                        label="Posting Instructions"
                                        value={
                                          creator.postingInstructions ?? ""
                                        }
                                        onChange={(e) =>
                                          handleFieldChange(
                                            creator.id,
                                            "postingInstructions",
                                            e.target.value
                                          )
                                        }
                                        sx={{ width: "10em" }}
                                        variant="outlined"
                                        size="small"
                                        multiline
                                      />
                                    </>
                                  )}
                                </>
                              )}

                              {creator.assetsApproved && (
                                <TextField
                                  label="Live Link"
                                  value={creator.liveLink ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "liveLink",
                                      e.target.value
                                    )
                                  }
                                  sx={{ width: "10em" }}
                                  variant="outlined"
                                  size="small"
                                />
                              )}

                              {creator.assetsApproved && creator.liveLink && (
                                <TextField
                                  label="Boost Code"
                                  value={creator.boostCode ?? ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      creator.id,
                                      "boostCode",
                                      e.target.value
                                    )
                                  }
                                  sx={{ width: "10em" }}
                                  variant="outlined"
                                  size="small"
                                />
                              )}

                              {isTcc && showSkipPayment && creator.liveLink && (
                                <FormControlLabel
                                  label="Skip Payment"
                                  checked={creator.skipPayout}
                                  disabled={creator.payoutComplete}
                                  onChange={() =>
                                    handleFieldChange(
                                      creator.id,
                                      "skipPayout",
                                      !creator.skipPayout
                                    )
                                  }
                                  color="primary"
                                  control={<Checkbox></Checkbox>}
                                />
                              )}

                              {isTcc && creator.liveLink && (
                                <Button
                                  color={"secondary"}
                                  sx={{
                                    ...(creator.payoutComplete && {
                                      "&.Mui-disabled": {
                                        background: "success.dark",
                                        borderColor: "success.dark",
                                        color: "success.dark",
                                      },
                                    }),
                                  }}
                                  disabled={
                                    unsavedChanges ||
                                    creator.skipPayout ||
                                    creator.payoutComplete ||
                                    isLoadingCompleteCreator ||
                                    !creator.liveLink
                                  }
                                  onClick={() =>
                                    completeCreator({ creator: creator.id })
                                  }
                                  variant={
                                    creator.payoutComplete ? "outlined" : "contained"
                                  }
                                >
                                  {creator.payoutComplete
                                    ? "Completed"
                                    : "Complete Creator"}
                                </Button>
                              )}

                              {/* Expandable section for creatorBrief, platforms, promotionType */}
                              <Box sx={{ width: "100%" }}>
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    handleFieldChange(
                                      creator.id,
                                      "showDetails",
                                      !creator.showDetails
                                    )
                                  }
                                >
                                  {creator.showDetails
                                    ? "Hide Details"
                                    : "Show Details"}
                                </Button>
                                <Collapse in={creator.showDetails}>
                                  <TextField
                                    label="Creator Brief"
                                    value={creator.creatorBrief ?? ""}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        creator.id,
                                        "creatorBrief",
                                        e.target.value
                                      )
                                    }
                                    sx={{ width: "100%", mt: 1 }}
                                    variant="outlined"
                                    size="small"
                                    multiline
                                  />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleDialogOpen(index)}
                                      sx={{ mt: 1 }}
                                    >
                                      Add File
                                    </Button>
                                  <TextField
                                    label="Promotion Platform"
                                    value={creator.promotionPlatform ?? ""}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        creator.id,
                                        "promotionPlatform",
                                        e.target.value
                                      )
                                    }
                                    sx={{ width: "100%", mt: 1 }}
                                    variant="outlined"
                                    size="small"
                                  />
                                  <TextField
                                    label="Promotion Type"
                                    value={creator.promotionType ?? ""}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        creator.id,
                                        "promotionType",
                                        e.target.value
                                      )
                                    }
                                    sx={{ width: "100%", mt: 1 }}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Collapse>
                              </Box>
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      renderSummary(creator)
                    )}
                  </Paper>
                ))}
            </List>
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <Tools campaignDetails={campaignDetails} onUpdate={InvalidateCampaign} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <TimelineTab
              campaignDetails={campaignDetails}
              timelineEvents={timelineEvents}
              onEditField={OnEditTimelineField}
              onCreateRow={AddTimelineEvent}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            {projectionsData ? (
              <CampaignProjectionsPanel campaignDetails={projectionsData} />
            ) : (
              <Typography>Loading projections...</Typography>
            )}
          </TabPanel>
        </Box>
      </Container>

      <Dialog open={showLaunchConfirmDialog} onClose={handleCancelLaunch}>
        <DialogTitle>Confirm Launch</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to launch this campaign? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLaunch} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLaunch} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import the SelectAssetDialog */}
      <SelectAssetDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        files={files}
        selectedAsset={selectedAsset}
        onAssetClick={handleAssetClick}
        refetchFiles={refetchFiles} // Pass the refetch function to refresh the asset list
      />
    </>
  );
};

export default EditCampaignPage;
