// DealIOCardList.js

import React, { memo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  Button,
  Checkbox,
  IconButton,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import profilePhoto from '../../Components/globalAssets/ppfLogo.png';

const summarizeText = (text, maxLength = 20) => {
  if (!text) return '';
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

const DealIOCardList = memo(function DealIOCardList({
  dealIOs,
  handleOpenDialog,
  handleShareDealIO,
  handleCopyDealIO,
  handleCheckboxChange,
  selectedDealIOs = [],
}) {
  return (
    <Grid container spacing={2}>
      {dealIOs.map((dealIO) => {
        const owner = dealIO.user_data.find((user) => user.role === 'Owner');
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={dealIO.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                height="200"
                image={profilePhoto}
                alt={owner ? owner.name : 'No Owner'}
                onClick={() => handleOpenDialog(dealIO)}
                style={{ cursor: 'pointer' }}
              />
              <CardContent
                onClick={() => handleOpenDialog(dealIO)}
                style={{ cursor: 'pointer', flexGrow: 1 }}
              >
                <Typography variant="h5" component="div">
                  {summarizeText(dealIO.name || 'No Name')}
                </Typography>
                <Typography variant="body2">
                  Owner: {owner ? owner.name : 'No Owner'}
                </Typography>
                <Typography variant="body2">
                  Vendor ID: {dealIO.creator_vendor_id}
                </Typography>
                <Typography variant="body2">
                  Amount Due: ${parseFloat(dealIO.amount_due).toFixed(2)}
                </Typography>
                <Typography variant="body2">
                  Amount Paid: ${parseFloat(dealIO.amount_paid).toFixed(2)}
                </Typography>
                <Typography variant="body2">
                  Deliverables: {dealIO.deliverables.length}
                </Typography>
              </CardContent>
              <CardActions>
                <Checkbox
                  checked={selectedDealIOs.includes(dealIO.id)}
                  onChange={() => handleCheckboxChange(dealIO.id)}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareDealIO(dealIO.id);
                  }}
                >
                  <ShareIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyDealIO(dealIO.id);
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default DealIOCardList;
