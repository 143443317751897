import React, { useState, useEffect } from 'react';
import {
    Table, TableHead, TableBody, TableRow, TableCell,
    Paper, TableContainer, Button, TextField, Box,
    CircularProgress, IconButton, Dialog, DialogActions,
    DialogContent, DialogTitle, Typography, Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import client from '../../../../../API'; // Adjust the import path as necessary

const InternalCampaigns = () => {
    const [campaignsData, setCampaignsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newCampaign, setNewCampaign] = useState({
        subject: '',
        recipients: [],
        status: 'active', // Added default status
        thread_id: '',    // Added thread_id field
        messages: [
            { sequence_number: 1, content: '', scheduled_date: '' }
        ]
    });
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [recipientInput, setRecipientInput] = useState('');

    // Function to fetch campaigns data
    const fetchCampaigns = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const res = await client.emailCampaigns.fetch();
            console.log("API response:", res);
            setCampaignsData(res.campaigns); // Assuming the response has 'campaigns' key
        } catch (err) {
            console.error("Error fetching campaigns:", err);
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch campaigns data on component mount
    useEffect(() => {
        fetchCampaigns();
    }, []);

    // Function to create a new campaign
    const createCampaign = async () => {
        setIsLoading(true);
        try {
            // Prepare data for the API
            const campaignData = {
                ...newCampaign,
                messages: newCampaign.messages.map((msg) => ({
                    ...msg,
                    scheduled_date: new Date(msg.scheduled_date).toISOString() // Ensure ISO format
                }))
            };

            await client.emailCampaigns.create(campaignData);
            setNewCampaign({
                subject: '',
                recipients: [],
                status: 'active',
                thread_id: '',
                messages: [
                    { sequence_number: 1, content: '', scheduled_date: '' }
                ]
            });
            setRecipientInput('');
            await fetchCampaigns(); // Refetch campaigns after creating
            setCreateDialogOpen(false);
        } catch (err) {
            console.error("Error creating campaign:", err);
            alert("There was an error creating the campaign.");
        } finally {
            setIsLoading(false);
        }
    };

    // Handle change in input fields for new campaign
    const handleCampaignChange = (e) => {
        const { name, value } = e.target;
        setNewCampaign({
            ...newCampaign,
            [name]: value
        });
    };

    // Handle change in message fields
    const handleMessageChange = (index, e) => {
        const { name, value } = e.target;
        const updatedMessages = [...newCampaign.messages];
        updatedMessages[index][name] = value;
        setNewCampaign({
            ...newCampaign,
            messages: updatedMessages
        });
    };

    // Add a new message to the campaign
    const addMessage = () => {
        const newSequenceNumber = newCampaign.messages.length + 1;
        setNewCampaign({
            ...newCampaign,
            messages: [
                ...newCampaign.messages,
                { sequence_number: newSequenceNumber, content: '', scheduled_date: '' }
            ]
        });
    };

    // Remove a message from the campaign
    const removeMessage = (index) => {
        const updatedMessages = newCampaign.messages.filter((_, i) => i !== index);
        // Update sequence numbers
        const updatedMessagesWithSequence = updatedMessages.map((msg, idx) => ({
            ...msg,
            sequence_number: idx + 1
        }));
        setNewCampaign({
            ...newCampaign,
            messages: updatedMessagesWithSequence
        });
    };

    // Handle form submission
    const handleSubmit = () => {
        // Basic validation
        if (newCampaign.subject && newCampaign.recipients.length > 0 && newCampaign.messages.length > 0) {
            createCampaign();
        } else {
            alert("Please fill in all required fields.");
        }
    };

    // Open the create dialog
    const handleCreateClick = () => {
        setCreateDialogOpen(true);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        setCreateDialogOpen(false);
    };

    // Handle recipient input change
    const handleRecipientInputChange = (e) => {
        setRecipientInput(e.target.value);
    };

    // Add recipient to the list
    const addRecipient = () => {
        if (recipientInput && validateEmail(recipientInput)) {
            setNewCampaign({
                ...newCampaign,
                recipients: [...newCampaign.recipients, recipientInput]
            });
            setRecipientInput('');
        } else {
            alert("Please enter a valid email address.");
        }
    };

    // Remove recipient from the list
    const removeRecipient = (email) => {
        const updatedRecipients = newCampaign.recipients.filter((recip) => recip !== email);
        setNewCampaign({
            ...newCampaign,
            recipients: updatedRecipients
        });
    };

    // Simple email validation
    const validateEmail = (email) => {
        // Basic regex for email validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const renderTable = () => (
        <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Recipients</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Thread ID</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        {/* Add more columns as needed */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campaignsData && campaignsData.length > 0 ? (
                        campaignsData.map((campaign) => (
                            <TableRow key={campaign.id}>
                                <TableCell>{campaign.subject}</TableCell>
                                <TableCell>
                                    {campaign.recipients ? campaign.recipients.join(', ') : 'N/A'}
                                </TableCell>
                                <TableCell>{campaign.status}</TableCell>
                                <TableCell>{campaign.thread_id || 'N/A'}</TableCell>
                                <TableCell>{new Date(campaign.created_at).toLocaleString()}</TableCell>
                                <TableCell>{new Date(campaign.updated_at).toLocaleString()}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6}>No campaigns available.</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
    

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Error loading campaigns: {error.message}</div>;
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant='h6'>Email Campaigns</Typography>
            {campaignsData?.length > 0 ? renderTable() : <div>No campaigns available.</div>}

            <Button
                onClick={handleCreateClick}
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
            >
                Create New Campaign
            </Button>

            {/* Create Campaign Dialog */}
            <Dialog open={createDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Create New Campaign</DialogTitle>
                <DialogContent>
                    <TextField
                        name="subject"
                        label="Subject"
                        value={newCampaign.subject}
                        onChange={handleCampaignChange}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        name="thread_id"
                        label="Thread ID"
                        value={newCampaign.thread_id}
                        onChange={handleCampaignChange}
                        margin="normal"
                        fullWidth
                        helperText="Optional: Provide a thread ID if applicable"
                    />
                    <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        Recipients
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="Email Address"
                            value={recipientInput}
                            onChange={handleRecipientInputChange}
                            margin="normal"
                            fullWidth
                        />
                        <Button
                            onClick={addRecipient}
                            variant="outlined"
                            sx={{ marginLeft: 2, marginTop: '16px', height: '56px' }}
                        >
                            Add
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 1 }}>
                        {newCampaign.recipients.map((email, index) => (
                            <Chip
                                key={index}
                                label={email}
                                onDelete={() => removeRecipient(email)}
                                sx={{ margin: '4px' }}
                            />
                        ))}
                    </Box>
                    <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        Messages
                    </Typography>
                    {newCampaign.messages.map((message, index) => (
                        <Box key={index} sx={{ border: '1px solid #ccc', padding: 2, marginBottom: 2 }}>
                            <Typography variant="subtitle1">Message {index + 1}</Typography>
                            <TextField
                                name="content"
                                label="Content"
                                value={message.content}
                                onChange={(e) => handleMessageChange(index, e)}
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                            />
                            <TextField
                                name="scheduled_date"
                                label="Scheduled Date"
                                type="datetime-local"
                                value={message.scheduled_date}
                                onChange={(e) => handleMessageChange(index, e)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Button
                                onClick={() => removeMessage(index)}
                                color="secondary"
                                sx={{ marginTop: 1 }}
                            >
                                Remove Message
                            </Button>
                        </Box>
                    ))}
                    <Button
                        onClick={addMessage}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{ marginTop: 2 }}
                    >
                        Add Message
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained">Create Campaign</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default InternalCampaigns;
