import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import client from "../../../../API"; // Ensure this is the correct path
import { useMutation, useQuery } from "react-query";
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Toolbar,
} from "@mui/material";
import BlitzHeader from "../../../../Components/BlitzHeader.js";
import PageTitle from "../../../../Components/PageTitle.js";

const OptOutCampaign = () => {
  const { campaignId } = useParams();
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [showOptOutDialog, setShowOptOutDialog] = useState(true);
  const navigate = useNavigate();

  // Fetch campaign details using useQuery
  const { data, error, isLoading } = useQuery(
    ['campaignDetails', campaignId],
    () => client.campaigns.fetch(campaignId),
    {
      onSuccess: (data) => {
        setCampaignDetails(data);
      },
      onError: (error) => {
        console.error("Failed to fetch campaign details:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  // Mutation for opting out
  const { mutate: optOut, isLoading: isOptingOut } = useMutation(
    () => client.campaigns.optout(campaignId),
    {
      onSuccess: () => {
        navigate('/campaigns'); // Redirect or any other appropriate action
      },
      onError: (error) => {
        console.error("Failed to opt out:", error);
      },
    }
  );
  
  
  

  const handleOptOut = () => {
    console.log("Campaign ID being passed:", campaignId); // Log to ensure campaignId is correct
    optOut();
  };
  

  const handleCloseDialog = () => {
    setShowOptOutDialog(false);
  };

  if (isLoading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading campaign details...
      </Typography>
    );
  }

  if (error || !campaignDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Failed to load campaign details.
      </Typography>
    );
  }

  return (
    <>
      <BlitzHeader />
      <Toolbar />
      <PageTitle title={`${campaignDetails.name} - Opt Out`} />

      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          {campaignDetails.name}
        </Typography>
        <Typography variant="body1">{campaignDetails.brief}</Typography>
        <Typography variant="body1">{campaignDetails.status}</Typography>

        {/* Add any other basic info about the campaign here */}

        <Dialog
          open={showOptOutDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>Opt Out of Notifications</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to opt out of notifications from this campaign?
              You can always start this campaign within your Blitz account whenever you're ready.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              onClick={handleOptOut}
              color="primary"
              variant="contained"
              disabled={isOptingOut}
            >
              Opt Out
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default OptOutCampaign;
