import React from "react";
import { Box, Typography, Container, Toolbar, Grid, Card, CardContent, CardActionArea } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";

const articles = [
  {
    title: "Streamline Your Influencer Campaigns with AI-Powered Blitz",
    description: "Learn how Blitz's AI-powered tools revolutionize influencer marketing.",
    image: "https://via.placeholder.com/400x250",
    link: "/articles/streamline-campaigns",
  },
  {
    title: "Future of Creator and Vendor Payments",
    description: "Discover how BlitzPay ensures fast, reliable, and transparent payouts.",
    image: "https://via.placeholder.com/400x250",
    link: "/articles/future-payments",
  },
  {
    title: "The Hottest New Free Creator Software",
    description: "Explore Blitz’s free tools to manage campaigns, creators, and payments.",
    image: "https://via.placeholder.com/400x250",
    link: "/articles/hottest-software",
  },
  // Add more articles as needed
];

const Articles = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
          Articles and Insights
        </Typography>

        {/* Articles Grid */}
        <Grid container spacing={4}>
          {articles.map((article, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardActionArea
                  href={article.link}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    component="img"
                    src={article.image}
                    alt={article.title}
                    sx={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "8px 8px 0 0",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                      {article.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#666" }}>
                      {article.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default Articles;
