import { Close, CloudUpload } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Tooltip,
  Alert,
  AlertTitle,
  Link,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import useIsDesktop from '../../Hooks/useIsDesktop';
import { TrackerTable } from './trackerTable';
import UploadFileDialog from '../../Components/tools/uploadfile';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../API';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../Config/routes';

export function Tracker({ open, handleClose, tracker }) {
  const isDesktop = useIsDesktop();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [updatedTracker, setUpdatedTracker] = useState(tracker);
  const [updating, setUpdating] = useState(false);
  const [exporting, setExporting] = useState(false);

  const queryClient = useQueryClient();
  // Update `updatedTracker` when `tracker` prop changes
  useEffect(() => {
    setUpdatedTracker(tracker);
  }, [tracker]);

  const trackedBlitzCreators = tracker?.links?.reduce((accum, currLink) => (!!currLink.creatorId ? accum + 1 : accum), 0);

  // Mutation to update the tracker
  const { mutate: updateTracker } = useMutation(
    ({ id, data }) =>
      client.trackers.update(id, data).then(() => client.trackers.get(id)),
    {
      onSuccess: (data) => {
        setUpdatedTracker(data); // Update with the full updated tracker
        setUpdating(false);
      },
      onError: (error) => {
        console.error('Error updating tracker:', error);
        alert(`Network error: ${error?.response?.data?.error || error.message}`);
        setUpdating(false);
      },
      // Invalidate any queries that might have cached the tracker data
      onSettled: () => {
        queryClient.invalidateQueries(['tracker', updatedTracker.id]);
      },
    }
  );

  // Mutation to export tracker to sheets
  // In your Tracker component
  const { mutate: exportToSheets } = useMutation(
    (trackerId) => client.trackers.exportSheet(trackerId),
    {
      onSuccess: (data) => {
        setExporting(false);
        window.open(data.sheetLink, '_blank'); // Access sheetLink directly
      },
      onError: (error) => {
        console.error('Error exporting tracker to sheets:', error);
        alert(`Network error: ${error?.response?.data?.error || error.message}`);
        setExporting(false);
      },
    }
  );


  const handleFileUploadSuccess = (url) => {
    setUploadDialogOpen(false);
    setUpdating(true);
    // Use `updatedTracker.id` to ensure we're using the latest ID
    updateTracker({ id: updatedTracker.id, data: { tracker_photo: url } });
  };

  const handleExportToSheets = () => {
    setExporting(true);
    exportToSheets(updatedTracker.id); // Pass only the tracker ID
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={!isDesktop}
      fullWidth
      maxWidth="xl"
      PaperProps={{ elevation: 2 }}
    >
      <DialogTitle sx={{ backgroundImage: `url('${tracker?.tracker_photo}')` }}>
        &nbsp;
        {!isDesktop && (
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers={true}>
        {trackedBlitzCreators && <Alert severity="info" sx={{ marginBlockEnd: 2 }}>
          <AlertTitle>Run your next campaign with Blitz!</AlertTitle>
          {trackedBlitzCreators} of the creators tracked are available on the Blitz Marketplace. <br />By running a campaign in Blitz, you get access to Demographic Projections, Instant Creator payment, Daily Tracking, and more! <br />
          <Link component={RouterLink} to={routes.search}>Try It!</Link>
        </Alert>}
        {/* Tracker Details Card */}

        <Typography variant="h6" component="div">
          Tracker: {updatedTracker?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Date: {new Date(updatedTracker?.date_created || 0).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Status: {updatedTracker?.status}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
          Description: {updatedTracker?.description}
        </Typography>
        <Button
          onClick={() => setUploadDialogOpen(true)}
          variant="outlined"
          sx={{ marginBottom: 2 }}
        >
          Change Tracker Photo
        </Button>


        {/* Tracker Table */}
        <TrackerTable tracker={updatedTracker} />
      </DialogContent>
      <DialogActions >
        <Tooltip title="Export to Google Sheets">
          <Button
            startIcon={<CloudUpload></CloudUpload>}
            onClick={handleExportToSheets}
            color='success'
            variant="text"
          >
            Export
          </Button>
        </Tooltip>
        <Button onClick={handleClose} color="secondary" variant="text">
          Close
        </Button>
      </DialogActions>

      <UploadFileDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onUploadSuccess={handleFileUploadSuccess}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 10 }}
        open={updating || exporting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
