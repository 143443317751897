import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../Components/HomeHeader";
import { globalStyles } from "../../Utils/Styles";
import HomeFooter from "../../Components/HomeFooter";
import { StyledDivider as Divider } from "../../Components/LightDivider";

const BlitzSummary = () => {

  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width:'100%',
      color: '#000'
    }}>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          color: "#000",
          marginBlockStart:4
        }}
      >
        {/* Blitz Summary Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ textAlign: "center", marginBlockEnd: 2 }}>
            Blitz Summary
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 4 }}>
            Pay - Create - Accelerate
          </Typography>
        </Box>

        <Divider />

        {/* About Blitz Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            About Blitz
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Blitz is a comprehensive AI-powered collaboration management software, meticulously designed to streamline operations for the entire creator economy. From offering the largest influencer marketplace in the world, to enabling brands and agencies to easily create unlimited campaigns and partnerships, automate their collaborations, and manage the finances of all projects, Blitz emerges as a refreshing take on the creator economy.
          </Typography>
        </Box>

        <Divider />

        {/* Accounts Payable / Vendors Overview */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Accounts Payable / Vendors Overview
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            BlitzPay, a feature of Blitz, is used all over the world by companies wanting to speed up and enhance their collaboration efforts by allowing for advancing payments to creators for a nominal fee.
          </Typography>
        </Box>

        <Divider />

        {/* Marketplace & Collaboration Features */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Marketplace & Collaboration Features
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            AI-Powered Campaign Management<br />
            Data-Driven Reporting<br />
            Immense Personalization for Partnerships, Legal, and More<br />
            Seamless Integration and Efficiency<br />
            Transparency in Negotiation and Price<br />
            Compliance and Security<br />
            Scalability and Growth
          </Typography>
        </Box>

        <Divider />

        {/* For Creators */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            For Creators
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            AI Manager - Improve Deal Flow Instantly<br />
            Access to the World's Top Brands<br />
            Personalized Business Management Suite<br />
            Financial Services for Tax and Investment Planning
          </Typography>
        </Box>

        <Divider />

        {/* Creators - Increase Your Revenue */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Creators - Increase Your Revenue by 2-3x
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            By leveraging Blitz's powerful tools and resources, you can significantly increase your revenue, achieving 2-3x growth.
          </Typography>
        </Box>
      </Container>

      <HomeFooter></HomeFooter>
    </Box>
  );
};

export default BlitzSummary;
