import React, { useMemo } from 'react'
import { getPlatformAndPromotion } from '../../../../Utils/constants';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Collapse, Typography } from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Add as AddIcon,
} from '@mui/icons-material';
import CampaignTimelineEntry from './campaignTimelineEntry';

export default function CampaignTimelineCard({
    creator,
    expandedCreators,
    handleExpandClick,
    handleAddCreatorEvent,
    handleDeleteCreatorEvent,
    handleEditCreatorEvent,
    setCreatorTimelines,
    masterTimeline,
    creatorTimelines }) {
    const platPromo = useMemo(() => getPlatformAndPromotion(creator.rateKey), ['creator'])
    return (
        <Card sx={{ marginBottom: 2, display: 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: { xs: 100, sm: 151 }, height: 151, maxHeight: 'none', maxWidth: 'none', minWidth: { xs: 100, sm: 151 }, minHeight: 151 }}
                image={creator.pfphref || '/default_profile.png'}
                alt={creator.creator}
            />
            <Box sx={{display:'flex', flexDirection:'column', flex:1}}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <CardContent sx={{ flex: '1 0 auto', padding: { xs: 0, sm: 2 } }}>
                        <Box>
                            <Typography component="div" variant="h6">
                                {creator.creator}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                            >
                                {platPromo.platform} - {platPromo.promotion}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={() => handleExpandClick(creator.creator)}
                            startIcon={<ExpandMoreIcon />}
                        >
                            {expandedCreators[creator.creator] ? 'Hide Timeline' : 'Show Timeline'}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddCreatorEvent(creator.creator)}
                        >
                            Add Event
                        </Button>
                    </CardActions>
                </Box>
                <Collapse
                    in={expandedCreators[creator.creator]}
                    timeout="auto"
                    unmountOnExit
                    sx={{alignSelf:'flex-end'}}
                >
                    <CardContent sx={{display:'flex', flexDirection:'column', maxWidth:'20em'}}>
                        {(creatorTimelines[creator.creator] || []).map((event, index) => (
                            <CampaignTimelineEntry
                                key={event.id}
                                event={event}
                                onEditField={(field, value) =>
                                    handleEditCreatorEvent(creator.creator, index, field, value)
                                }
                                onDelete={() => handleDeleteCreatorEvent(creator.creator, index)}
                            />
                        ))}
                        {(!creatorTimelines[creator.creator] ||
                            creatorTimelines[creator.creator].length === 0) && (
                                <Box textAlign="center" marginTop={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        No events. You can add events manually or apply the master
                                        timeline.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setCreatorTimelines((prev) => ({
                                                ...prev,
                                                [creator.creator]: masterTimeline.map((event) => ({
                                                    ...event,
                                                    id: Date.now() + Math.random(),
                                                })),
                                            }));
                                        }}
                                        sx={{ marginTop: 1 }}
                                    >
                                        Apply Master Timeline
                                    </Button>
                                </Box>
                            )}
                    </CardContent>
                </Collapse>
            </Box>
        </Card>
    )
}
