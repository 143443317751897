import React, { useState } from "react";
import logo from "../globalAssets/logo_placeholder.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/use-auth";
import routes from "../../Config/routes.js";
import client from "../../API";
import { useQuery } from "react-query";
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  styled,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
} from "@mui/material";
import { drawerWidth } from "../../Utils/constants.js";
import styles from "./NavBar.module.css";
import ListItemLink from "../ListItemLink.js";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  CreditCardOutlined,
  HomeOutlined,
  InsertDriveFileOutlined,
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp,
  LiveHelpOutlined,
  MonetizationOnOutlined,
  PeopleOutline,
  RateReviewOutlined,
  ReceiptLongOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import WhiteListButton from "../WhiteListButton.js";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TrackChangesOutlined from "@mui/icons-material/TrackChangesOutlined";
import HandshakeOutlined from "@mui/icons-material/HandshakeOutlined";
import CampaignOutlined from "@mui/icons-material/CampaignOutlined";
import ExtensionOutlined from "@mui/icons-material/ExtensionOutlined";
import AccountBalanceWalletOutlined from "@mui/icons-material/AccountBalanceWalletOutlined";

import API from "../../API";

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: "#000",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} - 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} - 1px)`,
  },
});

const IconDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "extended" })(
  ({ theme, extended }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(extended && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!extended && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const ListItemLinkWithTooltip = ({ to, primary, icon, tooltip, isOpen = true, count }) => (
  <Tooltip title={tooltip} placement="right">
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemLink
        to={to}
        primary={primary}
        icon={
          count !== undefined && count > 0 ? (
            <Badge badgeContent={count} color="secondary">
              {icon}
            </Badge>
          ) : (
            icon
          )
        }
        isOpen={isOpen}
      />
    </ListItem>
  </Tooltip>
);

const Navbar = ({ isDesktop = true, isOpen = true, onOpen, onClose }) => {
  const { getCurrrentUser, isAuthorized, unauthorize } = useAuth();
  const userInfo = getCurrrentUser();
  const navigate = useNavigate();

  const isExtended = !isDesktop || isOpen;

  const logout = () => {
    unauthorize();
  };

  const [companyData, setCompanyData] = useState({
    balance: "Loading...",
    credit_line: "Loading...",
    phphref: "",
    account_status: "Loading...",
  });

  useQuery(['company', userInfo?.company_id], client.companies.listFetch, {
    onSuccess: (data) => {
      if (data && data.balance !== undefined && data.credit_line !== undefined) {
        setCompanyData({
          balance: `$${parseFloat(data.balance).toFixed(2)}`,
          credit_line: data.credit_line
            ? `$${parseFloat(data.credit_line).toFixed(2)}`
            : "No Credit Line Established",
          phphref: data.phphref || "",
          account_status: data.account_status || "",
        });
      } else {
        console.error("Received data is not in the expected format:", data);
      }
    },
    onError: (error) => {
      console.error("Error fetching company data:", error);
      setCompanyData({
        balance: "Error loading balance",
        credit_line: "Error loading credit line",
        phphref: "",
        account_status: "Error loading account status",
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 1000*60*30,
    enabled: !!userInfo
  });

  // Fetch dashboard data
  const {
    data: dashboardData,
    isLoading: dashboardIsLoading,
    error: dashboardIsError,
  } = useQuery({
    queryKey: ["user", "dashboard"],
    queryFn: API.users.dashboard,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  // Define listItems inside the component, after fetching data
  const listItems = [
    {
      to: routes.dashboard,
      primary: "Dashboard",
      icon: <HomeOutlined />,
      tooltip: "View and manage your dashboard",
    },
    {
      to: routes.search,
      primary: "Search",
      icon: <StorefrontOutlined />,
      tooltip: "Explore our creator selection and make projects from there",
    },
    {
      to: routes.collaborations,
      primary: "Collaborations",
      icon: <HandshakeOutlined />,
      tooltip: "Manage collaborations and projects",
      count: dashboardIsLoading
        ? undefined
        : (dashboardData?.campaigns?.length ?? 0) + (dashboardData?.partnerships?.length ?? 0),
    },
    {
      to: routes.blitzpay,
      primary: "BlitzPay",
      icon: <CreditCardOutlined />,
      tooltip: "Make instant payments through Blitz Pay",
    },
  
    {
      to: routes.castingCalls,
      primary: "Casting Calls",
      icon: <CampaignOutlined />,
      tooltip: "View and manage casting calls",
    },
    {
      to: routes.assetsManager,
      primary: "File Management",
      icon: <InsertDriveFileOutlined />,
      tooltip: "Manage your digital assets, files, and more",
    },
    
    {
      to: routes.integrations,
      primary: "Integrations",
      icon: <ExtensionOutlined />,
      tooltip: "Manage integrations with external tools",
    },
    {
      to: routes.dealsIO,
      primary: "Deal Factoring",
      icon: <AccountBalanceWalletOutlined  />,
      tooltip: "Manage Deal Factoring",
    },
  ];

  return (
    <IconDrawer
      id="NavbarContainer"
      variant={isDesktop ? "permanent" : "temporary"}
      sx={{
        color: "#000 !important",
        "& .MuiDrawer-paper": {
          color: "#000 !important",
          boxSizing: "border-box",
          backgroundColor: "#FFF !important",
          backgroundImage: "none !important",
          overflowY: { xs: 'auto', md: "hidden" },
          scrollbarWidth: 'auto',
          scrollbarColor: 'rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0.24)',
          scrollbarWidth:'thin',
          maxHeight: '100vh'
        },
      }}
      open={isDesktop || isOpen}
      extended={isExtended}
      onClose={onClose}
    >
      <Box
        className={styles.navbarHeader}
        sx={{
          marginInline: 2,
          marginBlockStart: 2,
          display: { xs: "none", md: "flex" },
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isExtended ? "space-between" : "center",
        }}
      >
        <Box className={styles.logo}>
          {isExtended && (
            <RouterLink to={isAuthorized ? routes.dashboard : routes.home}>
              <img src={logo} alt="Logo" />
            </RouterLink>
          )}
        </Box>
        <WhiteIconButton
          onClick={() => {
            isOpen ? onClose() : onOpen();
          }}
        >
          {isExtended ? <KeyboardArrowLeftSharp /> : <KeyboardArrowRightSharp />}
        </WhiteIconButton>
      </Box>
      {isDesktop && (
        <Divider
          sx={{
            marginBlockStart: 2,
            marginBlockEnd: 1,
            borderColor: "rgba(0, 0, 0, 0.12) !important",
          }}
        />
      )}
      {isExtended && (
        <Box className={styles.user}>
          <Box className={styles.userTab} sx={{ marginBlockStart: { xs: 2, md: 0 } }}>
            <Box className={styles.userInfo}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: isExtended ? "space-between" : "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    maxWidth: isExtended ? "auto" : 0,
                    overflowX: "clip",
                  }}
                >
                  <Typography variant="subtitle1">
                    <b>Hey, {`${userInfo?.first_name ?? ""}`}!</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{userInfo?.company_name}</b>
                  </Typography>
                </Box>
                <Tooltip title={"Log out"} placement="right">
                  <WhiteIconButton onClick={logout}>
                    <LogoutIcon />
                  </WhiteIconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: isExtended ? "space-between" : "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    maxWidth: isExtended ? "auto" : 0,
                    overflowX: "clip",
                  }}
                >
                  <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
                    Balance: {companyData.balance}
                  </Typography>
                  <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
                    Credit Line: {companyData.credit_line}
                  </Typography>
                </Box>
                <Tooltip title={"Modify your account settings"} placement="right">
                  <WhiteIconButton onClick={() => navigate(routes.user)}>
                    <SettingsOutlinedIcon />
                  </WhiteIconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isDesktop && isExtended && (
        <Divider
          sx={{
            borderColor: "rgba(0, 0, 0, 0.12) !important",
          }}
        />
      )}
      <Box
        id='Icon-List'
        sx={{
          flex: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          id='Scrollable-Icon-List'
          sx={{
            flexGrow: 1,
            flexShrink: { xs: 0, md: 1 },
            minHeight: 0,
            overflowY: { xs: 'visible', md: isExtended ? 'auto' : 'clip' },
            overflowX:'clip',
            scrollbarWidth: 'auto',
            scrollbarColor: 'rgba(0, 0, 0, 0.24) transparent',
            scrollbarWidth:'thin'
          }}>
          <Box sx={{ maxHeight: 'max-content', minHeight: 'max-content' }}>
            <List disablePadding>
              {listItems.map((item) => (
                <ListItemLinkWithTooltip key={item.to} {...item} isOpen={isExtended} />
              ))}
              {(companyData.account_status === "Agency" ||
                companyData.account_status === "AlphaFree") && (
                  <ListItemLinkWithTooltip
                    isOpen={isExtended}
                    key={routes.agency}
                    to={routes.agency}
                    primary="My Agency"
                    icon={<PeopleOutline />}
                    tooltip="Manage your agency's roster, campaigns, and more"
                  />
                )}
              {companyData.account_status === "AlphaFree" && (
                <ListItemLinkWithTooltip
                  isOpen={isExtended}
                  key={routes.creatorCRMPublic}
                  to={routes.creatorCRMPublic}
                  primary="Creator CRM"
                  icon={<RateReviewOutlined />}
                  tooltip="Manage your creator relationships"
                />
              )}
              {companyData.account_status === "AlphaFree" && (
                <ListItemLinkWithTooltip
                  isOpen={isExtended}
                  key={routes.trackers}
                  to={routes.trackers}
                  primary="Trackers"
                  icon={<TrackChangesOutlined />}
                  tooltip="Track data from your list of social media links"
                />
              )}
            </List>
          </Box>
        </Box>

        <Box>
          <Divider
            sx={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
              borderColor: "rgba(0, 0, 0, 0.12) !important",
            }}
          />
          <ListItem disablePadding component={"div"}>
            <WhiteListButton
              onClick={() => navigate("/help")}
              sx={{
                justifyContent: isExtended ? "initial" : "center",
              }}
            >
              <ListItemIcon
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  minWidth: "36px",
                  marginInlineEnd: isOpen ? 0 : "auto",
                  justifyContent: isExtended ? "initial" : "center",
                }}
              >
                <LiveHelpOutlined />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "1.2rem" }}
                sx={{ opacity: isExtended ? 1 : 0 }}
                primary="Help"
              />
            </WhiteListButton>
          </ListItem>
        </Box>
      </Box>
    </IconDrawer>
  );
};

export default Navbar;
