import React, { useState } from 'react';
import { Box, Typography, Backdrop, CircularProgress, Toolbar, Button, TextField } from '@mui/material';


import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routes from '../Config/routes';
import { useCreatorAuth } from '../Hooks/creator-use-auth';
import { ConfigValue } from '../Config';

import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import BlitzHeader from '../Components/BlitzHeader';
import API from '../API';

const InternalLogin = () => {
    const navigate = useNavigate();
    const { login } = useCreatorAuth();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const [sub, setSub] = useState('');
    const [pass, setPass] = useState('');

    const queryParams = new URLSearchParams(location.search);

    // Check if the 'redirect' query parameter is set to 'edit'
    const redirect = queryParams.get('redirect');

    const GoToDesiredPage = (loginRedirect) => {
        switch (loginRedirect) {
            case 'edit':
                navigate(routes.creatorConnectEdit);
                return;
        }
        switch (redirect) {
            case 'edit':
                const newQueryParams = new URLSearchParams();
                newQueryParams.set('high', 'platforms'); // Add the parameter you want to pass
                navigate(`${routes.creatorConnectEdit}?${newQueryParams.toString()}`)
                break;
            default:
                navigate(routes.creatorConnectStart)
                break;
        }
    }

    const handleLoginSuccess = async () => {
        setIsLoading(true);
        try {
            const data = await API.creatorConnect.internallogin({ sub:sub, cron:pass });
            const {redirect: loginRedirect, ...loginData} = data;
            login(loginData);
            openDialog("Success", "You have logged in successfully",
                ()=>GoToDesiredPage(loginRedirect),
                ()=>GoToDesiredPage(loginRedirect), "Ok", null);
        } catch (error) {
            openDialog("Failure", 'Login process encountered an error.', closeDialog, closeDialog, "Ok", null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Failed to login with Google:', error);
        openDialog("Failure", 'Google login failed, please try again.', closeDialog, closeDialog, "Ok", null);
    };

    return (
        <>
            <BlitzHeader>
            </BlitzHeader>
            <Toolbar></Toolbar>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5rem' }}>
                <AlertDialog alertState={dialogState}></AlertDialog>
                <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>Log in to Blitz to see your campaigns</Typography>
                <Box style={{ width: '80%', maxWidth: '300px' }}>
                    <TextField
                    value={sub}
                    onChange={(e)=>{setSub(e.target.value)}}
                    ></TextField>
                    <TextField
                    value={pass}
                    onChange={(e)=>{setPass(e.target.value)}}
                    ></TextField>
                    <Button onClick={handleLoginSuccess}>Log</Button>
                </Box>
            </Box>
        </>
    );
};

export default InternalLogin;
