import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

export default function GenderCard({ data, fill }) {


    return (
        <Card variant='outlined'>
            <CardHeader subheader='Audience Gender'></CardHeader>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={data || []}
                        cx="50%"
                        cy="50%"
                        dataKey="value"
                        nameKey="name"
                        outerRadius="70%"
                        label
                    >
                        {[0,1].map((entry, index) => (
                            <Cell key={index} fill={["#AAA", "#666"][index]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </Card>
    )
}
