import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Box, CssBaseline, Toolbar, ThemeProvider, Container, Divider, Link } from '@mui/material';
import blitzLogo from "../../Components/globalAssets/blitzLogo.png";
import API from '../../API';
import { StyleContent } from '../../Utils/ThemedStyles';
import { useQuery } from 'react-query';
import { lightTheme } from '../../Utils/baseTheme';
import HomeHeader from '../../Components/HomeHeader';
import { platforms, platformToFollowing, sortCreators } from '../../Utils/constants';

const PartnerCreatorRoster = () => {

  const { data: creators, isLoading: loading, error } = useQuery({
    queryKey: ['partners'],
    queryFn: async () => sortCreators((await API.creators.listPartners()).creators || []),
  })

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{
        backgroundColor: "#f5f5f5",
        width: '100%',
        height: '100%',
        minHeight:'100vh'
      }}>
        <CssBaseline></CssBaseline>
        <HomeHeader></HomeHeader>
        <Toolbar></Toolbar>
        <Container
          sx={{
            paddingTop: 2,
            paddingBottom: 4,
          }}
        >
          {/* Solutions Header */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ textAlign: "center", fontWeight: "bold", my: 2 }}>
              Our Creator Partners
            </Typography>
          </Box>

          <Divider />

          {loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>}

          {error&&<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Typography color="error" variant="h6">
              {error}
            </Typography>
          </Box>}

          {!!creators && <Grid container sx={{ ...StyleContent, padding: 3 }} spacing={2}>
            {creators.length > 0 ? (
              creators.map((creator) => {

                const creatorPlatforms = platforms.filter(platform => !!creator[platformToFollowing[platform]]);

                const totalFollowers = platforms.reduce((prev, platform) => {
                  return prev + parseInt(creator[platformToFollowing[platform]] || '0', 10)
                }, 0);

                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={creator.creator}>
                    <Card sx={{ maxWidth: 345, margin: 'auto' }} variant='outlined'>
                      <CardMedia
                        sx={{ height: 200 }}
                        image={creator.pfphref || blitzLogo}
                        title={`${creator.creator}'s profile image`}
                      />
                      <CardContent>
                        <Link
                          variant='h5'
                          color='text.primary'
                          underline="hover"
                          href={`https://www.useblitz.co/creators/${creator.creator}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          sx={{ margin: 0, minWidth: 0 }}
                        >
                          @{creator.creator}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                          Platforms: {creatorPlatforms.length > 0 ? creatorPlatforms.join(', ') : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Followers: {totalFollowers?.toLocaleString() || 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  No creators available for this partner.
                </Typography>
              </Grid>
            )}
          </Grid>}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default PartnerCreatorRoster;
