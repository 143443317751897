import React, { useState, useEffect} from "react";
import {
    Paper,
    Backdrop,
    CircularProgress,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    Box,
    Typography,
    TableBody,
    TableRow,
    Chip,
    Snackbar,
    Alert,
    TableFooter,
    TablePagination
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import API from "../../../../API";
import useIsDesktop from "../../../../Hooks/useIsDesktop";
import { StyledTableRow } from "../../../../Utils/styledcell";
import BrandEmailDialog from "./brandEmailDialog";

const styles = {
    singleLine: { wordBreak: 'keep-all', whiteSpace: 'nowrap' },
};

const HideMobileCell = styled(TableCell)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'table-cell',
    },
}));

const rowsPerPage = 15;

export default function BrandEmails({ }) {
    const [toastOpen, setToastOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [selectedEmail, setSelectedEmail] = useState(null)
    const isDesktop = useIsDesktop();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const { data: keywords, error: keywordsErrors, isLoading: keywordsLoading } = useQuery({
        queryKey: ['creatorEmailsKeywords'],
        queryFn: API.creators.adminBrandKeywords,
        staleTime: 1000.0 * 60 * 60,
        refetchOnWindowFocus: false,
    });
    
    const { data: emails, error: emailsError, isFetching: fetchingEmails } = useQuery({
        queryKey: ['creatorEmails', page],
        queryFn: ()=>API.creators.adminBrandEmailsList({ page, rows: rowsPerPage }),
        refetchInterval: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        enabled: !!keywords
    });

    useEffect(() => {
        if (!!emailsError) {
            console.error('An error occurred:', emailsError.message);
            setToastOpen(true);
        }
    }, [emailsError]);

    const onCloseDialog = () => {
        setSelectedEmail(null);
    }

    return (
        <Box sx={{padding:2}}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={ (keywordsLoading||fetchingEmails) && !emailsError}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
                    {emailsError?.response?.data?.error || emailsError?.message || ''}
                </Alert>
            </Snackbar>
            <Box sx={{ marginBlockStart: 1, maxWidth: '100em', marginInline: "auto" }}>
                <TableContainer component={Paper} elevation={1}>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <HideMobileCell align='left'>
                                    ID
                                </HideMobileCell>
                                <TableCell sx={{ width: '15rem' }}>
                                    Sender
                                </TableCell>
                                <TableCell sx={{ width: '15rem' }}>
                                    Recipient
                                </TableCell>
                                <TableCell sx={{ minWidth: '25rem' }}>
                                    Content
                                </TableCell>
                                <HideMobileCell align='right'>
                                    Date
                                </HideMobileCell>
                                <TableCell align='left'>
                                    Category
                                </TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {emails && emails.emails.map((row, index) => (
                                <StyledTableRow key={index} onClick={()=>setSelectedEmail(row.id)}>
                                     <TableCell align='left'>
                                        {row.id}
                                    </TableCell>
                                    <TableCell sx={{ overflowX: 'hidden', width: '15rem', maxWidth: 0 }} align='left'>
                                        <span style={styles.singleLine}>{row.sender}</span>
                                    </TableCell>
                                    <TableCell sx={{ overflowX: 'hidden', width: '15rem', maxWidth: 0 }} align='left'>
                                        <span style={styles.singleLine}>{row.receiver}</span>
                                    </TableCell>
                                    <TableCell sx={{ overflowX: 'hidden', minWidth: '25rem', maxWidth: 0 }} align='left'>
                                        <span style={styles.singleLine}>{row.subject} - <Typography variant='caption' color='text.secondary'>{row.snippet}</Typography></span>
                                    </TableCell>
                                    <HideMobileCell align='right'>
                                        <span style={styles.singleLine}>{row.date_received?.toLocaleString()}</span>
                                    </HideMobileCell>
                                    <TableCell align='left'>
                                        <Chip label={row.category} color={row.category == 'Paid' ? 'primary' : 'secondary'}></Chip>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={isDesktop ? 6 : 4}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[rowsPerPage]}
                                    count={emails?.total || 0}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <BrandEmailDialog email={selectedEmail} keywords={keywords?.keywords_mapping} onClose={onCloseDialog} ></BrandEmailDialog>
        </Box>
    );
};