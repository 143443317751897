import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Button,
} from '@mui/material';
import { getPlatformAndPromotion } from '../../../../Utils/constants';

const CampaignConfirmation = ({
  campaignData,
  selectedCreatorsData = [],
  isBlitzFeeOnCreators,
  timelinesData,
  onBack,
  onFinish,
  idealDueDate
}) => {
  // Calculate the project sum
  const projectSum = selectedCreatorsData.reduce(
    (sum, creator) => sum + (creator.adjustedPrice || 0),
    0
  );

  const blitzFee = isBlitzFeeOnCreators ? 0 : projectSum * 0.05;

  const finalCost = projectSum + blitzFee;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 800 }}>
        <Typography variant="h5" gutterBottom>
          Campaign Confirmation
        </Typography>
        <Typography variant="subtitle1">
          <strong>Campaign Name:</strong> {campaignData.campaignName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Campaign Brief:</strong> {campaignData.campaignBrief}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Due Date:</strong> {idealDueDate.toLocaleDateString()}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Email Recipient:</strong> {campaignData.emailRecipient}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Is Agency:</strong> {campaignData.isAgency ? 'Yes' : 'No'}
        </Typography>
        {campaignData.manager && (
          <Typography variant="subtitle1">
            <strong>Manager:</strong> {campaignData.manager.name} (
            {campaignData.manager.email})
          </Typography>
        )}
        {campaignData.asset && (
          <Typography variant="subtitle1">
            <strong>Attached Asset:</strong> {campaignData.asset}
          </Typography>
        )}
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h6" gutterBottom>
          Selected Creators
        </Typography>
        <Grid container spacing={2}>
          {selectedCreatorsData.map((creator) => {
            const platPromo = getPlatformAndPromotion(creator.rateKey);
            return (
            <Grid item xs={12} key={creator.creator}>
              <Card sx={{ display: 'flex', marginBottom: 2 }}>
                <CardMedia
                  component="img"
                  sx={{ width: 151, height: 151 }}
                  image={creator.pfphref || '/default_profile.png'}
                  alt={creator.name}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Typography component="div" variant="h6">
                      {creator.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {platPromo.platform} - {platPromo.promotion}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Base Price:</strong> $
                      {creator.basePrice ? parseFloat(creator.basePrice).toFixed(2) : '0.00'}
                    </Typography>
                    {false&&<Typography variant="body2">
                      <strong>Cross-Post:</strong>{' '}
                      {creator.crossPost ? 'Yes' : 'No'}
                    </Typography>}
                    <Typography variant="body2">
                      <strong>Usage Rights:</strong>{' '}
                      {creator.usageRights ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Adjusted Price:</strong> $
                      {creator.adjustedPrice
                        ? parseFloat(creator.adjustedPrice).toFixed(2)
                        : '0.00'}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          )})}
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h6">
          Project Sum: ${projectSum.toFixed(2)}
        </Typography>
        <Typography variant="h6">
          Blitz Fee: ${blitzFee.toFixed(2)}
        </Typography>
        <Typography variant="h6">
          Final Cost: ${finalCost.toFixed(2)}
        </Typography>
        {/* Optionally display timelines data */}
        {timelinesData && (
          <>
            <Divider sx={{ marginY: 2 }} />
            <Typography variant="h6" gutterBottom>
              Timelines
            </Typography>
            {Object.entries(timelinesData).map(([creatorId, events]) => (
              <Box key={creatorId} sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Creator:</strong>{' '}
                  {
                    creatorId
                  }
                </Typography>
                {events.map((event, index) => (
                  <Box key={index} sx={{ paddingLeft: 2 }}>
                    <Typography variant="body2">
                      <strong>Objective:</strong> {event.objective}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Notes:</strong> {event.notes}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Manager Notes:</strong> {event.manager_notes}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Projected Date:</strong>{' '}
                      {event.projected_date.toLocaleDateString()}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </>
        )}
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}
        >
          <Button variant="contained" onClick={onBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={onFinish}>
            Confirm and Finish
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignConfirmation;
