import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Badge,
  List,
  ListItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import API from '../../../API';
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import ConsentForm from "../popups/consentform";
import { useGoogleLogin } from "@react-oauth/google";
import UploadFileDialogPFP from "../../onboardupload";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import { useSearchParams } from "react-router-dom";
import CreatorPlatforms from "../../../Components/CreatorData/creatorPlatforms";
import { platformSelection, validatePlatformSelection } from "../../../Components/CreatorData/creatorValidation";
import TabPanelHidden from "../../../Components/TabPanelHidden";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import CreatorPfp from "../../../Components/CreatorData/creatorPfp";
import CreatorInfo from "../../../Components/CreatorData/creatorInfo";

const EditCreator = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current tab value from search parameters; default to "0" if none exists
  const tabValue = searchParams.get('tab') || 'platforms';

  // Handle tab change
  const handleChangeTab = (event, newValue) => {
    setSearchParams((params) => ({ ...params, tab: newValue }));
  };
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const isDesktop = useIsDesktop();

  const [contactEmail, setContactEmail] = useState('');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [creatorRegion, setCreatorRegion] = useState('');
  const [platforms, setPlatforms] = useState(platformSelection);
  const [primaryMarket, setPrimaryMarket] = useState('');
  const [notesContentStyle, setNotesContentStyle] = useState('');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');
  const [location, setLocation] = useState('');

  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");
  const [instagramFeedRate, setInstagramFeedRate] = useState("");

  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtubeIntegrationRate, setYoutubeIntegrationRate] = useState("");
  const [youtubeShortsRate, setYoutubeShortsRate] = useState("");
  const [youtubeDedicatedRate, setYoutubeDedicatedRate] = useState("");

  const [xLink, setXLink] = useState('');
  const [xFollowerCount, setXFollowerCount] = useState('');
  const [xFeedPost, setXFeedPost] = useState('');
  const [xRepost, setXRepost] = useState('');

  const [podcastLink, setPodcastLink] = useState('');
  const [podcastFollowerCount, setPodcastFollowerCount] = useState('');
  const [podcastPreroll, setPodcastPreroll] = useState('');
  const [podcastMidroll, setPodcastMidroll] = useState('');
  const [podcastPostroll, setPodcastPostroll] = useState('');
  const [podcastFull, setPodcastFull] = useState('');

  const [kickLink, setKickLink] = useState('');
  const [kickFollowerCount, setKickFollowerCount] = useState('');
  const [kickPlacement, setKickPlacement] = useState('');

  const [twitchLink, setTwitchLink] = useState('');
  const [twitchFollowerCount, setTwitchFollowerCount] = useState('');
  const [twitchPlacement, setTwitchPlacement] = useState('');

  const [userStatus, setUserStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("PayPal");
  const [isStripeNew, setIsStripeNew] = useState(true);
  const [stripeUserId, setStripeUserId] = useState("");
  const [openConsent, setOpenConsent] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [processEmails, setProcessEmails] = useState(true);

  const [showPlatformsTip, setShowPlatformsTip] = useState(false);
  const [googleRequest, setGoogleRequest] = useState('');

  const [completedInfo, setCompletedInfo] = useState(true);

  const scope = [
    'https://www.googleapis.com/auth/gmail.modify',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ];

  const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx === 0 ? '' : ' ') + currSco), '');
  const googleLogin = useGoogleLogin({
    onError: errorResponse => {
      openDialog("Error", `Error getting Partner permissions: ${errorResponse}`, closeDialog, closeDialog, "Ok", null);
    },
    scope: scopeString,
    flow: "auth-code",
    ux_mode: 'redirect',
    redirect_uri: 'https://www.useblitz.co/creatorconnect/redirect',
    state: googleRequest
  });

  useEffect(() => {
    if (googleRequest && userStatus === "Partner") {
      googleLogin();
    }
  }, [googleRequest, userStatus]);

  // Check if the 'redirect' query parameter is set to 'edit'
  const high = searchParams.get('high');

  useEffect(() => {
    switch (high) {
      case 'platforms':
        // Show the tooltip
        setShowPlatformsTip(true);
        setSearchParams((params) => ({ ...params, tab: 'platforms' }));

        // Close the tooltip after 6 seconds
        const timer = setTimeout(() => {
          setShowPlatformsTip(false);
        }, 6000);

        // Cleanup the timer when the component unmounts or if the effect runs again
        return () => clearTimeout(timer);
    }
  }, []);

  const userData = useRef(null);

  async function GetUserData() {
    try {
      setIsLoading(true);
      const response = await API.creatorConnect.getCreator();
      const user = response.user;
      userData.current = response.user;
      setCompletedInfo(user.completed_info);

      const creator = response.user.creator;

      setContactEmail(creator.email);
      setPaymentEmail(user.paypal || '');
      setPhoneNumber(creator.phone_number || '1');
      setCreatorName(creator.creator);
      setCreatorRegion(creator.region || '');
      setPlatforms({
        TikTok: creator.tiktok_link !== null,
        Instagram: creator.instagram_link !== null,
        YouTube: creator.youtube_link !== null,
        X: creator.x_link !== null,
        Podcast: creator.podcast_link !== null,
        Kick: creator.kick_link !== null,
        Twitch: creator.twitch_link !== null,
      });
      setPrimaryMarket(creator.primary_market||'');
      setNotesContentStyle(creator.notes_content_style||'');
      setRace(creator.ethnicity || '');
      setGender(creator.gender || '');
      setLocation(creator.geolocation || '');
      setProfilePhotoUrl(creator.pfphref);

      //Instagram
      setInstagramLink(creator.instagram_link ? creator.instagram_link.replace("https://www.instagram.com/", "") : "");
      setInstagramFollowerCount(creator.instagram ? formatNumber(creator.instagram) : "");

      setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
      setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
      setInstagramFeedRate(creator.rate_ig_feedpost?.toFixed(2) || "");
      setInstagramStoryRate(creator.rate_ig_story?.toFixed(2) || "");

      //TikTok
      setTikTokLink(creator.tiktok_link ? creator.tiktok_link.replace("https://www.tiktok.com/@", "") : "");
      setTikTokFollowerCount(creator.tiktok ? formatNumber(creator.tiktok) : "");

      setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
      setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

      //Youtube
      setYoutubeLink(creator.youtube_link ? creator.youtube_link.replace("https://www.youtube.com/", "") : "");
      setYoutubeFollowerCount(creator.youtube ? formatNumber(creator.youtube) : "");

      setYoutubeIntegrationRate(creator.rate_yt_integ?.toFixed(2) || "");
      setYoutubeShortsRate(creator.rate_yt_short?.toFixed(2) || "");
      setYoutubeDedicatedRate(creator.rate_yt_dedicated?.toFixed(2) || "");

      //X
      setXLink(creator.x_link ? creator.x_link.replace("https://www.x.com/", "") : "");
      setXFollowerCount(creator.follow_x ? formatNumber(creator.follow_x.toString()) : "");

      setXFeedPost(creator.rate_x_feedpost?.toFixed(2) || "");
      setXRepost(creator.rate_x_repost?.toFixed(2) || "");

      //Kick
      setKickLink(creator.kick_link ? creator.kick_link.replace("https://www.kick.com/", "") : "");
      setKickFollowerCount(creator.follow_kick ? formatNumber(creator.follow_kick.toString()) : "");

      setKickPlacement(creator.rate_kick_product?.toFixed(2) || "");

      //Twitch
      setTwitchLink(creator.twitch_link ? creator.twitch_link.replace("https://www.twitch.tv/", "") : "");
      setTwitchFollowerCount(creator.twitch ? formatNumber(creator.twitch) : "");
      setTwitchPlacement(creator.rate_twitch_product?.toFixed(2) || "");

      //Podcast
      setPodcastLink(creator.podcast_link || "");
      setPodcastFollowerCount(creator.follow_podcast ? formatNumber(creator.follow_podcast) : "");
      setPodcastPreroll(creator.rate_podcast_preroll?.toFixed(2) || "");
      setPodcastMidroll(creator.rate_podcast_midroll?.toFixed(2) || "");
      setPodcastPostroll(creator.rate_podcast_postroll?.toFixed(2) || "");
      setPodcastFull(creator.rate_podcast_full?.toFixed(2) || "");

      setUserStatus(creator.status ? creator.status : (user.refresh_token ? 'Partner' : 'Associate'));

      setPaymentMethod(user.payout_preferred || '');
      setProcessEmails(user.process_emails);
      setIsStripeNew(user.stripe_id === null || user.stripe_id.length === 0);

      setIsLoading(false);

    } catch (error) {
      openDialog("Error", `Network error: ${(error.response && error.response.data.error) ? error.response.data.error : error.message}\n Try refreshing the page.`, closeDialog, closeDialog, "Ok", null);
      console.error('Network error:', error);
    }
  }

  useEffect(() => {
    GetUserData();
  }, []);

  const handleOpenUploadDialog = () => {
    setIsUploadDialogOpen(true);
  };

  const handleCloseUploadDialog = (uploadedUrl) => {
    if (uploadedUrl) {
      setProfilePhotoUrl(uploadedUrl);
    }
    setIsUploadDialogOpen(false);
  };

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setPaymentMethod(method);
    sessionStorage.setItem("paymentMethod", method);
    sessionStorage.setItem("creatorName", creatorName);
  };

  const goToStripeId = () => {
    const redirectUri = `https://www.useblitz.co/creatorconnect/start/stripe`;
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(redirectUri)}`;
  };

  const formatNumber = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "0" : number.toString();
  };

  const handleStartSaveCreator = () => {
    if (phoneNumber.length < 11) {
      alert(`This Phone number is not valid`);
      handleChangeTab(null, 'info');
      return;
    }

    if (!validatePlatformSelection(
      platforms,
      (message) => alert(message),
      tikTokBrandRate, tikTokSongRate,
      instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
      youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
      xFeedPost, xRepost,
      kickPlacement,
      twitchPlacement,
      podcastPreroll,
      podcastMidroll,
      podcastPostroll,
      podcastFull
    )) {
      handleChangeTab(null, 'platforms');
      return;
    }


    if (userStatus === 'Partner' && userData.current.creator.status != userStatus) {
      openDialog("Changing Partnership Status", "A Google pop-up will now open up for you to connect your Gmail account and become a Blitz Partner.", handleSaveCreator, handleSaveCreator, "Ok", null);
    } else {
      handleSaveCreator();
    }
  };

  const handleSaveCreator = async () => {
    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;
    const formattedXLink = xLink ? `https://www.x.com/${xLink}` : null;
    const formattedKickLink = kickLink ? `https://www.kick.com/${kickLink}` : null;
    const formattedTwitchLink = twitchLink ? `https://www.twitch.tv/${twitchLink}` : null;

    let payload = null

    try {
      payload = {
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,

        twitch: parseLocalInt(twitchFollowerCount) || null,
        twitch_link: formattedTwitchLink || null,

        follow_x: parseLocalInt(xFollowerCount) || null,
        x_link: formattedXLink || null,

        follow_podcast: parseLocalInt(podcastFollowerCount) || null,
        podcast_link: podcastLink || null,

        follow_kick: parseLocalInt(kickFollowerCount) || null,
        kick_link: formattedKickLink || null,

        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),

        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_ig_story: parseLocalFloat(instagramStoryRate),

        rate_yt_integ: parseLocalFloat(youtubeIntegrationRate),
        rate_yt_short: parseLocalFloat(youtubeShortsRate),
        rate_yt_dedicated: parseLocalFloat(youtubeDedicatedRate),

        rate_podcast_preroll: parseLocalFloat(podcastPreroll),
        rate_podcast_midroll: parseLocalFloat(podcastMidroll),
        rate_podcast_postroll: parseLocalFloat(podcastPostroll),
        rate_podcast_full: parseLocalFloat(podcastFull),

        rate_twitch_product: parseLocalFloat(twitchPlacement),
        rate_kick_product: parseLocalFloat(kickPlacement),
        rate_x_feedpost: parseLocalFloat(xFeedPost),
        rate_x_repost: parseLocalFloat(xRepost),

        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_market: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,

        contactEmail: contactEmail || null,
        paymentEmail: paymentEmail || null,
        phone_number: phoneNumber || null,
        payment_method: paymentMethod || null,
        stripe_id: stripeUserId,
        pfphref: profilePhotoUrl,
        status: userStatus,
        process_emails: processEmails,
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }

    setIsLoading(true);
    console.log('Sending.')
    try {
      const data = await API.creatorConnect.editCreator(payload);
      console.log('Success.');
      if (userStatus === "Partner" && userData.current.creator.status != userStatus) {
        console.log('Starting google request.', userData.current.creator.status, userStatus)
        setGoogleRequest(data.request_id);
        return;
      }


      if (paymentMethod === 'PayPal' || !isStripeNew) {
        console.log('No redirection.')
        openDialog("Success", "Creator data edited successfully", closeDialog, closeDialog, "Ok", null);
        await GetUserData();
        console.log('Closing is loading.');
        setIsLoading(false);
      } else {
        openDialog("Success", "Creator data edited successfully. You will now be redirected to Stripe to set up BlitzPay", goToStripeId, goToStripeId, "Ok", null);
        setIsLoading(false);
      }

    } catch (error) {
      openDialog("Error", `Network error: ${error?.response?.data?.error || error.message}`, closeDialog, closeDialog, "Ok", null);
      console.error('Network error:', error);
      setIsLoading(false);
    }
  };

  const OnClickUpdateStripe = () => {
    openDialog("Change Payment Data", "This will redirect you without saving any changes. Click Update to be redirected to update your payment info, or Cancel to continue editing and save.", goToStripeId, closeDialog, "Update", "Cancel");
  };

  const OnSelectField = (e) => {
    if (e.target.value === 'Associate') {
      openDialog("Change Partnership", "This will delete your Google connection from our server, and we won't be managing your brand deals through our platform. Continue?", () => { setUserStatus('Associate'); closeDialog(); }, closeDialog, "Yes", "Cancel");
    } else {
      setOpenConsent(true);
    }
  };

  const OnConsentGoogle = (consented) => {
    setOpenConsent(false);
    if (!consented) {
      setUserStatus('Associate');
    } else {
      setUserStatus('Partner');
    }
  };

  let errorInfo = 0;
  let errorPlatform = 0;
  let errorPay = 0;

  const hasPhoneNumber = phoneNumber && phoneNumber.length >= 9;
  const hasPlatform = !completedInfo && validatePlatformSelection(
    platforms,
    (message) => 0,
    tikTokBrandRate, tikTokSongRate,
    instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
    youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
    xFeedPost, xRepost,
    kickPlacement,
    twitchPlacement,
    podcastPreroll,
    podcastMidroll,
    podcastPostroll,
    podcastFull
  );
  const hasInfo = race && primaryMarket && location && creatorRegion && contactEmail && notesContentStyle && notesContentStyle != 'tell us about yourself';
  const hasPayment = paymentMethod && (paymentMethod == 'PayPal' && paymentEmail);

  if (!isLoading) {
    if (!race || !notesContentStyle || !location || !gender || !primaryMarket || !phoneNumber || !creatorRegion || !contactEmail || phoneNumber.length < 11)
      errorInfo = 1;
    const anyPlatform = Object.values(platforms).some((value) => value);
    if (!anyPlatform) {
      errorPlatform = 1;
    }
    if (paymentMethod == 'PayPal' && !paymentEmail)
      errorPay = 1;
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <ConsentForm open={openConsent} onClose={OnConsentGoogle}></ConsentForm>
      <Paper elevation={4} square>
        <Tabs
          centered={isDesktop}
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="tab navigation"
        >
          <Tab label={<Badge badgeContent={errorPlatform} color='error' variant="dot">Platforms</Badge>} value="platforms" />
          <Tab label={<Badge badgeContent={errorPay} color='error' variant="dot">Payment</Badge>} value="payment" />
          <Tab label={<Badge badgeContent={errorInfo} color='error' variant="dot">Profile</Badge>} value="info" />
        </Tabs>
      </Paper>
      {!completedInfo && <Alert variant="filled" severity="warning" square sx={{ marginBlock: 4 }}>
        <AlertTitle>Finish your account so you can be selected for marketing campaigns:</AlertTitle>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          {!hasPlatform && <ListItem disablePadding sx={{ display: 'list-item' }}>Select a rate for a promotion in your selected platforms.</ListItem>}
          {!hasPhoneNumber && <ListItem disablePadding sx={{ display: 'list-item' }}>Add your phone number.</ListItem>}
          {!hasPayment && <ListItem disablePadding sx={{ display: 'list-item' }}>Add your Payment Information.</ListItem>}
          {!hasInfo && <ListItem disablePadding sx={{ display: 'list-item' }}>Fill out your information and bio.</ListItem>}
        </List>
      </Alert>
      }
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          const form = e.target;
          if (!form.checkValidity()) {
            const invalidElements = form.querySelectorAll(':invalid');
            const firstInvalidElement = invalidElements[0];

            if (firstInvalidElement) {
              const tabId = firstInvalidElement.closest('[id^="tab-contents-"]')?.id;
              if (tabId) {
                const tabIndex = tabId.replace("tab-contents-", "");
                handleChangeTab(null, tabIndex);
              }

              setTimeout(() => {
                form.reportValidity(); // Wait until re-render to show validation errors.
              }, 0);
            }
            return;
          }
          handleStartSaveCreator();
        }}
      >
        <Paper elevation={1} sx={{ paddingInline: 4, paddingBlockStart: 2, paddingBlockEnd: 10, marginBlockStart: 3, maxWidth: '50em', marginInline: "auto" }}>
          <TabPanelHidden value={tabValue} index='platforms' id='tab-contents-platforms'>
            <Tooltip open={showPlatformsTip} title={<Typography variant="subtitle1">Add rates and links to our new platforms here!</Typography>} placement="top" arrow>
              <Typography variant="h5" sx={{ marginBlockEnd: 1, marginBlockStart: 1 }}>Platform and Rates</Typography>
            </Tooltip>
            <CreatorPlatforms
              platforms={platforms} setPlatforms={setPlatforms}
              instagramLink={instagramLink} setInstagramLink={setInstagramLink}
              instagramFollowerCount={instagramFollowerCount} setInstagramFollowerCount={setInstagramFollowerCount}
              instagramBrandRate={instagramBrandRate} setInstagramBrandRate={setInstagramBrandRate}
              instagramSongRate={instagramSongRate} setInstagramSongRate={setInstagramSongRate}
              instagramFeedRate={instagramFeedRate} setInstagramFeedRate={setInstagramFeedRate}
              instagramStoryRate={instagramStoryRate} setInstagramStoryRate={setInstagramStoryRate}
              tikTokLink={tikTokLink} setTikTokLink={setTikTokLink}
              tikTokFollowerCount={tikTokFollowerCount} setTikTokFollowerCount={setTikTokFollowerCount}
              tikTokBrandRate={tikTokBrandRate} setTikTokBrandRate={setTikTokBrandRate}
              tikTokSongRate={tikTokSongRate} setTikTokSongRate={setTikTokSongRate}
              youtubeLink={youtubeLink} setYoutubeLink={setYoutubeLink}
              youtubeFollowerCount={youtubeFollowerCount} setYoutubeFollowerCount={setYoutubeFollowerCount}
              youtubeIntegrationRate={youtubeIntegrationRate} setYoutubeIntegrationRate={setYoutubeIntegrationRate}
              youtubeShortsRate={youtubeShortsRate} setYoutubeShortsRate={setYoutubeShortsRate}
              youtubeDedicatedRate={youtubeDedicatedRate} setYoutubeDedicatedRate={setYoutubeDedicatedRate}
              xLink={xLink} setXLink={setXLink}
              xFollowerCount={xFollowerCount} setXFollowerCount={setXFollowerCount}
              xFeedPost={xFeedPost} setXFeedPost={setXFeedPost}
              xRepost={xRepost} setXRepost={setXRepost}
              podcastLink={podcastLink} setPodcastLink={setPodcastLink}
              podcastFollowerCount={podcastFollowerCount} setPodcastFollowerCount={setPodcastFollowerCount}
              podcastPreroll={podcastPreroll} setPodcastPreroll={setPodcastPreroll}
              podcastMidroll={podcastMidroll} setPodcastMidroll={setPodcastMidroll}
              podcastPostroll={podcastPostroll} setPodcastPostroll={setPodcastPostroll}
              podcastFull={podcastFull} setPodcastFull={setPodcastFull}
              kickLink={kickLink} setKickLink={setKickLink}
              kickFollowerCount={kickFollowerCount} setKickFollowerCount={setKickFollowerCount}
              kickPlacement={kickPlacement} setKickPlacement={setKickPlacement}
              twitchLink={twitchLink} setTwitchLink={setTwitchLink}
              twitchFollowerCount={twitchFollowerCount} setTwitchFollowerCount={setTwitchFollowerCount}
              twitchPlacement={twitchPlacement} setTwitchPlacement={setTwitchPlacement}
            ></CreatorPlatforms>
          </TabPanelHidden>
          <TabPanelHidden value={tabValue} index='payment' id='tab-contents-payment'>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: "end" }}>
              <FormControl fullWidth sx={{ flex: 1 }} margin="dense">
                <InputLabel>Payment Method</InputLabel>
                <Select label='Payment Method' value={paymentMethod} onChange={handlePaymentMethodChange}>
                  <MenuItem value="PayPal">PayPal, Standard, Fees Apply</MenuItem>
                  <MenuItem value="Stripe">BlitzPay, Faster, Less Fees</MenuItem>
                </Select>
              </FormControl>
              {paymentMethod === "PayPal" && <TextField
                margin="dense"
                label="Paypal Payment Email"
                type="email"
                fullWidth
                sx={{ flex: 1 }}
                value={paymentEmail}
                onChange={(e) => setPaymentEmail(e.target.value)}
                variant="outlined"
              />}
              {paymentMethod === "Stripe" && <Button
                variant="contained"
                color="secondary"
                sx={{ flex: 1, margin: 0 }}
                onClick={OnClickUpdateStripe}
              >
                {isStripeNew ? "Add Payment Information" : "Update Payment Information"}
              </Button>}
            </Box>
            <FormControl fullWidth margin="dense">
              <InputLabel>Status</InputLabel>
              <Select label='Status' value={userStatus} onChange={OnSelectField}>
                <MenuItem value="Associate">Associate</MenuItem>
                <MenuItem value="Partner">Partner</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              checked={processEmails}
              onChange={() => setProcessEmails((lastValue) => !lastValue)}
              control={<Checkbox></Checkbox>}
              label='Enable Auto-emailer'
            ></FormControlLabel>
          </TabPanelHidden>
          <TabPanelHidden value={tabValue} index='info' id='tab-contents-info'>
            <CreatorPfp
              creatorName={creatorName}
              profilePhotoUrl={profilePhotoUrl}
              handleOpenUploadDialog={handleOpenUploadDialog}
            ></CreatorPfp>
            <CreatorInfo
              creatorName={creatorName}
              setCreatorName={null}
              creatorRegion={creatorRegion}
              setCreatorRegion={setCreatorRegion}
              race={race}
              setRace={setRace}
              gender={gender}
              setGender={setGender}
              location={location}
              setLocation={setLocation}
              primaryMarket={primaryMarket}
              setPrimaryMarket={setPrimaryMarket}
              notesContentStyle={notesContentStyle}
              setNotesContentStyle={setNotesContentStyle}
              contactEmail={contactEmail}
              setContactEmail={setContactEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              validationError={validationError}
              require={false}
            ></CreatorInfo>
          </TabPanelHidden>
        </Paper>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={theme => ({
            marginTop: 1,
            marginBottom: 2,
            position: 'fixed',
            right: { xs: theme.spacing(4), md: theme.spacing(1) },
            bottom: theme.spacing(1),
          })}
        >
          Save Changes
        </Button>
      </form >
      <UploadFileDialogPFP open={isUploadDialogOpen} onClose={handleCloseUploadDialog} />
    </>
  );
};

export default EditCreator;
