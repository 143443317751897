import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import API from "../../../API";
import { CloseRounded } from "@mui/icons-material";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const AgencyCampaignPitchDialog = ({
  openDialog,
  handleCloseDialog,
  campaignId,
  username
}) => {
  const [campaign, setCampaign] = useState(null);
  const editedEvents = useRef(false)
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useIsDesktop();
  const queryClient = useQueryClient();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await API.campaigns.fetch(campaignId);
        if (openDialog) {
          editedEvents.current = false;
          console.log(data);
          setCampaign(data);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err)
        handleCloseDialog();
      }
    };
    if (openDialog) fetchCampaignData();
    else
      editedEvents.current = false;
  }, [openDialog]);

  const creator = useMemo(() => {
    if (!campaign) {
      return undefined;
    }
    const creators = campaign.creators;
    return creators.find(
      (creator) => creator.id === username
    );
  }, [campaign]);

  const { mutate: updateStatus, isLoading: updatingStatus } = useMutation({
    mutationFn: (input) => API.agency.campaignAccept(campaignId, input),
    onSuccess: () => {
      queryClient.invalidateQueries(['creators', username])
      queryClient.invalidateQueries(['campaigns'])
    }
  })

  function handleChangeStatus(accepted) {
    updateStatus({ status: accepted ? 'Accepted' : 'Declined', creator: username }, {
      onSuccess: () => {
        const alertTitle = accepted ? 'Accepted Campaign' : 'Rejected Campaign';
        const alertText = accepted ? 'You have accepted this campaign on behalf of this creator.' :
          'You rejected this campaign in behalf of your creator';
        openAlert(alertTitle, alertText,
          () => { closeDialog(); handleCloseDialog(); }, () => { closeDialog(); handleCloseDialog(); }, 'Ok', null);
      },
      onError: (error) => {
        openAlert("Error", (<>There was an error while changing your status:<br></br>{(error.response?.data?.error) ? error.response.data.error : error.message}</>),
          () => { closeDialog(); handleCloseDialog(); }, () => { closeDialog(); handleCloseDialog(); }, 'Ok', null);
      }
    });
  }

  const onClickOption = (accepted = false) => {
    const alertTitle = accepted ? 'Accept Campaign' : 'Reject Campaign';
    const alertText = accepted ? 'You\'ll accept this campaign in behalf of your creator, they\'ll be notified of upcoming due dates and receive messages from the campaign manager. Continue?' :
      'You\'ll reject this campaign in behalf of your creator. This action is not reversable, and the campaign will disappear from any dashboard. Continue?';
    openAlert(alertTitle, alertText,
      () => { closeDialog(); handleChangeStatus(accepted); }, closeDialog, 'Continue', 'Cancel');
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={isLoading ? "sm" : "md"}
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {(isLoading || updatingStatus) && (
          <DialogContent style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <CircularProgress />
          </DialogContent>
        )}

        {!isLoading && !updatingStatus && (
          <>
            <Paper square elevation={2}>
              <DialogTitle>
                Accept this campaign?
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseRounded />
              </IconButton>
            </Paper>

            <DialogContent dividers={true}>
              <Typography variant="h5">Campaign Name: {campaign.name}</Typography>
              <Typography variant="h6">Ideal due date: {formatIdealDueDate(campaign.ideal_duedate)}</Typography>
              <Typography variant="body1">Campaign Brief: {campaign.brief}</Typography>
              <Divider></Divider>
              <Box sx={{ paddingBlockEnd: 2, marginTop: 2 }}>
                <FormControlLabel
                  control={<Checkbox checked={!!creator.promotionPlatform} disabled />}
                  label={`Platform: ${creator?.promotionPlatform || "Not specified"}`}
                />
                <FormControlLabel
                  control={<Checkbox checked={!!creator.promotionType} disabled />}
                  label={`Promotion Type: ${creator?.promotionType || "Not specified"}`}
                />
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="body1">Creator Brief</Typography>
                  <TextField fullWidth value={creator?.creatorBrief || ""} disabled multiline />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="body1">Posting Instructions</Typography>
                  <TextField fullWidth value={creator?.postingInstructions || ""} disabled multiline />
                </Box>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="body1">Boost Code</Typography>
                  <TextField fullWidth value={creator?.boostCode || ""} disabled />
                </Box>
              </Box>
            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button variant="contained" onClick={() => onClickOption(true)} color='success'>Accept</Button>
            <Button variant="contained" onClick={() => onClickOption(false)} color='error'>Reject</Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState}></AlertDialog>
    </>
  );
};

export default AgencyCampaignPitchDialog;
