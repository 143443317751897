import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import profilePhoto from "../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import API from "../../API";

const CreatorBanner = () => {
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    API.creators.listPartners().then((data) => setCreators(data.creators))
      .catch((error) => console.error("Error fetching creator partners:", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300, // Speed up the slider
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
        },
      },
    ],
  };

  return (
    <Box sx={{
      paddingInline: 4,
      paddingBlock: 12,
      backgroundColor: "#7b2a2a",
    }}>
      <Typography variant="h3" sx={{ color: "#fff", fontWeight: "bold", textAlign: "center", marginBottom: "1rem" }}>
        Search for over 500 creators
      </Typography>
      <Typography variant="h6" sx={{ color: "#fff", textAlign: "center", marginBottom: "2rem" }}>
        We’ve worked hard vetting the best creators who drive results for our clients.
      </Typography>

      <Box
        sx={{
          maxWidth:'60rem',
          marginInline: 'auto'
        }}
      >
        <Slider {...settings}>
          {creators.slice(0, 10).map((creator, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                margin: "10px", // Added margin to create space between items
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center the image and text
              }}
            >
              <img
                style={{
                  width: 140,
                  height: 140,
                  borderRadius: "50%", // Make the image circular
                  objectFit: "cover", // Ensure the image covers the circular frame
                  marginBottom: "10px",
                  border: "3px solid #fff", // Add a white border around the image
                }}
                src={creator.pfphref || profilePhoto}
                alt={creator.creator}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  marginTop: "15px",
                  fontWeight: "bold",
                  // Apply mobile-specific styling
                  "@media (max-width: 480px)": {
                    marginTop: "5px", // Reduce the space between the image and title on mobile
                    fontSize: "14px", // Adjust the font size on mobile
                  },
                }}
              >
                @{creator.creator}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default CreatorBanner;
