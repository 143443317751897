import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

export function TrackerTable({ tracker = { links: [{ link: '' }] } }) {

    return (
        <TableContainer component={Paper} elevation={4}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='left' sx={{width:'18rem'}}>
                            Link
                        </TableCell>
                        <TableCell align='right'>
                            Comments
                        </TableCell>
                        <TableCell align='right'>
                            Views
                        </TableCell>
                        <TableCell align='right'>
                            Likes
                        </TableCell>
                        <TableCell align='right' sx={{width:'8rem'}}>
                            Last Updated
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(tracker?.links || []).map((link, index) => {
                        const isError = (!link.fetched && link.attempts > 2);
                        return (
                            <TableRow sx={{backgroundColor: isError ? 'error.dark' : 'transparent', height:'2rem'}} key={index}>
                                <TableCell align='left' sx={{width:'18rem'}}>
                                    {link.link}
                                </TableCell>
                                <TableCell align='right'>
                                    {link.fetched ? link.comments.toLocaleString() : <Skeleton animation={isError ? false : 'wave'} />}
                                </TableCell>
                                <TableCell align='right'>
                                    {link.fetched ? link.totalViews?.toLocaleString() : <Skeleton animation={isError ? false : 'wave'}/>}
                                </TableCell>
                                <TableCell align='right'>
                                    {link.fetched ? link.likes.toLocaleString() : <Skeleton animation={isError ? false : 'wave'}/>}
                                </TableCell>
                                <TableCell align='right' sx={{maxWidth:'8rem'}}>
                                    {isError ? 'Could not fetch data.' : (link.fetched ? ((new Date(link.lastFetched*1000)).toLocaleDateString()) : 'No updates...')}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
