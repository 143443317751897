import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import { StyledDivider as Divider } from "../../../Components/LightDivider";

const FutureOfPayments = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        color: "#000",
      }}
    >
      <HomeHeader />
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
            Future of Creator and Vendor Payments
          </Typography>

          {/* Introduction */}
          <Typography variant="body1" paragraph>
            The world of influencer marketing and brand collaboration demands seamless financial
            management. Blitz steps in as the ultimate solution for creators and brands alike,
            offering automated invoicing, real-time tracking, and a streamlined payment process.
          </Typography>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <img
              src="https://storage.googleapis.com/blitzccmc.appspot.com/1/IMG_131748_0.jpeg"
              alt="Blitz financial solutions"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Box>

          <Divider />

          {/* Key Features */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Simplify Financial Management
            </Typography>
            <Typography variant="body1" paragraph>
              With Blitz, managing finances has never been easier. Say goodbye to manual
              calculations and delayed payouts. Blitz ensures that creators and brands can focus
              on their work while we handle the rest.
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>Automated invoicing for hassle-free transactions</li>
              <li>Real-time tracking for complete transparency</li>
              <li>Effortless payments across global networks</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/WhatsApp%20Image%202024-10-27%20at%2000.45.21.jpeg"
                alt="Automated invoicing"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* Connecting Brands and Creators */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Bridging the Gap Between Brands and Creators
            </Typography>
            <Typography variant="body1" paragraph>
              Blitz serves as a bridge, connecting brands with the right creators for impactful
              collaborations. Our platform ensures that every partnership is financially
              transparent and efficiently managed.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/dff18294-fa5c-4b5e-b5bf-69638d3f303f.jfif"
                alt="Brand and creator collaboration"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* BlitzPay: Faster Payments */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Get Paid Faster with BlitzPay
            </Typography>
            <Typography variant="body1" paragraph>
              Delayed payments are a thing of the past. With BlitzPay, creators and vendors
              receive their payments quickly and reliably, ensuring financial flexibility and
              peace of mind.
            </Typography>
            <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
              <li>24-hour global payment processing</li>
              <li>Secure transactions with no hidden fees</li>
              <li>Transparency at every step of the process</li>
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/WhatsApp%20Image%202024-09-12%20at%2006.59.38.jpeg"
                alt="Fast and reliable payments"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* How Blitz Ensures Transparency */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Financial Transparency at Its Best
            </Typography>
            <Typography variant="body1" paragraph>
              Transparency is at the core of Blitz. From automated invoices to real-time payment
              tracking, you’ll always know the status of your financial transactions.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/classics_club_rocket%20BLACK%20CLEAN%20AF.png"
                alt="Real-time tracking"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Divider />

          {/* Call to Action */}
          <Box sx={{ my: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              Experience the Future of Payments
            </Typography>
            <Typography variant="body1" paragraph>
              Whether you're a brand looking to collaborate with creators or a creator managing
              multiple partnerships, Blitz is your go-to platform for seamless financial
              transactions. Join today and redefine the way you handle payments.
            </Typography>
            <Box sx={{ textAlign: "center", my: 3 }}>
              <img
                src="https://storage.googleapis.com/blitzccmc.appspot.com/1/New%20Project%282%29.jpg"
                alt="Join Blitz today"
                style={{ maxWidth: "100%", borderRadius: "8px" }}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default FutureOfPayments;
