import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Paper,
  Button,
  Divider,
  Link,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../../API";

const AssetsTab = ({ campaignDetails, creatorEntry }) => {
  const [liveLink, setLiveLink] = useState(creatorEntry?.liveLink || "");
  const [saving, setSaving] = useState(false);
  const canEdit = campaignDetails?.campaign_status === 'Launched' && creatorEntry?.status !== undefined
    && creatorEntry.status !== 'Declined' && creatorEntry.status !== 'Dropped'
    && creatorEntry.status !== 'Pitched';

  const handleLiveLinkChange = (event) => {
    setLiveLink(event.target.value);
  };

  const { mutate: updateLink, isLoading: isUpdatingLink } = useMutation(
    (input) => client.agency.campaignLiveLink(campaignDetails.id, input),
    {
      onSuccess: () => {
        alert("Live Link updated.");
      },
      onError: (error) => {
        const errorMsg = (error.response && error.response.data && error.response.data.error)
          ? error.response.data.error
          : error.message;
        alert(`Error when updating Live Link: ${errorMsg}`);
      },
    }
  );

  const handleSaveLiveLink = () => {
    updateLink({ creator: creatorEntry.id, liveLink });
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  if (!creatorEntry || !campaignDetails) return <>Loading</>;

  return (
    <Paper sx={{ margin: 1, padding: 3, paddingBlockEnd: 1 }} elevation={2}>
      <Typography variant="h6">Campaign Brief: {campaignDetails.brief}</Typography>
      <Divider></Divider>
      <Box sx={{ paddingBlockEnd: 2, marginTop: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={!!creatorEntry.promotionPlatform} disabled />}
          label={`Platform: ${creatorEntry?.promotionPlatform || "Not specified"}`}
        />
        <FormControlLabel
          control={<Checkbox checked={!!creatorEntry.promotionType} disabled />}
          label={`Promotion Type: ${creatorEntry?.promotionType || "Not specified"}`}
        />
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Creator Brief</Typography>
          {isValidURL(creatorEntry.creatorBrief) ? (
            <Link href={creatorEntry.creatorBrief} target="_blank" rel="noopener">
              Open Creator Brief
            </Link>
          ) : (
            <TextField fullWidth value={creatorEntry?.creatorBrief || ""} disabled multiline />
          )}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Posting Instructions</Typography>
          <TextField fullWidth value={creatorEntry?.postingInstructions || ""} disabled multiline />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Boost Code</Typography>
          <TextField fullWidth value={creatorEntry?.boostCode || ""} disabled />
        </Box>
        {creatorEntry.submissionLink && canEdit && (
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Button variant="contained" color="primary" href={creatorEntry.submissionLink} target="_blank">
              Upload your assets here
            </Button>
          </Box>
        )}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">{canEdit ? 'Insert live link here' : 'Live Link'}</Typography>
          <TextField fullWidth value={liveLink} onChange={handleLiveLinkChange} disabled={isUpdatingLink || !canEdit} />
          {canEdit && <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveLiveLink}
            disabled={isUpdatingLink}
            sx={{ marginTop: 2 }}
          >
            {isUpdatingLink ? "Saving..." : "Save Live Link"}
          </Button>}
        </Box>
      </Box>
    </Paper>
  );
};

export default AssetsTab;
