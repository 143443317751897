import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, ListItemText, MenuItem, Slider, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { getHeadersFromKeys, platforms, platPromoToHead, platPromoToKey } from '../../../../Utils/constants';
import { useMutation } from 'react-query';
import API from '../../../../API';

export default function CreateCallDialog({ open, handleClose, fetchCastingCalls }) {
    const [newCastingCall, setNewCastingCall] = useState({
        platforms: [],
        promotionTypes: [],
        brief: "",
        budgetMin: 100,
        budgetMax: 500,
    });

    const { mutate: postNewCastingCall, isLoading: isPosting } = useMutation(
        API.castingCalls.postNew,
        {
            onSuccess: () => {
                fetchCastingCalls();
                setNewCastingCall({
                    platforms: [],
                    promotionTypes: [],
                    brief: "",
                    budgetMin: 100,
                    budgetMax: 500,
                });
                handleClose(false);
            },
            onError: (error) => {
                console.error("Error uploading casting call:", error);
                alert(`An error ocurred while creating your Casting Call: ${error?.response?.data?.error || error.message}`);
            },
        }
    );

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewCastingCall((prev) => ({ ...prev, [name]: value }));
    };

    const handlePlatformChange = (event) => {
        const { value } = event.target;
        setNewCastingCall((prev) => ({
            ...prev,
            platforms: value,
            promotionTypes: [], // Reset promotion types when platforms change
        }));
    };

    const handlePromotionTypeChange = (event) => {
        const { value } = event.target;
        setNewCastingCall((prev) => ({
            ...prev,
            promotionTypes: typeof value === "string" ? value.split(",") : value,
        }));
    };

    const handleSliderChange = (field) => (event, value) => {
        setNewCastingCall((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewCastingCall(newCastingCall)
    };

    const availablePromotionTypes = newCastingCall.platforms.flatMap((platform) => {
        if (platPromoToKey[platform] && platPromoToHead[platform]) {
            return Object.keys(platPromoToKey[platform]).map((promo) => ({
                key: platPromoToKey[platform][promo],
                header: platPromoToHead[platform][promo],
            }));
        }
        return []; // Return an empty array if the platform doesn't exist or has no promotions
    });

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
            <DialogTitle>Create a New Casting Call</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" gutterBottom>
                    Enter details for your casting call. Your selected budget range and
                    brief will help creators understand the opportunity.
                </Typography>
                <TextField
                    select
                    label='Platform Type'
                    fullWidth
                    margin='normal'
                    value={newCastingCall.platforms}
                    onChange={handlePlatformChange}
                    required
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => selected.join(", ")
                    }}
                >
                    {platforms.map((platform) => (
                        <MenuItem key={platform} value={platform}>
                            <Checkbox
                                checked={newCastingCall.platforms.includes(platform)}
                            />
                            <ListItemText primary={platform} />
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label='Promotion Types'
                    fullWidth
                    margin='normal'
                    value={newCastingCall.promotionTypes}
                    onChange={handlePromotionTypeChange}
                    required
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => getHeadersFromKeys(selected).join(", ")
                    }}
                >
                    {availablePromotionTypes.map((type) => (
                        <MenuItem key={type.key} value={type.key}>
                            <Checkbox
                                checked={newCastingCall.promotionTypes.includes(type.key)}
                            />
                            <ListItemText primary={type.header} />
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Brief"
                    name="brief"
                    fullWidth
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                    margin="dense"
                    required
                />
                <Box sx={{ paddingInline: 2 }}>
                    <Typography gutterBottom>
                        Minimum Budget: ${newCastingCall.budgetMin}
                    </Typography>
                    <Slider
                        value={newCastingCall.budgetMin}
                        onChange={handleSliderChange("budgetMin")}
                        min={50}
                        max={newCastingCall.budgetMax - 1}
                        step={1}
                        valueLabelDisplay="auto"
                    />
                    <Typography gutterBottom>
                        Maximum Budget: ${newCastingCall.budgetMax}
                    </Typography>
                    <Slider
                        value={newCastingCall.budgetMax}
                        onChange={handleSliderChange("budgetMax")}
                        min={newCastingCall.budgetMin + 1}
                        max={10000}
                        step={1}
                        valueLabelDisplay="auto"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='text'>
                    Cancel
                </Button>
                <Button type='submit' color="primary" disabled={isPosting} variant='text'>
                    {isPosting ? <CircularProgress size={24} /> : "Create"}
                </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
