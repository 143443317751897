import React, { memo } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, CardActions, Button, Checkbox } from '@mui/material';
import profilePhoto from "../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo
import { formatCampaignSum, formatProposalDate } from '../../../Utils/constants';

const summarizeText = (text, maxLength = 20) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + "...";
};

const CampaignCardList = memo(function CampaignCardList({ campaigns, handleOpenDialog, handleShareCampaign, handleCopyCampaign, handleCheckboxChange, selectedCampaigns = [] }) {
    return (
        <Grid container spacing={2}>
            {campaigns.map((campaign) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={campaign.id}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <CardMedia
                            component="img"
                            height="300"
                            image={
                                campaign.creators.length > 0
                                    ? campaign.creators[0].pfphref
                                    : profilePhoto
                            }
                            alt={campaign.name}
                            onClick={() => handleOpenDialog(campaign)}
                            style={{ cursor: 'pointer' }}
                        />
                        <CardContent
                            onClick={() => handleOpenDialog(campaign)}
                            style={{ cursor: 'pointer', flexGrow: 1 }}
                        >
                            <Typography variant="h5" component="div">
                                {summarizeText(campaign.name)}
                            </Typography>
                            <Typography variant="body2">
                                Campaign Sum: {formatCampaignSum(campaign.campaign_sum)}
                            </Typography>
                            <Typography variant="body2">
                                Created: {formatProposalDate(campaign.proposal_date)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Typography variant="body1">
                                Campaign {campaign.campaign_status}
                            </Typography>
                            <Checkbox
                                checked={selectedCampaigns.includes(campaign.id)}
                                onChange={() => handleCheckboxChange(campaign.id)}
                            />
                            <Button
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleShareCampaign(campaign.id);
                                }}
                            >
                                Share
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopyCampaign(campaign.id);
                                }}
                            >
                                Copy
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
});

export default CampaignCardList;
