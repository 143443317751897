import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from 'react-query';
import client from '../../API';
import useAuth from '../../Hooks/use-auth';

const VALID_DELIVERABLE_TYPES = ['Video', 'Payment', 'Other'];

const DealIOCreateForm = ({ onClose, onCreateSuccess }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();

  const [name, setName] = useState(''); // New field
  const [user, setUser] = useState({
    id: currentUser.id,
    name: `${currentUser.first_name} ${currentUser.last_name}`,
  });
  const [amountOfMonths, setAmountOfMonths] = useState(2);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [deliverables, setDeliverables] = useState([]);
  const [amountDue, setAmountDue] = useState('');
  const [creators, setCreators] = useState([]);

  const { mutate: fetchCreatorsData } = useMutation(
    () => client.creators.list({ is_vendor: true }),
    {
      onSuccess: (data) => {
        const formattedData = data.map((creator) => ({
          ...creator,
          label: creator.name || creator.creator,
          id: creator.creator,
        }));
        setCreators(formattedData);
      },
      onError: (error) => {
        console.error('Error fetching creators:', error);
      },
    }
  );

  useEffect(() => {
    fetchCreatorsData();
  }, []);

  useEffect(() => {
    setDeliverables((prevDeliverables) => {
      let newDeliverables = [...prevDeliverables];
      if (amountOfMonths > prevDeliverables.length) {
        for (let i = prevDeliverables.length; i < amountOfMonths; i++) {
          newDeliverables.push({
            id: 'deliv-' + Date.now() + '-' + i,
            month: i + 1,
            work_info: '',
            amount: '',
            due_date: '',
            type: 'Payment',
          });
        }
      } else if (amountOfMonths < prevDeliverables.length) {
        newDeliverables = newDeliverables.slice(0, amountOfMonths);
      }
      return newDeliverables;
    });
  }, [amountOfMonths]);
  

  useEffect(() => {
    if (amountDue && amountOfMonths) {
      const totalAmountDue = parseFloat(amountDue) || 0;
      const equalAmount = (totalAmountDue / amountOfMonths).toFixed(2);

      setDeliverables((prevDeliverables) => {
        return prevDeliverables.map((deliverable) => ({
          ...deliverable,
          amount: equalAmount,
        }));
      });
    }
  }, [amountDue, amountOfMonths]);

  const handleDeliverableChange = (index, field, value) => {
    setDeliverables((prevDeliverables) => {
      const newDeliverables = [...prevDeliverables];
      newDeliverables[index] = {
        ...newDeliverables[index],
        [field]: value,
      };
      return newDeliverables;
    });
  };

  const applyToAllDeliverables = () => {
    setDeliverables((prevDeliverables) => {
      const month1Deliverable = prevDeliverables[0];
      const newDeliverables = prevDeliverables.map((deliverable) => ({
        ...deliverable,
        work_info: month1Deliverable.work_info,
        type: month1Deliverable.type,
      }));
      return newDeliverables;
    });
  };

  const calculateTotalDeliverableAmount = () => {
    return deliverables.reduce((total, deliverable) => {
      return total + parseFloat(deliverable.amount || 0);
    }, 0);
  };

  const validateTotalAmount = () => {
    const totalDeliverableAmount = calculateTotalDeliverableAmount();
    const totalAmountDue = parseFloat(amountDue) || 0;
    return Math.abs(totalDeliverableAmount - totalAmountDue) < 0.01;
  };

  // Automatically set subsequent month due dates based on month 1
  useEffect(() => {
    if (deliverables.length > 0 && deliverables[0].due_date) {
      const firstDate = new Date(deliverables[0].due_date);
      // Only update subsequent months if the first month has a valid date
      if (!isNaN(firstDate)) {
        setDeliverables((prevDeliverables) =>
          prevDeliverables.map((del, i) => {
            if (i === 0) return del; // Skip first month
            const newDate = new Date(firstDate);
            newDate.setDate(newDate.getDate() + 30 * i);
            return {
              ...del,
              due_date: newDate.toISOString().split('T')[0],
            };
          })
        );
      }
    }
  }, [deliverables[0]?.due_date]); // Re-run if the first month's due_date changes

  // Mutation for creating Deal IO
  const { mutate: createDealIO, isLoading } = useMutation(client.dealsIO.create, {
    onSuccess: (data) => {
      alert('Deal IO created successfully!');
      onCreateSuccess();
      onClose();
    },
    onError: (error) => {
      console.error('Error creating Deal IO:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
      alert('Error creating Deal IO: ' + errorMessage);
    },
  });

  const handleSubmit = () => {
    if (!name) {
      alert('Please enter a name for the Deal IO.');
      return;
    }

    if (!selectedCreator || !selectedCreator.id) {
      alert('Please select a Creator Vendor.');
      return;
    }

    if (!amountDue || isNaN(parseFloat(amountDue))) {
      alert('Please enter a valid Amount Due.');
      return;
    }

    if (!deliverables || deliverables.length === 0) {
      alert('Deliverables cannot be empty.');
      return;
    }

    if (!validateTotalAmount()) {
      alert('The total amount of deliverables must equal the Amount Due.');
      return;
    }

    const data = {
      name: name,
      amount_of_months: amountOfMonths,
      creator_vendor_id: selectedCreator.id,
      deliverables: deliverables,
      amount_due: parseFloat(amountDue),
    };

    createDealIO(data);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Create New Deal IO
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        {/* Name Field */}
        <TextField
          label="Deal IO Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
          required
        />

        {/* Amount Due */}
        <TextField
          label="Amount Due"
          type="number"
          value={amountDue}
          onChange={(e) => setAmountDue(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: <InputAdornment position="start">USD</InputAdornment>,
          }}
        />

        {/* Creator Vendor */}
        <Autocomplete
          sx={{ marginTop: 2 }}
          options={creators}
          getOptionLabel={(option) => option.label || ''}
          value={selectedCreator}
          onChange={(event, newValue) => {
            setSelectedCreator(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Creator Vendor"
              variant="outlined"
              required
            />
          )}
        />

        {/* Amount of Months */}
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
          Amount of Months
        </Typography>
        <ToggleButtonGroup
          value={amountOfMonths}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setAmountOfMonths(newValue);
            }
          }}
          aria-label="Amount of Months"
          sx={{ flexWrap: 'wrap' }}
        >
          {[...Array(11)].map((_, index) => {
            const month = index + 2;
            return (
              <ToggleButton key={month} value={month} aria-label={`${month} months`}>
                {month}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        {/* Deliverables */}
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
          Deliverables
        </Typography>

        {deliverables.map((deliverable, index) => {
          const deliverableContent = (
            <>
              <TextField
                label="Work Info"
                value={deliverable.work_info}
                onChange={(e) => handleDeliverableChange(index, 'work_info', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Deliverable Type"
                value={deliverable.type}
                onChange={(e) => handleDeliverableChange(index, 'type', e.target.value)}
                fullWidth
                margin="normal"
              >
                {VALID_DELIVERABLE_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Amount"
                type="number"
                value={deliverable.amount}
                onChange={(e) => handleDeliverableChange(index, 'amount', e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                }}
              />
              <TextField
                label="Due Date"
                type="date"
                value={deliverable.due_date}
                onChange={(e) => handleDeliverableChange(index, 'due_date', e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          );

          if (index === 0) {
            return (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  border: '1px solid #ccc',
                  padding: 2,
                  borderRadius: 1,
                }}
              >
                <Typography variant="subtitle1">
                  Deliverables for Month {deliverable.month}
                </Typography>
                {deliverableContent}
                <Button
                  variant="outlined"
                  onClick={applyToAllDeliverables}
                  sx={{ mt: 2 }}
                >
                  Apply Work Info to all months
                </Button>
              </Box>
            );
          } else {
            return (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Deliverables for Month {deliverable.month}</Typography>
                </AccordionSummary>
                <AccordionDetails>{deliverableContent}</AccordionDetails>
              </Accordion>
            );
          }
        })}

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Total Deliverables Amount: USD {calculateTotalDeliverableAmount().toFixed(2)}
        </Typography>

        {!validateTotalAmount() && (
          <Typography color="error">
            The total amount of deliverables must equal the Amount Due.
          </Typography>
        )}

        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Deal IO'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DealIOCreateForm;
