import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  Box,
  TextField,
  Divider,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  Snackbar,
  Alert,
  Container,
  Stepper,
  Step,
  StepLabel,
  CssBaseline,
  InputLabel,
  FormControl,
  Select,
  ThemeProvider,
  Tabs,
  Tab
} from '@mui/material';
import { keyframes } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../Config/routes';
import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import ConsentForm from '../../CreatorUser/CreatorPages/popups/consentform';
import client from '.././../API'
import { useGoogleLogin } from '@react-oauth/google';
import { niches, region } from '../../Utils/constants'
import { GoogleLogin } from '@react-oauth/google';
import GoogleButton from '../../Components/GoogleButton';
import { useMutation } from 'react-query';
import MuiPhoneInput from '../../Components/MuiPhoneInput';
import {
  validateEmail,
  validatePassword,
  capitalizeFirstLetter,
} from "../../Utils";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";
import HomeFooter from "../../Components/HomeFooter";

const pageTheme = lightTheme;

// The AllOnboard component
const AllOnboard = () => {
  const [selectedTab, setSelectedTab] = useState('brand'); // 'brand' or 'creator'

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider theme={pageTheme}>
      <CssBaseline />
      <HomeHeader />
      <Toolbar />

      <Container sx={{ paddingTop: 6, paddingBottom: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
            Welcome to Blitz!
          </Typography>
          <Typography variant="body1" paragraph>
            Enter your information to continue with Blitz
          </Typography>
          <Divider sx={{ marginBlockEnd: '1em' }} />
        </Box>

        <Box sx={{ maxWidth: 800, margin: '0 auto', mb: 4 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="Sign up as a Brand User" value="brand" />
            <Tab label="Sign up as a Creator" value="creator" />
          </Tabs>

          <Divider sx={{ my: 2 }} />

          {selectedTab === 'brand' && <BrandRegisterForm />}
          {selectedTab === 'creator' && <CreatorOnboardForm />}
        </Box>
      </Container>

      <HomeFooter />
    </ThemeProvider>
  );
};

// Brand Register Form (adapted from DemoRegister)
const BrandRegisterForm = () => {
  const navigate = useNavigate();
  const { ref } = useParams();
  const referringUserId = ref && !isNaN(Number(ref)) ? Number(ref) : null;
  const referralSource = referringUserId === null ? ref : "";

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    phoneNumber: "",
    industry: "",
    bio: "",
    businessType: "",
    isAgency: "",
    monthlySpend: "",
    teamAccounts: "",
    paymentMethod: "",
    referralSource: referralSource,
    referringUserId: referringUserId,
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const [openDialog, setOpenDialog] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Personal Information", "Company Information", "Business Details"];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { mutate: register, isLoading } = useMutation(
    client.companies.demoRegister,
    {
      onSuccess: () => {
        navigate(routes.login);
      },
      onError: (error) => {
        console.error("An error occurred during registration: ", error);
        let errorMessage = "An error occurred, please try again.";
        if (error.code === "ERR_NETWORK") {
          errorMessage = "Network is disconnected!";
        } else {
          if (error.response && error.response.data) {
            errorMessage = error.response.data.error || errorMessage;
          }
        }
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      },
    }
  );

  const handleNext = () => {
    let bValidation = true;

    const requiredFields = getRequiredFieldsForStep(activeStep);

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    if (!bValidation) return;

    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setSnackbarMessage("Invalid email address!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (!validatePassword(userInfo.password)) {
        setSnackbarMessage("Password should have 6 or more characters!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (userInfo.password !== userInfo.confirmPassword) {
        setSnackbarMessage("Passwords should match!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }
    }

    if (activeStep === steps.length - 1) {
      handleRegister();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRegister = async () => {
    let bValidation = true;

    const requiredFields = Object.keys(userInfo).filter(
      (field) => field !== "referralSource" && field !== "referringUserId"
    );

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    if (
      bValidation &&
      !userInfo.referralSource &&
      !userInfo.referringUserId
    ) {
      setSnackbarMessage(
        "Either Referral Source or Referring User ID is required!"
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      bValidation = false;
    }

    if (!bValidation) return;

    const params = { ...userInfo };

    register(params);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0:
        return [
          "firstName",
          "lastName",
          "email",
          "password",
          "confirmPassword",
          "phoneNumber",
        ];
      case 1:
        return [
          "companyName",
          "streetAddress",
          "city",
          "state",
          "country",
          "industry",
        ];
      case 2:
        return [
          "businessType",
          "isAgency",
          "monthlySpend",
          "teamAccounts",
          "paymentMethod",
        ];
      default:
        return [];
    }
  };

  const industryOptions = [
    "Fashion",
    "Tech",
    "Food",
    "Beauty",
    "Sports",
    "Travel",
    "Finance",
    "Health",
    "Education",
    "Entertainment",
    "Automotive",
    "Real Estate",
    "Hospitality",
    "Retail",
    "Manufacturing",
    "Telecommunications",
  ];

  const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Mexico",
    "Russia",
    "South Korea",
    "South Africa",
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <TextField
              disabled={isLoading}
              label="First Name"
              name="firstName"
              variant="outlined"
              value={userInfo.firstName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={userInfo.lastName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Email"
              name="email"
              variant="outlined"
              value={userInfo.email}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Password"
              name="password"
              variant="outlined"
              type="password"
              value={userInfo.password}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Re-enter Password"
              name="confirmPassword"
              variant="outlined"
              type="password"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
          </div>
        );
      case 1:
        return (
          <div>
            <TextField
              disabled={isLoading}
              label="Company Name"
              name="companyName"
              variant="outlined"
              value={userInfo.companyName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              value={userInfo.streetAddress}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="City"
              name="city"
              variant="outlined"
              value={userInfo.city}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="State"
              name="state"
              variant="outlined"
              value={userInfo.state}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={userInfo.country}
                onChange={handleChange}
                label="Country"
              >
                {countryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Industry</InputLabel>
              <Select
                name="industry"
                value={userInfo.industry}
                onChange={handleChange}
                label="Industry"
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Tell us about your company"
              name="bio"
              value={userInfo.bio}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>
                Is your business all under one company, or is it separate?
              </InputLabel>
              <Select
                name="businessType"
                value={userInfo.businessType}
                onChange={handleChange}
                label="Is your business all under one company, or is it separate?"
              >
                <MenuItem value="One Company">One Company</MenuItem>
                <MenuItem value="Separate">Separate</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Are you an agency?</InputLabel>
              <Select
                name="isAgency"
                value={userInfo.isAgency}
                onChange={handleChange}
                label="Are you an agency?"
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How much do you expect to spend every month?"
              name="monthlySpend"
              value={userInfo.monthlySpend}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <TextField
              label="How many accounts do you need (for your team)?"
              name="teamAccounts"
              value={userInfo.teamAccounts}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>
                What is your main method of payment?
              </InputLabel>
              <Select
                name="paymentMethod"
                value={userInfo.paymentMethod}
                onChange={handleChange}
                label="What is your main method of payment?"
              >
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="PayPal">PayPal</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How did you hear about us?"
              name="referralSource"
              value={userInfo.referralSource}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required={!userInfo.referringUserId}
              fullWidth
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: 600, margin: '0 auto' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            minWidth: "100%",
            mt: 2
          }}
        >
          {getStepContent(activeStep)}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={
                isLoading &&
                activeStep === steps.length - 1 && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
              disabled={isLoading}
            >
              {activeStep === steps.length - 1 ? "Create Account" : "Next"}
            </Button>
          </Box>
          {userInfo.referringUserId && (
            <Box mt={2} textAlign="center">
              <Typography variant="body2" sx={{ color: "#000" }}>
                Referred by Creator ID: {userInfo.referringUserId}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thanks for Booking a Creator!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thanks for booking a creator through their media kit. To access your
            collaboration, make an account here.
          </Typography>
          <Typography variant="body2" mt={2}>
            For any questions or concerns, please contact{" "}
            <a href="mailto:jc@thecultureclub.us">jc@thecultureclub.us</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Creator Onboard Form (adapted from CreatorOnboard)
const CreatorOnboardForm = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('')
  const [validationError, setValidationError] = useState('');
  const [creatorRegion, setCreatorRegion] = useState('');
  const [creatorNiche, setCreatorNiche] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [youtube, setYoutube] = useState('');
  const [podcast, setPodcast] = useState('');
  const [twitch, setTwitch] = useState('');
  const [kick, setKick] = useState('');
  const [x, setX] = useState('');
  const [isPartner, setIsPartner] = useState(false);

  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const [consentForm, setConsentForm] = useState(false);
  const inputRef = useRef(null);
  const formRef = useRef(null);

  const [googleRequest, setGoogleRequest] = useState('');
  const [disclaimer, setOpenDisclaimer] = useState(false);
  const [loading, setLoading] = useState(false);

  const scope = [
    'https://www.googleapis.com/auth/gmail.modify',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ];

  const scopeString = scope.join(" ");

  const googleLogin = useGoogleLogin({
    onError: errorResponse => {
      openDialog("Error", `Error getting Partner permissions: ${errorResponse}`, closeDialog, closeDialog, "Ok", null);
      setLoading(false);
    },
    scope: scopeString,
    flow: "auth-code",
    ux_mode: 'redirect',
    redirect_uri: 'https://www.useblitz.co/creatorconnect/redirect',
    state: googleRequest
  });

  useEffect(() => {
    setOpenDisclaimer(false);
  }, []);

  const redirectLogin = () => {
    navigate(routes.creatorLogin);
  }

  useEffect(() => {
    if (googleRequest && isPartner)
      googleLogin();
  }, [googleRequest]);

  function handleCredentialResponse(response) {
    SubmitCreator(response.credential);
  }

  const handleLoginFailure = (error) => {
    console.error('Failed to login with Google:', error);
    setLoading(false);
    openDialog('Error', <>There was an error creating your account: {error} <br />
      Your Google and payment information has been blanked. Try again.</>, closeDialog, closeDialog, 'Ok', null);
  };


  const handleConsentClose = (consented) => {
    setConsentForm(false);
    setIsPartner(consented);
  };

  const handlePartnerSwitch = (e) => {
    if (e.target.checked) {
      setConsentForm(true);
    } else {
      setIsPartner(false);
    }
  }

  const SubmitCreator = async (googleCode) => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    }

    if (username.includes(" ")) {
      setValidationError("Creator name cannot contain spaces.");
      inputRef.current.focus();
      return;
    }

    if (phoneNumber.length < 9) {
      openDialog('Error', 'You have to enter a valid phone number', closeDialog, closeDialog, 'Ok', null);
      return;
    }

    if (!youtube && !instagram && !tiktok && !podcast && !twitch && !kick && !x) {
      openDialog('Error', 'You have enter a link for at least one platform', closeDialog, closeDialog, 'Ok', null);
      return;
    }

    setLoading(true);
    const payload = {
      username,
      is_partner: isPartner,
      region: creatorRegion,
      niche: creatorNiche,
      youtube: youtube || null,
      instagram: instagram || null,
      tiktok: tiktok || null,
      podcast: podcast || null,
      x: x || null,
      twitch: twitch || null,
      kick: kick || null,
      google_code: googleCode,
      phone_number: phoneNumber,
    };

    try {
      const data = await client.creatorConnect.onboard(payload);
      if (isPartner) {
        setGoogleRequest(data.request_id);
        return;
      }
      openDialog('Success', 'You have successfully created your CreatorConnect account.', redirectLogin, redirectLogin, 'Ok', null);
    } catch (error) {
      console.error("Error during form submission:", error);
      openDialog('Error', <>There was an error creating your account: {error.response?.data?.error || error.message} <br />
        Your Google and payment information has been blanked. Try again.</>, closeDialog, closeDialog, 'Ok', null);
      setLoading(false);
    }
  }

  const OnSubmit = (e) => {
    e.preventDefault();
    SubmitCreator(null);
  }

  // Gradient border animation
  const moveGradient = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  `;

  const rainbowBorder = {
    position: 'relative',
    padding: '1em',
    marginBlock: 2,
    borderRadius: '8px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    zIndex: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      background: 'linear-gradient(90deg, red, orange, yellow, green, cyan, blue, violet)',
      zIndex: -2,
      animation: `${moveGradient} 3s linear infinite`,
      backgroundSize: '200% 200%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '4px',
      left: '4px',
      right: '4px',
      bottom: '4px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      zIndex: -1,
    },
  };

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ paddingInline: 2 }}>
        <form onSubmit={OnSubmit} ref={formRef} noValidate>
          <Box sx={{ marginInline: 'auto', marginBlockStart: 4, marginBlockEnd: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '30em' }}>
            <Box sx={{ marginBlockEnd: 1, textAlign: 'center' }}>
              <Typography variant='h3'>Account Setup</Typography>
              <Typography variant='body1' color='text.secondary'>Help us build your Media Kit!</Typography>
            </Box>
            <TextField
              label='Username'
              name='onboard-name'
              helperText={validationError || 'Use the username of your biggest social media.'}
              required
              value={username}
              error={!!validationError}
              onChange={e => setUsername(e.target.value)}
              inputRef={inputRef}
            >
            </TextField>
            <TextField
              label='Your Region'
              name='onboard-region'
              select
              required
              value={creatorRegion}
              onChange={e => setCreatorRegion(e.target.value)}
            >
              {region.map((value) => (<MenuItem key={value} value={value}>{value === 'NACAUKAUS' ? 'USA, Canada, UK, Australia' : value}</MenuItem>))}
            </TextField>
            <TextField
              label='Your Niche'
              name='onboard-niche'
              select
              required
              value={creatorNiche}
              onChange={e => setCreatorNiche(e.target.value)}
            >
              {niches.map((market) => (
                <MenuItem key={market} value={market}>
                  {market}
                </MenuItem>
              ))}
            </TextField>
            <MuiPhoneInput
              country={'us'}
              preferredCountries={['us']}
              value={phoneNumber}
              onChange={setPhoneNumber}
              isValid={(value) => value.length >= 10}
              required
            />
            <Divider></Divider>
            <TextField
              label='Youtube Link (If applicable)'
              name='onboard-youtube'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?(youtube\\.com|youtu\\.be)/(@?[\\w\\-]+)$",
                title: 'Input a valid Youtube link'
              }}
              value={youtube}
              onChange={e => setYoutube(e.target.value)}
            />
            <TextField
              label='Instagram Link (If applicable)'
              name='onboard-instagram'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?instagram\\.com/[a-zA-Z0-9._]+/?$",
                title: 'Input a valid Instagram link'
              }}
              value={instagram}
              onChange={e => setInstagram(e.target.value)}
            />
            <TextField
              label='Tiktok Link (If applicable)'
              name='onboard-tiktok'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?tiktok\\.com/@[a-zA-Z0-9._]+/?$",
                title: 'Input a valid Tiktok link'
              }}
              value={tiktok}
              onChange={e => setTiktok(e.target.value)}
            />
            <TextField
              label='Podcast Link (If applicable)'
              name='onboard-tiktok'
              type='url'
              value={podcast}
              onChange={e => setPodcast(e.target.value)}
            />
            <TextField
              label='X Link (If applicable)'
              name='onboard-x'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?(x|twitter)\\.com/[a-zA-Z0-9_]+/?$",
                title: 'Input a valid X link'
              }}
              value={x}
              onChange={e => setX(e.target.value)}
            />
            <TextField
              label='Kick Link (If applicable)'
              name='onboard-kick'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?kick\\.com/[a-zA-Z0-9_]+/?$",
                title: 'Input a valid Kick link'
              }}
              value={kick}
              onChange={e => setKick(e.target.value)}
            />
            <TextField
              label='Twitch Link (If applicable)'
              name='onboard-twitch'
              inputProps={{
                pattern: "^(https?://)?(www\\.)?twitch\\.tv/[a-zA-Z0-9_]+/?$",
                title: 'Input a valid Twitch link'
              }}
              value={twitch}
              onChange={e => setTwitch(e.target.value)}
            />
            <Card elevation={2} sx={rainbowBorder}>
              <Box sx={{ position: 'relative', zIndex: 2 }}>
                <FormControlLabel
                  sx={{ marginInlineStart: 0, color: 'black' }}
                  labelPlacement='start'
                  checked={isPartner}
                  onChange={handlePartnerSwitch}
                  label='Enable pitch feature, AI inbound assistance, lower Blitz instant pay fee, and high priority on marketplace here.'
                  control={<Switch />}
                />
              </Box>
            </Card>
            <Typography variant='caption' color='text.secondary' sx={{ mt: -1, ml: 2 }}>
              Creators who enable this feature get 2-5x more deals
            </Typography>
            {!isPartner && <Box sx={{ alignSelf: 'flex-end' }}>
              <GoogleLogin
                size='large'
                onSuccess={handleCredentialResponse}
                onError={handleLoginFailure} />
            </Box>}
            {isPartner && <Box sx={{ alignSelf: 'flex-end' }}>
              <GoogleButton type='submit'></GoogleButton>
            </Box>
            }
          </Box>
        </form>
      </Box>

      <ConsentForm open={consentForm} onClose={handleConsentClose}></ConsentForm>

      <Dialog open={disclaimer} onClose={() => setOpenDisclaimer(false)}>
        <DialogTitle>Signup Form Information</DialogTitle>
        <DialogContent>
          <p>
            This signup form is for joining Blitz's CreatorConnect. You will be available in the marketplace for companies to send campaigns directly to your email and phone number. By joining, you are joining a community of thousands of creators worldwide!
          </p>
          <p>
            <strong>Why Google Login?</strong><br />
            We use Google login to secure creator accounts with industry-leading security features, ensuring your data and login credentials remain safe.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDisclaimer(false)} color="primary" variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllOnboard;
