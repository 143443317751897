import React, { useState, useEffect, useMemo } from 'react'
import { StyledTableRow } from '../../../Utils/styledcell'
import { Avatar, Box, Divider, InputAdornment, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { SearchRounded } from '@mui/icons-material';
import AgencyPartnershipDialog from './agencyCampaignPartnershipDialog';

const creatorPartnershipOptions = ['pending', 'Completed'];

function PartnershipRow({ partnership, onClick }) {
    const offer = partnership.total_sum?.toFixed(2) || '0'
    const type = 'Something'
    return (
        <StyledTableRow onClick={onClick} sx={{ cursor: 'pointer' }}>
            <TableCell align='left'>
                {partnership.name}
            </TableCell>
            <TableCell align='right'>
                {offer}
            </TableCell>
            <TableCell align='right'>
                {partnership.finish_date?.toDateString() || 'N/A'}
            </TableCell>
        </StyledTableRow>
    )
}

//ideal_duedate
export default function AgencyCreatorPartnerships({ creatorData }) {
    const [status, setStatus] = useState(creatorPartnershipOptions[0]);
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [selectedPartnership, setSelectedParnership] = useState(null);
    const [openPartnership, setOpenPartnership] = useState(false)

    const HandleClickRow = (partnership) => {
        setSelectedParnership(partnership);
        setOpenPartnership(true);
    }

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
    }, [searchInput]);

    const filteredPartnerships = useMemo(() => creatorData?.partnerships.filter((partnership) => {
        const searchMatch = currentSearch === "" || Object.values(partnership).some((val) => typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase()));
        const statusMatch = partnership.status === status;

        return searchMatch && statusMatch;
    }), [creatorData, currentSearch, status]);

    return (
        <>
            <Paper elevation={2} square sx={{ marginBlockStart: 2, width: '100%', overflowX: 'hidden' }}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={creatorData?.creator.pfphref || undefined}>
                            {!creatorData?.creator.pfphref && creatorData?.creator.creator[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={creatorData?.creator.creator || <>&nbsp;</>} secondary={creatorData?.creator.notes_content_style || <>&nbsp;</>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
                </ListItem>
            </Paper>
            <Box sx={{ paddingInline: { xs: 1, md: 2 }, paddingBlock: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <TextField sx={{ width: '10rem' }} InputProps={{ sx: { textTransform: 'capitalize' } }} select label='Status' value={status} onChange={(event) => setStatus(event.target.value)}>
                        {creatorPartnershipOptions.map((value) => (<MenuItem value={value} key={value} sx={{ textTransform: 'capitalize' }}>{value}</MenuItem>))}
                    </TextField>
                    <TextField
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchRounded>
                                    </SearchRounded>
                                </InputAdornment>)
                        }}>
                    </TextField>
                </Box>
                <Divider></Divider>
                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    Name
                                </TableCell>
                                <TableCell align='right'>
                                    Offer
                                </TableCell>
                                <TableCell align='right'>
                                    Due date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!filteredPartnerships && filteredPartnerships.map((partnership) => (
                                <PartnershipRow
                                    partnership={partnership}
                                    key={partnership.id}
                                    onClick={() => HandleClickRow(partnership)}
                                >
                                </PartnershipRow>
                            ))}
                            {!!filteredPartnerships && filteredPartnerships.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        No partnerships found. Try changing the search filters!
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <AgencyPartnershipDialog
                partnership={selectedPartnership}
                openDialog={openPartnership}
                handleCloseDialog={() => setOpenPartnership(false)}></AgencyPartnershipDialog>
        </>
    )
}
