import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Toolbar,
  ThemeProvider,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HomeHeader from '../../../Components/HomeHeader';
import HomeFooter from '../../../Components/HomeFooter';
import { globalStyles } from '../../../Utils/Styles';
import { StyledDivider as Divider } from '../../../Components/LightDivider';
import API from '../../../API';
import { lightTheme } from '../../../Utils/baseTheme';
import { useQuery } from 'react-query';
import useAlertDialog from '../../../Components/useAlertDialog';
import AlertDialog from '../../../Components/AlertDialog';

const InvoicePage = () => {
  const { invoiceId } = useParams();
  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const { data: invoiceData, isLoading: loading, error } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => API.invoices.fetch(invoiceId),
    refetchOnWindowFocus: false,
    enabled: !isNaN(invoiceId)
  });

  const invoice = invoiceData?.invoice;
  const user = invoiceData?.user;
  const company = invoiceData?.company;
  const payouts = invoiceData?.payouts;

  useEffect(() => {
    if (error) {
      openDialog('Error', <>Error fetching invoice: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, 'Ok', null);
    }
  }, [error]);

  const handleDownloadPDF = () => {
    const input = document.getElementById('invoice-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${invoice.id}.pdf`);
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState}></AlertDialog>
        <CircularProgress />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AlertDialog alertState={dialogState}></AlertDialog>
        <Typography>Invoice not found.</Typography>
      </Box>
    );
  }

  const formattedDate = (date) => new Date(date).toLocaleDateString();
  const getAmountDue = (amount) => amount || 0;

  // Calculate amounts
  const subtotal = payouts.reduce((acc, payout) => acc + getAmountDue(payout.amount), 0);
  const amountDue = getAmountDue(invoice.amount_due);
  const amountPaid = getAmountDue(invoice.amount_paid);
  const platformFee = amountDue - subtotal;
  const total = subtotal + platformFee;
  const amountDueAfterPayment = amountDue - amountPaid;

  return (
    <ThemeProvider theme={lightTheme}>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HomeHeader />
        <Toolbar />
        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              ...globalStyles.wholeHeightMinusToolbar,
              py: 2,
              maxWidth: 'md',
            }}
          >
            <Box id="invoice-content" sx={{ mb: 4 }}>
              <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
                {/* Company Logo */}
                {company && company.phphref && (
                  <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <img
                      src={company.phphref}
                      alt={`${company.name} Logo`}
                      style={{ maxWidth: '200px', maxHeight: '100px' }}
                    />
                  </Box>
                )}

                {/* Invoice Header */}
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  sx={{ fontWeight: 'bold', mb: 2 }}
                >
                  Invoice From Blitz
                </Typography>

                <Typography variant="body1" align="center">
                  Invoice ID: {invoiceId}
                </Typography>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                      Billed To
                    </Typography>
                    <Typography variant="body1">
                      {invoice.client_name || `${user?.first_name} ${user?.last_name}` || 'Client Name'}
                    </Typography>
                    <Typography variant="body1">
                      {invoice.client_email || user?.email || 'Client Email'}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDownloadPDF}
                      sx={{ mt: 4 }}
                    >
                      Download PDF
                    </Button>
                  </Box>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="body1">
                    Date of Issue: {formattedDate(invoice.created_at)}
                  </Typography>
                  <Typography variant="body1">
                    Invoice Number: {invoice.po_number || 'N/A'}
                  </Typography>
                  <Typography variant="body1">
                    Amount Due (USD): ${amountDue.toFixed(2)}
                  </Typography>
                </Box>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4 }}>
                  <TableContainer component={Paper} elevation={1}>
                    <Table>
                      <TableHead sx={{ bgcolor: '#000' }}>
                        <TableRow>
                          <TableCell sx={{ color: '#fff' }}>{payouts.length > 0 ? 'Creator' : ' '}</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Rate</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Qty</TableCell>
                          <TableCell sx={{ color: '#fff' }}>Line Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payouts.map((payout) => (
                          <TableRow key={payout.id}>
                            <TableCell>{payout.creator_id}</TableCell>
                            <TableCell>
                              {payout.notes || `PO Number ${payout.po_number}`}
                            </TableCell>
                            <TableCell>${getAmountDue(payout.amount).toFixed(2)}</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>${getAmountDue(payout.amount).toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                        {payouts.length == 0 && (
                          <TableRow>
                            <TableCell>{''}</TableCell>
                            <TableCell>
                              Payment to {company.name}
                            </TableCell>
                            <TableCell>${getAmountDue(invoice.amount_due).toFixed(2)}</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>${getAmountDue(invoice.amount_due).toFixed(2)}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="body1">Subtotal: ${subtotal.toFixed(2)}</Typography>
                  <Typography variant="body1">
                    Platform Fee: ${platformFee.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">Total: ${total.toFixed(2)}</Typography>
                  <Typography variant="body1">
                    Amount Paid: ${amountPaid.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Amount Due (USD): ${amountDueAfterPayment.toFixed(2)}
                  </Typography>
                </Box>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    Terms
                  </Typography>
                  <Typography variant="body1">
                    Make all checks payable to "DBA Pangea Omninational Corporation"
                  </Typography>
                  <Typography variant="body1">Account holder: Houston Alexander Roa</Typography>
                  <Typography variant="body1">Account Name: Pangea</Typography>
                  <Typography variant="body1">
                    Address of Beneficiary: 8724 Cavell Lane, Houston, TX 77055
                  </Typography>
                  <Typography variant="body1">Bank name: Prosperity Bank</Typography>
                  <Typography variant="body1">
                    Address of bank: 2117 N. Fry Road, Katy, TX 77449
                  </Typography>
                  <Typography variant="body1">Account number: 217841714</Typography>
                  <Typography variant="body1">SWIFT: PROYUS44</Typography>
                  <Typography variant="body1">
                    ABA / Routing number/IBAN: 113122655
                  </Typography>
                </Box>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Pay with PayPal, Credit Card, and more!
                  </Typography>
                  {invoice.invoice_link && <Button
                    variant="contained"
                    color="secondary"
                    href={invoice.invoice_link}
                    sx={{ mt: 2 }}
                  >
                    Pay Invoice
                  </Button>}
                </Box>
              </Paper>
            </Box>
          </Container>
        </Box>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default InvoicePage;
