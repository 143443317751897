import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import { useQuery } from "react-query";
import client from "../../../API";

const Commissions = () => {
  const { data: commissions, error, isLoading } = useQuery('commissions', client.commissions.list);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error fetching commissions</Typography>;
  }

  // Calculate total earned and total pending amounts
  const totalEarned = commissions.reduce((sum, commission) => {
    const amountEarned = commission.total_profit * (commission.rebate_percentage / 100);
    return commission.invoice_status.toLowerCase() === 'paid' ? sum + amountEarned : sum;
  }, 0);

  const totalPending = commissions.reduce((sum, commission) => {
    const amountEarned = commission.total_profit * (commission.rebate_percentage / 100);
    return commission.invoice_status.toLowerCase() === 'sent' ? sum + amountEarned : sum;
  }, 0);

  return (
    <Box sx={{ py: 4 }}>
      {/* Header with total earned and total pending */}
      <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: "center", pt: 4 }}>
        Your Commissions
      </Typography>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h6">
          Total Earned: ${totalEarned.toFixed(2)}
        </Typography>
        <Typography variant="h6">
          Total Pending: ${totalPending.toFixed(2)}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ width: '80%', marginInline: 'auto' }}>
        {commissions.map((commission) => {
          const amountEarned = commission.total_profit * (commission.rebate_percentage / 100);
          return (
            <Grid item xs={12} sm={6} md={4} key={commission.id}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6">
                    Invoice ID: {commission.invoice_id}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Date Created: {new Date(commission.date_created).toLocaleString()}
                  </Typography>
                  <Typography variant="body1">
                    Amount {commission.invoice_status.toLowerCase() === 'paid' ? 'Earned' : 'Will Earn'}: ${amountEarned.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Invoice Status: {commission.invoice_status}
                  </Typography>
                  {/* Other details can be displayed as needed */}
          
  
                  <Typography variant="h6">
                    Total Profit: ${commission.total_profit}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                  <Button size="small" color="primary">
                    Download Invoice
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Commissions;
