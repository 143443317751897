import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react'
import SelectPopup from '../../../../Components/SelectPopup';
import FilterPopup from '../../../../Components/FilterPopup';
import { ageGroups, CountSets, ToggleSet } from '../../../../Utils/constants';
import iso3311a2 from 'iso-3166-1-alpha-2';

const priorityCountries = ['United States', 'Canada', 'United Kingdom'];
// Create the country array and sort it
const countryArray = Object.entries(iso3311a2.getData()).sort(([codeA, countryA], [codeB, countryB]) => {
const indexA = priorityCountries.indexOf(countryA);
const indexB = priorityCountries.indexOf(countryB);

if (indexA !== -1 && indexB === -1) return -1; // countryA is in priority, countryB is not
if (indexA === -1 && indexB !== -1) return 1;  // countryB is in priority, countryA is not
if (indexA !== -1 && indexB !== -1) return indexA - indexB; // Both are in priority, sort by their priority order

return countryA.localeCompare(countryB);
});

export default function DemoFilters({ genderValue, ageValue, countryValue, onChangeValue }) {
    const [internalGender, setInternalGender] = useState(new Set([]));
    const [internalCountry, setInternalCountry] = useState(new Set([]));
    const [internalAge, setInternalAge] = useState(new Set([]));
    const [showAll, setShowAll] = useState(false)

    const onOpen = () => {
        setInternalGender(new Set(genderValue));
        setInternalCountry(new Set(countryValue));
        setInternalAge(new Set(ageValue));
        setShowAll(false);
    }

    const onClear = () => {
        setInternalGender(new Set([]));
        setInternalCountry(new Set([]));
        setInternalAge(new Set([]));
        onChangeValue({
            age:new Set([]),
            country:new Set([]),
            gender:new Set([])
        });
    };

    const onApply = () => {
        onChangeValue({
            age:new Set(internalAge),
            country:new Set(internalCountry),
            gender:new Set(internalGender)
        });
    };

    const preview = (CountSets([genderValue,ageValue,countryValue])) ? '...' : ''

    return (
        <SelectPopup value={preview} label='Target Demographic' inputStyle={{ width: '13.5rem' }} onOpenPopup={onOpen} >
            <FilterPopup boxStyle={{ padding: 2, overflowY: 'auto', maxHeight: '25rem' }} onApply={onApply} onClear={onClear}>
                <Typography variant='subtitle1' color='text.secondary' ><b>Gender groups</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)', width:'fit-content' }}>
                    {['female', 'male'].map((gender) =>
                        <FormControlLabel key={`gender-${gender}`} 
                        sx={{textTransform:'capitalize'}}
                        checked={internalGender.has(gender)} 
                        onChange={() => ToggleSet(gender, internalGender, setInternalGender)} 
                        label={gender} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
                <Typography variant='subtitle1' color='text.secondary' ><b>Age groups</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)', width:'fit-content'  }}>
                    {ageGroups.map((age) =>
                        <FormControlLabel key={`age-${age}`} 
                        checked={internalAge.has(age)} 
                        onChange={() => ToggleSet(age, internalAge, setInternalAge)} 
                        label={age} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
                <Typography variant='subtitle1' color='text.secondary' ><b>Regions</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)', width:'fit-content'  }}>
                    {countryArray.slice(0, showAll ? countryArray.length : 3).map(([code, country]) =>
                        <FormControlLabel key={`region-${code}`} 
                        checked={internalCountry.has(code)} 
                        onChange={() => ToggleSet(code, internalCountry, setInternalCountry)} 
                        label={country} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
                <Button size='small' variant={showAll ? 'contained':'text'} onClick={()=>setShowAll(prevState=>!prevState)}>{showAll ? 'Show Less' : 'Show More'}</Button>
            </FilterPopup>
        </SelectPopup>
    )
}