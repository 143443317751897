import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Button,
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import { useIsMounted } from "../../Hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../API";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/use-auth";
import DealIOCardList from "./dealCardList";
import DealIOCreateForm from "./dealscreate"; // Import the DealIOCreateForm

function formatDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

function summarizeText(text, maxLength = 50) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
}

const DealIOs = () => {
  const isMounted = useIsMounted();
  const [dealIOs, setDealIOs] = useState([]);
  const [filteredDealIOs, setFilteredDealIOs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDealIOs, setSelectedDealIOs] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("dealios");

  const navigate = useNavigate();

  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const currentUserId = currentUser.id;

  // Add state variables for the dialog
  const [openDialog, setOpenDialog] = useState(false);

  // Function to handle opening the dialog
  const handleOpenCreateDialog = () => {
    setOpenDialog(true);
  };

  // Function to handle closing the dialog
  const handleCloseCreateDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    filterDealIOs();
  }, [searchQuery, dealIOs]);

  const { mutate: fetchDealIOs } = useMutation(client.dealsIO.list, {
    onSuccess: (data) => {
      let filteredData = data;
      setDealIOs(filteredData);
      setFilteredDealIOs(filteredData);
      setLoading(false);
    },
    onError: (error) => {
      console.error("Error fetching Deal IOs:", error);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!isMounted) return;
    setLoading(true);
    fetchDealIOs();
  }, [isMounted]);

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
  };

  const handleCheckboxChange = (dealIOId) => {
    const selectedIndex = selectedDealIOs.indexOf(dealIOId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDealIOs, dealIOId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDealIOs.slice(1));
    } else if (selectedIndex === selectedDealIOs.length - 1) {
      newSelected = newSelected.concat(selectedDealIOs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDealIOs.slice(0, selectedIndex),
        selectedDealIOs.slice(selectedIndex + 1)
      );
    }

    setSelectedDealIOs(newSelected);
  };

  const handleOpenDealIODetail = useCallback(
    (dealIO) => {
      navigate(`/deals/${dealIO.id}`); // Adjust the route as needed
    },
    [navigate]
  );

  const { mutateAsync: deleteDealIO, isLoading: isDeletingDealIO } = useMutation(
    client.dealsIO.delete,
    {
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.error("Error deleting Deal IO:", error);
        alert("Error deleting Deal IO: " + error.message);
      },
    }
  );

  const applyAction = async () => {
    if (!selectedAction) {
      alert("Please choose an action!");
      return;
    }

    if (selectedAction === "delete") {
      if (selectedDealIOs.length === 0) {
        alert("No Deal IOs selected for deletion!");
        return;
      }

      // Delete each selected Deal IO individually
      for (const dealId of selectedDealIOs) {
        await deleteDealIO(dealId);
      }

      alert("Selected Deal IO(s) deleted successfully!");
      fetchDealIOs();
    }

    setSelectedDealIOs([]);
  };

  const sortedDealIOs = dealIOs.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const handleShareDealIO = useCallback((dealIOId) => {
    const url = `${window.location.origin}/deals/public/${dealIOId}`;
    navigator.clipboard.writeText(url);
    alert("Share link copied to clipboard!");
  }, []);

  const handleCopyDealIO = useCallback(
    async (dealIOId) => {
      try {
        const data = await client.dealsIO.copy({ dealIOId: dealIOId });
        alert(data.message);
        fetchDealIOs();
      } catch (error) {
        console.error("Failed to copy Deal IO:", error);
        alert(
          `Error copying Deal IO:\n${error?.response?.data?.error || error.message}`
        );
      }
    },
    [fetchDealIOs]
  );

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filterDealIOs = () => {
    const filtered = dealIOs.filter((dealIO) => {
      // Find the owner from user_data
      const owner = dealIO.user_data.find((user) => user.role === "Owner");
      return (
        owner &&
        owner.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    setFilteredDealIOs(filtered);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ textAlign: "center" }}
          >
            Deal Factoring
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenCreateDialog}
            >
              Create Deal IO
            </Button>
          </Box>

          {view === "dealios" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                  my: 4,
                  width: "100%",
                  boxSizing: "border-box",
                  overflowX: "auto",
                  padding: 0,
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search by user name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  sx={{ minWidth: 240 }}
                />
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 240 }}
                >
                  <InputLabel id="action-type-label">Action Type</InputLabel>
                  <Select
                    labelId="action-type-label"
                    value={selectedAction}
                    onChange={handleActionChange}
                    label="Action Type"
                  >
                    <MenuItem value="edit">Edit</MenuItem>
                    <MenuItem value="delete">Delete</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applyAction}
                  startIcon={
                    isDeletingDealIO && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  Apply
                </Button>
              </Box>
              <DealIOCardList
                dealIOs={filteredDealIOs}
                handleOpenDialog={handleOpenDealIODetail}
                handleShareDealIO={handleShareDealIO}
                handleCopyDealIO={handleCopyDealIO}
                selectedDealIOs={selectedDealIOs}
                handleCheckboxChange={handleCheckboxChange}
              />
            </>
          )}

          <Dialog
            open={openDialog}
            onClose={handleCloseCreateDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <DealIOCreateForm
                onClose={handleCloseCreateDialog}
                onCreateSuccess={fetchDealIOs}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default DealIOs;
