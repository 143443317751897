import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Globe from "./globe";
import { styled } from "@mui/material/styles";
import { useIsMounted } from "../../../Hooks/use-is-mounted";
import { StyledTableRow } from "../../../Utils/styledcell";
import ZapFeedForUser from "./zapfeedforusers";
import useAuth from "../../../Hooks/use-auth";
import API from "../../../API";

const StyledGraphContainer = styled(Box)`
  max-width: 600px;
  height: 300px;
  margin: 20px;
  overflow: hidden;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #666;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  z-index: 2;
`;

const StyledGlobeFloat = styled(Box)`
  position: absolute;
  overflow-x: clip;
  overflow-y: clip;
  top: 0px;
  width: 450px;
  height: 450px;
  right: 0px;
  z-index: 0;
  opacity: 0.5;
`;

const StyledGlobeContainer = styled(Box)`
  position: absolute;
  top: -20px;
  right: -40px;
`;

// New CombinedCard component
const CombinedCard = ({
  title,
  value,
  icon,
  description,
  buttonText,
  route,
  loading,
}) => {
  const navigate = useNavigate();

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5">
            {value} <span className="material-icons-round">{icon}</span>
          </Typography>
        )}
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => navigate(route)}>
          {buttonText}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

const tableHeaders = {
  creators: [
    "Creator",
    "TikTok",
    "Instagram",
    "YouTube",
    "Manager",
    "Email",
    "Date Added",
  ],
  campaigns: [
    "Name",
    "Campaign Sum",
    "Proposal Date",
    "Brief",
    "Campaign Status",
  ],
  payouts: ["Creator ID", "Amount", "Payout Date", "BlitzPay", "Status"],
  invoices: ["Campaign/Creator", "Amount Due", "Status"],
};

const Dashboard = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();

  const [dataView, setDataView] = useState("campaigns");
  const [tabView, setTabView] = useState("table");

  const {
    data: dashboardData,
    isLoading: dashboardIsLoading,
    error: dashboardIsError,
  } = useQuery({
    queryKey: ["user", "dashboard"],
    queryFn: API.users.dashboard,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  const handleDataViewChange = (event, newValue) => {
    setDataView(newValue);
  };

  const handleTabViewChange = (event, newValue) => {
    setTabView(newValue);
  };

  const handleInviteFriend = () => {
    navigate(`/demoregister/${userInfo.id}`);
  };

  const renderTableData = () => {
    const dataSets = {
      creators: dashboardData?.creators || [],
      campaigns: dashboardData?.campaigns || [],
      payouts: dashboardData?.payouts || [],
      invoices: [],
    };
    const data = dataSets[dataView];
    return data.map((item, index) => (
      <StyledTableRow key={index}>
        {tableHeaders[dataView].map((header) => (
          <TableCell key={header}>
            {item[header.toLowerCase().replace(/\s/g, "_")]}
          </TableCell>
        ))}
      </StyledTableRow>
    ));
  };

  return (
    <Box p={2}>
      <StyledGlobeFloat>
        <StyledGlobeContainer>
          <Globe size={450} />
        </StyledGlobeContainer>
      </StyledGlobeFloat>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Typography variant="h2">Welcome to Blitz - Dashboard</Typography>
          <ZapFeedForUser />
          <Button
            variant="contained"
            color="primary"
            onClick={handleInviteFriend}
            sx={{ marginTop: 2 }}
          >
            Invite a friend to blitz, earn commission!
          </Button>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <ActionCardGrid>
              <CombinedCard
                title="Total Campaigns"
                value={dashboardData?.campaigns.length || 0}
                icon="campaigns"
                description="Review and manage your existing campaigns."
                buttonText="View"
                route="/campaigns"
                loading={dashboardIsLoading}
              />
              <CombinedCard
                title="Total Payouts"
                value={`$${dashboardData?.payouts
                  ?.reduce((sum, payout) => sum + (payout.amount || 0), 0)
                  .toFixed(2)}`}
                icon="paid"
                description="Issue payments to your creators quickly and securely."
                buttonText="Pay"
                route="/blitzpay"
                loading={dashboardIsLoading}
              />
              <CombinedCard
                title="Total Creators"
                value={dashboardData?.creators.length || 0}
                icon="people"
                description="Generate your unique Signup link to have creators onboard seamlessly."
                buttonText="Generate Link"
                route={`/creatorsignup/${userInfo.company_name}`}
                loading={dashboardIsLoading}
              />
              <CombinedCard
                title="Total Partnerships"
                value={dashboardData?.partnerships.length || 0}
                icon="partnerships"
                description="Review and manage your ongoing partnerships!"
                buttonText="View"
                route="/partnerships"
                loading={dashboardIsLoading}
              />
            </ActionCardGrid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">In-Depth Summaries</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ overflowX: "auto", maxWidth: "100%" }}>
            <Paper
              elevation={3}
              square={true}
              sx={{ position: "sticky", left: 0 }}
            >
              <Tabs value={tabView} onChange={handleTabViewChange}>
                <Tab label="Table" value="table" />
                <Tab label="Tutorials" value="tutorials" />
              </Tabs>
            </Paper>
            {tabView === "table" && (
              <>
                <Paper
                  elevation={3}
                  square={true}
                  sx={{ position: "sticky", left: 0 }}
                >
                  <Tabs value={dataView} onChange={handleDataViewChange}>
                    <Tab label="Creators" value="creators" />
                    <Tab label="Campaigns" value="campaigns" />
                    <Tab label="Payouts" value="payouts" />
                    <Tab label="Invoices" value="invoices" />
                  </Tabs>
                </Paper>
                <Paper elevation={2} sx={{ width: "fit-content" }}>
                  {dashboardIsLoading ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Table sx={{ marginBlockStart: 2 }}>
                      <TableHead>
                        <TableRow>
                          {tableHeaders[dataView].map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderTableData()}</TableBody>
                    </Table>
                  )}
                </Paper>
              </>
            )}

            {tabView === "tutorials" && (
              <>
                <Typography variant="h6">Watch Tutorials</Typography>
                {/* Render YouTube Videos or other tutorial content here */}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
