import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import client from "../../API";
import useAuth from "../../Hooks/use-auth";
import { validateEmail, validatePassword } from "../../Utils";
import routes from "../../Config/routes";
import { lightTheme } from "../../Utils/baseTheme";
import HomeHeader from "../../Components/HomeHeader";
// Import necessary components for Google Login
import { GoogleLogin } from "@react-oauth/google";
import { FaGoogle } from "react-icons/fa";
import { useCreatorAuth } from "../../Hooks/creator-use-auth";
import API from "../../API";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { authorize, setCurrentUser } = useAuth();
  const { login: creatorLogin } = useCreatorAuth(); // For creator authentication
  const [isGoogleLoading, setIsGoogleLoading] = useState(false); // State for Google login loading

  const { mutate: login, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      console.log("Login in", data);
      authorize(data.token);
      setCurrentUser(data.user);
      navigate(routes.search);
    },
    onError: (error) => {
      console.error("An error occurred during login: ", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network busy. Try again in a few seconds.";
      } else {
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || errorMessage;
        }
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setSnackbarMessage("Email field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validateEmail(email)) {
      setSnackbarMessage("Invalid email address!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (password.length === 0) {
      setSnackbarMessage("Password field is required!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validatePassword(password)) {
      setSnackbarMessage("Password should have 6 or more characters!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    login({
      email,
      password,
    });
  };

  // Handle Creator Google Login Success
  const handleCreatorGoogleLoginSuccess = async (response) => {
    setIsGoogleLoading(true);
    try {
      const token = response.credential;
      const data = await API.creatorConnect.login({ token });
      const { redirect: loginRedirect, ...loginData } = data;
      creatorLogin(loginData);
      navigate(routes.creatorConnectStart); // Navigate to creator dashboard
    } catch (error) {
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network busy. Try again in a few seconds.";
      } else {
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || errorMessage;
        }
      }
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  // Handle Creator Google Login Failure
  const handleCreatorGoogleLoginFailure = (error) => {
    console.error("Failed to login with Google:", error);
    setSnackbarMessage("Google login failed, please try again.");
    setSnackbarSeverity("error");
    setOpenSnackbar(true);
  };

  const handleRegister = () => {
    navigate(routes.requestAccess);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <HomeHeader />
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Welcome back
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Sign in to continue with Blitz
            </Typography>
          </Box>
          <form onSubmit={handleLogin}>
            <TextField
              disabled={isLoading}
              autoComplete="username"
              id="username"
              label="Email"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              disabled={isLoading}
              required
              autoComplete="current-password"
              id="current-password"
              label="Password"
              variant="outlined"
              type="password"
              sx={{ marginBottom: 2 }}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <input type="checkbox" id="remember-me" name="remember-me" />
              <label htmlFor="remember-me" style={{ marginLeft: 8 }}>
                Remember me
              </label>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ marginBottom: 2 }}
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              disabled={isLoading}
              type="submit"
              fullWidth
            >
              Login
            </Button>
          </form>

          {/* Creator Sign In with Google */}
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Creator Login With Google Below
            </Typography>
            <GoogleLogin
              onSuccess={handleCreatorGoogleLoginSuccess}
              onError={handleCreatorGoogleLoginFailure}
              render={(renderProps) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled || isGoogleLoading}
                  startIcon={<FaGoogle />}
                  fullWidth
                >
                  Creator Sign In
                </Button>
              )}
            />
          </Box>

          {/* Contact Us Button */}
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Typography variant="body2">
              Don't have an account?
            </Typography>
            <Button
              color="primary"
              onClick={handleRegister}
              sx={{ mt: 1 }}
            >
              Contact Us
            </Button>
          </Box>
        </Paper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
