import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import API from '../../../../API';
import { useQuery } from 'react-query';

export default function BrandEmailDialog({ email, keywords, onClose }) {
    const { data: message, error: messageError, isLoading: messageLoading } = useQuery({
        queryKey: ['creatorEmail', email],
        queryFn: () => API.creators.adminBrandEmail(email),
        enabled: !!email,
        staleTime: 1000.0 * 60 * 60,
        refetchOnWindowFocus: false,
    });

    const categoryData = useMemo(() => {
        if (!!message) {
            for (let catIdx = 0; catIdx < keywords.length; catIdx++) {
                let category = keywords[catIdx]
                for (let wordIdx = 0; wordIdx < category.keywords.length; wordIdx++) {
                    const word = category.keywords[wordIdx].toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');;

                    // Create a regex pattern with word boundaries around the keyword
                    const pattern = new RegExp(`\\b${word}\\b`, 'i');

                    const match = message.body.match(pattern);
                    if (match) {
                        return {
                            category: category.category,
                            keyword: word,
                            index: match.index
                        };
                    }
                }
            }
            return { category: null, keyword: null, index: -1 }; // No word from the list found
        }
        return undefined;
    }, [message]);

    const splitText = useMemo(() => {
        if (!categoryData)
            return { before: '', high: '', after: '' }

        if (!categoryData.category)
            return { before: message.body, high: '', after: '' }

        return {
            before: message.body.slice(0, categoryData.index),
            high: message.body.slice(categoryData.index, categoryData.index + categoryData.keyword.length),
            after: message.body.slice(categoryData.index + categoryData.keyword.length)
        }
    }, [categoryData])

    return (
        <Dialog open={!!email} onClose={onClose}>
            <DialogTitle>Email: {email}</DialogTitle>
            <DialogContent dividers>
                <Typography variant='subtitle1' component={'span'}><b>Subject:</b>
                    <Typography variant='body1' color='textSecondary' component={'span'}> {message?.message.subject}
                    </Typography>
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Sender:</b>
                    <Typography variant='body1' color='textSecondary' component={'span'}> {message?.message.sender}
                    </Typography>
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Recipient:</b>
                    <Typography variant='body1' color='textSecondary' component={'span'}> {message?.message.receiver}
                    </Typography>
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Thread ID:</b>
                    <Typography variant='body1' color='textSecondary' component={'span'}> {message?.message.thread_id}
                    </Typography>
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Date:</b>
                    <Typography variant='body1' color='textSecondary' component={'span'}> {message?.message.date_received?.toLocaleString()}
                    </Typography>
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Body:</b>
                </Typography><br />
                <Typography variant='body1' component={'span'}>{splitText.before}
                </Typography>
                <Typography variant='body1' component={'span'} sx={{ color: 'black', backgroundColor: 'yellowgreen' }}>{splitText.high}
                </Typography>
                <Typography variant='body1' component={'span'}>{splitText.after}
                </Typography><br />
                <Typography variant='subtitle1' component={'span'}><b>Detected as:</b>
                    <Typography variant='body1' component={'span'}> {categoryData?.category}
                    </Typography>
                    <Typography variant='body2' component={'span'}> (Because of keyword <i>{categoryData?.keyword}</i>)
                    </Typography>
                </Typography>
                <br />
                <span><Typography variant='subtitle1'><b>Labels:</b> </Typography>{
                    message?.labels.map((label) => <Chip key={label} label={label} sx={{ marginInlineStart: 1 }}></Chip>)
                }</span><br />
            </DialogContent>
            <DialogActions>
                <Button variant='text' onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
