import React from "react";
import { Card, CardContent, CardHeader, Typography, Box, Button } from "@mui/material";

const DeliverableCard = ({ deliverable, onViewOffer, isUserMessage }) => (
  <Card
    sx={{
      marginBottom: 2,
      color: "white",
      backgroundColor: "#424242",
      borderRadius: 2,
      padding: 2,
      minHeight: 300,
      maxWidth: 300,
      boxShadow: 3,
      height: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignSelf: isUserMessage ?  "flex-start" : "flex-end", // Align based on sender
    }}
  >
    <CardHeader
      title={`Deliverable: ${deliverable.deliverable_type}`}
      sx={{ color: "#ffffff" }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography variant="body2">
        Amount: ${deliverable.amount || "N/A"}
      </Typography>
      <Typography variant="body2">Status: {deliverable.status}</Typography>
      <Typography variant="body2">Due Date: {deliverable.due_date}</Typography>
      <Typography variant="body2">
        Platforms: {deliverable.platforms?.join(", ")}
      </Typography>
    </CardContent>
    <Box sx={{ paddingTop: 2 }}>
      <Button
        onClick={() => onViewOffer(deliverable)}
        color="primary"
        variant="contained"
        fullWidth
      >
        View Offer
      </Button>
    </Box>
  </Card>
);

export default DeliverableCard;
