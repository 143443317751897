import { Button, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlitzHeader from "./BlitzHeader";
import { Box } from "@mui/system";
import routes from "../Config/routes";

import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import BoltIcon from "@mui/icons-material/Bolt";
import PaidIcon from "@mui/icons-material/Paid";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";

import useAuth from "../Hooks/use-auth";

export default function HomeHeader() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [creatorMenuAnchorEl, setCreatorMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { isAuthorized } = useAuth();

  const handleSignUp = () => navigate(routes.login);
  const handleOnboard = () => navigate(routes.onboard);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (benefit) => {
    setAnchorEl(null);
    navigate(`${routes.solutions}#${benefit}`);
  };

  const handleMenuAgenciesClick = () => {
    setAnchorEl(null);
    navigate(routes.solutionsAgencies);
  };

  const handleCreatorMenuClick = (event) => {
    setCreatorMenuAnchorEl(event.currentTarget);
  };

  const handleCreatorMenuClose = () => {
    setCreatorMenuAnchorEl(null);
  };

  const handleCreatorMenuItemClick = (benefit) => {
    setCreatorMenuAnchorEl(null);
    navigate(`${routes.forcreators}#${benefit}`);
  };

  return (
    <BlitzHeader>
      <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
        {isMobile ? (
          <IconButton color="inherit" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
        ) : (
          <Box>
            <Button
              color="inherit"
              variant="text"
              onClick={() => navigate(routes.about)}
            >
              About
            </Button>
            <Button
              color="inherit"
              variant="text"
              onMouseEnter={handleMenuClick}
            >
              Solutions
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleMenuClose}
              onMouseLeave={handleMenuClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px",
                  gap: "20px",
                },
              }}
            >
              <MenuItem
                onClick={handleMenuAgenciesClick}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <Typography>For Agencies</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <Typography>BlitzPay - Accounts Payable</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("creatorSourcing")}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <Typography>Creator Sourcing</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleMenuItemClick("aiCampaignManagement")
                }
              >
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <Typography>AI Campaign Management</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleMenuItemClick("acceleratedCollaborations")
                }
              >
                <ListItemIcon>
                  <BoltIcon />
                </ListItemIcon>
                <Typography>Accelerated Creator Collaborations</Typography>
              </MenuItem>
            </Menu>

            <Button
              color="inherit"
              variant="text"
              onMouseEnter={handleCreatorMenuClick}
            >
              For Creators
            </Button>
            <Menu
              anchorEl={creatorMenuAnchorEl}
              open={!!creatorMenuAnchorEl}
              onClose={handleCreatorMenuClose}
              onMouseLeave={handleCreatorMenuClose}
              PaperProps={{
                style: {
                  display: "flex",
                  flexDirection: "row",
                  padding: "20px",
                  gap: "20px",
                },
              }}
            >
              <MenuItem
                onClick={() => handleCreatorMenuItemClick("getPaidFaster")}
              >
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <Typography>Get Paid Faster</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleCreatorMenuItemClick("manageDeals")}
              >
                <ListItemIcon>
                  <HandshakeIcon />
                </ListItemIcon>
                <Typography>Manage Brand Deals and Collabs</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleCreatorMenuItemClick("aiSalesManager")}
              >
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <Typography>AI Sales Manager</Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleCreatorMenuItemClick("businessSuite")
                }
              >
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <Typography>Creator Business Suite</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => navigate(routes.parntersListL)}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <Typography>Our Partners</Typography>
              </MenuItem>
            </Menu>

            {/* New Articles Button */}
            <Button
              color="inherit"
              variant="text"
              onClick={() => navigate(routes.articles)}
            >
              Articles
            </Button>
          </Box>
        )}
      </Box>

      {/* Login and Creator Login */}
      <Box display="flex" justifyContent="end" flex={1} overflow={'visible'} textAlign='right' whiteSpace='nowrap' minWidth='0'>
        {isAuthorized ? (
          <Button color="inherit" variant="text" onClick={() => navigate(routes.dashboard)}>
            Back to Blitz
          </Button>
        ) : (
          <>
            <Button color="inherit" variant="text" onClick={handleSignUp}>
              Login
            </Button>
            <Button color="inherit" variant="text" onClick={handleOnboard}>
              Signup
            </Button>
          </>
        )}
       
      </Box>

      {/* Mobile Dropdown Menu */}
      {isMobile && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical:'bottom',
            horizontal:'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => navigate(routes.about)}>About</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
            Solutions
          </MenuItem>
          <MenuItem onClick={() => navigate(routes.solutionsAgencies)}>For Agencies</MenuItem>
          <MenuItem onClick={() => navigate(routes.forcreators)}>
            For Creators
          </MenuItem>
          <MenuItem onClick={() => navigate(routes.articles)}>
            Articles
          </MenuItem>
          <MenuItem onClick={() => navigate(routes.parntersListL)}>
            Our Partners
          </MenuItem>
        </Menu>
      )}
    </BlitzHeader>
  );
}
