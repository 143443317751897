import { Add, Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link, List, ListItem, ListItemText, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useIsDesktop from '../../Hooks/useIsDesktop';
import UploadFileDialog from '../../Components/tools/uploadfile';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../Config/routes';
import { isValidVideoUrl } from '../../Utils/constants';

export function NewTracker({ open, handleClose, handleAddTracker }) {
    const [links, setLinks] = useState(['']);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [trackerPhoto, setTrackerPhoto] = useState(''); // To store the uploaded photo URL
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false); // To handle upload dialog visibility
    const [freq, setFreq] = useState('2')
    const isDesktop = useIsDesktop();

    // **Dummy links array**
    const dummyPhotoLinks = [
        'https://storage.googleapis.com/blitzccmc.appspot.com/1/287262.jpg',
        'https://storage.googleapis.com/blitzccmc.appspot.com/1/wp2468668.jpg',
        'https://storage.googleapis.com/blitzccmc.appspot.com/1/eev9l01j44ab1.jpg',
    ];

    const cleanupFields = () => {
        setLinks(['']);
        setName('');
        setDescription('');
        setTrackerPhoto('');
        setFreq('2');

    };

    useEffect(() => {
        if (open) {
            cleanupFields();
        }
    }, [open]);

    const handleFreq = (event) => {
        if (event.target.valueAsNumber < 2)
            event.target.setCustomValidity('Only Blitz Campaigns support daily tracker notifications');
        else
            event.target.setCustomValidity('');
        setFreq(event.target.value);
    }

    const handleLink = (event, idx) => {
        setLinks((prevState) => {
            const updatedLinks = [...prevState];
            updatedLinks[idx] = event.target.value;
            return [...updatedLinks];
        })

        const isValid = isValidVideoUrl(event.target.value);

        if (!isValid && event.target.value)
            event.target.setCustomValidity('This is not a valid Youtube or TikTok link');
        else
            event.target.setCustomValidity('');
    }


    const handleClickCreate = (e) => {
        e.preventDefault();
        // **Assign a random dummy photo link if no photo was uploaded**
        let finalTrackerPhoto = trackerPhoto;
        if (!trackerPhoto) {
            const randomIndex = Math.floor(Math.random() * dummyPhotoLinks.length);
            finalTrackerPhoto = dummyPhotoLinks[randomIndex];
        }

        try {
            const tracker = {
                name,
                description,
                notif_freq: parseInt(freq),
                links: links.map((link) => ({ link, notes: '' })),
                tracker_photo: finalTrackerPhoto, // Include the photo URL in the tracker data
            };
            console.log('Tracker: ', tracker);
            cleanupFields();
            handleAddTracker(tracker);
        }
        catch (error) {
            alert(error);
        }
    };

    const handleFileUploadSuccess = (url) => {
        setTrackerPhoto(url); // Set the uploaded file URL
        setUploadDialogOpen(false); // Close the upload dialog after upload
    };

    return (
        <Dialog open={open} onClose={handleClose} fullScreen={!isDesktop}>
            <form onSubmit={handleClickCreate}>
                <DialogTitle>Create New Tracker</DialogTitle>
                <DialogContent dividers={true}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Tracker Name"
                        name="name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Description"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        type='number'
                        margin="dense"
                        label="Notification Frequency"
                        name="frequency"
                        value={freq}
                        onChange={handleFreq}
                        inputMode='decimal'
                        inputProps={{ min: 1 }}
                        error={parseInt(freq) < 2}
                        helperText={parseInt(freq) > 1 ?
                            `Get an email update every ${freq} days` :
                            <>For daily updates, try making a
                                <Link href={'https://www.useblitz.co/search'} target='_blank' rel='noopener noreferrer' color='secondary'> Blitz Campaign</Link>
                            </>}
                    />
                    {/* File Upload Button */}
                    {false && <Button
                        onClick={() => setUploadDialogOpen(true)}
                        variant="outlined"
                        sx={{ marginBlockEnd: 2 }}
                    >
                        Upload Tracker Photo
                    </Button>}
                    <Divider sx={{ paddingBlockStart: 1 }}></Divider>
                    {/* Display the uploaded image if available */}
                    {trackerPhoto && (
                        <img src={trackerPhoto} alt="Tracker" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain', marginBottom: '10px' }} />
                    )}

                    {/* Add link fields dynamically based on refresh frequency */}
                    <List dense={true}>
                        {links.map((link, i) => {
                            const isValid = isValidVideoUrl(link) || !link;

                            return (
                                <ListItem
                                    key={i}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            disabled={links.length <= 1}
                                            onClick={() => {
                                                if (links.length > 1)
                                                    setLinks(prevState =>
                                                        prevState.filter((_, index) => index !== i)
                                                    );
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    }>
                                    <ListItemText primary={
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            label={`Link ${i + 1}`}
                                            value={link}
                                            variant="standard"
                                            required
                                            onChange={(e) => handleLink(e, i)}
                                            error={!isValid}
                                            helperText={!isValid ? "Please enter a valid TikTok or YouTube URL" : ""}
                                        />
                                    }></ListItemText>

                                </ListItem>)
                        })}
                        <ListItem
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="add"
                                    disabled={links.length >= 5}
                                    onClick={() => {
                                        if (links.length < 5) {
                                            setLinks((prevState) => [...prevState, '']);
                                        }
                                    }}
                                >
                                    <Add />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={<TextField fullWidth margin="dense" label={''} value={''} variant="standard" disabled />}></ListItemText>

                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="text">Cancel</Button>
                    <Button type='submit' color="primary" variant="text">Create</Button>
                </DialogActions>

                {/* Upload File Dialog */}
                <UploadFileDialog
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                    onUploadSuccess={handleFileUploadSuccess} // Pass upload success handler
                />
            </form>
        </Dialog>
    );
}
