import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Toolbar,
  Container,
  Stepper,
  Step,
  StepLabel,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import { useMutation } from "react-query";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import client from "../../../API";
import routes from "../../../Config/routes";
import {
  validateEmail,
  validatePassword,
  capitalizeFirstLetter,
} from "../../../Utils";
import { lightTheme } from "../../../Utils/baseTheme";

const pageTheme = lightTheme;

const DemoRegister = () => {
  const navigate = useNavigate();
  const { ref } = useParams();
  const referringUserId = ref && !isNaN(Number(ref)) ? Number(ref) : null;
  const referralSource = referringUserId === null ? ref : ""; // If it's not a number, treat it as a referral source

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    phoneNumber: "",
    industry: "",
    bio: "",
    businessType: "",
    isAgency: "",
    monthlySpend: "",
    teamAccounts: "",
    paymentMethod: "",
    referralSource: referralSource, // Use the referral source string here
    referringUserId: referringUserId, // Use the numeric ID if available
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const [openDialog, setOpenDialog] = useState(true); // Set to true to show the dialog initially

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Personal Information", "Company Information", "Business Details"];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { mutate: register, isLoading } = useMutation(
    client.companies.demoRegister,
    {
      onSuccess: (data) => {
        navigate(routes.login);
      },
      onError: (error) => {
        console.error("An error occurred during registration: ", error);
        let errorMessage = "An error occurred, please try again.";
        if (error.code === "ERR_NETWORK") {
          errorMessage = "Network is disconnected!";
        } else {
          if (error.response && error.response.data) {
            errorMessage = error.response.data.error || errorMessage;
          }
        }
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      },
    }
  );

  const handleNext = () => {
    let bValidation = true;

    // Validate the fields for the current step
    const requiredFields = getRequiredFieldsForStep(activeStep);

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    if (!bValidation) return;

    // Additional validation for specific fields
    if (activeStep === 0) {
      if (!validateEmail(userInfo.email)) {
        setSnackbarMessage("Invalid email address!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (!validatePassword(userInfo.password)) {
        setSnackbarMessage("Password should have 6 or more characters!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }

      if (userInfo.password !== userInfo.confirmPassword) {
        setSnackbarMessage("Passwords should match!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        return;
      }
    }

    if (activeStep === steps.length - 1) {
      // Last step, submit the form
      handleRegister();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRegister = async () => {
    let bValidation = true;

    // Exclude 'referralSource' and 'referringUserId' from required fields
    const requiredFields = Object.keys(userInfo).filter(
      (field) => field !== "referralSource" && field !== "referringUserId"
    );

    requiredFields.forEach((item) => {
      if (bValidation && (!userInfo[item] || userInfo[item].length === 0)) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        bValidation = false;
      }
    });

    // Require either referralSource or referringUserId
    if (
      bValidation &&
      !userInfo.referralSource &&
      !userInfo.referringUserId
    ) {
      setSnackbarMessage(
        "Either Referral Source or Referring User ID is required!"
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      bValidation = false;
    }

    if (!bValidation) return;

    const params = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      password: userInfo.password,
      confirmPassword: userInfo.confirmPassword,
      companyName: userInfo.companyName,
      streetAddress: userInfo.streetAddress,
      city: userInfo.city,
      state: userInfo.state,
      country: userInfo.country,
      phoneNumber: userInfo.phoneNumber,
      industry: userInfo.industry,
      bio: userInfo.bio,
      businessType: userInfo.businessType,
      isAgency: userInfo.isAgency,
      monthlySpend: userInfo.monthlySpend,
      teamAccounts: userInfo.teamAccounts,
      paymentMethod: userInfo.paymentMethod,
      referralSource: userInfo.referralSource, // Send referral source if it's a string
      referringUserId: userInfo.referringUserId, // Send referring user ID if available
    };

    register(params);
  };
  const handleCreatorLogin = () => {
    navigate(routes.creatorConnectOnboard);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getRequiredFieldsForStep = (step) => {
    switch (step) {
      case 0:
        return [
          "firstName",
          "lastName",
          "email",
          "password",
          "confirmPassword",
          "phoneNumber",
        ];
      case 1:
        return [
          "companyName",
          "streetAddress",
          "city",
          "state",
          "country",
          "industry",
        ];
      case 2:
        return [
          "businessType",
          "isAgency",
          "monthlySpend",
          "teamAccounts",
          "paymentMethod",
        ];
      default:
        return [];
    }
  };

  const industryOptions = [
    "Fashion",
    "Tech",
    "Food",
    "Beauty",
    "Sports",
    "Travel",
    "Finance",
    "Health",
    "Education",
    "Entertainment",
    "Automotive",
    "Real Estate",
    "Hospitality",
    "Retail",
    "Manufacturing",
    "Telecommunications",
  ];

  const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Mexico",
    "Russia",
    "South Korea",
    "South Africa",
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            {/* Step 1: Personal Information */}
            <TextField
              disabled={isLoading}
              label="First Name"
              name="firstName"
              variant="outlined"
              value={userInfo.firstName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              name="lastName"
              variant="outlined"
              value={userInfo.lastName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Email"
              name="email"
              variant="outlined"
              value={userInfo.email}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Password"
              name="password"
              variant="outlined"
              type="password"
              value={userInfo.password}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Re-enter Password"
              name="confirmPassword"
              variant="outlined"
              type="password"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
          </div>
        );
      case 1:
        return (
          <div>
            {/* Step 2: Company Information */}
            <TextField
              disabled={isLoading}
              label="Company Name"
              name="companyName"
              variant="outlined"
              value={userInfo.companyName}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              value={userInfo.streetAddress}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="City"
              name="city"
              variant="outlined"
              value={userInfo.city}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <TextField
              disabled={isLoading}
              label="State"
              name="state"
              variant="outlined"
              value={userInfo.state}
              onChange={handleChange}
              margin="dense"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={userInfo.country}
                onChange={handleChange}
                label="Country"
              >
                {countryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>Industry</InputLabel>
              <Select
                name="industry"
                value={userInfo.industry}
                onChange={handleChange}
                label="Industry"
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Tell us about your company"
              name="bio"
              value={userInfo.bio}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </div>
        );
      case 2:
        return (
          <div>
            {/* Step 3: Business Details */}
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel >
                Is your business all under one company, or is it separate?
              </InputLabel>
              <Select
                name="businessType"
                value={userInfo.businessType}
                onChange={handleChange}
                label="Is your business all under one company, or is it separate?"

              >
                <MenuItem value="One Company" >
                  One Company
                </MenuItem>
                <MenuItem value="Separate" >
                  Separate
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel >Are you an agency?</InputLabel>
              <Select
                name="isAgency"
                value={userInfo.isAgency}
                onChange={handleChange}
                label="Are you an agency?"
              >
                <MenuItem value="Yes" >Yes</MenuItem>
                <MenuItem value="No" >No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How much do you expect to spend every month?"
              name="monthlySpend"
              value={userInfo.monthlySpend}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <TextField
              label="How many accounts do you need (for your team)?"
              name="teamAccounts"
              value={userInfo.teamAccounts}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required
              fullWidth
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              required
            >
              <InputLabel>
                What is your main method of payment?
              </InputLabel>
              <Select
                name="paymentMethod"
                value={userInfo.paymentMethod}
                onChange={handleChange}
                label="What is your main method of payment?"
              >
                <MenuItem value="Credit Card">
                  Credit Card
                </MenuItem>
                <MenuItem value="Bank Transfer">
                  Bank Transfer
                </MenuItem>
                <MenuItem value="PayPal">
                  PayPal
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="How did you hear about us?"
              name="referralSource"
              value={userInfo.referralSource}
              onChange={handleChange}
              margin="dense"
              variant="outlined"
              required={!userInfo.referringUserId}
              fullWidth
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <ThemeProvider theme={pageTheme}>
      <CssBaseline></CssBaseline>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          paddingTop: 6,
          paddingBottom: 4,
        }}
      >
        <Box
          sx={{
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Blitz logo removed */}
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2}}>
            Welcome to Blitz!
          </Typography>
          <Typography variant="body1" paragraph>
            Enter your information to continue with Blitz
          </Typography>
          <Divider sx={{ marginBlockEnd: "1em" }}></Divider>

          <Box sx={{ width: "100%", maxWidth: 600 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                minWidth: "100%",
              }}
            >
              {getStepContent(activeStep)}

              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  endIcon={
                    isLoading &&
                    activeStep === steps.length - 1 && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                  disabled={isLoading}
                >
                  {activeStep === steps.length - 1 ? "Create Account" : "Next"}
                </Button>
              </Box>
              {userInfo.referringUserId && (
                <Box mt={2} textAlign="center">
                  <Typography variant="body2" sx={{ color: "#000" }}>
                    Referred by Creator ID: {userInfo.referringUserId}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
{/* Creator Section */}
<Box sx={{ textAlign: "center", mt: 4, p: 2, backgroundColor: "#f5f5f5", borderRadius: 2 }}>
          <Typography variant="h6">Are you a creator?</Typography>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Sign in here
          </Typography>
          <Button variant="outlined" color="secondary" size="large" onClick={handleCreatorLogin}>
            Creator Connect Login
          </Button>
        </Box>
      <HomeFooter></HomeFooter>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thanks for Booking a Creator!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thanks for booking a creator through their media kit. To access your
            collaboration, make an account here.
          </Typography>
          <Typography variant="body2" mt={2}>
            For any questions or concerns, please contact{" "}
            <a href="mailto:jc@thecultureclub.us">jc@thecultureclub.us</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DemoRegister;
