import React, { useMemo, useState } from "react";
import {
  Paper,
  Box,
  IconButton,
  Button,
  Badge,
  Typography,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  headerToKeyMap,
  indexToPromoFilter,
  platformToKey,
  platPromoToKey,
} from "../../../Utils/constants";
import {
  ShoppingCart as ShoppingCartIcon,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import CreatorDetails from "./steps/creatordetails";
import CampaignDetails from "./steps/campaigndetails";
import Timelines from "./steps/campaigntimelines";
import CampaignConfirmation from "./steps/campaignconfirmation";
import LeftColumnSearch from "./leftColumnSearch";

const steps = [
  "Sourcing",
  "Creator Details",
  "Campaign Details",
  "Timelines",
  "Confirmation",
];

function MarketplaceBooking({
  onInviteToPartnership,
  data,
  loading,
  onSubmitCampaign,
  initialFilters = null,
  initialCollectionId = ""  // new prop
}) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  let itemsPerPage = 4;
  if (isSmScreen) itemsPerPage = 6;
  if (isLgScreen) itemsPerPage = 15;

  const [timelinesData, setTimelinesData] = useState({});
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [creatorsData, setCreatorsData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isBlitzFeeOnCreators, setIsBlitzFeeOnCreators] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const [promoIndex, setPromoIndex] = useState(1);

  const today = new Date();
  const sevenDays = new Date(today.getTime() + (7 * 24 * 60 * 60 * 1000));

  const idealDueDate = useMemo(() => Object.entries(timelinesData).reduce((accum, [creator, timeline]) => {
    const latestDueDate = timeline.reduce((accum, event) => event.projected_date <= accum ? accum : event.projected_date, sevenDays)
    return latestDueDate <= accum ? accum : latestDueDate
  }, sevenDays), [timelinesData]);

  const handleFinish = () => {
    // Handle the final action, e.g., submit campaign data
    console.log("Campaign confirmed:", {
      campaignData,
      selectedCreators: data.filter((creator) =>
        selectedItems.has(creator.creator)
      ),
    });
    onSubmitCampaign({
      campaignData: {
        ...campaignData,
        idealDueDate
      },
      timelinesData,
      creatorsData,
      isBlitzFeeOnCreators,
    })
    // Reset steps and selections if needed
    /*setActiveStep(0);
    setCampaignData(null);
    setTimelinesData({});
    setCreatorsData([]);*/
  };


  const handleAddToCampaign = (creatorId) => {


    const isSelected = selectedItems.has(creatorId);
    if (isSelected) {
      setSelectedItems((prev) => (prev.delete(creatorId), new Set(prev)));
      setCreatorsData((prev) =>
        prev.filter((item) => item.id !== creatorId)
      );
      setTimelinesData(prev => {
        delete prev[creatorId];
        return prev;
      });
    } else {
      const { platform, promo: promotionType } = indexToPromoFilter(promoIndex);
      const creator = data.find((creator) => creator.creator == creatorId);
      const rateKey = platPromoToKey[platform][promotionType];


      setSelectedItems((prev) => (prev.add(creatorId), new Set(prev)));
      setCreatorsData((prev) =>
        [
          ...prev,
          {
            ...creator,
            rateKey: rateKey,
            basePrice: creator[rateKey],
            crossPost: false,
            usageRights: false,
            adjustedPrice: 0,
          }
        ]
      );
    }
  }

  const handleRemoveCreator = (creatorId) => {
    const isSelected = selectedItems.has(creatorId);
    if (isSelected) {
      handleAddToCampaign(creatorId);
    }
  };

  return (
    <>
      <Box>
        <Paper
          elevation={8}
          square
          sx={{
            paddingBlock: 1,
            paddingInline: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: 'relative',
            left: 0,
            top: 0,
            zIndex: 2
          }}
        >
          <Typography variant="h6">{steps[activeStep]}</Typography>
          <IconButton onClick={() => setActiveStep(1)}>
            <Badge
              badgeContent={selectedItems.size}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Paper>

        {/* Stepper */}
        <Paper elevation={4} square sx={{
          paddingBlock: 4, position: 'relative',
          left: 0,
          top: 0,
          zIndex: 1,
          display: { xs: 'none', md: 'block' }
        }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Box>

      {/* Conditionally render content based on activeStep */}
      {activeStep === 0 && <LeftColumnSearch
        onCreatorSelect={handleAddToCampaign}
        selectedItems={selectedItems}
        onInviteToPartnership={onInviteToPartnership}
        itemsPerPage={itemsPerPage}
        data={data}
        loading={loading}
        onChangePromo={setPromoIndex}
        initialFilters={initialFilters}
        initialCollectionId={initialCollectionId} // new prop passed down
      ></LeftColumnSearch>
      }

      {activeStep === 1 && (
        <CreatorDetails
          selectedCreators={selectedItems}
          creatorsData={creatorsData}
          setCreatorsData={setCreatorsData}
          isBlitzFeeOnCreators={isBlitzFeeOnCreators}
          setIsBlitzFeeOnCreators={setIsBlitzFeeOnCreators}
          onNext={() => setActiveStep(2)}
          onBack={() => setActiveStep(0)}
          onRemove={handleRemoveCreator}
          data={data}
        />
      )}

      {activeStep === 2 && (
        <CampaignDetails
          onNext={() => setActiveStep(3)}
          onBack={() => setActiveStep(1)}
          setCampaignData={setCampaignData}
        />
      )}

      {activeStep === 3 && (
        <Timelines
          campaignDetails={campaignData}
          selectedCreatorsData={creatorsData}
          creatorTimelines={timelinesData}
          setCreatorTimelines={setTimelinesData}
          onNext={() => setActiveStep(4)}
          onBack={() => setActiveStep(2)}
        />
      )}

      {activeStep === 4 && (
        <CampaignConfirmation
          selectedCreatorsData={creatorsData}
          timelinesData={timelinesData}
          isBlitzFeeOnCreators={isBlitzFeeOnCreators}
          data={data}
          campaignData={campaignData}
          onBack={() => setActiveStep(3)}
          onFinish={handleFinish}
          idealDueDate={idealDueDate}
        />
      )}
      <MobileStepper activeStep={activeStep} square variant="progress" steps={steps.length} position="static" sx={{ display: { xs: 'flex', md: 'none' } }}
        backButton={
          <Button
            size="small"
            variant="text"
            onClick={() => setActiveStep(prev => Math.max(prev - 1, 0))}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={<Typography variant="subtitle2">{steps[activeStep]}</Typography>
        }>
      </MobileStepper>
      <MobileStepper component={Paper} square elevation={8} activeStep={activeStep} variant="progress" steps={steps.length} position="bottom" sx={{ display: { xs: 'flex', md: 'none' } }}
        backButton={
          <Button
            size="small"
            variant="text"
            onClick={() => setActiveStep(prev => Math.max(prev - 1, 0))}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={<Typography variant="subtitle2"><b>{steps[activeStep]}</b></Typography>
        }>
      </MobileStepper>
    </>
  );
};

export default MarketplaceBooking;