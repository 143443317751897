import { Box } from "@mui/material";
import React from "react";

function TabPanelHidden({ children, value, index, display='inline', sx, ...other }) {

    return (
        <Box {...other} sx={{...sx, display:value!=index ? 'none' : display}}>
            {children}
        </Box>
    );
}

export default TabPanelHidden;