import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Toolbar
} from "@mui/material";
import routes from "../../../Config/routes";
import HomeHeader from "../../../Components/HomeHeader";

import HomeFooter from "../../../Components/HomeFooter";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import { globalStyles } from "../../../Utils/Styles";

const ForCreators = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.creatorConnectOnboard}`);

  const aiSalesManager = useRef(null);
  const getPaidFaster = useRef(null);
  const manageDeals = useRef(null);
  const businessSuite = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);
      scrollToSection(targetId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "aiSalesManager":
        aiSalesManager.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "getPaidFaster":
        getPaidFaster.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "manageDeals":
        manageDeals.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "businessSuite":
        businessSuite.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width: '100%',
      color: '#000'
    }}>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        {/* Section 1: Introduction */}
        <Box sx={{ mb: 4, scrollMarginTop:'64px' }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Why Creators Choose Blitz
          </Typography>
          <Typography variant="body1" paragraph>
            Unlock the full potential of your creative career with Blitz. Our platform simplifies your workflow, connects you with top brands, and accelerates your growth.
          </Typography>
        </Box>

        <Divider />

        {/* Section 2: All-in-One AI Manager */}
        <Box sx={{ mb: 4, scrollMarginTop:'64px' }} ref={aiSalesManager}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            All-in-One AI Manager
          </Typography>
          <Typography variant="body1" paragraph>
            Blitz’s AI Manager instantly improves your deal flow by connecting you with top brands and automating your negotiations. Say goodbye to hefty human manager fees and hello to streamlined, AI-powered success.
          </Typography>
        </Box>

        <Divider />

        {/* Section 3: Marketplace Access */}
        <Box sx={{ mb: 4, scrollMarginTop:'64px' }} ref={manageDeals}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Access to the World's Top Brands
          </Typography>
          <Typography variant="body1" paragraph>
            Gain exclusive access to Blitz’s global marketplace, where the world's leading brands are searching for creators like you. Expand your reach, secure high-paying partnerships, and grow your personal brand.
          </Typography>
        </Box>

        <Divider />

        {/* Section 4: Business Management Suite */}
        <Box sx={{ mb: 4, scrollMarginTop:'64px' }} ref={businessSuite}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Personalized Business Management Suite
          </Typography>
          <Typography variant="body1" paragraph>
            Manage your entire business with ease using Blitz’s suite of tools designed to handle everything from legal contracts to financial services, tax, and investment planning. Tailor-made for creators, Blitz gives you control over your career.
          </Typography>
        </Box>

        <Divider />

        {/* Section 5: Financial Growth */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Increase Your Revenue by 2-3x
          </Typography>
          <Typography variant="body1" paragraph>
            By leveraging Blitz's powerful tools and resources, you can significantly increase your revenue, achieving 2-3x growth. Maximize your income while minimizing the time and effort spent managing your career.
          </Typography>
        </Box>

        <Divider />

        {/* Section 6: Get Paid Faster */}
        <Box sx={{ mb: 4, scrollMarginTop:'64px' }} ref={getPaidFaster}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Get Paid Faster
          </Typography>
          <Typography variant="body1" paragraph>
            BlitzPay ensures you receive payments quickly and efficiently, so you can focus on what you do best—creating. No more delays or uncertainty, just fast and reliable payments every time.
          </Typography>
        </Box>

        <Divider />

        {/* Section 7: Discover */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Discover the Power of Blitz
          </Typography>
          <Typography variant="body1" paragraph>
            The future of creator management is here. Join the Blitz revolution today and unlock the full potential of our platform. Let’s schedule a meeting to secure your free early access and begin your journey toward innovation and success.
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: "1rem",
              textTransform: "none",
              backgroundColor: "#7b2a2a",
              color: "#fff",
            }}
            onClick={handleAccess}
          >
            Request Access
          </Button>
        </Box>
      </Container>

      <HomeFooter></HomeFooter>
    </Box>
  );
};

export default ForCreators;
