import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import client from '../../../../../API';

const AddCRMDialog = ({ open, onClose }) => {
  const [mode, setMode] = useState('single'); // 'single' or 'bulk'
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    contactSource: '',
  });
  const [emailsInput, setEmailsInput] = useState('');

  const queryClient = useQueryClient();

  const mutation = useMutation((newContact) => client.crm.add(newContact), {
    onSuccess: () => {
      queryClient.invalidateQueries('crmContacts');
      onClose();
    },
    onError: (error) => {
      console.error('Error adding contact:', error);
    },
  });

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const handleInputChange = (e) => {
    if (mode === 'single') {
      const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value,
      });
    } else {
      setEmailsInput(e.target.value);
    }
  };

  const handleSubmit = async () => {
    if (mode === 'single') {
      mutation.mutate(formValues);
    } else {
      const emails = emailsInput
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email !== '');

      const contacts = emails.map((email) => ({
        email,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        companyName: '',
        contactSource: '',
      }));

      try {
        // If your API supports bulk addition, you can send contacts array directly
        await Promise.all(contacts.map((contact) => client.crm.add(contact)));
        queryClient.invalidateQueries('crmContacts');
        onClose();
      } catch (error) {
        console.error('Error adding contacts:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Contact(s) to CRM</DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="add mode"
          style={{ marginBottom: '16px' }}
        >
          <ToggleButton value="single" aria-label="single contact">
            Single Entry
          </ToggleButton>
          <ToggleButton value="bulk" aria-label="bulk entry">
            Bulk Entry
          </ToggleButton>
        </ToggleButtonGroup>

        {mode === 'single' ? (
          <>
            <TextField
              autoFocus
              margin="dense"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              value={formValues.firstName}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              value={formValues.lastName}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={formValues.email}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="phoneNumber"
              label="Phone Number"
              type="text"
              fullWidth
              value={formValues.phoneNumber}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="companyName"
              label="Company Name"
              type="text"
              fullWidth
              value={formValues.companyName}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="contactSource"
              label="Contact Source"
              type="text"
              fullWidth
              value={formValues.contactSource}
              onChange={handleInputChange}
            />
          </>
        ) : (
          <TextField
            autoFocus
            margin="dense"
            label="Emails (comma-separated)"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={emailsInput}
            onChange={handleInputChange}
            placeholder="email1@example.com, email2@example.com, email3@example.com"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {mode === 'single' ? 'Add Contact' : 'Add Emails'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCRMDialog;
