import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import routes from '../../Config/routes';

export default function AgencyStart({ }) {
    const navigate = useNavigate();

    return (
        <>
        <Typography variant='h5' sx={{paddingInline:2, paddingBlockStart:2}}>Agency Portal</Typography>
        <Box sx={{ padding: 2, width:'20rem' }}>
            <Card elevation={2}>
                <CardContent>
                    <Typography variant="h6">Agency Creators</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(routes.agencyCreators)}>
                        Manage Creators
                    </Button>
                </CardContent>
            </Card>
        </Box>
        </>
    )
}
