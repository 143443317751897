import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

const formatLabel = (value) => {
    return value.toFixed(2); // Rounds to two decimal places
  };

export default function CountryCard({ data, fill }) {


    return (
        <Card variant='outlined'>
            <CardHeader subheader='Audience Countries'></CardHeader>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data || []} layout="vertical" margin={{left:0,top:0,bottom:5,right:40}}>
                    <XAxis type="number" />
                    <YAxis dataKey="code" type="category" />
                    <Bar layout="vertical" dataKey="value" label={{position:'right', formatter: formatLabel}} fill={fill}>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    )
}
