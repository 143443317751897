import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import client from '../../../API';
import { useMutation, useQuery } from 'react-query';
import routes from '../../../Config/routes';
import CampaignDetailsUI from './campaigndetailsUI';
import {
  Button,
  Divider,
  Drawer,
  Box,
} from '@mui/material';
import { globalStyles } from '../../../Utils/Styles';
import LeftColumnSearch from '../Search/leftColumnSearch';
import { sortCreators } from '../../../Utils/constants';

const RightDrawerWidth = '26rem';

const classes = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    overflow: "hidden",
  },
  drawer: {
    width: RightDrawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: RightDrawerWidth,
    padding: 2,
    marginBottom: 2,
    overflowY: "auto",
    overflowX: "clip",
  },
};

const AddCreators = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedCreatorsData, setSelectedCreatorsData] = useState([]);

  const { data: campaignDetails, error,
  } = useQuery({
    queryKey: ['campaign', campaignId], // Add specific key/params if needed
    queryFn: () => client.campaigns.fetch(campaignId),
    enabled: !!campaignId,
    refetchOnWindowFocus: false,
  });

  const { data: creatorList, isFetching: creatorIsLoading } = useQuery({
    queryKey: ['creators'],
    queryFn: async () => sortCreators(await client.creators.list({ include_reviews: true, include_public: true })),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const prevCreators = useMemo(() => {
    if (!campaignDetails)
      return (new Set([]));

    return (new Set(
      campaignDetails.creators.map((creator) => creator.id)
    ))
  }, [campaignDetails]);

  useEffect(() => {
    setSelectedItems((prev) => (new Set([...prev, ...prevCreators])));
  }, [prevCreators])

  // Handle errors with useEffect
  useEffect(() => {
    if (!!error) {
      const errorMessage = error.response?.data?.error || error.message;
      alert(`Error fetching campaign: ${errorMessage}`);
    }
  }, [error]);

  const handleCreatorSelect = (creatorId, creatorData) => {
    if (prevCreators.has(creatorId))
      return;

    const isSelected = selectedItems.has(creatorId);
    if (isSelected) {
      setSelectedItems((prev) => (prev.delete(creatorId), new Set(prev)));
    } else {
      setSelectedItems((prev) => (prev.add(creatorId), new Set(prev)));
    }
    if (!isSelected) {
      setSelectedCreatorsData((prev) => [...prev, creatorData]);
    } else {
      setSelectedCreatorsData((prev) =>
        prev.filter((item) => item.id !== creatorId)
      );
    }
  };

  const { mutate: updateCreatorList } = useMutation(client.campaigns.updateCreatorList, {
    onSuccess: (data) => {
      console.log('Successfully updated campaign creators:', data);

      // Optionally, navigate to another route upon success
      navigate(routes.campaigns);
    },
    onError: (error) => {
      console.error('Error updating campaign creators:', error);
      const errorMessage = error.response?.data?.error || error.message;
      alert(`Error fetching campaign: ${errorMessage}`);
    },
  });

  const handleConfirmCreatorChanges = async () => {
    const newCreators = selectedCreatorsData.map((creator) => ({
      id: creator.id,
      name: creator.name,
      price: creator.price,
      following: creator.following,
      promotionPlatform: creator.promotionPlatform,
      promotionType: creator.promotionType,
      platformLink: creator.platformLink,
      pfphref: creator.pfphref
    }));

    const payload = {
      campaignId: parseInt(campaignId),
      newCreators: newCreators,
    };

    updateCreatorList(payload);
  };

  return (
    <>
      <Box>
        <Box sx={{ ...globalStyles.wholeHeightMinusToolbar, marginInlineEnd: RightDrawerWidth }}>
          <LeftColumnSearch
            onCreatorSelect={handleCreatorSelect} 
            data={creatorList}
            loading={creatorIsLoading}
            selectedItems={selectedItems} 
            />
        </Box>
        <Drawer
          sx={classes.drawer}
          variant="persistent"
          anchor="right"
          open={true}
          PaperProps={{
            sx: classes.drawerPaper,
          }}
        >
          <CampaignDetailsUI
            campaignDetails={campaignDetails}
            newCreators={selectedCreatorsData}
          />
          <Divider sx={{ margin: "20px 0" }} />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirmCreatorChanges}
          >
            Confirm Creator Changes
          </Button>
        </Drawer>
      </Box>
    </>
  );
};

export default AddCreators;
