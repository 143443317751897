import React, { useState, useEffect, useMemo } from 'react'
import { StyledTableRow } from '../../../Utils/styledcell'
import { Avatar, Box, Divider, InputAdornment, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { getOfferFromCampaign } from '../../../Utils/constants';
import { SearchRounded } from '@mui/icons-material';
import AgencyCampaignPitchDialog from './agencyCampaignPitchDialog';
import AgencyCampaignDialog from './agencyCampaignDialog';

const creatorCampaignOptions = ['Pitched', 'Launched', 'Completed'];

function CampaignRow({ campaign, username, onClick }) {

    const creator = campaign.creators.find(c => c.id === username);
    const offer = getOfferFromCampaign(campaign, username)
    const type = `${creator?.promotionPlatform} - ${creator?.promotionType}`
    return (
        <StyledTableRow onClick={onClick} sx={{cursor: 'pointer'}}>
            <TableCell align='left'>
                {campaign.name}
            </TableCell>
            <TableCell align='right'>
                {type}
            </TableCell>
            <TableCell align='right'>
                {offer}
            </TableCell>
            <TableCell align='right'>
                {campaign.ideal_duedate.toDateString()}
            </TableCell>
        </StyledTableRow>
    )
}

//ideal_duedate
export default function AgencyCreatorCampaigns({ creatorData }) {
    const [status, setStatus] = useState(creatorCampaignOptions[0]);
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [openPitch, setOpenPitch] = useState(false);
    const [openCampaign, setOpenCampaign] = useState(false)

    const HandleClickRow = (campaign) => {
        const username = creatorData.creator.creator
        const creator = campaign.creators.find(c => c.id === username);
        const isPitched = (creator?.status === undefined || creator?.status === 'Pitched') && campaign.campaign_status === 'Launched';
        setSelectedCampaign(campaign.id);
        if (isPitched)
            setOpenPitch(true);
        else
            setOpenCampaign(true);
    }

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
    }, [searchInput]);

    const filteredCampaigns = useMemo(() => creatorData?.campaigns.filter((campaign) => {
        const searchMatch = currentSearch === "" || Object.values(campaign).some((val) => typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase()));
        const username = creatorData.creator.creator
        const creator = campaign.creators.find(c => c.id === username);
        let isAcceptedCreator = creator?.status !== undefined && creator.status !== 'Declined' && creator.status !== 'Dropped' && creator.status !== 'Pitched';
        let isValidSelection = true;
        switch (status) {
            default:
                isValidSelection = isAcceptedCreator && campaign.campaign_status === 'Launched';
                break;
            case 'Pitched':
                isValidSelection = (creator?.status === undefined || creator?.status === 'Pitched') && campaign.campaign_status === 'Launched';
                break;
            case 'Completed':
                isValidSelection = isAcceptedCreator && campaign.campaign_status === 'Completed';
                break;
        }
        return searchMatch && isValidSelection;
    }), [creatorData, currentSearch, status]);

    return (
        <>
            <Paper elevation={2} square sx={{ marginBlockStart: 2, width: '100%', overflowX: 'hidden' }}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={creatorData?.creator.pfphref || undefined}>
                            {!creatorData?.creator.pfphref && creatorData?.creator.creator[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={creatorData?.creator.creator || <>&nbsp;</>} secondary={creatorData?.creator.notes_content_style || <>&nbsp;</>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
                </ListItem>
            </Paper>
            <Box sx={{ paddingInline: { xs: 1, md: 2 }, paddingBlock: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <TextField sx={{ width: '10rem' }} select label='Status' value={status} onChange={(event) => setStatus(event.target.value)}>
                        {creatorCampaignOptions.map((value) => (<MenuItem value={value} key={value}>{value}</MenuItem>))}
                    </TextField>
                    <TextField
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchRounded>
                                    </SearchRounded>
                                </InputAdornment>)
                        }}>
                    </TextField>
                </Box>
                <Divider></Divider>
                <TableContainer component={Paper} elevation={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    Name
                                </TableCell>
                                <TableCell align='right'>
                                    Type
                                </TableCell>
                                <TableCell align='right'>
                                    Price
                                </TableCell>
                                <TableCell align='right'>
                                    Due date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!filteredCampaigns && filteredCampaigns.map((campaign) => (
                                <CampaignRow
                                    campaign={campaign}
                                    username={creatorData.creator.creator}
                                    key={campaign.id}
                                    onClick={() => HandleClickRow(campaign)}
                                >
                                </CampaignRow>
                            ))}
                            {!!filteredCampaigns && filteredCampaigns.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        No campaigns found. Try changing the search filters!
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <AgencyCampaignDialog
                campaignId={selectedCampaign}
                openDialog={openCampaign}
                username={creatorData?.creator.creator}
                handleCloseDialog={() => setOpenCampaign(false)}></AgencyCampaignDialog>

            <AgencyCampaignPitchDialog
                campaignId={selectedCampaign}
                openDialog={openPitch}
                username={creatorData?.creator.creator}
                handleCloseDialog={() => setOpenPitch(false)}></AgencyCampaignPitchDialog>
        </>
    )
}
