import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, Container, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import paymentImage from "../../../Components/globalAssets/blackwhite.png";
import marketplaceImage from "../../../Components/globalAssets/logo_placeholder.png";
import routes from "../../../Config/routes";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";

const styles = {
  sectionContainer: {
    display: "flex",
    scrollMarginTop:'64px',
    flexDirection: { xs: "column", md: "row" },
    justifyContent: 'space-evenly',
    alignItems: "center",
    px: 2,
    paddingBlock: 8
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 }, maxWidth: { xs: '150px', md: '300px' }
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: '30rem',
    alignItems: { xs: "center", md: "flex-start" },
    textAlign: { xs: "center", md: "left" }
  }
}

const Solutions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paymentRef = useRef(null);
  const marketplaceRef = useRef(null);
  const aiManagerRef = useRef(null);
  const acceleratedCollabRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);
      scrollToSection(targetId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "blitzPay":
        paymentRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "creatorSourcing":
        marketplaceRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "aiCampaignManagement":
        aiManagerRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "acceleratedCollaborations":
        acceleratedCollabRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };


  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width: '100%',
      color: '#000'
    }}>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 2,
          paddingBottom: 4,
        }}
      >
        {/* Solutions Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ textAlign: "center", fontWeight: "bold", my: 2 }}>
            Our Solutions
          </Typography>
        </Box>

        <Divider />

        {/* BlitzPay Section */}
        <Box ref={paymentRef} sx={styles.sectionContainer}>
          <Box flex={1} sx={styles.imageContainer} >
            <img src={paymentImage} alt="Payment" style={{
              width: "100%",
              borderRadius: "50%", // Circular frame
              objectFit: "cover", // Ensure the image covers the circular frame properly
            }} />
          </Box>
          <Box
            sx={styles.textContainer}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              BlitzPay - Accounts Payable
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Streamline your payment process and ensure creators are paid on time, every time, through their preferred methods.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Creator Sourcing Section */}
        <Box ref={marketplaceRef} sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}>
          <Box flex={1} sx={styles.imageContainer}>
            <img src={marketplaceImage} alt="Marketplace" style={{ width: "100%" }} />
          </Box>
          <Box sx={{ ...styles.textContainer, textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Creator Sourcing
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Discover and collaborate with top creators around the globe, all within Blitz's expansive network.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* AI Campaign Management Section */}
        <Box ref={aiManagerRef} sx={styles.sectionContainer}>
          <Box sx={styles.imageContainer} >
            <img src={paymentImage} alt="AI Manager" style={{
              width: "100%",
              borderRadius: "50%", // Circular frame
              objectFit: "cover", // Ensure the image covers the circular frame properly
            }} />
          </Box>
          <Box sx={styles.textContainer}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              AI Campaign Management
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Automate and optimize your campaign management with Blitz's AI, reducing costs and increasing efficiency.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Accelerated Creator Collaborations Section */}
        <Box ref={marketplaceRef} sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}>
          <Box flex={1} sx={styles.imageContainer}>
            <img src={marketplaceImage} alt="Collaborations" style={{ width: "100%" }} />
          </Box>
          <Box sx={{ ...styles.textContainer, textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Accelerated Creator Collaborations
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Fast-track your partnerships with creators through Blitz’s efficient collaboration tools.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>
      </Container>

      <HomeFooter></HomeFooter>
    </Box>
  );
};

export default Solutions;
