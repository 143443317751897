import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from '@mui/material';
import Layout from '../layout';
import UserInformationTable from '../adminComponents/userInfo';
import UpdateDialog from './sendUpdate';
import client from '../../../../../API';
import CRMInformationTable from './crmInfo';
import EmailCRMDialog from './sendEmailCRM';
import SharedWithTable from './sharedwith';
import AddCRMDialog from './addcrmdialog';

const MarketingView = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddCRMDialog, setOpenAddCRMDialog] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [openCRMDialog, setOpenCRMDialog] = useState(false);
  const [selectedCRMContacts, setSelectedCRMContacts] = useState([]);

  // New state variables for the counts
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSharedWith, setTotalSharedWith] = useState(0);
  const [pendingUsers, setPendingUsers] = useState(0);
  const [loadingCounts, setLoadingCounts] = useState(true);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Fetch users from userinformation table
        const userInfoData = await client.users.userDetails();
        setTotalUsers(userInfoData.length);

        // Fetch users from sharedwith table
        const sharedWithData = await client.users.fetchSharedWith();
        setTotalSharedWith(sharedWithData.length);

        // Calculate pending users
        const userEmails = new Set(userInfoData.map((user) => user.email));
        const pendingUsersData = sharedWithData.filter(
          (user) => !userEmails.has(user.email)
        );
        setPendingUsers(pendingUsersData.length);

        setLoadingCounts(false);
      } catch (error) {
        console.error('Failed to fetch counts:', error);
        setLoadingCounts(false);
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await client.users.userDetails();
        const usersWithSelection = data.map((user) => ({ ...user, selected: false }));
        setUserData(usersWithSelection);
        console.log('Fetched user data:', usersWithSelection);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };
    fetchUserData();
  }, []);

const handleOpenDialog = () => {
  const selectedData = userData.filter((user) => selectedUsers[user.id]);
  setSelectedUserData(selectedData); // Set selected user data
  setOpenDialog(true);
  console.log('Opening update dialog with selected data:', selectedData);
};


  const handleOpenAddCRMDialog = () => {
    setOpenAddCRMDialog(true);
  };

  const handleOpenCRMDialog = () => {
    setOpenCRMDialog(true);
    console.log('Opening CRM dialog with selected data:', selectedCRMContacts);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    console.log('Update dialog closed');
  };

  const handleCloseAddCRMDialog = () => {
    setOpenAddCRMDialog(false);
  };

  const handleCloseCRMDialog = () => {
    setOpenCRMDialog(false);
    console.log('CRM dialog closed');
  };

  const handleSelectionChange = useCallback((newSelectedUsers) => {
    setSelectedUsers(newSelectedUsers);
    console.log('User selection changed:', newSelectedUsers);
  }, []);

  const handleCRMSelectionChange = useCallback((newSelectedCRMContacts) => {
    setSelectedCRMContacts(newSelectedCRMContacts);
    console.log('CRM selection changed:', newSelectedCRMContacts);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {/* Display the three cards at the top */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {loadingCounts ? (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Total Users
                  </Typography>
                  <Typography variant="h2">{totalUsers}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Total Shared With
                  </Typography>
                  <Typography variant="h2">{totalSharedWith}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Pending Users
                  </Typography>
                  <Typography variant="h2">{pendingUsers}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <Typography variant="h4" gutterBottom>
        Active User Marketing
      </Typography>
      <Button variant="contained" onClick={handleOpenDialog} sx={{ mb: 2 }}>
        Send Update
      </Button>
      <UserInformationTable onSelectionChange={handleSelectionChange} />
      <Typography variant="h4" gutterBottom>
        Re-Engagement Marketing
      </Typography>
      <Button variant="contained" onClick={handleOpenCRMDialog} sx={{ mb: 2 }}>
        Send Promotional
      </Button>
      <SharedWithTable onSelectionChange={handleCRMSelectionChange} />
      <Typography variant="h4" gutterBottom>
        CRM Dashboard
      </Typography>
      <Button variant="contained" onClick={handleOpenCRMDialog} sx={{ mb: 2 }}>
        Send Email
      </Button>
      <Button variant="contained" onClick={handleOpenAddCRMDialog} sx={{ mb: 2 }}>
        Add to CRM
      </Button>
      <CRMInformationTable onSelectionChange={handleCRMSelectionChange} />
      <UpdateDialog open={openDialog} onClose={handleCloseDialog} userData={selectedUserData} />
      <EmailCRMDialog open={openCRMDialog} onClose={handleCloseCRMDialog} userData={selectedCRMContacts} />
      <AddCRMDialog open={openAddCRMDialog} onClose={handleCloseAddCRMDialog} />
    </Box>
  );
};

export default MarketingView;
