
export const platformSelection = {
    TikTok: false,
    Instagram: false,
    YouTube: false,
    X: false,
    Podcast: false,
    Kick: false,
    Twitch: false,
  }

export const validatePlatformSelection = (
    platforms, alertFunction,
    tikTokBrandRate, tikTokSongRate,
    instagramBrandRate, instagramSongRate, instagramFeedRate, instagramStoryRate,
    youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate,
    xFeedPost, xRepost,
    kickPlacement,
    twitchPlacement,
    podcastPreroll,
    podcastMidroll, 
    podcastPostroll, 
    podcastFull, 
) => {
    const validatePlatformPromotion = (platformName, platformCheck, promoRates) => {
        if (platformCheck) {
            const hasPromo = promoRates.some((value) => value);
            if (!hasPromo) {
                alertFunction(`You need to select at least one promotion type for ${platformName}.`);
                return false;
            }
        }
        return true;
    };

    // Check if any platform is selected
    const anyPlatform = Object.values(platforms).some((value) => value);
    if (!anyPlatform) {
        alertFunction('You need to select at least one platform.');
        return false;
    }

    // Validate each platform's promotion selection
    return (
        validatePlatformPromotion('TikTok', platforms.TikTok, [tikTokBrandRate, tikTokSongRate]) &&
        validatePlatformPromotion('Instagram', platforms.Instagram, [instagramBrandRate, instagramSongRate, instagramStoryRate, instagramFeedRate]) &&
        validatePlatformPromotion('YouTube', platforms.YouTube, [youtubeIntegrationRate, youtubeShortsRate, youtubeDedicatedRate]) &&
        validatePlatformPromotion('X', platforms.X, [xFeedPost, xRepost]) &&
        validatePlatformPromotion('Kick', platforms.Kick, [kickPlacement]) &&
        validatePlatformPromotion('Twitch', platforms.Twitch, [twitchPlacement]) &&
        validatePlatformPromotion('Podcast', platforms.Podcast, [podcastPreroll,
            podcastMidroll, 
            podcastPostroll, 
            podcastFull, ])
    );
};

