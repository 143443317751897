import { Box, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

export default function HomeFooter(props) {

    return (
        <Box
            sx={{
                paddingBlockStart: {xs:2, md: 4},
                paddingBlockEnd: 2,
                paddingInline:1,
                textAlign: "center",
                backgroundColor: '#ECECEB',
                color:'#222'
            }}>
            <Typography
                variant="body2"
                color="inherit"
                sx={{ mt: 4, textAlign: 'center' }}
            >
                © 2024 Pangea, Inc. All Rights Reserved.{' '}
                <Link component={RouterLink} to="/privacypolicy">
                    <b>Privacy Policy</b>
                </Link>
            </Typography>

        </Box >
    )
}
