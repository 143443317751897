import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import API from "../../../../API";
import CreatorConversation from "./pconvo"; // Retained import for CreatorConversation
import { styled } from "@mui/system";

// Styled Components
const ActionButton = styled(Button)({
  backgroundColor: "#616161",
  color: "white",
  "&:hover": {
    backgroundColor: "#757575",
  },
});

const WarningBanner = styled(Paper)({
  padding: "16px",
  marginBottom: "16px",
  backgroundColor: "#fff3cd",
  color: "#856404",
  textAlign: "center",
});

const PartnershipDetailsCard = styled(Paper)({
  padding: "16px",
  marginBottom: "16px",
});

const CreatorSpecificPartnership = () => {
  const { partnershipId } = useParams();
  const { creatorToken } = useCreatorAuth();

  // Fetch partnership details using useQuery
  const {
    isLoading,
    error,
    data: partnership,
    refetch,
  } = useQuery(
    ["creator", "partnership", partnershipId],
    () => {
      if (creatorToken.creator_user.username) {
        return API.creatorConnect.getPartnershipSingle(
          partnershipId,
          creatorToken.creator_user.username
        );
      }
      return null;
    },
    { enabled: !!partnershipId }
  );

  useEffect(() => {
    if (partnershipId) {
      refetch();
    }
  }, [partnershipId, refetch]);

  // Loading state
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: "white" }} />
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          Error fetching partnership: {error.message}
        </Typography>
      </Box>
    );
  }

  // No partnership found
  if (!partnership) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">No partnership found.</Typography>
      </Box>
    );
  }

  // Main Render
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ mb: 2 }}>
        <ActionButton variant="contained" onClick={() => window.history.back()}>
          Go Back
        </ActionButton>
      </Box>

      <PartnershipDetailsCard elevation={2}>
        <Typography variant="h5" gutterBottom>
          {partnership.partnership.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Status:</strong> {partnership.partnership.status}
        </Typography>
        <Typography variant="body2">
          {partnership.partnership.description}
        </Typography>
      </PartnershipDetailsCard>

      <WarningBanner>
        ⚠️ Deals and payments must happen within the Blitz platform. Going
        outside the Blitz platform could result in banishment from the platform
        and losing deals from other partnerships.
      </WarningBanner>

      <CreatorConversation partnershipId={partnershipId} />
    </Container>
  );
};

export default CreatorSpecificPartnership;
