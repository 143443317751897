import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Toolbar,
} from "@mui/material";
import routes from "../../../Config/routes";
import HomeHeader from "../../../Components/HomeHeader";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";

const WhyPartner = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.creatorConnectOnboard}`);

  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width: '100%',
      color: '#000'
    }}>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        {/* Section 1: Introduction */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Welcome to Blitz: The Ultimate Platform for Creators
          </Typography>
          <Typography variant="body1" paragraph>
            In today's fast-paced digital world, managing collaborations, campaigns, payouts, and partnerships can be overwhelming. Blitz is here to simplify this process, giving creators a home for all their professional needs. Whether you are a budding creator or an established influencer, Blitz offers a range of tools designed to streamline your operations and accelerate global collaboration.
          </Typography>
        </Box>

        <Divider />

        {/* Section 2: For All Creators */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            For All Creators: Unleash Your Potential
          </Typography>
          <Typography variant="body1" paragraph>
            Blitz is the most powerful collaboration management software designed to meet the unique needs of creators. Our platform ensures that your collaborations are smoother, more efficient, and more effective than ever before. From managing campaigns to handling payouts, Blitz offers a comprehensive solution that grows with your needs.
          </Typography>
        </Box>

        <Divider />

        {/* Section 3: Partner Program */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Why Become a Partner? Maximize Your Opportunities
          </Typography>
          <Typography variant="body1" paragraph>
            Joining the Blitz Partner Program opens doors to a world of prioritized opportunities. As a partner, you’ll receive more deals and collaborations through our platform, with a dedicated sales team working tirelessly to sell your services to top brands. This program is designed for creators who are ready to take their business to the next level.
          </Typography>
          <Typography variant="body1" paragraph>
            In addition, BlitzPay provides instant access to your earnings for a small fee, enabling you to manage your finances with ease. Say goodbye to long payment delays and hello to a smoother, more efficient financial experience.
          </Typography>
        </Box>

        <Divider />

        {/* Section 4: Associate Program */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Associate Program: Your Gateway to Success
          </Typography>
          <Typography variant="body1" paragraph>
            Our Associate Program is the default option for creators joining the Blitz platform. It offers access to all our tools, including campaign management, invoicing, and BlitzPay. While associates benefit from streamlined operations, our partners receive additional prioritization in sales and collaborations, giving them a competitive edge in the market.
          </Typography>
        </Box>

        <Divider />

        {/* Section 5: Value */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            The Blitz Value Proposition
          </Typography>
          <Typography variant="body1" paragraph>
            At Blitz, we prioritize:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 2 }}>
            <li>Seamless Integration and Efficiency</li>
            <li>Transparency and Collaboration</li>
            <li>Personalization and Engagement</li>
            <li>Compliance and Security</li>
            <li>Scalability and Growth</li>
          </Typography>
          <Typography variant="body1" paragraph>
            Our platform is designed to evolve with your needs, ensuring that you always have the tools you need to succeed.
          </Typography>
        </Box>

        <Divider />

        {/* Section 6: BlitzPay */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            BlitzPay: Revolutionizing Financial Management
          </Typography>
          <Typography variant="body1" paragraph>
            Managing your finances as a creator has never been easier. BlitzPay offers streamlined invoicing, SMS notifications for payment updates, and customized accounting features. This system is designed to remove the complexity of financial management, giving you more time to focus on what you do best – creating.
          </Typography>
          <Typography variant="body1" paragraph>
            Whether you're looking to get paid faster or need cash advances for your campaigns, BlitzPay has got you covered. Say goodbye to financial stress and hello to a world where your money works for you.
          </Typography>
        </Box>

        <Divider />

        {/* Section 7: Discover */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Discover the Power of Blitz
          </Typography>
          <Typography variant="body1" paragraph>
            The future of creator management is here. Join the Blitz revolution today and unlock the full potential of our platform. Let’s schedule a meeting to secure your free early access and begin your journey toward innovation and success.
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: "1rem",
              textTransform: "none",
              backgroundColor: "#7b2a2a",
              color: "#fff",
            }}
            onClick={handleAccess}
          >
            Request Access
          </Button>
        </Box>
      </Container>

      {/* Footer */}
      <HomeFooter></HomeFooter>
    </Box>
  );
};

export default WhyPartner;
