import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Toolbar,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HomeHeader from '../../../../Components/HomeHeader';
import HomeFooter from '../../../../Components/HomeFooter';
import { globalStyles } from '../../../../Utils/Styles';
import { StyledDivider as Divider } from '../../../../Components/LightDivider';
import API from '../../../../API';

const CreatorInvoicePage = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const data = await API.creatorConnect.getInvoice(id);
        setInvoice(data);
      } catch (error) {
        console.error('Error fetching invoice:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  const handleDownloadPDF = () => {
    const input = document.getElementById('invoice-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${id}.pdf`);
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!invoice) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Invoice not found.</Typography>
      </Box>
    );
  }

  const formattedDate = (date) => new Date(date).toLocaleDateString();
  const getAmountDue = (amount) => amount || 0;

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        color: '#000',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HomeHeader />
      <Toolbar />
      {/* Main Content */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Container
          sx={{
            ...globalStyles.wholeHeightMinusToolbar,
            py: 2,
            maxWidth: 'md',
          }}
        >
          <Box id="invoice-content" sx={{ mb: 4 }}>
            <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ fontWeight: 'bold', mb: 2 }}
              >
                PANGEA OMNINATIONAL CORPORATION - DBA: The Culture Club Inc
              </Typography>
              <Typography variant="body1" align="center">
                8724 Cavell Lane, Houston, TX 77055
              </Typography>
              <Typography variant="body1" align="center">
                Invoice ID: {id}
              </Typography>

              <Divider sx={{ my: 4 }} />

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    Billed To
                  </Typography>
                  <Typography variant="body1">{invoice.client_name}</Typography>
                  <Typography variant="body1">{invoice.client_email}</Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadPDF}
                    sx={{ mt: 4 }}
                  >
                    Download PDF
                  </Button>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="body1">
                  Date of Issue: {formattedDate(invoice.invoice_created_at)}
                </Typography>
                <Typography variant="body1">
                  Due Date: {formattedDate(invoice.payout_date)}
                </Typography>
                <Typography variant="body1">Invoice Number: {invoice.id}</Typography>
                <Typography variant="body1">
                  Amount Due (USD): ${getAmountDue(invoice.amount).toFixed(2)}
                </Typography>
              </Box>

              <Divider sx={{ my: 4 }} />

              <Box sx={{ mt: 4 }}>
                <TableContainer component={Paper} elevation={1}>
                  <Table>
                    <TableHead sx={{ bgcolor: '#000' }}>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Rate</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Qty</TableCell>
                        <TableCell sx={{ color: '#fff' }}>Line Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{invoice.po_number}</TableCell>
                        <TableCell>${getAmountDue(invoice.amount).toFixed(2)}</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>${getAmountDue(invoice.amount).toFixed(2)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography variant="body1">
                  Subtotal: ${getAmountDue(invoice.amount).toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  Platform Fee: ${(getAmountDue(invoice.amount) * 0.05).toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  Total: ${(getAmountDue(invoice.amount) * 1.05).toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  Amount Paid: ${getAmountDue(invoice.amount_paid).toFixed(2)}
                </Typography>
                <Typography variant="body1">
                  Amount Due (USD): $
                  {(
                    getAmountDue(invoice.amount) * 1.05 -
                    getAmountDue(invoice.amount_paid)
                  ).toFixed(2)}
                </Typography>
              </Box>

              <Divider sx={{ my: 4 }} />

              <Box sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                  Terms
                </Typography>
                <Typography variant="body1">
                  Make all checks payable to "DBA Pangea Omninational Corporation"
                </Typography>
                <Typography variant="body1">Account holder: Houston Alexander Roa</Typography>
                <Typography variant="body1">Account Name: Pangea</Typography>
                <Typography variant="body1">
                  Address of Beneficiary: 8724 Cavell Lane, Houston, TX 77055
                </Typography>
                <Typography variant="body1">Bank name: Prosperity Bank</Typography>
                <Typography variant="body1">
                  Address of bank: 2117 N. Fry Road, Katy, TX 77449
                </Typography>
                <Typography variant="body1">Account number: 217841714</Typography>
                <Typography variant="body1">SWIFT: PROYUS44</Typography>
                <Typography variant="body1">
                  ABA / Routing number/IBAN: 113122655
                </Typography>
              </Box>

              <Divider sx={{ my: 4 }} />

              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Pay with PayPal, Credit Card, and more!
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  href={invoice.invoice_link}
                  sx={{ mt: 2 }}
                >
                  Pay Invoice
                </Button>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
      <HomeFooter />
    </Box>
  );
};

export default CreatorInvoicePage;
