import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Rating, styled } from '@mui/material';
import useAuth from '../../../Hooks/use-auth';
import API from '../../../API';
import AlertDialog from '../../../Components/AlertDialog';
import useAlertDialog from '../../../Components/useAlertDialog';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.primary.light,
  },
}));

const LeaveReview = ({ open, onClose, creatorId }) => {
  const { getCurrrentUser } = useAuth();

  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState('');
  const {dialogState, openDialog, closeDialog} = useAlertDialog();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrrentUser();
        console.log('Current User:', currentUser); // Debugging log
        setUser(currentUser);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [getCurrrentUser]);

  const OnCloseDialog = () => {
    closeDialog();
    onClose();
  }

  const handleSubmit = async () => {
    if (!user) {
      console.error('User not authenticated');
      openDialog('User Error', 'You need to log in to leave a review!', OnCloseDialog, OnCloseDialog, 'Ok', null);
      return;
    }
    try {
      await API.creators.postReview(creatorId, {
        rating,
        description,
        user_id: user.id,
      });
      openDialog('Success', <>Your review was submitted succesfully</>, OnCloseDialog, OnCloseDialog, 'Ok', null);
    } catch (error) {
      openDialog('System Error', <>Error while submitting your review<br/>{error?.response?.data?.error || error.message}</>, OnCloseDialog, OnCloseDialog, 'Ok', null);
      console.error('Error submitting review:', error);
    }
  };

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Leave a Review</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '1rem' }}>
            <StyledRating
              name="rating"
              value={rating}
              color='primary'
              onChange={(event, newValue) => setRating(newValue)}
            />
          </div>
          <TextField
            margin="dense"
            label="Description"
            multiline
            rows={4}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error" variant='contained'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary" variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaveReview;
