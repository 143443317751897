import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer, Select, MenuItem, FormControl, InputLabel, Button, Box } from '@mui/material';
import API from '../../../../../API';

const CreatorEmailView = () => {
    const [creatorEmails, setCreatorEmails] = useState([]);
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [creatorIds, setCreatorIds] = useState([]);
    const [selectedCreator, setSelectedCreator] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    // Fetch creator emails and set the creator IDs
    useEffect(() => {
        API.creators.adminEmails().then(data => {
                setCreatorEmails(data);
                setFilteredEmails(data);
                const uniqueCreatorIds = [...new Set(data.map(email => email.creator_id))];
                setCreatorIds(uniqueCreatorIds);
            })
            .catch(error => {
                console.error('Failed to fetch creator emails:', error?.response?.data?.error || error.message);
                alert(`Failed to fetch creator emails: ${error?.response?.data?.error || error.message}`);
            });
    }, []);

    // Filter by creator ID and sort emails
    const handleFilter = () => {
        let filtered = [...creatorEmails];

        if (selectedCreator) {
            filtered = filtered.filter(email => email.creator_id === selectedCreator);
        }

        filtered.sort((a, b) => {
            const dateA = new Date(a.date_received);
            const dateB = new Date(b.date_received);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredEmails(filtered);
    };

    return (
        <Box sx={{padding:3}}>
            {/* Filter and Sort Controls */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <FormControl style={{ minWidth: 200 }}>
                    <InputLabel id="creator-select-label">Filter by Creator ID</InputLabel>
                    <Select
                        labelId="creator-select-label"
                        value={selectedCreator}
                        onChange={(e) => setSelectedCreator(e.target.value)}
                    >
                        <MenuItem value=""><em>All Creators</em></MenuItem>
                        {creatorIds.map((id) => (
                            <MenuItem key={id} value={id}>{id}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                <FormControl>
                    <InputLabel id="sort-order-label">Sort by Date</InputLabel>
                    <Select
                        labelId="sort-order-label"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                    >
                        <MenuItem value="asc">Oldest First</MenuItem>
                        <MenuItem value="desc">Newest First</MenuItem>
                    </Select>
                </FormControl>

                <Button variant="contained" onClick={handleFilter}>Apply Filters</Button>
            </div>

            {/* Table for displaying emails */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Creator ID</TableCell>
                            <TableCell>Date Received</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>Sender</TableCell>
                            <TableCell>Receiver</TableCell>
                            <TableCell>Category</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredEmails.map(email => (
                            <TableRow key={email.id}>
                                <TableCell>{email.creator_id}</TableCell>
                                <TableCell>{new Date(email.date_received).toLocaleString()}</TableCell>
                                <TableCell>{email.subject}</TableCell>
                                <TableCell>{email.sender}</TableCell>
                                <TableCell>{email.receiver}</TableCell>
                                <TableCell>{email.category}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CreatorEmailView;
