import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import client from "../../../API"; // Adjust the path to your API client
import { getPONumber } from "../../../Utils/constants";

const steps = [
  "Select Payment Method",
  "Enter Client Information",
  "Enter Invoice Details",
  "Confirm and Submit",
];

const InvoiceDialog = ({ open, onClose, invoiceInfo = undefined }) => {
  const [poNumber, setPONumber] = useState(invoiceInfo?.po_number ?? "");
  const [amountDue, setAmountDue] = useState(invoiceInfo?.amount_due ?? "");
  const [email, setEmail] = useState(""); // State to hold email input
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");

  // New state for client information
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientNotes, setClientNotes] = useState("");

  useEffect(() => {
    if (invoiceInfo) {
      setPONumber(invoiceInfo.po_number ?? "");
      setAmountDue(invoiceInfo.amount_due ?? "");
    }
  }, [invoiceInfo]);

  const handleNext = () => {
    // Validate fields before moving to the next step
    if (activeStep === 0 && !paymentMethod) {
      alert("Please select a payment method.");
      return;
    }
    if (activeStep === 1 && (!clientName || !clientEmail)) {
      alert("Please enter client name and email.");
      return;
    }
    if (activeStep === 2 && (!poNumber || !amountDue)) {
      alert("Please enter PO Number and Amount Due.");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleSubmit = async () => {
    const submissionData = {
      po_number: poNumber,
      amount_due: amountDue,
      email: email,
      payment_method: paymentMethod,
      client_name: clientName,
      client_email: clientEmail,
      client_notes: clientNotes,
    };

    try {
      const response = await client.invoices.create(submissionData);
      if (response.status === "Success") {
        onClose(true);
        alert("Invoice created and shared successfully!");
      } else {
        throw new Error(response.message || "Error creating invoice");
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
      alert("Error submitting invoice: " + error.message);
      onClose(true);
    }
  };

  const handleGeneratePO = () => {
    setPONumber(getPONumber("TCC"));
  };

  const handlePayPalPayment = async () => {
    try {
      const data = {
        amount: parseFloat(amountDue),
        po_number: poNumber,
      };

      const response = await client.invoices.createPaypalPayment(data);

      if (response.status === "Success") {
        window.location.href = response.approvalUrl;
      } else {
        alert("PayPal payment initiation failed");
      }
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
      alert("Error initiating PayPal payment");
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              select
              label="Payment Method"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              fullWidth
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              margin="normal"
            >
              <option value=""></option>
              <option value="ach">Bank Wire / ACH Transfer</option>
              <option value="paypal">PayPal</option>
              <option value="stripe">Stripe</option>
            </TextField>
          </Box>
        );
      case 1:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Client Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Client Email"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Client Notes"
              value={clientNotes}
              onChange={(e) => setClientNotes(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Box>
        );
      case 2:
        return (
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <TextField
              margin="dense"
              label="PO #"
              fullWidth
              value={poNumber}
              onChange={(e) => setPONumber(e.target.value)}
              variant="outlined"
              required
            />
            <Button variant="outlined" onClick={handleGeneratePO}>
              Generate PO
            </Button>
            <TextField
              label="Amount Due"
              type="number"
              fullWidth
              margin="dense"
              value={amountDue}
              onChange={(e) => setAmountDue(e.target.value)}
              required
            />
            <TextField
              label="Share this Invoice (Email)"
              type="email"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email to share the invoice"
            />
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h6">Review Invoice Details</Typography>
            <Typography variant="body1">Payment Method: {paymentMethod}</Typography>
            <Typography variant="body1">Client Name: {clientName}</Typography>
            <Typography variant="body1">Client Email: {clientEmail}</Typography>
            <Typography variant="body1">Client Notes: {clientNotes}</Typography>
            <Typography variant="body1">PO Number: {poNumber}</Typography>
            <Typography variant="body1">Amount Due: ${amountDue}</Typography>
            <Typography variant="body1">Email to Share: {email}</Typography>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="invoice-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="invoice-dialog-title">Create Invoice</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>{renderStepContent(activeStep)}</Box>
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Button onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <>
            {paymentMethod === "stripe" && (
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            )}
            {paymentMethod === "paypal" && (
              <Button onClick={handlePayPalPayment} color="secondary">
                Pay with PayPal
              </Button>
            )}
            {(paymentMethod === "ach" || paymentMethod === "bank_wire") && (
              <Button onClick={handleSubmit} color="primary">
                Notify Team
              </Button>
            )}
          </>
        ) : (
          <Button onClick={handleNext}>
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDialog;
