import React, { useState, useMemo } from 'react';
import {
    Backdrop, Box, Button, CircularProgress, CssBaseline, Divider, Grid,
    Paper, styled, ThemeProvider,
    Toolbar, Typography, Link
} from '@mui/material';
import { lightTheme } from '../../../Utils/baseTheme';
import HomeHeader from '../../../Components/HomeHeader';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../API';
import PageTitle from '../../../Components/PageTitle';
import ppfLogo from '../../../Components/globalAssets/ppfLogo.png';

const pageTheme = lightTheme;

const GradientButton = styled(Button)(({ theme }) => ({
    width: '5.5em',
    background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
    color: '#FFF',
    '&:hover': {
        background: 'linear-gradient(45deg, #747AFF, #8B3A3A)',
    },
}));

const BookButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)',
    backgroundSize: '400% 400%',
    color: '#FFF',
    borderRadius: '23px',
    fontWeight: '700',
    height: '46px',
    transition: 'transform 0.3s ease-in-out',
    animation: 'rainbow 6s ease infinite',
    border: '4px solid transparent',
    '&:hover': {
        transform: 'scale(1.1)',
        borderColor: 'currentColor',
    },
    '@keyframes rainbow': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        },
    },
}));

function RateCard({ rate, label }) {
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: 1,
                padding: 2,
                textAlign: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <Typography variant="h6">{label}</Typography>
            <Typography variant="subtitle1">${rate.toLocaleString()}</Typography>
        </Box>
    );
}

export default function TempCreatorPage() {
    const { creatorId, discount } = useParams();
    const navigate = useNavigate();

    const { data: creatorDetails, error, isLoading } = useQuery({
        queryKey: ['creatorCrm', creatorId],
        queryFn: () => API.crm.creator_fetch(creatorId),
        enabled: !!creatorId,
    });

    const rates = useMemo(() => {
        if (!creatorDetails) return [];

        const data = creatorDetails;

        return [
            { platform: 'Instagram', rate: data.following * 0.01 },
            { platform: 'TikTok', rate: data.following * 0.008 },
            { platform: 'YouTube', rate: data.following * 0.012 },
        ];
    }, [creatorDetails]);

    if (isLoading) return 'Loading creator details';
    if (error || !creatorDetails) return `No creator details found: ${error?.response?.data?.error || error?.message || 'Unknown error'}`;

    const totalFollowers = creatorDetails.following;

    const handleOnboardClick = () => {
        navigate('/creatorconnect/onboard');
    };

    return (
        <ThemeProvider theme={pageTheme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <PageTitle title={`Temporary Mediakit for @${creatorId} - Blitz`} />
            <CssBaseline />
            <HomeHeader />
            <Toolbar />

            <Box
                id="non-scroll-bg"
                sx={(theme) => ({
                    '@media (min-width:0px)': {
                        minHeight: {
                            xs: 'calc(100vh - 56px)',
                            md: theme.spacing(80),
                        },
                        top: '56px',
                        '@media (orientation: landscape)': {
                            minHeight: {
                                xs: 'calc(100vh - 48px)',
                                md: theme.spacing(80),
                            },
                            top: '48px',
                        },
                    },
                    '@media (min-width:600px)': {
                        minHeight: {
                            xs: 'calc(100vh - 64px)',
                            md: theme.spacing(80),
                        },
                        top: '64px',
                    },
                    width: '100%',
                    position: { xs: 'static', md: 'sticky' },
                    backgroundImage: 'linear-gradient(#646AFF, #7B2A2A)',
                    zIndex: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingInline: 1,
                })}
            >
                <Box
                    id="creator-header"
                    sx={{
                        maxWidth: { xs: '100%', md: 1200 },
                        width: 1200,
                        overflowX: 'clip',
                        marginInline: 'auto',
                        marginBlock: 2,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'stretch',
                        padding: { xs: 2, md: 0 },
                        gap: 4,
                        '@media (orientation: landscape)': {
                            flexDirection: 'row',
                            alignItems: 'stretch',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 300,
                            maxWidth: 300,
                            gap: 2,
                            marginInline: 'auto',
                        }}
                    >
                        <Box sx={{ height: 300 }}>
                            <img
                                src={ppfLogo}
                                style={{ height: '100%', borderRadius: '3.125%' }}
                                alt="Creator"
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            color: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flex: 1,
                        }}
                    >
                        <Box>
                            <Typography variant="h3" sx={{ textAlign: 'justify', wordBreak: 'break-all' }}>
                                @{creatorDetails.username}
                            </Typography>
                            <Typography variant="subtitle1">
                                <Link href={creatorDetails.link} target="_blank" rel="noopener noreferrer" color="inherit">
                                    {creatorDetails.link}
                                </Link>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ marginBlockEnd: -1 }}>Total Followers:</Typography>
                            <Typography sx={{ marginBlockEnd: -1 }} variant="h6">
                                {totalFollowers.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Paper
                square
                elevation={1}
                sx={{ width: '100%', paddingInline: 1, paddingBlock: 4, position: 'relative' }}
            >
                <Box sx={{ maxWidth: 1200, marginInline: 'auto' }}>
                    <Typography variant="h6">Suggested rates given your following</Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    <Grid container spacing={2}>
                        {rates.map((rate) => (
                            <Grid item xs={12} sm={6} md={4} key={rate.platform}>
                                <RateCard label={rate.platform} rate={rate.rate} />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                        <BookButton onClick={handleOnboardClick}>
                            Onboard today
                        </BookButton>
                    </Box>
                </Box>
            </Paper>
        </ThemeProvider>
    );
}
