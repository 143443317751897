import React, { useState } from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import client from '../../../../../API';

const SMSChannels = () => {
  const [messages, setMessages] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);

  const { mutate: fetchMessages } = useMutation(client.twilio.fetch, {
    onSuccess: (data) => {
      setMessages(data);
    },
    onError: (error) => {
      console.error('Failed to fetch messages:', error);
    },
  });

  React.useEffect(() => {
    fetchMessages();
  }, []);

  // Sort messages by timestamp descending
  const sortedMessages = [...messages].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  // Group messages by from_number
  const groupedMessages = sortedMessages.reduce((acc, message) => {
    if (!acc[message.from_number]) {
      acc[message.from_number] = [];
    }
    acc[message.from_number].push(message);
    return acc;
  }, {});

  // Get fromNumbers sorted by the latest message timestamp
  const sortedFromNumbers = Object.keys(groupedMessages).sort((a, b) => {
    const aLatestTimestamp = new Date(groupedMessages[a][0].timestamp);
    const bLatestTimestamp = new Date(groupedMessages[b][0].timestamp);
    return bLatestTimestamp - aLatestTimestamp;
  });

  const handleCardClick = (fromNumber) => {
    setSelectedSender(fromNumber);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {selectedSender ? (
        <Box>
          <Typography variant="h6">Messages from {selectedSender}</Typography>
          {groupedMessages[selectedSender]
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort messages by timestamp descending
            .map((message) => (
              <Card key={message.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="body2">{new Date(message.timestamp).toLocaleString()}</Typography>
                  <Typography variant="body1">{message.message_body}</Typography>
                </CardContent>
              </Card>
            ))}
          <Typography
            variant="body2"
            onClick={() => setSelectedSender(null)}
            style={{ cursor: 'pointer', color: 'blue' }}
          >
            Back to All Messages
          </Typography>
        </Box>
      ) : (
        sortedFromNumbers.map((fromNumber) => {
          const latestMessage = groupedMessages[fromNumber][0]; // Latest message for this sender
          return (
            <Card
              key={fromNumber}
              sx={{ mb: 2 }}
              onClick={() => handleCardClick(fromNumber)}
              style={{ cursor: 'pointer' }}
            >
              <CardHeader
                avatar={
                  latestMessage.pfphref ? (
                    <Avatar src={latestMessage.pfphref} />
                  ) : (
                    <Avatar>{latestMessage.creator_name ? latestMessage.creator_name[0] : 'U'}</Avatar>
                  )
                }
                title={latestMessage.creator_name || 'Unknown Sender'}
                subheader={fromNumber}
              />
              <CardContent>
                <Typography variant="body2">
                  Last message: {latestMessage.message_body}
                </Typography>
              </CardContent>
            </Card>
          );
        })
      )}
    </Box>
  );
};

export default SMSChannels;
