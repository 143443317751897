import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import client from "../../API";
import { validateEmail, validatePassword, capitalizeFirstLetter } from "../../Utils";
import { lightTheme } from "../../Utils/baseTheme";
import "./Login.css";
import HomeHeader from "../../Components/HomeHeader";

const Register = () => {
  const navigate = useNavigate();
  const { company } = useParams(); // Get company from URL params

  const [companies, setCompanies] = useState([]);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: company || "", // Set companyName to the URL param if it exists
    streetAddress: "",
    city: "",
    state: "",
    country: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const { mutate: register, isLoading } = useMutation(client.users.register, {
    onSuccess: () => navigate("/login"),
    onError: (error) => {
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  const { mutate: fetchCompanies } = useMutation(client.companies.list, {
    onSuccess: (data) => setCompanies(data),
    onError: (error) => {
      console.log("Error fetching companies - ", error);
      setCompanies([]);
    },
  });

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleRegister = (e) => {
    e.preventDefault();
    let isValid = true;

    Object.keys(userInfo).forEach((item) => {
      if (isValid && userInfo[item].length === 0) {
        setSnackbarMessage(`${capitalizeFirstLetter(item)} field is required!`);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        isValid = false;
      }
    });

    if (!isValid) return;

    if (!validateEmail(userInfo.email)) {
      setSnackbarMessage("Invalid email address!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (!validatePassword(userInfo.password)) {
      setSnackbarMessage("Password should have 6 or more characters!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (userInfo.password !== userInfo.confirmPassword) {
      setSnackbarMessage("Passwords do not match!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const selectedCompany = companies.find((item) => item.name === userInfo.companyName);
    const params = {
      username: userInfo.email,
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
      email: userInfo.email,
      password: userInfo.password,
      company_id: selectedCompany ? selectedCompany.id : null,
      company_name: userInfo.companyName,
      address: `${userInfo.streetAddress}, ${userInfo.city}, ${userInfo.state}, ${userInfo.country}`,
    };

    register(params);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <HomeHeader />
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Create an Account
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Join Blitz and get started
            </Typography>
          </Box>
          <form onSubmit={handleRegister}>
            <TextField
              disabled={isLoading}
              label="First Name"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              value={userInfo.firstName}
              onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              value={userInfo.lastName}
              onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Email"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              type="email"
              value={userInfo.email}
              onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Password"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              type="password"
              value={userInfo.password}
              onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Confirm Password"
              variant="outlined"
              fullWidth
              required
              sx={{ marginBottom: 2 }}
              type="password"
              value={userInfo.confirmPassword}
              onChange={(e) => setUserInfo({ ...userInfo, confirmPassword: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Company Code"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={userInfo.companyName}
              onChange={(e) => setUserInfo({ ...userInfo, companyName: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Street Address"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={userInfo.streetAddress}
              onChange={(e) => setUserInfo({ ...userInfo, streetAddress: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="City"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={userInfo.city}
              onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="State"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={userInfo.state}
              onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}
            />
            <TextField
              disabled={isLoading}
              label="Country"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={userInfo.country}
              onChange={(e) => setUserInfo({ ...userInfo, country: e.target.value })}
            />

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ marginBottom: 2 }}
              startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
              disabled={isLoading}
              type="submit"
            >
              Register
            </Button>
          </form>
        </Paper>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default Register;
