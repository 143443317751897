import React from "react";
import { Box, Typography, Container, Toolbar } from "@mui/material";
import { globalStyles } from "../Utils/Styles";
import HomeFooter from "../Components/HomeFooter";
import { StyledDivider as Divider } from "../Components/LightDivider";

const Integrations = () => {
  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width:'100%',
      color: '#000'
    }}>
      <Toolbar />

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          color: "#000",
          marginBlockStart:4
        }}
      >
        {/* Integrations Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ textAlign: "center", marginBlockEnd: 2 }}>
            Blitz Integrations (Blitz 4.0)
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center", mb: 4 }}>
            Pay - Create - Accelerate
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
            Note: These features are currently under development and will be available soon.
          </Typography>
        </Box>

        <Divider />

        {/* For Creators */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            For Creators
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <ul>
              <li><strong>Blitz App Marketplace:</strong> Build, monetize, and launch your own apps on the Blitz ecosystem, where creators can subscribe, and Blitz earns a small fee.</li>
              <li><strong>Direct Bank Integration:</strong> Add your bank account to receive ACH payments directly.</li>
              <li><strong>Enhanced Invoicing:</strong> Streamline invoicing for platforms like YouTube and Snapchat.</li>
              <li><strong>Creator Showcase Uploading:</strong> Append showcase content to your media kit for potential partners.</li>
              <li><strong>Demo Data Storage:</strong> Store and manage your story posts, associated links, and other campaign data.</li>
              <li><strong>Email Campaign Follow-Ups:</strong> Use refresh tokens to send follow-up emails through the Pitch/Outbound component.</li>
            </ul>
          </Typography>
        </Box>

        <Divider />

        {/* For Brands */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            For Brands
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <ul>
              <li><strong>Expanded Social Media Tools:</strong> Gain access to powerful non-creator tools for enhanced brand management.</li>
              <li><strong>Platform Sign-In and Tracking:</strong> Sign in with your brand accounts to track analytics, schedule, and manage content directly.</li>
              <li><strong>AI Ads Manager:</strong> Create targeted ads with AI-driven templates, similar to Canva.</li>
              <li><strong>Content Creation Automation:</strong> Generate AI-powered text, photos, and images for custom content.</li>
            </ul>
          </Typography>
        </Box>

        <Divider />

        {/* Partnerships */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Partnerships
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <ul>
              <li><strong>Ongoing Bug Fixes:</strong> We're continuously working to improve the Blitz platform, ensuring seamless partnerships and collaborations.</li>
            </ul>
          </Typography>
        </Box>

        <Divider />

        {/* Business App Suite */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Business App Suite
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            <ul>
              <li><strong>AI Video Reconfiguration:</strong> Upload videos to create optimized variations, automatically pushed to various pages.</li>
            </ul>
          </Typography>
        </Box>
      </Container>

      <HomeFooter />
    </Box>
  );
};

export default Integrations;
