import { Box } from "@mui/material";
import React from "react";

function TabPanel({ children, value, index, ...other }) {

    return (
        <>
            {value === index && children}
        </>
    );
}

export default TabPanel;