import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Grid,
  Card,
  CardContent,
  CardMedia, // Import CardMedia to display images
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import client from '../../API'; // Assuming your API client is set up here
import { NewTracker } from './newTracker';
import { Tracker } from './tracker';

const Trackers = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [open, setOpen] = useState(false);
  const [openTracker, setOpenTracker] = useState(false);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const [selectedTrackers, setSelectedTrackers] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);

  // Fetch trackers using react-query
  const {
    data: trackers,
    isLoading,
    error,
    refetch,
  } = useQuery('trackers', client.trackers.fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle open and close of dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenTracker = (tracker) => {
    setSelectedTracker(tracker);
    setOpenTracker(true);
  };

  const handleCloseTracker = () => {
    setOpenTracker(false);
  };

  const { mutate: postTracker, isLoading: postTrackerLoading } = useMutation({
    mutationFn: client.trackers.create,
    onSuccess: () => {
      refetch(); // Refetch trackers after creation
      handleClose(); // Close the dialog after adding the tracker
    },
    onError: (error) => {
      console.error('Error creating tracker:', error);
      alert(`Network error: ${error?.response?.data?.error || error.message}`);
    },
  });

  // Add new tracker and refetch data
  const handleAddTracker = (tracker) => {
    postTracker(tracker);
  };

  // Filter trackers by active tab
  const filteredTrackers = trackers?.filter((tracker) => {
    if (activeTab === 'all') return true;
    return tracker.status.toLowerCase() === activeTab;
  });

  // Handle tracker selection
  const handleTrackerSelect = (trackerId) => {
    setSelectedTrackers((prevSelected) => {
      if (prevSelected.includes(trackerId)) {
        return prevSelected.filter((id) => id !== trackerId);
      }
      return [...prevSelected, trackerId];
    });
  };

  // Handle applying status changes to selected trackers
  const handleApplyChanges = async () => {
    if (!newStatus) {
      alert('Please select a new status');
      return;
    }
    setUpdateLoading(true);
    try {
      await Promise.all(
        selectedTrackers.map((id) => client.trackers.update(id, { status: newStatus }))
      );
      refetch();
      setSelectedTrackers([]);
      setNewStatus('');
    } catch (error) {
      console.error('Error updating trackers:', error);
      alert(`Network error: ${error?.response?.data?.error || error.message}`);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Paper elevation={2} square sx={{ padding: 2, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Trackers
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleOpen}>
            <AddIcon /> Add Tracker
          </Button>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>

            </Select>
          </FormControl>
        </Box>
      </Paper>

      {/* Tabs for switching between statuses */}
      <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 4 }}>
        <Tab label="All" value="all" />
        <Tab label="Active" value="active" />
        <Tab label="Completed" value="completed" />
        <Tab label="Archived" value="archived" />

      </Tabs>

      {/* Status selector and Apply Changes button when trackers are selected */}
      {selectedTrackers.length > 0 && (
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>New Status</InputLabel>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>

            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleApplyChanges}>
            Apply Changes
          </Button>
        </Box>
      )}

      {isLoading && <Typography>Loading trackers...</Typography>}
      {error && (
        <Typography>Error fetching trackers: {error.message}</Typography>
      )}
      {filteredTrackers?.length < 1 && (
        <Typography>You have no trackers yet.</Typography>
      )}
      <Grid container spacing={3} padding={1}>
        {(filteredTrackers || []).map((tracker) => (
          <Grid item xs={12} sm={6} md={4} key={tracker.id}>
            <Card
              onClick={() => handleOpenTracker(tracker)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'translateY(-4px)',
                },
              }}
            >
              {/* Include the photo using CardMedia */}
              {tracker.tracker_photo && (
                <CardMedia
                  component="img"
                  height="140"
                  image={tracker.tracker_photo} // Display tracker photo
                  alt={tracker.name}
                />
              )}
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Checkbox
                    checked={selectedTrackers.includes(tracker.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTrackerSelect(tracker.id);
                    }}
                  />
                  <Typography variant="h6">{tracker.name}</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {tracker.description}
                </Typography>
                <Typography variant="body2">
                  Links: {tracker.links.length}
                </Typography>
                <Typography variant="body2">
                  Created: {new Date(tracker.date_created).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Status: {tracker.status}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <NewTracker
        open={open}
        handleAddTracker={handleAddTracker}
        handleClose={handleClose}
      ></NewTracker>
      <Tracker
        open={openTracker}
        handleClose={handleCloseTracker}
        tracker={selectedTracker}
      ></Tracker>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.modal + 10 })}
        open={postTrackerLoading || isLoading || updateLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Trackers;
