import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Switch,
  IconButton,
  Button,
  Tabs,
  Tab,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import client from "../../../../API";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo
import { StyledTableRow } from "../../../../Utils/styledcell";

const Partnerships = () => {
  //const [partnerships, setPartnerships] = useState([]);
  const [tabValue, setTabValue] = useState("All");
  const [actionStatus, setActionStatus] = useState("");
  const [isPrettyView, setIsPrettyView] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate hook

  const {data: partnerships, refetch: fetchPartnerships } = useQuery({
    
    queryKey: ['partnerships'],
    queryFn: client.partnerships.list,
    refetchOnWindowFocus: false,
    initialData: [],
  })

  const { mutate: updateStatus } = useMutation(
    ({ id, status }) => client.partnerships.updateStatus(id, { status }),
    {
      onSuccess: () => {
        fetchPartnerships();
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
      },
    }
  );


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleChange = (event) => {
    setIsPrettyView(event.target.checked);
  };

  const handleRowClick = (partnership) => {
    navigate(`/partnerships/${partnership.id}`); // Navigate to the partnership page
  };

  const handleStatusChange = (partnershipId, status) => {
    updateStatus({ id: partnershipId, status });
  };

  const handleDelete = (partnershipId) => {
    handleStatusChange(partnershipId, "DELETED");
  };

  const handleSelectChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const applyStatusChange = () => {
    selectedIds.forEach((id) => {
      handleStatusChange(id, actionStatus);
    });
    setSelectedIds([]); // Clear selected after applying
  };

  const filteredPartnerships = partnerships.filter(
    (partnership) =>
      partnership.status !== "DELETED" && partnership.status !== "Archived" && // Exclude deleted partnerships
      (tabValue === "All" || partnership.status === tabValue) &&
      partnership.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedPartnerships = filteredPartnerships.sort((a, b) => {
    const dateA = new Date(a.proposal_date);
    const dateB = new Date(b.proposal_date);
    const finishDateA = a.finish_date ? new Date(a.finish_date) : null;
    const finishDateB = b.finish_date ? new Date(b.finish_date) : null;

    if (dateB - dateA !== 0) {
      return dateB - dateA; // Sort by proposal_date (most recent first)
    } else if (finishDateA && finishDateB) {
      return finishDateA - finishDateB; // Sort by finish_date (earliest first)
    } else {
      return 0; // If proposal_date and finish_date are equal or unavailable
    }
  });

  return (
    <Box sx={{ padding: 2 }}>
    <Typography
  variant="h4"
  gutterBottom
  component="div"
  sx={{ textAlign: "center", mb: 2 }}
>
  Your Creator Partnerships
</Typography>
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      my: 4,
      width: "100%",
      boxSizing: "border-box",
      overflowX: "auto",
    }}
  >
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search by name"
      value={searchQuery}
      onChange={(event) => setSearchQuery(event.target.value)}
      sx={{ minWidth: 240 }}
    />
    <FormControl variant="outlined" size="small" sx={{ minWidth: 240 }}>
      <InputLabel id="action-status-label">Change Status To</InputLabel>
      <Select
        labelId="action-status-label"
        value={actionStatus}
        onChange={(event) => setActionStatus(event.target.value)}
        label="Change Status To"
      >
        <MenuItem value="Active">Active</MenuItem>
        <MenuItem value="pending">Pending</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Archived">Archived</MenuItem>
      </Select>
    </FormControl>
    <Typography>Make it Pretty</Typography>
    <Switch checked={isPrettyView} onChange={handleToggleChange} color="secondary" />
    <Button variant="contained" onClick={applyStatusChange} disabled={selectedIds.length === 0}>
      Apply Status Change
    </Button>
  </Box>


      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="All" value="All" />
        <Tab label="Active" value="Active" />
        <Tab label="Pending" value="pending" />
        <Tab label="Completed" value="Completed" />
        <Tab label="Archived" value="Archived" />
      </Tabs>

      {isPrettyView ? (
        <Grid container spacing={2}>
          {sortedPartnerships.map((partnership) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={partnership.id}>
              <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <CardMedia
                  component="img"
                  height="300"
                  image={partnership.pfphref || profilePhoto}
                  alt={partnership.creator}
                  onClick={() => handleRowClick(partnership)}
                  style={{ cursor: "pointer" }}
                />
                <CardContent
                  onClick={() => handleRowClick(partnership)}
                  style={{ cursor: "pointer", flexGrow: 1 }}
                >
                  <Typography variant="h5" component="div">
                    {partnership.name}
                  </Typography>
                  <Typography variant="body2">
                    Creator: {partnership.creator}
                  </Typography>
                  <Typography variant="body2">
                    Total Sum: {partnership.total_sum ? `$${parseFloat(partnership.total_sum).toFixed(2)}` : "N/A"}
                  </Typography>
                  <Typography variant="body2">Status: {partnership.status}</Typography>
                  {!!partnership.user && <Typography variant="body2">By user: {partnership.user.username}</Typography>}
                  {<Typography variant="body2">User email: {partnership.email}</Typography>}
                </CardContent>
                <Checkbox
                  checked={selectedIds.includes(partnership.id)}
                  onChange={() => handleSelectChange(partnership.id)}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(partnership.id);
                  }}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer>
          <Table aria-label="partnerships table" width={"100%"}>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Partnership Name</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Proposal Date</TableCell>
                <TableCell>Finish Date</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Paid Date</TableCell>
                <TableCell>Total Sum</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedPartnerships.length > 0 ? (
                sortedPartnerships.map((partnership) => (
                  <StyledTableRow
                    key={partnership.id}
                    hover
                    onClick={() => handleRowClick(partnership)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedIds.includes(partnership.id)}
                        onChange={() => handleSelectChange(partnership.id)}
                      />
                    </TableCell>
                    <TableCell>{partnership.name}</TableCell>
                    <TableCell>{partnership.creator}</TableCell>
                    <TableCell>{partnership.description}</TableCell>
                    <TableCell>{partnership.email}</TableCell>
                    <TableCell>{partnership.proposal_date}</TableCell>
                    <TableCell>{partnership.finish_date || "N/A"}</TableCell>
                    <TableCell>{partnership.invoice_date || "N/A"}</TableCell>
                    <TableCell>{partnership.paid_date || "N/A"}</TableCell>
                    <TableCell>
                      {partnership.total_sum
                        ? `$${parseFloat(partnership.total_sum).toFixed(2)}`
                        : "N/A"}
                    </TableCell>
                    <TableCell>{partnership.status}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(partnership.id);
                        }}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="12" align="center">
                    No partnerships available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Partnerships;
