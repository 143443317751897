import { Box, Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import routes from '../../Config/routes';
import { globalStyles } from '../../Utils/Styles';
import { useRouteMatch } from '../../Hooks/useRouteMatch';
import useIsDesktop from '../../Hooks/useIsDesktop';

const tabData = [
    { name: 'Home', route: routes.agency, value: routes.agency },
    { name: 'Creators', route: routes.agencyCreators, value: `${routes.agencyCreators}/*` },
];

export default function AgencyRoot({ }) {
    const routeMatch = useRouteMatch([routes.agency, `${routes.agencyCreators}/*`]);
    const currentTab = routeMatch?.pattern?.path;
    const isDesktop = useIsDesktop();
    return (
        <Box sx={{ ...globalStyles.wholeHeightMinusToolbar}}>
            <Paper sx={{ paddingInline: { xs: 0, md: 2 }, position:'sticky' }} elevation={4} square>
                <Tabs value={currentTab} centered={isDesktop}>
                    {tabData.map((entry) => (
                        <Tab key={entry.route} label={entry.name} value={entry.value} to={entry.route} component={Link} >
                        </Tab>
                    ))}
                </Tabs>
            </Paper>
            <Outlet></Outlet>
        </Box>
    )
}
