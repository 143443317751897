import { Alert, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import API from '../../../../API'
import { StyledTableRow } from '../../../../Utils/styledcell'
import { Add, Delete } from '@mui/icons-material'

export default function Blocklist({ }) {
    const [newBlocked, setNewBlocked] = useState('');
    const [errorNewBlocked, setErrorNewBlocked] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false)

    const queryClient = useQueryClient();
    const { data: blocklist, isLoading: blockListLoading, error: blocklistError, refetch } = useQuery({
        queryKey: ['blocklist'],
        queryFn: API.blocklist.list,
        refetchOnWindowFocus: false
    });

    const { mutate: blockedCreate, isLoading: blockedCreateLoading, error: blockedCreateError } = useMutation({
        mutationFn: (input) => API.blocklist.create(input),
        onSuccess: (data) => {
            queryClient.invalidateQueries(['blocklist']);
            refetch();
        }
    });

    const { mutate: blockedDelete, isLoading: blockedDeleteLoading, error: blockedDeleteError } = useMutation({
        mutationFn: (input) => API.blocklist.delete(input),
        onSuccess: (data) => {
            queryClient.invalidateQueries(['blocklist']);
            refetch();
        }
    });

    useEffect(() => {
        if (blockedCreateError || blockedCreateError) {
            setOpenToast(true);
        }
    }, [blockedCreateError, blocklistError])

    const HandleOpenDialog = () => {
        setOpenDialog(true);
    }

    const HandleCloseDialog = () => {
        setOpenDialog(false);
    }

    const HandleCloseToast = () => {
        setOpenToast(false);
    }

    const HandleUploadContact = () => {
        if (!newBlocked) {
            setErrorNewBlocked('This field can\'t be empty');
            return;
        }
        if (!newBlocked.includes('@')) {
            setErrorNewBlocked('Please input a valid email');
            return;
        }

        setErrorNewBlocked(null);

        blockedCreate({ mail: newBlocked });
        setNewBlocked('');
        setOpenDialog(false);
    }

    const HandleDeleteContact = (email) => {
        blockedDelete(email);
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={blockedCreateLoading || blockedDeleteLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography variant='h6'>CRM Blocked Contacts</Typography>
            <TableContainer component={Paper} elevation={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left'>
                                Contact
                            </TableCell>
                            <TableCell align='right'>
                                <IconButton onClick={HandleOpenDialog}>
                                    <Add></Add>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blocklist && blocklist.map((contact) => (
                            <StyledTableRow key={`row-${contact.email}`}>
                                <TableCell align='left'>
                                    {contact.email}
                                </TableCell>
                                <TableCell align='right'>
                                    <IconButton color='error' onClick={() => HandleDeleteContact(contact.email)}>
                                        <Delete></Delete>
                                    </IconButton>
                                </TableCell>
                            </StyledTableRow>
                        ))}
                        {blocklist && blocklist.length < 1 &&
                            <StyledTableRow>
                                <TableCell colSpan={2} align='center'>
                                    No contacts blocked yet.
                                </TableCell>
                            </StyledTableRow>
                        }
                        {blockListLoading &&
                            <StyledTableRow>
                                <TableCell colSpan={2} align='center'>
                                    <CircularProgress size={18}></CircularProgress>
                                </TableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={HandleCloseDialog}>
                <DialogTitle>Add Contact to Block</DialogTitle>
                <DialogContent>
                    <TextField
                        label='Contact'
                        type='email'
                        value={newBlocked}
                        onChange={(e) => { setNewBlocked(e.target.value) }}
                        helperText={errorNewBlocked}
                        error={!!errorNewBlocked}
                        sx={{ marginBlockStart: 1 }}
                    >
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={HandleUploadContact}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openToast}
                autoHideDuration={6000}
                onClose={HandleCloseToast}
            >
                <Alert onClose={HandleCloseToast} severity={'error'} sx={{ width: '100%' }}>
                    {`Error: ${blocklistError?.response?.data?.error
                        || blocklistError?.message
                        || ''}${blockedCreateError?.response?.data?.error
                        || blocklistError?.message
                        || ''}${blockedDeleteError?.response?.data?.error
                        || blockedDeleteError?.message
                        || ''}`}
                </Alert>
            </Snackbar>
        </Box>
    )
}
