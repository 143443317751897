import React, { useState } from "react";
import { Backdrop, Box, CircularProgress, Paper, Typography, TextField, IconButton, Button } from "@mui/material";
import API from "../../../API";
import { globalStyles } from "../../../Utils/Styles";
import InternalBookingDialog from "./steps/partnershipsbooking";
import { useMutation, useQuery } from "react-query";
import MarketplaceBooking from "./MarketplaceBooking";
import { getPlatformAndPromotion, parseLocalFloat, platformToFollowing, platformToLink, sortCreators } from "../../../Utils/constants";
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";
import logo from "../../../Components/globalAssets/platty.png"
const AISearchPage = () => {

  const [isBookingDialogOpen, setIsBookingDialogOpen] = useState(false);
  const [selectedCreatorForBooking, setSelectedCreatorForBooking] = useState(null);
  const { dialogState, openDialog, closeDialog } = useAlertDialog();
  const navigate = useNavigate();

  const [showSearchOverlay, setShowSearchOverlay] = useState(true);
  const [userQuery, setUserQuery] = useState("");
  const [appliedFilters, setAppliedFilters] = useState(null);  

  const { data: creatorList, isFetching: creatorIsLoading } = useQuery({
    queryKey: ['creators'],
    queryFn: async () => sortCreators(await API.creators.list({ include_reviews: true, include_public: true })),
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const { mutate: postCampaign, isLoading: loadingCampaign } = useMutation({
    mutationFn: API.campaigns.create,
    onError: error => {
      openDialog('Error', <>An error occurred while creating your campaign: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, "Ok", null);
    },
    onSuccess: data => {
      openDialog('Campaign Builder', <>Your campaign has been created successfully</>, () => navigate(`/editcampaign/${data.id}`), () => navigate(`/editcampaign/${data.id}`), "Ok", null);
    }
  });

  const { mutate: postPartnership, isLoading: loadingPartnership } = useMutation(
    API.partnerships.book,
    {
      onError: error => {
        openDialog('Error', <>An error occurred while creating your partnership: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, "Ok", null);
      },
      onSuccess: data => {
        openDialog('Partnership Builder', <>Your partnership has been created successfully</>, () => navigate(`/partnerships/${data.data.id}`), () => navigate(`/partnerships/${data.data.id}`), "Ok", null);
      }
    }
  );

  const submitBooking = (bookingDetails) => {
    postPartnership({
      name: `${bookingDetails.partnershipName} - ${bookingDetails.promotionPlatform} - ${bookingDetails.promotionType}`,
      creator: selectedCreatorForBooking.id,
      description: bookingDetails.details,
      proposal_date: bookingDetails.selectedDate,
      total_sum: parseLocalFloat(bookingDetails.rate),
      notes: bookingDetails.details,
      finish_date: bookingDetails.selectedDate
    })
  };

  const handleInviteToPartnership = (creator) => {
    setSelectedCreatorForBooking(creator);  
    setIsBookingDialogOpen(true);
  };

  const onFinishCampaign = (campaign) => {
    const newCreators = campaign.creatorsData.map((creator) => {
      const price = parseFloat(creator.adjustedPrice);
      const platPromo = getPlatformAndPromotion(creator.rateKey)
      return {
        id: creator.creator,
        name: creator.creator,
        price: price,
        agencyRate: campaign.isBlitzFeeOnCreators ? price * 0.95 : price,
        following: creator[platformToFollowing[platPromo.platform]],
        promotionPlatform: platPromo.platform,
        promotionType: platPromo.promotion,
        platformLink: creator[platformToLink[platPromo.platform]],
        pfphref: creator.pfphref,
      };
    });

    const timelineEvents = Object.entries(campaign.timelinesData).flatMap(([creatorId, events]) => events.map((event) => ({ ...event, username: creatorId })))

    const projectData = {
      name: campaign.campaignData.campaignName,
      brief: campaign.campaignData.asset ? campaign.campaignData.asset.file_url : campaign.campaignData.campaignBrief,
      videoAmount: parseInt(1, 10),
      creators: newCreators,
      timelines: timelineEvents,
      idealDueDate: campaign.campaignData.idealDueDate,
      emailRecipient: campaign.campaignData.emailRecipient,
      agency: campaign.campaignData.isAgency,
      campaign_manager: campaign.campaignData.manager,
    };

    postCampaign(projectData);
  };

  const { mutate: aiSearchMutation, isLoading: aiSearching } = useMutation(
    (queryText) => API.creators.search({ query: queryText }),
    {
      onError: error => {
        openDialog('Error', <>An error occurred while performing AI search: <br />{error?.response?.data?.error || error.message}</>, closeDialog, closeDialog, "Ok", null);
      },
      onSuccess: data => {
        setAppliedFilters(data.filters);
        setShowSearchOverlay(false);
      }
    }
  );

  const handleSearchSubmit = (e) => {
    if (e.key === "Enter") {
      aiSearchMutation(userQuery);
    }
  };

  const handleSearchClick = () => {
    aiSearchMutation(userQuery);
  };

  const handleSkip = () => {
    setShowSearchOverlay(false);
  };

  return (
    <>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={loadingCampaign||loadingPartnership||aiSearching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {showSearchOverlay && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            bgcolor: 'background.paper',
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
              <Box sx={{ mb: 2 }}>
    <img src={logo} alt="Logo" style={{ maxWidth: '150px', height: 'auto' }} />
  </Box>
          <Typography variant="h4" gutterBottom>
            What are you looking for today?
          </Typography>
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: { xs: '90%', sm: '50%', md: '40%' },
              boxShadow: 4,
              mb: 2
            }}
            onSubmit={(e) => { e.preventDefault(); handleSearchClick(); }}
          >
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. female creators from Asia who do food content with an audience 18-24"
              onChange={(e) => setUserQuery(e.target.value)}
              onKeyDown={handleSearchSubmit}
              value={userQuery}
              InputProps={{ disableUnderline: true, sx: { px: 2 } }}
            />
            <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button variant="text" onClick={handleSkip}>Skip</Button>
        </Box>
      )}

      <Box
        id="search-holder"
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MarketplaceBooking
          onInviteToPartnership={(creator) => handleInviteToPartnership(creator)}
          data={creatorList}
          loading={creatorIsLoading}
          onSubmitCampaign={onFinishCampaign}
          initialFilters={appliedFilters}
        />
      </Box>
      <InternalBookingDialog
        open={isBookingDialogOpen}
        onClose={() => setIsBookingDialogOpen(false)}
        submitBooking={submitBooking}
        creatorData={{ ...selectedCreatorForBooking}}
      />
    </>
  );
};

export default AISearchPage;
