import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, TableContainer, Button, Tabs, Tab, Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import client from '../../../../../API';
import EditCreditsDialog from './editCreditsAdmin';
import EditUserDialog from './editUserAdmin';

const UserAdminView = ({ onSelectionChange }) => {
    const [userData, setUserData] = useState([]);
    const [creatorUserData, setCreatorUserData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);
    const [isCreatorUser, setIsCreatorUser] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [sortType, setSortType] = useState('');
    const [lastLoginSort, setLastLoginSort] = useState('');

    const { mutate: fetchUserDetails } = useMutation(client.users.userAdminFetch, {
        onSuccess: (data) => {
            setUserData(data);
            const selection = data.reduce((acc, user) => {
                acc[user.id] = false;
                return acc;
            }, {});
            setSelectedUsers(selection);
            onSelectionChange(selection);
        },
        onError: (error) => {
            console.error('Failed to fetch user details:', error);
        },
    });

    const { mutate: fetchCreatorUserDetails } = useMutation(client.users.creatorUserAdminFetch, {
        onSuccess: (data) => {
            setCreatorUserData(data);
        },
        onError: (error) => {
            console.error('Failed to fetch creator user details:', error);
        },
    });

    useEffect(() => {
        fetchUserDetails();
        fetchCreatorUserDetails();
    }, []);

    const handleEditClick = (user, isCreator = false) => {
        setUserToEdit(user);
        setIsCreatorUser(isCreator);
        setEditDialogOpen(true);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSortChange = (event) => {
        const value = event.target.value;
        setSortType(value);
        const sortedData = [...userData].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return value === 'asc' ? dateA - dateB : dateB - dateA;
        });
        setUserData(sortedData);
    };

    const handleLastLoginSortChange = (event) => {
        const value = event.target.value;
        setLastLoginSort(value);
        const sortedData = [...userData].sort((a, b) => {
            const dateA = a.last_login ? new Date(a.last_login) : 0;
            const dateB = b.last_login ? new Date(b.last_login) : 0;
            return value === 'asc' ? dateA - dateB : dateB - dateA;
        });
        setUserData(sortedData);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper elevation={4} sx={{ marginBlockEnd: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="User Table" />
                    <Tab label="Creator User Table" />
                </Tabs>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBlockEnd: 2 }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Sort by Sign Up Date</InputLabel>
                    <Select value={sortType} onChange={handleSortChange}>
                        <MenuItem value="asc">Oldest to Newest</MenuItem>
                        <MenuItem value="desc">Newest to Oldest</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Sort by Last Login Date</InputLabel>
                    <Select value={lastLoginSort} onChange={handleLastLoginSortChange}>
                        <MenuItem value="asc">Oldest to Newest</MenuItem>
                        <MenuItem value="desc">Newest to Oldest</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {tabValue === 0 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Company ID</TableCell>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>Updated At</TableCell>
                                <TableCell>Last Login</TableCell>
                                <TableCell>PHPHref</TableCell>
                                <TableCell>Credits</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userData.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.first_name}</TableCell>
                                    <TableCell>{user.last_name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.company_id}</TableCell>
                                    <TableCell>{user.company_name}</TableCell>
                                    <TableCell>{user.address}</TableCell>
                                    <TableCell>{user.status ? 'Active' : 'Inactive'}</TableCell>
                                    <TableCell>{user.type}</TableCell>
                                    <TableCell>{user.created_at}</TableCell>
                                    <TableCell>{user.updated_at}</TableCell>
                                    <TableCell>{user.last_login}</TableCell>
                                    <TableCell>{user.phphref}</TableCell>
                                    <TableCell>{user.credits}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(user, false)}>Edit</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
  {tabValue === 1 && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>Balance</TableCell>
                                <TableCell>Date Created</TableCell>
                                <TableCell>Date Updated</TableCell>
                                <TableCell>Last Sign In</TableCell>
                                <TableCell>Payout Preferred</TableCell>
                                <TableCell>Stripe ID</TableCell>
                                <TableCell>PayPal</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Credits</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {creatorUserData.map((user) => (
                                <TableRow key={user.username}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.balance}</TableCell>
                                    <TableCell>{user.date_created}</TableCell>
                                    <TableCell>{user.date_updated}</TableCell>
                                    <TableCell>{user.last_sign_in}</TableCell>
                                    <TableCell>{user.payout_preferred}</TableCell>
                                    <TableCell>{user.stripe_id}</TableCell>
                                    <TableCell>{user.paypal}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.credits}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(user, true)}>Edit</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}           
            {userToEdit && isCreatorUser ? (
                <EditCreditsDialog
                    open={editDialogOpen}
                    onClose={(updated) => {
                        setEditDialogOpen(false);
                        if (updated) {
                            fetchUserDetails();
                            fetchCreatorUserDetails();
                        }
                    }}
                    userInfo={userToEdit}
                />
            ) : (
                <EditUserDialog
                    open={editDialogOpen}
                    onClose={(updated) => {
                        setEditDialogOpen(false);
                        if (updated) {
                            fetchUserDetails();
                            fetchCreatorUserDetails();
                        }
                    }}
                    userInfo={userToEdit}
                />
            )}
        </Box>
    );
};

export default UserAdminView;
