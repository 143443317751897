import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RateField } from '../../../../Components/RateField'; // Ensure correct import path
import { platformRatesListFromSelectedKeys } from '../../../../Utils/constants';

const BookingDialog = ({
  open,
  onClose,
  submitBooking,
  creator,
  castingCall,
}) => {
  const [formData, setFormData] = useState({
    selectedPromotion: '',
    rate: castingCall?.budget_min || 50,
    selectedDate: new Date().toISOString().substring(0, 10),
    details: '',
    email: '',
    partnershipName: '',
    creatorId: creator?.creator || ''
  });

  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  // Handle rate slider change
  const handleSliderChange = (event, newValue) => {
    setFormData(prevState => ({ ...prevState, rate: newValue }));
  };

  const promotions = useMemo(() => {
    if (!creator || !castingCall) {
      return []
    }
    return platformRatesListFromSelectedKeys(creator, castingCall.promotion_types);
  }, [creator, castingCall])

  // Handle promotion type change
  const handlePromoChange = (value) => {
    // Find the selected promotion to get the rate
    const selectedPromo = promotions.find(promo => promo.rateKey === value);
    const newRate = selectedPromo ? selectedPromo.rate : castingCall.budget_min;
    setFormData(prevState => ({
      ...prevState,
      selectedPromotion: value,
      rate: newRate
    }));
  };



  // Calculate minimum rate based on casting call budget
  const minRate = castingCall?.budget_min || 50.0;

  useEffect(() => {
    if (formData.rate < minRate) {
      setWarning(`Warning: Offers below the casting call's minimum budget ($${minRate}) are usually rejected.`);
    } else {
      setWarning('');
    }
  }, [formData.rate, minRate]);

  useEffect(() => {
    if (creator && castingCall) {
      setFormData({
        selectedPromotion: '',
        rate: castingCall.budget_min,
        selectedDate: new Date().toISOString().substring(0, 10),
        details: '',
        email: '',
        partnershipName: '',
        creatorId: creator.creator || ''
      });
    }
  }, [creator, castingCall]);

  const handleSubmit = async (e) => {

    e.preventDefault();
    // Ensure all fields are filled
    const { selectedPromotion, rate, selectedDate, details, email, partnershipName } = formData;
    if (!selectedPromotion || !rate || !selectedDate || !details || !email || !partnershipName) {
      setError('All fields are required.');
    }

    const form = e.target;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }



    setLoading(true);
    setError('');
    const success = await submitBooking({
      ...formData,
      notes: formData.details
    });
    setLoading(false);
    if (success) {
      onClose();
    } else {
      setError('Failed to submit booking. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          Book a Promotion
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Chat with this top creator directly</Typography>
          <Typography variant="subtitle1">Instructions will be emailed to you.</Typography>
          <Typography variant="h6" style={{ marginTop: '16px' }}>How to Make a Booking</Typography>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/Uzl1iZx56Ng?si=hMiRLUVmKaKHmI3d"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ marginTop: '8px', marginBottom: '16px' }}
          />

          <TextField
            label="Partnership Name"
            fullWidth
            name="partnershipName"
            value={formData.partnershipName}
            onChange={handleChange}
            margin="dense"
            required
          />
          <TextField
            select
            label="Promotion Type"
            fullWidth
            name="selectedPromotion"
            value={formData.selectedPromotion}
            onChange={(e) => handlePromoChange(e.target.value)}
            margin="dense"
            required
          >
            {promotions.length > 0 ? (
              promotions.map((promo) => (
                <MenuItem key={`promoselect-${promo.rateKey}`} value={promo.rateKey}>
                  {promo.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No promotions available</MenuItem>
            )}
          </TextField>
          <Typography gutterBottom>
            Your Budget: ${formData.rate}
          </Typography>
          <RateField
            error={!!warning}
            helperText={warning}
            label="Budget"
            variant="outlined"
            minValue={minRate / 2}
            fullWidth
            value={formData.rate}
            onChange={(value) =>
              handleSliderChange(null, value)
            }
            sx={{ marginTop: 1, marginBottom: 2 }}
          />
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
            Each creator partnership requires a budget that fits within the creator's rate range. You are more than welcome to negotiate directly with the creator once you claim your Blitz account. Upon booking, a signup link will be emailed to you.
          </Typography>

          <TextField
            label="Email"
            type="email"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
            margin="dense"
            required
          />
          <TextField
            id="date-picker"
            label="Select Date"
            type="date"
            fullWidth
            variant="outlined"
            name="selectedDate"
            value={formData.selectedDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
            required
          />
          <TextField
            label="Details"
            fullWidth
            multiline
            rows={4}
            name="details"
            value={formData.details}
            onChange={handleChange}
            margin="dense"
            required
          />

          {/* Display error message if any */}
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: '8px' }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant='text'>Cancel</Button>
          <Button
            variant='text'
            type='submit'
            color="secondary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BookingDialog;
