import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import client from "../../../../API";
import { useQuery } from "react-query";
import {
  Typography,
  Box,
  Divider,
  Toolbar,
  Button,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TabPanel from "../../../../Components/TabPanel.js";
import useIsDesktop from "../../../../Hooks/useIsDesktop.js";
import PageTitle from "../../../../Components/PageTitle.js";
import CRMDialog from "../../../Misc/crmComponents/crmPopup.js";
import HomeHeader from "../../../../Components/HomeHeader.js";
import AgencyDetailsPanel from "./agencydetails.js";

const AgencyDetailsPage = () => {
  const { manager } = useParams();
  const [agencyDetails, setAgencyDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [viewMode, setViewMode] = useState("pretty");
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const isDesktop = useIsDesktop();
  const hasShownMessage = useRef(false);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeView = (event, newValue) => {
    if (newValue === null) newValue = viewMode === "pretty" ? "list" : "pretty";
    setViewMode(newValue);
  };

  // Fetch agency details
  const { data, error, isLoading, refetch } = useQuery(
    ["agencyDetails", manager],
    () => client.creators.listManager(manager),
    {
      onSuccess: (data) => {
        setAgencyDetails({ ...data });
      },
      onError: (error) => {
        console.error("Failed to fetch agency details:", error);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const handleCloseCRMDialog = () => {
    setShowCRMDialog(false);
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(agencyDetails);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `agency_details_${manager}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToSheets = async () => {
    try {
      const response = await client.creators.exportSheet({
        manager,
        agencyDetails,
      });
      if (response.sheetLink) {
        window.open(response.sheetLink, "_blank");
      } else {
        console.error("Error exporting to Google Sheets:", response.error);
      }
    } catch (error) {
      console.error(
        "Failed to export to Google Sheets:",
        error?.response?.data?.error ? error.response.data.error : error.message
      );
    }
  };

  const convertToCSV = (data) => {
    if (!data || !Array.isArray(data.creators)) return "";

    const headers = [
      "Name",
      "Following",
      "Youtube",
      "Instagram",
      "TikTok",
      "Youtube Link",
      "Instagram Link",
      "TikTok Link",
    ];

    const rows = data.creators.map((creator) => [
      creator.creator ?? "",
      creator.following ?? "",
      creator.youtube ?? "",
      creator.instagram ?? "",
      creator.tiktok ?? "",
      creator.youtube_link ?? "",
      creator.instagram_link ?? "",
      creator.tiktok_link ?? "",
    ]);

    const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
    return csvContent;
  };

  if (isLoading || !agencyDetails) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading agency details...
      </Typography>
    );
  }

  return (
    <>
      <HomeHeader />
      <Toolbar />
      <PageTitle title={`${manager} - Blitz`} />

      <Box
        sx={{
          backgroundImage: "linear-gradient(#646AFF, #7B2A2A)",
          color: "#FFF",
          textAlign: "center",
          padding: 5,
          marginBottom: 4,
        }}
      >
        <Typography variant="h3" gutterBottom>
          Creator Roster by: {manager}
        </Typography>
        <Typography variant="h6">
          {/* You can add a brief or description here if available */}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: 3 }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleChangeView}
            aria-label="creator-view"
          >
            <ToggleButton value="list">See in List</ToggleButton>
            <ToggleButton value="pretty">Make it Pretty</ToggleButton>
          </ToggleButtonGroup>
          <Button color="success" variant="outlined" onClick={downloadCSV}>
            Download CSV
          </Button>
          <Button color="success" variant="contained" onClick={exportToSheets}>
            Export to Google Sheets
          </Button>
        </Box>
      </Box>
      <Box sx={{ margin: { xs: 0, md: 2 }, textAlign: "center" }}>
        <Paper
          elevation={1}
          square={!isDesktop}
          sx={{
            width: { xs: "100%", md: "fit-content" },
            paddingInline: 1,
            marginInline: "auto",
          }}
        >
          <Tabs
            centered
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Creators" />
            {/* You can add more tabs if needed */}
          </Tabs>
        </Paper>
        <Divider sx={{ marginBlockStart: 2 }} />
        <Box sx={{ paddingInline: { xs: 1, md: 4 }, marginBlockStart: { xs: 1, md: 2 } }}>
          <TabPanel value={selectedTab} index={0}>
            <AgencyDetailsPanel agencyDetails={agencyDetails} viewMode={viewMode} />
          </TabPanel>
          {/* Add more TabPanels if you have more tabs */}
        </Box>
      </Box>
      {showCRMDialog && (
        <CRMDialog
          isOpen={showCRMDialog}
          handleClose={handleCloseCRMDialog}
          origin={`${manager} - roster`}
        />
      )}
      <Dialog open={showInstructions} onClose={() => setShowInstructions(false)}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <Typography>
            Welcome to the Agency Roster page! Here you can view all the creators associated
            with {manager}. Use the "See in List" or "Make it Pretty" buttons to change the view
            mode. You can also download the list of creators as a CSV file or export it to Google
            Sheets for your convenience. Enjoy exploring the roster!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowInstructions(false)}>Got it!</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AgencyDetailsPage;
