import { Avatar, Box } from '@mui/material'
import React from 'react'
import { PhotoCamera } from '@mui/icons-material';

export default function CreatorPfp({
    creatorName,
    profilePhotoUrl,
    handleOpenUploadDialog
}) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
            <Avatar
                alt={creatorName}
                src={profilePhotoUrl}
                sx={{ width: 100, height: 100, cursor: 'pointer' }}
                onClick={handleOpenUploadDialog}
            >
                {!profilePhotoUrl && <PhotoCamera fontSize="large" />}
            </Avatar>
        </Box>
    )
}
