import React, { memo } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Link,
  CardMedia,
  Grid,
  Button,
  Box,
  Rating,
  styled,
} from '@mui/material';
import profilePhoto from '../../../Components/globalAssets/ppfLogo.png'; // Placeholder for the profile photo
import { platformToFollowing, platPromoToKey } from '../../../Utils/constants';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.primary.light,
  },
}));

function CreatorContainers({
  creators,
  platform,
  promotion,
  onAddToCampaign,
  selectedItems = new Set(), // Ensure selectedItems is at least an empty Set
  onInviteToPartnership = null,
}) {
  const parseNumber = (numStr) => {
    if (numStr === null || numStr === undefined || numStr === 'N/A') {
      return 0; // Return 0 for non-numeric or absent values
    }
    if (Number.isFinite(numStr))
      return numStr;
    return parseInt(numStr.replace(/,/g, ''), 10);
  };

  return (
    <Grid container spacing={1}>
      {creators.map((creator, index) => {
        const followerCount = parseNumber(creator[platformToFollowing[platform]] || '0');
        const avgViews = parseNumber(creator.avg_views || '0'); // Default to '0' if avg_views is undefined
        let engagement = null;
        if (followerCount && avgViews) {
          engagement = (avgViews / followerCount) * 100; // calculate engagement percentage
        }
        const avgReview = creator.average_rating || 0;
        const stars = Math.round(avgReview);

        return (
          <Grid item xs={12} sm={6} md={6} lg={4} key={creator.creator}>
            <Card
              sx={{
                display: 'flex',
                height: '16rem',
                backgroundColor: selectedItems.has(creator.creator)
                  ? 'action.focus' // Light grey on selection
                  : 'background.paper', // Darker shade
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginInlineEnd: 1, overflow: 'hidden', padding: 1, justifyContent: 'space-evenly' }}>
                <img
                  style={{ width: '10rem', height: 'auto', aspectRatio: '1/1', objectFit: 'cover', borderRadius: '8px' }}
                  src={creator.pfphref || profilePhoto}
                  alt={creator.creator}
                />
                <Box>
                  <Link
                    variant='h6'
                    color='text.primary'
                    underline="hover"
                    href={`https://www.useblitz.co/creators/${creator.creator}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{ margin: 0, minWidth: 0 }}
                  >
                    @{creator.creator}
                  </Link>
                  <Typography variant='body2' color='text.secondary' sx={{ minWidth: 0 }}>{creator.region}</Typography>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                textAlign: 'right',
                alignItems: 'flex-end',
                paddingBlock: 2,
                paddingInlineEnd: 2,
                justifyContent: 'space-between'
              }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  {avgReview > 0 ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledRating value={stars} color='primary' readOnly></StyledRating>
                      </Box>

                    </>
                  ) : (
                    <Typography variant='body2' color='text.secondary' sx={{ lineHeight: '24px !important', marginBlock: 0 }}>
                      No reviews yet.
                    </Typography>
                  )}
                  <Typography variant='body2'>
                    Followers: {followerCount.toLocaleString()}
                  </Typography>

                  <Link
                    href={creator[`${platform.toLowerCase()}_link`]}
                    target='_blank'
                    rel='noopener noreferrer'
                    variant='body2'
                    sx={{ color: 'text.secondary' }}
                  >
                    View {platform} Profile
                  </Link>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant='body2' color='text.primary' sx={{ fontSize: '14px !important', lineHeight: '14px !important', marginBlock: 0.5 }}>
                    <i>Suggested offer: ${creator[platPromoToKey[platform]?.[promotion]]?.toLocaleString()}</i>
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{ marginBlockEnd: 0.5 }}
                    onClick={() => onAddToCampaign(creator.creator)}
                  >
                    Add to Campaign
                  </Button>
                  {onInviteToPartnership &&
                    <Button
                      variant='outlined'
                      color='secondary'
                      fullWidth
                      onClick={() => onInviteToPartnership(creator)}
                    >
                      Book Partnership
                    </Button>}
                </Box>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default memo(CreatorContainers);
