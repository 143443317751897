import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, Container, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import paymentImage from "../../../Components/globalAssets/blackwhite.png";
import marketplaceImage from "../../../Components/globalAssets/logo_placeholder.png";
import routes from "../../../Config/routes";
import { StyledDivider as Divider } from "../../../Components/LightDivider";
import HomeHeader from "../../../Components/HomeHeader";
import HomeFooter from "../../../Components/HomeFooter";
import { globalStyles } from "../../../Utils/Styles";
import frame from "../../../Components/globalAssets/frame.png";
import agencyPreview from "../../../Components/globalAssets/agencyPreview.png";
import laptopFrame from "../../../Components/globalAssets/laptop-02.png";
import marketPreview from "../../../Components/globalAssets/marketplacePreview.png";

const styles = {
  sectionContainer: {
    display: "flex",
    scrollMarginTop: '64px',
    flexDirection: { xs: "column", md: "row" },
    justifyContent: 'space-evenly',
    alignItems: "center",
    px: 2,
    paddingBlock: 8
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 }, maxWidth: { xs: '150px', md: '300px' }
  },
  laptopContainer: {
    display: "flex",
    justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 }
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: '30rem',
    alignItems: { xs: "center", md: "flex-start" },
    textAlign: { xs: "center", md: "left" }
  }
}

const Agencies = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paymentRef = useRef(null);
  const marketplaceRef = useRef(null);

  return (
    <Box sx={{
      backgroundColor: "#f5f5f5",
      width: '100%',
      color: '#000'
    }}>
      <HomeHeader></HomeHeader>
      <Toolbar></Toolbar>

      {/* Main Content */}
      <Container
        sx={{
          ...globalStyles.wholeHeightMinusToolbar,
          paddingTop: 2,
          paddingBottom: 4,
        }}
      >
        {/* Solutions Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h3" sx={{ textAlign: "center", fontWeight: "bold", my: 2 }}>
            For agencies
          </Typography>
        </Box>

        <Divider />

        {/* AI Campaign Management Section */}
        <Box sx={styles.sectionContainer}>
          <Box sx={styles.imageContainer} >
            <Box
              sx={{
                position: "relative",
                rotate: '4.96deg',
                boxShadow: '-6px 6px 12px black',
                borderRadius: '48px',
                aspectRatio:'9/18',
                maxWidth: "600px", // Fixed width for desktop
                width:'50vw',
                height:'auto'
              }}
            >
              <img
                src={frame}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
              <img
                src={agencyPreview} // Replace with the source URL or path to your video
                alt='Image showing a preview of the Creator Management feature'
                style={{
                  width: "88%",
                  height: "94%",
                  objectFit: "cover",
                  position: "absolute",
                  left: "6%",
                  top: "3%",
                  borderRadius: '4%',
                  zIndex: 0,
                }}
              />
            </Box>
          </Box>
          <Box sx={styles.textContainer}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Creators Management
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Manage, modify and check on your agency creators and their campaigns on our agency management portal.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider></Divider>

        {/* BlitzPay Section */}
        <Box ref={paymentRef} sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}>
          <Box flex={1} sx={styles.imageContainer} >
            <img src={paymentImage} alt="Payment" style={{
              width: "100%",
              borderRadius: "50%", // Circular frame
              objectFit: "cover", // Ensure the image covers the circular frame properly
            }} />
          </Box>
          <Box
            sx={styles.textContainer}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              BlitzPay - Accounts Payable
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Streamline your payment process and ensure creators are paid on time, every time, through their preferred methods.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Creator Sourcing Section */}
        <Box ref={marketplaceRef} sx={styles.sectionContainer}>
          <Box sx={styles.laptopContainer}>
            <Box
              sx={{
                aspectRatio:'16/9',
                position: "relative",
                maxWidth: "600px", // Fixed width for desktop
                width:'50vw',
                height:'auto'
              }}
            >
              <img
                src={laptopFrame}
                alt="Frame"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
              <img
                src={marketPreview} // Replace with the source URL or path to your video
                style={{
                  width: "72%",
                  height: "80%",
                  objectFit: "fill",
                  position: "absolute",
                  left: "14%",
                  top: "7%",
                  zIndex: 2,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ ...styles.textContainer, textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Your Creators, everywhere
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Make you're creators available on the Blitz Marketplace to be seen and enter partnerships with one of the biggest brands.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>

        <Divider />

        {/* Accelerated Creator Collaborations Section */}
        <Box ref={marketplaceRef} sx={{ ...styles.sectionContainer, flexDirection: { xs: "column", md: "row-reverse" } }}>
          <Box flex={1} sx={styles.imageContainer}>
            <img src={marketplaceImage} alt="Collaborations" style={{ width: "100%" }} />
          </Box>
          <Box sx={{ ...styles.textContainer, textAlign: { xs: "center", md: "left" } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              Accelerated Creator Collaborations
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Fast-track your partnerships with creators through Blitz’s efficient collaboration tools.
            </Typography>
            <Button variant="contained" sx={{ fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
              Request Access
            </Button>
          </Box>
        </Box>
      </Container>

      <HomeFooter></HomeFooter>
    </Box>
  );
};

export default Agencies;
