import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Typography
} from '@mui/material';
import { RateField } from '../../../../Components/RateField';
import { getPlatformAndPromotion, platformRatesListFromCreator } from '../../../../Utils/constants';

const InternalBookingDialog = ({ open, onClose, submitBooking, creatorData }) => {
  const [formData, setFormData] = useState({
    selectedPromotion: '',
    rate: 50,
    selectedDate: new Date().toISOString().substring(0, 10),
    details: '',
    partnershipName: '',
    promotionType: '',
    promotionPlatform: ''
  });

  const promotions = useMemo(() => platformRatesListFromCreator(creatorData), [creatorData])

  const [warning, setWarning] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSliderChange = (event, newValue) => {
    setFormData(prevState => ({ ...prevState, rate: newValue }));
  };

  const handlePromoChange = (value) => {
    if (value) {
      const platPromo = getPlatformAndPromotion(value)
      setFormData(prevState => ({
        ...prevState,
        selectedPromotion: value,
        rate: creatorData[value]?.toFixed(2) || '',
        promotionType: platPromo.promotion,
        promotionPlatform: platPromo.platform,

      }));
    }
  };

  const minRate = formData.selectedPromotion ? creatorData[formData.selectedPromotion] : 50.0;

  useEffect(() => {
    if (formData.rate < minRate) {
      setWarning(`Warning: Offers below the creator's asking range ($${minRate}) are usually rejected.`);
    } else {
      setWarning('');
    }
  }, [formData, minRate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitBooking(formData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Partner with {creatorData.name} today!</DialogTitle>
        <DialogContent>

          <Typography variant="subtitle1">
            Set up a partnership with {creatorData.name}
          </Typography>
          <TextField
            label="Partnership Name"
            fullWidth
            name="partnershipName"
            value={formData.partnershipName}
            onChange={handleChange}
            margin="dense"
            required
          />
          <TextField
            select
            label="Promotion Type"
            fullWidth
            name="selectedPromotion"
            value={formData.selectedPromotion}
            onChange={(e) => handlePromoChange(e.target.value)}
            margin="dense"
            required
          >
            {promotions.map((promo) => (
              <MenuItem key={`promoselect-${promo.rateKey}`} value={promo.rateKey}>
                {promo.label}
              </MenuItem>
            ))}
          </TextField>
          <Typography gutterBottom>Your Budget: ${formData.rate}</Typography>
          <RateField
            error={!!warning}
            required
            helperText={warning}
            label="Budget"
            variant="outlined"
            minValue={Math.round(minRate / 2)}
            fullWidth
            value={formData.rate}
            onChange={(value) =>
              handleSliderChange(null, value)
            }
            sx={{ marginTop: 1, marginBottom: 2 }}
          />
          <TextField
            id="date-picker"
            label="Select Date"
            type="date"
            fullWidth
            variant="outlined"
            name="selectedDate"
            value={formData.selectedDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
            required
          />
          <TextField
            label="Details"
            fullWidth
            multiline
            rows={4}
            name="details"
            value={formData.details}
            onChange={handleChange}
            margin="dense"
            required
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant='contained'>Cancel</Button>
          <Button type='submit' color="secondary" variant='contained'>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );

};

export default InternalBookingDialog;
