import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Checkbox,
  Alert,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Button
} from '@mui/material';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import SendIcon from '@mui/icons-material/Send';
import { ConfigValue } from '../../../Config';

const Pitch = () => {
  const { creatorToken } = useCreatorAuth();
  const creatorUsername = creatorToken?.creator_user?.username;

  const [pitchesSent, setPitchesSent] = useState(0);
  const [credits, setCredits] = useState(0);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [rate, setRate] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [isPitchLoading, setIsPitchLoading] = useState(false);

  // Utility function to get the domain from an email
  const getEmailDomain = (email) => {
    if (!email) return '';
    const domain = email.split('@')[1];
    return domain || '';
  };

  // Fetch users by company, excluding Gmail addresses
  const fetchUsersByCompany = async () => {
    const response = await fetch(
      `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/creatorUsers/users_by_company`
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Exclude Gmail addresses
    const filteredData = {};
    Object.keys(data).forEach((companyName) => {
      const filteredUsers = data[companyName].filter(
        (user) =>
          user.email &&
          !user.email.endsWith('@gmail.com') &&
          !user.email.endsWith('@gmail')
      );
      if (filteredUsers.length > 0) {
        filteredData[companyName] = filteredUsers;
      }
    });
    return filteredData;
  };

  // Fetch contacts, excluding Gmail addresses and contacts without email
  const fetchContacts = async () => {
    const response = await fetch(
      'https://blitz-backend-nine.vercel.app/api/crm/contacts'
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Exclude contacts without email and Gmail addresses
    const filteredContacts = data.filter(
      (contact) =>
        contact.email &&
        !contact.email.endsWith('@gmail.com') &&
        !contact.email.endsWith('@gmail')
    );
    return filteredContacts;
  };

  const fetchCredxMapping = async (creatorUsername) => {
    try {
      const response = await fetch(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/credits?creator_username=${creatorUsername}`
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.error}`);
      }
      const data = await response.json();
      setCredits(data.credits);
    } catch (error) {
      console.error('Error fetching credits:', error.message);
    }
  };

  useEffect(() => {
    if (creatorUsername) {
      fetchCredxMapping(creatorUsername);
    }
  }, [creatorUsername]);

  const {
    isLoading: isLoadingUsers,
    error: errorUsers,
    data: usersByCompany,
  } = useQuery('usersByCompany', fetchUsersByCompany, {
    keepPreviousData: true,
  });

  const {
    isLoading: isLoadingContacts,
    error: errorContacts,
    data: contacts,
  } = useQuery('contacts', fetchContacts, {
    keepPreviousData: true,
  });

  // Processed users by company, already excluding Gmail addresses
  const processedUsersByCompany = usersByCompany;

  // Processed contacts, already excluding Gmail addresses
  const processedContacts = contacts;

  // Generate recommendations from contacts
  const recommendations = useMemo(() => {
    if (!processedContacts) return [];

    // Group contacts by email domain
    const domainCounts = {};
    processedContacts.forEach((contact) => {
      if (contact.email) {
        const emailDomain = getEmailDomain(contact.email);
        if (emailDomain) {
          if (!domainCounts[emailDomain]) {
            domainCounts[emailDomain] = { count: 0, contacts: [] };
          }
          domainCounts[emailDomain].count += 1;
          domainCounts[emailDomain].contacts.push(contact);
        }
      }
    });

    // Sort domains by frequency
    const sortedDomains = Object.keys(domainCounts).sort(
      (a, b) => domainCounts[b].count - domainCounts[a].count
    );

    // Collect contacts from the most frequent domains
    let contactsForRecommendations = [];
    sortedDomains.forEach((domain) => {
      contactsForRecommendations = contactsForRecommendations.concat(
        domainCounts[domain].contacts
      );
    });

    // Randomly shuffle the contacts
    for (let i = contactsForRecommendations.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [contactsForRecommendations[i], contactsForRecommendations[j]] = [
        contactsForRecommendations[j],
        contactsForRecommendations[i],
      ];
    }

    // Limit to top 10 recommendations
    return contactsForRecommendations.slice(0, 10);
  }, [processedContacts]);

  // Handle checkbox changes
  const handleCheckboxChange = (identifier) => {
    setSelectedCompanies((prevSelected) =>
      prevSelected.includes(identifier)
        ? prevSelected.filter((name) => name !== identifier)
        : [...prevSelected, identifier]
    );
  };

  const handleOpenDialog = () => {
    if (credits > 0 && selectedCompanies.length > 0) {
      setOpen(true);
    } else {
      alert(
        'Not enough credits or no company selected. Please select at least one company and ask our team for credits if needed.'
      );
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handlePitch = async () => {
    if (!ccEmail) {
      alert('CC Email is required.');
      return;
    }

    setIsPitchLoading(true);

    try {
      const response = await fetch(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/subtract_credits`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            creator_username: creatorUsername,
            email_count: selectedCompanies.length, // Assuming 1 email per company selected
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
        setIsPitchLoading(false);
        return;
      }

      const data = await response.json();

      setPitchesSent(pitchesSent + selectedCompanies.length);
      setCredits(data.credits); // Update credits from the response

      // Send email to each selected company or contact
      for (const identifier of selectedCompanies) {
        // Check if identifier is a company name in usersByCompany
        if (processedUsersByCompany && processedUsersByCompany[identifier]) {
          const companyUsers = processedUsersByCompany[identifier];
          for (const user of companyUsers) {
            await fetch(
              `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/send_pitch_email`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  recipient_email: user.email,
                  subject,
                  body,
                  cc_email: ccEmail,
                  creator_username: creatorUsername,
                }),
              }
            );
          }
        } else {
          // Otherwise, identifier is a contact email
          const contact = processedContacts.find(
            (contact) => contact.email === identifier
          );
          if (contact) {
            await fetch(
              `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/send_pitch_email`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  recipient_email: contact.email,
                  subject,
                  body,
                  cc_email: ccEmail,
                  creator_username: creatorUsername,
                }),
              }
            );
          }
        }
      }

      handleCloseDialog();
    } catch (error) {
      console.error('Error sending pitch:', error.message);
    } finally {
      setIsPitchLoading(false);
    }
  };

  if (isLoadingUsers || isLoadingContacts)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (errorUsers)
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching users: {errorUsers.message}
      </Typography>
    );
  if (errorContacts)
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching contacts: {errorContacts.message}
      </Typography>
    );

  return (
    <Box
      sx={{
        p: 4,
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      {/* Page Header */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        User Pitches
      </Typography>

      {/* Credits and Pitches Sent */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography variant="body1">Pitches Sent: {pitchesSent}</Typography>
        <Typography variant="body1">Credits: {credits}</Typography>
      </Box>

      {credits === 0 && (
        <Alert severity="info" sx={{ mb: 4 }}>
          If you want to pitch to brands, ask our team for some credits.
        </Alert>
      )}

      {/* Recommendations Bar */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Recommendations
        </Typography>
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedCompanies.length > 0 &&
                      selectedCompanies.length < recommendations.length
                    }
                    checked={
                      recommendations.length > 0 &&
                      selectedCompanies.length === recommendations.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCompanies((prev) => [
                          ...prev,
                          ...recommendations.map((contact) => contact.email),
                        ]);
                      } else {
                        setSelectedCompanies((prev) =>
                          prev.filter(
                            (id) =>
                              !recommendations
                                .map((contact) => contact.email)
                                .includes(id)
                          )
                        );
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Domain</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recommendations.length > 0 ? (
                recommendations.map((contact) => {
                  if (!contact.email) return null; // Skip if no email
                  const identifier = contact.email;
                  const displayName =
                    contact.company_name || getEmailDomain(contact.email);
                  const domain = getEmailDomain(contact.email);
                  return (
                    <TableRow key={contact.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCompanies.includes(identifier)}
                          onChange={() => handleCheckboxChange(identifier)}
                        />
                      </TableCell>
                      <TableCell>{displayName}</TableCell>
                      <TableCell>
                        <Link
                          href={`https://${domain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {domain}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography>No recommendations available</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Verified Blitz Users */}
      <Typography variant="h5" gutterBottom>
        Verified Blitz Users - 2 credits per pitch
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 6 }} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedCompanies.length > 0 &&
                    selectedCompanies.length <
                      Object.keys(processedUsersByCompany || {}).length
                  }
                  checked={
                    processedUsersByCompany &&
                    Object.keys(processedUsersByCompany).length > 0 &&
                    selectedCompanies.length ===
                      Object.keys(processedUsersByCompany).length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCompanies((prev) => [
                        ...prev,
                        ...Object.keys(processedUsersByCompany),
                      ]);
                    } else {
                      setSelectedCompanies((prev) =>
                        prev.filter(
                          (id) =>
                            !Object.keys(processedUsersByCompany).includes(id)
                        )
                      );
                    }
                  }}
                />
              </TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Domain</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processedUsersByCompany &&
            Object.keys(processedUsersByCompany).length > 0 ? (
              Object.keys(processedUsersByCompany).map((companyName) => {
                const identifier = companyName;
                const user = processedUsersByCompany[companyName][0];
                const domain = getEmailDomain(user.email);
                return (
                  <TableRow key={companyName}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCompanies.includes(identifier)}
                        onChange={() => handleCheckboxChange(identifier)}
                      />
                    </TableCell>
                    <TableCell>{companyName}</TableCell>
                    <TableCell>
                      <Link
                        href={`https://${domain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {domain}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography>No users available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Culture Club Connections */}
      <Typography variant="h5" gutterBottom>
        Culture Club Connections - 1 credit per pitch
      </Typography>
      <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedCompanies.length > 0 &&
                    selectedCompanies.length < processedContacts.length
                  }
                  checked={
                    processedContacts.length > 0 &&
                    selectedCompanies.length === processedContacts.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCompanies((prev) => [
                        ...prev,
                        ...processedContacts.map((contact) => contact.email),
                      ]);
                    } else {
                      setSelectedCompanies((prev) =>
                        prev.filter(
                          (id) =>
                            !processedContacts
                              .map((contact) => contact.email)
                              .includes(id)
                        )
                      );
                    }
                  }}
                />
              </TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Domain</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processedContacts && processedContacts.length > 0 ? (
              processedContacts.map((contact) => {
                if (!contact.email) return null; // Skip if no email
                const identifier = contact.email;
                const displayName =
                  contact.company_name || getEmailDomain(contact.email);
                const domain = getEmailDomain(contact.email);
                return (
                  <TableRow key={contact.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCompanies.includes(identifier)}
                        onChange={() => handleCheckboxChange(identifier)}
                      />
                    </TableCell>
                    <TableCell>{displayName}</TableCell>
                    <TableCell>
                      <Link
                        href={`https://${domain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {domain}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography>No contacts available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Floating Pitch Button */}
      {selectedCompanies.length > 0 && credits > 0 && (
        <Fab
          color="primary"
          aria-label="Pitch"
          onClick={handleOpenDialog}
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <SendIcon />
        </Fab>
      )}

      {/* Pitch Dialog */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Send Pitch</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for your pitch.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Subject"
            fullWidth
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Body"
            fullWidth
            variant="outlined"
            multiline
            rows={6}
            value={body}
            onChange={(e) => setBody(e.target.value)}
            helperText="Write something meaningful for brands to know about your page! They will be sent instructions on how to book."
          />
          <TextField
            margin="dense"
            label="Rate"
            fullWidth
            variant="outlined"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Your Email (CC)"
            fullWidth
            variant="outlined"
            required
            value={ccEmail}
            onChange={(e) => setCcEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handlePitch}
            disabled={isPitchLoading}
          >
            {isPitchLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Send Pitch'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Pitch;
