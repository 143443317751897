
import React, { useMemo } from 'react';
import { Typography, Box, Card, CardHeader, CardContent, Button, styled } from '@mui/material';
import { generateRainbowColors, getAgeDemos, getGenderDemos, higherValueAge, higherValueGender } from '../../../../../Utils/constants';
import { Bar, BarChart, Cell, LabelList, Tooltip, XAxis, YAxis, ResponsiveContainer, Pie, PieChart } from 'recharts';

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
  color: '#FFF',
  '&:hover': {
    background: 'linear-gradient(45deg, #747AFF, #8B3A3A)',
  },
}));

const ScrollCardContent = styled(CardContent)(({ theme }) => ({
  overflowX: theme.breakpoints.down('md') ? 'auto' : 'clip',
}));

const ScrollCard = styled(Card)({
  maxWidth: '100%',
});

const CampaignProjectionsPanel = ({ campaignDetails }) => {
  const creatorsReachProjections = useMemo(() => {
    if (!campaignDetails) return [];

    return campaignDetails.creators.map((creator) => {
      const relevantDemo = creator.creator_details?.public?.find(
        (demo) => demo.platform?.name === creator.promotionPlatform
      );
      if (!relevantDemo) return null;

      const price = creator.agencyRate ? creator.agencyRate : creator.price;

      return {
        id: creator.id,
        age_data: getAgeDemos(relevantDemo.audience_data),
        gender_data: getGenderDemos(relevantDemo.audience_data),
        country_data: relevantDemo.country_data,
        average_views: relevantDemo.average_views * 0.33,
        cpm: (price / relevantDemo.average_views) * 1000 * 0.33,
      };
    }).filter(Boolean);
  }, [campaignDetails]);

  const totalProjectedData = useMemo(() => {
    const accumDetails = creatorsReachProjections.reduce(
      (campReach, reach) => {
        campReach.age_data = reach.age_data.reduce((curr_data, entry) => {
          const { age_range, value } = entry;
          const existingEntry = curr_data.find(item => item.age_range === age_range);

          if (existingEntry) {
            existingEntry.value += value;
          } else {
            const newEntry = { age_range, value: value }
            curr_data.push(newEntry);
          }
          return curr_data;
        }, campReach.age_data);

        campReach.gender_data = reach.gender_data.reduce((curr_data, entry) => {
          const { gender, value } = entry;
          const existingEntry = curr_data.find(item => item.gender === gender);

          if (existingEntry) {
            existingEntry.value += value;
          } else {
            const newEntry = { gender, value: value }
            curr_data.push(newEntry);
          }
          return curr_data;
        }, campReach.gender_data);

        campReach.country_data = reach.country_data.reduce((curr_data, entry) => {
          const { code, value } = entry;
          const existingEntry = curr_data.find(item => item.code === code);

          if (existingEntry) {
            existingEntry.value += value;
          } else {
            const newEntry = { code, value: value }
            curr_data.push(newEntry);
          }
          return curr_data;
        }, campReach.country_data);

        campReach.average_views += reach.average_views;
        campReach.average_cpm += reach.cpm;
        return campReach;
      }, {
        age_data: [],
        gender_data: [],
        country_data: [],
        average_views: 0,
        average_cpm: 0,
        projected_top_audience: 0,
        top_audience: 'N/A',
        projected_top_gender: 0,
        top_gender: 'N/A',
      }
    );

    accumDetails.age_data = accumDetails.age_data.map((entry) => ({ ...entry, value: entry.value / creatorsReachProjections.length }));
    accumDetails.gender_data = accumDetails.gender_data.map((entry) => ({ ...entry, value: entry.value / creatorsReachProjections.length }));
    accumDetails.country_data = accumDetails.country_data.map((entry) => ({ ...entry, value: entry.value / creatorsReachProjections.length })).filter((entry) => (entry.value > 0.9));
    const totalCountry = accumDetails.country_data.reduce((prevValue, entry) => (prevValue + entry.value), 0);
    accumDetails.country_data.push({ code: 'Others', value: (100 - totalCountry) });

    const highestAge = higherValueAge(accumDetails.age_data);
    const highestGender = higherValueGender(accumDetails.gender_data);

    accumDetails.top_audience = highestAge.age_range;
    accumDetails.projected_top_audience = (0.01 * highestAge.value) * accumDetails.average_views;

    accumDetails.top_gender = highestGender.gender;
    accumDetails.projected_top_gender = (0.01 * highestGender.value) * accumDetails.average_views;

    return accumDetails;
  }, [creatorsReachProjections]);

  const creatorColors = generateRainbowColors(creatorsReachProjections.length);

  return (
    <>
      <Typography variant="h6">Projections</Typography>
      <Box display="flex" gap={2} flexWrap="wrap" marginTop={1} alignItems="stretch" flexDirection={{ xs: 'column', md: 'row' }} marginBlockEnd={2}>
        <Card elevation={2}>
          <CardHeader title="Total" />
          <CardContent>
            <Typography variant="body1"><b>Projected total reach</b>: {totalProjectedData.average_views.toLocaleString()} views</Typography>
            <Typography variant="body1"><b>Average reach per post</b>: {Math.round(totalProjectedData.average_views / creatorsReachProjections.length).toLocaleString()} views</Typography>
            <Typography variant="body1"><b>Creators in campaign</b>: {campaignDetails.creators.length.toLocaleString()} creators</Typography>
            <Typography variant="body1"><b>Average CPM</b>: {Math.round(totalProjectedData.average_cpm / creatorsReachProjections.length).toLocaleString()}$</Typography>
            <Typography variant="body1"><b>Top audience reach</b> ({totalProjectedData.top_audience}): {Math.round(totalProjectedData.projected_top_audience).toLocaleString()} views</Typography>
            <Typography variant="body1"><b>Top gender reach</b> ({totalProjectedData.top_gender}): {totalProjectedData.projected_top_gender.toLocaleString()} views</Typography>
          </CardContent>
        </Card>
        
        <ScrollCard elevation={2}>
          <CardHeader title="Projected Country Percentage" />
          <ScrollCardContent>
            <ResponsiveContainer width={400} height="100%" minHeight={400}>
              <BarChart data={totalProjectedData.country_data} layout="vertical" margin={{ right: 15 }}>
                <XAxis type="number" />
                <YAxis dataKey="code" type="category" />
                <Bar layout="vertical" dataKey="value" fill="#99F" name="Percentage">
                  <LabelList dataKey="value" position="right" formatter={(value) => value.toFixed(0)} />
                </Bar>
                <Tooltip labelStyle={{ color: '#000' }} formatter={(value) => value.toFixed(0)} itemStyle={{ color: '#555' }} />
              </BarChart>
            </ResponsiveContainer>
          </ScrollCardContent>
        </ScrollCard>

        <ScrollCard elevation={2}>
          <CardHeader title="Projected Gender Percentage" />
          <ScrollCardContent>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={totalProjectedData.gender_data}
                  cx="50%"
                  cy="50%"
                  dataKey="value"
                  nameKey="gender"
                  outerRadius="70%"
                  label
                >
                  {totalProjectedData.gender_data.map((entry, index) => (
                    <Cell key={index} fill={["#BBB", "#777"][index]} />
                  ))}
                </Pie>
                <Tooltip labelStyle={{ color: '#000' }} formatter={(value) => value.toFixed(0)} itemStyle={{ color: '#555' }} />
              </PieChart>
            </ResponsiveContainer>
          </ScrollCardContent>
        </ScrollCard>

        <ScrollCard elevation={2}>
          <CardHeader title="Creators Reach Projections" />
          <ScrollCardContent>
            <ResponsiveContainer width={500} height="100%" minHeight={300}>
              <BarChart data={creatorsReachProjections} layout="horizontal">
                <XAxis type="category" dataKey="id" name="Creator" tick={false} />
                <YAxis type="number" name="Proj. views" />
                <Bar dataKey="average_views" name="Projected views">
                  {creatorColors.map((_, index) => (
                    <Cell key={`views-cell-${index}`} fill={creatorColors[index]} />
                  ))}
                </Bar>
                <Tooltip labelStyle={{ color: '#000' }} contentStyle={{ color: '#ff0000' }} formatter={(value) => value.toLocaleString()} />
              </BarChart>
            </ResponsiveContainer>
          </ScrollCardContent>
        </ScrollCard>

        <ScrollCard elevation={2}>
          <CardHeader title="Creators by CPM" />
          <ScrollCardContent>
            <ResponsiveContainer width={500} height="100%" minHeight={300}>
              <BarChart data={creatorsReachProjections} layout="horizontal">
                <XAxis type="category" dataKey="id" name="Creator" tick={false} />
                <YAxis type="number" name="CPM" />
                <Bar dataKey="cpm" name="CPM">
                  {creatorColors.map((_, index) => (
                    <Cell key={`views-cell-${index}`} fill={creatorColors[index]} />
                  ))}
                </Bar>
                <Tooltip labelStyle={{ color: '#000' }} contentStyle={{ color: '#ff0000' }} formatter={(value) => value.toLocaleString()} />
              </BarChart>
            </ResponsiveContainer>
          </ScrollCardContent>
        </ScrollCard>
      </Box>
    </>
  );
};

export default CampaignProjectionsPanel;
