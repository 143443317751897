import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import useAuth from '../../../../Hooks/use-auth';
import { useQuery } from 'react-query';
import client from '../../../../API';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const CampaignDetails = ({ onNext, onBack, setCampaignData }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const userCompany = currentUser.company_name;

  const [campaignName, setCampaignName] = useState('');
  const [campaignBrief, setCampaignBrief] = useState('');
  const [emailRecipient, setEmailRecipient] = useState('');
  const [isAgency, setIsAgency] = useState(false);
  const [managers, setManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState('0');
  const [asset, setAsset] = useState(null);
  const [assetDialogOpen, setAssetDialogOpen] = useState(false);
  const { data: files } = useQuery('files', () => client.files.fetch(''));
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.companies.listUsers();
        setManagers(data);
      } catch (error) {
        setManagers([]);
      }
    };
    fetchData();
  }, []);

  const handleNext = () => {
    const managerIndex = Number.parseInt(currentManager);
    const campaignData = {
      campaignName,
      campaignBrief,
      emailRecipient,
      isAgency,
      manager: managers[managerIndex]
        ? {
            name: `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`,
            email: managers[managerIndex].email,
          }
        : null,
      asset: asset ? asset.file_url : null,
    };
    setCampaignData(campaignData);
    onNext();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Campaign Details
      </Typography>
      <Box sx={{ width: '100%', maxWidth: 600 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Campaign Name"
              variant="outlined"
              fullWidth
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </Grid>
          {asset ? (
            <Grid item xs={12}>
              <Typography variant="body1" color="textPrimary">
                Attached File:
              </Typography>
              <Chip
                label={asset.filename}
                onDelete={() => setAsset(null)}
                color="primary"
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField
                label="Campaign Brief"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={campaignBrief}
                onChange={(e) => setCampaignBrief(e.target.value)}
              />
            </Grid>
          )}
          {!asset && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setAssetDialogOpen(true)}
                sx={{ marginBottom: 2 }}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add File
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Email Recipient"
              variant="outlined"
              fullWidth
              value={emailRecipient}
              onChange={(e) => setEmailRecipient(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAgency}
                  onChange={(e) => setIsAgency(e.target.checked)}
                  color="primary"
                />
              }
              label="Agency"
            />
          </Grid>
          {userCompany === 'TCC' && managers && managers.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Manager</InputLabel>
                <Select
                  value={currentManager}
                  onChange={(e) => setCurrentManager(e.target.value)}
                  label="Manager"
                >
                  {managers.map((manager, index) => (
                    <MenuItem value={index} key={index}>
                      {manager.first_name} {manager.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={onBack}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!campaignName || (!campaignBrief && !asset)}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Asset Selection Dialog */}
      <Dialog open={assetDialogOpen} onClose={() => setAssetDialogOpen(false)}>
        <DialogTitle>Select a File</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {files?.map((file) => (
              <Grid item xs={12} sm={6} md={4} key={file.id}>
                <Card
                  onClick={() => {
                    setAsset(file);
                    setAssetDialogOpen(false);
                    setCampaignBrief('');
                  }}
                  style={{
                    cursor: 'pointer',
                    border: asset?.id === file.id ? '2px solid blue' : 'none',
                  }}
                >
                  <CardContent>
                    {/* You can add a file icon or preview here */}
                    <Typography variant="h6">{file.filename}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssetDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignDetails;
