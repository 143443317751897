import React, { useState, useEffect, useMemo } from 'react'
import { Backdrop, Box, Button, CircularProgress, CssBaseline, Divider, Grid, List, ListItem, ListItemText, MenuItem, Paper, Rating, styled, TextField, ThemeProvider, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material'
import { lightTheme, darkTheme } from '../../Utils/baseTheme'
import HomeHeader from '../../Components/HomeHeader';
import PlaceIcon from '@mui/icons-material/Place';
import PlatformCard from './Components/platformCard';
import TikTokIcon from '../../Components/Icons/TikTok';
import { useNavigate, useParams } from 'react-router-dom';
import StatsCard from './Components/statsCard';
import { generateRainbowColors, getPlatformEngagement, mapPlatformsToAudience, mapPlatformsToEngagement, platformRatesListFromCreator, platforms, platformToFollowing } from '../../Utils/constants';
import { useQuery } from 'react-query';
import API from '../../API';
import CountryCard from './Components/countryCard';
import GenderCard from './Components/genderCard';
import AgeCard from './Components/ageCard';
import PageTitle from '../../Components/PageTitle';
import BookingDialog from '../Misc/bookingdialog';
import LeaveReview from '../Dashboard/Creators/leaveReview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Instagram as InstagramIcon,
    YouTube as YoutubeIcon,
    Twitter as TwitterIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CastIcon from '@mui/icons-material/Cast';
import TwitchIcon from '../../Components/Icons/Twitch';
import useAlertDialog from '../../Components/useAlertDialog';
import AlertDialog from '../../Components/AlertDialog';

const pageTheme = lightTheme;

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconFilled': {
        color: '#fff',
    },
    '& .MuiRating-iconHover': {
        color: '#ddd',
    },
}));

const GradientButton = styled(Button)(({ theme }) => ({
    width: '5.5em',
    background: 'linear-gradient(45deg, #646AFF, #7B2A2A)',
    color: '#FFF',
    '&:hover': {
        background: 'linear-gradient(45deg, #747AFF, #8B3A3A)',
    },
}));

const BookButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)',
    backgroundSize: '400% 400%',
    color: '#FFF',
    borderRadius: '23px',
    fontWeight: '700',
    height: '46px',
    transition: 'transform 0.3s ease-in-out',
    animation: 'rainbow 6s ease infinite',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    '@keyframes rainbow': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        },
    },
}));


const StylePromotionalHeader = {
    '@keyframes slideDown': {
        '0%': {
            transform: 'translateY(-200%)',
        },
        '100%': {
            transform: 'translateY(0)',
        }
    },
    backgroundColor: '#ff9900',
    color: '#fff',
    textAlign: 'center',
    padding: '5px',
    fontSize: '1.5em',
    fontWeight: 'bold',
    animation: 'slideDown 1s ease-out',
    position: 'fixed',
    top: '70px',
    width: '100%',
    zIndex: 10,
    textAlign: 'center'
}

const AudienceCardsData = [
    {
        label: 'Youtube',
        followingKey: 'youtube',
        linkKey: 'youtube_link',
        icon: <YoutubeIcon style={{ verticalAlign: "middle" }}></YoutubeIcon>
    },
    {
        label: 'Instagram',
        followingKey: 'instagram',
        linkKey: 'instagram_link',
        icon: <InstagramIcon style={{ verticalAlign: "middle" }}></InstagramIcon>
    },
    {
        label: 'TikTok',
        followingKey: 'tiktok',
        linkKey: 'tiktok_link',
        icon: <TikTokIcon style={{ verticalAlign: "middle" }}></TikTokIcon>
    },
    {
        label: 'Podcasts',
        followingKey: 'follow_podcast',
        linkKey: 'podcast_link',
        icon: <PodcastsIcon style={{ verticalAlign: "middle" }}></PodcastsIcon>
    },
    {
        label: 'Twitch',
        followingKey: 'twitch',
        linkKey: 'twitch_link',
        icon: <TwitchIcon style={{ verticalAlign: "middle" }}></TwitchIcon>
    },
    {
        label: 'X',
        followingKey: 'follow_x',
        linkKey: 'x_link',
        icon: <TwitterIcon style={{ verticalAlign: "middle" }}></TwitterIcon>
    },
    {
        label: 'Kick',
        followingKey: 'follow_kick',
        linkKey: 'kick_link',
        icon: <CastIcon style={{ verticalAlign: "middle" }}></CastIcon>
    }
]

const platformColors = {
    TikTok: " #222", Instagram: "#833AB4", Youtube: "#EE0022", X: "#00acee", Kick: "#00e701", Podcasts: "#1DB954", Twitch: "#6441a5"
}

function RateCard({ rate, label }) {
    return (
        <ListItem disableGutters secondaryAction={<GradientButton>${rate.toLocaleString()}</GradientButton>}>
            <ListItemText primary={label}></ListItemText>
        </ListItem>)
}

function EngagementData({ engagement }) {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <StatsCard icon={engagement.icon} label={engagement.label} data={engagement.value == 0 ? 'N/A' : engagement.value.toLocaleString()}  ></StatsCard>
        </Grid>
    )
}

export default function MediaKit({ }) {
    const { creatorId, discount } = useParams();
    const [selectedPlatform, setSelectedPlatform] = useState('');

    const [showCRMDialog, setShowCRMDialog] = useState(true);
    const [bookingDialogOpen, setBookingDialogOpen] = useState(true);
    const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
    const {dialogState, openDialog, closeDialog} = useAlertDialog();

    const navigate = useNavigate();

    const { data: creator, error: creatorError, isLoading: creatorLoading } = useQuery({
        queryKey: ['creatorDetails', creatorId],
        queryFn: () => API.creators.fetchDetails(creatorId),
        refetchInterval: false,
        refetchOnWindowFocus: false,
        enabled: !!creatorId,
    });

    const totalFollowers = useMemo(() => {
        if (!creator)
            return 0;

        return platforms.reduce((prev, platform) => {
            return prev + parseInt(creator.creator[platformToFollowing[platform]] || '0', 10)
        }, 0);

        const youtube = creator.creator.youtube ? parseInt(creator.creator.youtube, 10) : 0;
        const instagram = creator.creator.instagram ? parseInt(creator.creator.instagram, 10) : 0;
        const tiktok = creator.creator.tiktok ? parseInt(creator.creator.tiktok, 10) : 0;

        return youtube + instagram + tiktok;

    }, [creator]);

    const rateList = useMemo(() => platformRatesListFromCreator(creator?.creator), [creator]);
    const audienceList = useMemo(() => mapPlatformsToAudience(AudienceCardsData, creator?.creator), [creator]);
    const engagementList = useMemo(() => mapPlatformsToEngagement(AudienceCardsData, creator?.creator), [creator]);

    const hasYoutubeStats = !!getPlatformEngagement(creator?.creator, 'Youtube');
    const hasInstagramStats = !!getPlatformEngagement(creator?.creator, 'Instagram');
    const hasTiktokStats = !!getPlatformEngagement(creator?.creator, 'TikTok');

    //const { promotionData } = useMemo(() => creator?.creator ? platformRatesListFromCreator(creator.creator) : {}, [creator]);

    const selectedPublic = useMemo(() => {
        if (!creator)
            return [];
        if (!selectedPlatform)
            return creator.creator.public;
        const platformData = creator.creator.public.find(
            platform => platform.platform.name === selectedPlatform
        );

        // If platform not found, return null
        if (!platformData) {
            return [];
        }
        return [platformData]
    }, [creator, selectedPlatform]);

    const handleBookingSubmission = async (bookingDetails) => {
        try {
            const result = await API.partnerships.book({
                name: `${bookingDetails.partnershipName} - ${bookingDetails.promotionPlatform} - ${bookingDetails.promotionType}`,
                creator: creator.creator.creator,
                description: bookingDetails.details,
                email: bookingDetails.email,
                proposal_date: bookingDetails.selectedDate,
                total_sum: bookingDetails.rate,
                unique_code: `promo_${creator.creator.creator}_${new Date().getTime()}`,
                notes: bookingDetails.details,  // Passing details as notes
                finish_date: bookingDetails.selectedDate,
            });
            if(!!result.data.user_id)
                openDialog('Success', <>Booking created succesfully. Enter Blitz for details.</>,() => navigate(`/partnerships/${result.data.id}`), () => navigate(`/partnerships/${result.data.id}`), 'Ok', null);
            else
                openDialog('Success', <>Booking created succesfully. Check your email for details.</>, closeDialog, closeDialog, 'Ok', null);
            //console.log('Booking successful:', result);
            return true;
        } catch (error) {
            console.error('Error submitting booking:', error);
            openDialog('Error', <>There was an error submitting your booking: <br/>{error?.response?.data?.error || error?.message}</>, closeDialog, closeDialog, 'Ok', null);
            return false;
        }
    };

    const ageDemos = useMemo(() => {
        return selectedPublic.reduce((acc, demo) => {
            demo.audience_data.forEach((entry) => {
                const { age_range, value } = entry;
                const existingEntry = acc.find(item => item.name === age_range);

                if (existingEntry) {
                    existingEntry.percent += Math.round(value);
                } else {
                    acc.push({ name: age_range, percent: Math.round(value) });
                }
            });
            return acc;
        }, []).map(item => ({
            name: item.name,
            percent: item.percent / selectedPublic.length
        }));
    }, [selectedPublic]);

    const countryDemos = useMemo(() => {
        return selectedPublic.reduce((acc, demo) => {
            demo.country_data.forEach((entry) => {
                const { code, value } = entry;
                const existingEntry = acc.find(item => item.code === code);

                if (existingEntry) {
                    existingEntry.value += value;
                } else {
                    acc.push({ code: code, value: value });
                }
            });
            return acc;
        }, []).map(item => ({
            code: item.code,
            value: item.value / selectedPublic.length
        })).sort((a, b) => b.value - a.value).slice(0, 8);
    }, [selectedPublic]);

    const genderDemos = useMemo(() => {
        const aggregatedData = selectedPublic.flatMap(demo => demo.audience_data);
        return [
            {
                name: 'Male',
                value: aggregatedData.reduce((accum, item) => {
                    if (item.gender.toLowerCase() === 'male') accum += Math.round(item.value);
                    return accum;
                }, 0) / selectedPublic.length
            },
            {
                name: 'Female',
                value: aggregatedData.reduce((accum, item) => {
                    if (item.gender.toLowerCase() === 'female') accum += Math.round(item.value);
                    return accum;
                }, 0) / selectedPublic.length
            }
        ];
    }, [selectedPublic]);

    const ageDemoColors = generateRainbowColors(ageDemos.length || 0);

    const avgReview = creator?.average_rating || 0;
    const stars = Math.round(avgReview);

    return (
        <ThemeProvider theme={pageTheme}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={creatorLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <PageTitle title={`Book @${creatorId} today - Blitz`} />
            <CssBaseline></CssBaseline>
            <HomeHeader></HomeHeader>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Toolbar></Toolbar>
            {discount && (
                <Box sx={StylePromotionalHeader}>
                    <Typography variant='h4'>BOOK TODAY FOR {discount}% OFF!</Typography>
                </Box>
            )}
            <Box id='non-scroll-bg' sx={theme => ({
                '@media (min-width:0px)': {
                    minHeight: {
                        xs: 'calc(100vh - 56px)',
                        md: theme.spacing(80)
                    },
                    top: '56px',
                    '@media (orientation: landscape)': {
                        minHeight: {
                            xs: 'calc(100vh - 48px)',
                            md: theme.spacing(80)
                        },
                        top: '48px',
                    },
                },
                '@media (min-width:600px)': {
                    minHeight: {
                        xs: 'calc(100vh - 64px)',
                        md: theme.spacing(80)
                    },
                    top: '64px'
                },
                width: '100%',
                position: { xs: 'static', md: 'sticky' },
                backgroundImage: 'linear-gradient(#646AFF, #7B2A2A)',
                zIndex: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingInline: 1
            })}>
                <Box id='creator-header' sx={{
                    maxWidth: { xs: '100%', md: 1200 },
                    width: 1200,
                    overflowX: 'clip',
                    marginInline: 'auto',
                    marginBlock: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'stretch',
                    padding: { xs: 2, md: 0 },
                    gap: 4,
                    '@media (orientation: landscape)': {
                        flexDirection: 'row',
                        alignItems: 'stretch'
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 300,
                        maxWidth: 300,
                        gap: 2,
                        marginInline: 'auto',
                    }}>
                        <Box sx={{ height: 300 }}>
                            <img src={creator?.creator.pfphref || ''} style={{ height: '100%', borderRadius: '3.125%' }} >
                            </img>
                        </Box>
                        <BookButton onClick={() => setBookingDialogOpen(true)} >Book me!</BookButton>
                    </Box>
                    <Box sx={{
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flex: 1
                    }}>
                        <Box>
                            <Typography variant='h3' sx={{ textAlign: 'justify', wordBreak: 'break-all' }}>{creatorId}</Typography>
                            {creator?.creator.is_verified && (
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    This creator is verified <CheckCircleIcon sx={{ marginLeft: '8px', color: '#fff' }} />
                                </Typography>
                            )}
                            <Typography variant='h6'>
                                <PlaceIcon fontSize='inherit' style={{ verticalAlign: "middle" }}></PlaceIcon>
                                {' '}{creator?.creator.geolocation} ({creator?.creator.region})
                            </Typography>
                            <BookButton sx={{ height: '36px', marginBlock: 1 }} onClick={() => alert('Clicked!')}>
                                {creator?.creator.primary_market}
                            </BookButton>
                            <Typography>
                                {creator?.creator.notes_content_style}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ marginBlockEnd: -1 }}>Total Followers:</Typography>
                            <Typography sx={{ marginBlockEnd: -1 }} variant='h6'>{totalFollowers.toLocaleString()}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ alignSelf: 'flex-start', display: 'flex', flexDirection: 'row', color: '#fff', alignItems: 'center', gap: '0.25em' }}>
                        <StyledRating value={stars} onClick={() => setReviewDialogOpen(true)}></StyledRating>
                        <Typography> ({creator?.review_count || 0})</Typography>
                    </Box>
                </Box>
            </Box>
            <Paper square elevation={1} sx={{ width: '100%', paddingInline: 1, paddingBlock: 4, position: 'relative' }}>
                <Box sx={{ maxWidth: 1200, marginInline: 'auto' }}>
                    <Box sx={{
                        marginInline: 'auto',
                        marginBottom: 4,
                    }}
                    >
                        <Grid container spacing={2}>
                            {audienceList.map((audience, index) =>
                                <Grid item xs={6} md={4} key={index}>
                                    <PlatformCard
                                        key={audience.link}
                                        handle={audience.handle}
                                        link={audience.link}
                                        following={audience.following}
                                        views={audience.average_views}
                                        icon={audience.icon}>
                                    </PlatformCard>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Typography variant='h6'>Rates Card</Typography>
                    <Divider></Divider>
                    <List>
                        {rateList.map((rate) => <RateCard label={rate.label} rate={rate.rate} key={rate.label}></RateCard>)}
                    </List>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <Typography variant='h6'>Platform Statistics</Typography>
                        <ToggleButtonGroup
                            value={selectedPlatform}
                            exclusive
                            onChange={(event, newState) => setSelectedPlatform(newState)}
                        >
                            {hasTiktokStats && <ToggleButton value='TikTok'>
                                <TikTokIcon></TikTokIcon>
                            </ToggleButton>}
                            {hasInstagramStats && <ToggleButton value='Instagram'>
                                <InstagramIcon></InstagramIcon>
                            </ToggleButton>}
                            {hasYoutubeStats && <ToggleButton value='Youtube'>
                                <YoutubeIcon></YoutubeIcon>
                            </ToggleButton>}
                        </ToggleButtonGroup>
                    </Box>
                    <Divider></Divider>
                    <Box
                        sx={{
                            marginInline: 'auto',
                            marginBlockStart: 2,
                        }}>
                        <Grid container spacing={2}>
                            {engagementList.map((engagement, index) => (
                                <EngagementData key={`engagement-${index}`} engagement={engagement}></EngagementData>
                            ))}
                            <Grid item xs={12} sm={6} md={4}>
                                <CountryCard data={countryDemos} fill={!!selectedPlatform ? platformColors[selectedPlatform] : '#646AFF'}></CountryCard>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <GenderCard data={genderDemos}></GenderCard>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <AgeCard data={ageDemos} fill={ageDemoColors}></AgeCard>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>

            <BookingDialog
                open={bookingDialogOpen}
                onClose={() => setBookingDialogOpen(false)}
                submitBooking={handleBookingSubmission}
                creator={creator?.creator}
                promotionData={rateList}
            />
            <LeaveReview
                open={reviewDialogOpen}
                onClose={() => setReviewDialogOpen(false)}
                creatorId={creatorId}
            />
        </ThemeProvider>
    )
}