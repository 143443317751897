import { Box, Button, Divider} from '@mui/material';
import React from 'react'

export default function FilterPopup({ children, boxStyle, onApply, onClear }) {
    const handleApply = () => {
        onApply?.();
    };

    const handleClear = () => {
        onClear?.();
    };

    return (
        <>
            <Box sx={{maxHeight:'75vh', maxWidth:'80vw', overflowY: 'auto',...boxStyle}}>
                {children}
            </Box>
            <Divider></Divider>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 1 }}>
                <Button variant='text' color='inherit' onClick={handleClear}>Clear All</Button>
                <Button variant='contained' onClick={handleApply}>Apply</Button>
            </Box>
        </>
    )
}
