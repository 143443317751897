import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Grid,
  Paper,
  Link,
} from "@mui/material";
import {
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CastIcon from '@mui/icons-material/Cast';

import client from "../../../../API"; // Adjust the import path based on your project structure
import { useQuery } from "react-query";
import InvitedCreators from "./invitingcastingcalls";
import BookingDialog from "./bookingnew";
import TikTokIcon from "../../../../Components/Icons/TikTok";
import { getHeadersFromKeys } from "../../../../Utils/constants";
import TwitchIcon from "../../../../Components/Icons/Twitch";
import useIsDesktop from "../../../../Hooks/useIsDesktop";

const socialIcons = {
  tiktok: <TikTokIcon />,
  instagram: <InstagramIcon />,
  youtube: <YouTubeIcon />,
  x: <TwitterIcon />,
  twitch: <TwitchIcon></TwitchIcon>,
  podcast:<PodcastsIcon></PodcastsIcon>,
  kick: <CastIcon></CastIcon>,
};

const CastingCallDialog = ({ open, onClose, castingCallId }) => {
  const [tabValue, setTabValue] = useState(0); // For tab navigation
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [promotionData, setPromotionData] = useState([]); // Initialize as empty array
  const [error, setError] = useState('');
  const isDesktop = useIsDesktop();

  // Fetch the casting call details
  const { data: castingCall, error: queryError, isLoading, refetch } = useQuery(
    ["castingCall", castingCallId],
    () => client.castingCalls.getById(castingCallId),
    {
      enabled: !!castingCallId, // Fetch only when castingCallId is present
      refetchOnWindowFocus: false
    }
  );

  // Handle error state
  useEffect(() => {
    if (queryError) {
      console.error("Error fetching casting call:", queryError);
      setError(`Failed to load casting call details: ${queryError?.response?.data?.error || queryError.message}`);
    } else {
      setError(null);
    }
  }, [queryError]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const submitBooking = async (bookingData) => {
    try {
      // Implement your booking submission logic here
      const postData = await client.partnerships.book(bookingData);
    } catch (error) {
      setError(`Failed to submit booking: ${error?.response?.data?.error || error.message}`);
      console.error("Error submitting booking:", error);
    }
    return false;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth fullScreen={!isDesktop} PaperProps={{
      elevation: 0,
      sx: { boxShadow: 24 }
    }}>
      <DialogTitle>Casting Call Details</DialogTitle>
      <DialogContent dividers sx={{paddingInline:{xs:0, md:3}, paddingBlockStart:0, paddingBlockEnd:{xs:0,md:2}}}>
        <Paper elevation={4} sx={{ width: {xs: '100%', md:'fit-content'}, marginInline: 'auto' }}>
          <Tabs value={tabValue} onChange={handleTabChange} allowScrollButtonsMobile>
            <Tab label="Overview" />
            <Tab label="Applications" />
            <Tab label="Invited Creators" />
          </Tabs>
        </Paper>
        <Box mt={1}>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          {tabValue === 0 && castingCall && (
            <Box marginInline={{xs:2,md:3}}>
              <Typography variant="h6">
                Platforms:{" "}
                {castingCall.platforms
                  ? castingCall.platforms.join(", ")
                  : "N/A"}
              </Typography>
              <Typography variant="body1">
                Brief: {castingCall.brief || "N/A"}
              </Typography>
              <Typography variant="body1">
                Budget: ${castingCall.budget_min || 0} - ${castingCall.budget_max || 0}
              </Typography>

              <Typography variant="body1">
                Promotion Types:{" "}
                {!!(castingCall?.promotion_types) ? getHeadersFromKeys(castingCall.promotion_types).join(", ") : "N/A"}
              </Typography>
            </Box>
          )}
          {tabValue === 1 && castingCall && (
            <Box marginInline={{xs:2,md:3}}>
              <Typography variant="h6">Applications</Typography>
              {castingCall.applicants && castingCall.applicants.length ? (
                <Grid container spacing={2} mt={2}>
                  {castingCall.applicants.map((applicant) => (
                    <Grid item xs={12} sm={6} md={4} key={applicant.username}>
                      <Card variant="outlined">
                        <CardContent>
                          <Link
                            color='text.primary'
                            underline="hover"
                            href={`https://www.useblitz.co/creators/${applicant.username}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <Box display="flex" alignItems="center">
                              <Avatar
                                src={applicant.pfphref}
                                alt={applicant.username}
                                sx={{ width: 56, height: 56, mr: 2 }}
                              >
                                {applicant.pfphref ? '' : applicant.username[0]}
                              </Avatar>
                              <Typography variant="h6">
                                {applicant.username}
                              </Typography>
                            </Box>
                          </Link>
                          <Box mt={1}>
                            {Object.entries(applicant.links).map(
                              ([platform, url]) =>
                                url && (
                                  <IconButton
                                    key={platform}
                                    component="a"
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={platform}
                                  >
                                    {socialIcons[platform] || <LinkIcon />}
                                  </IconButton>
                                )
                            )}
                          </Box>
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setSelectedCreator(applicant);
                              setBookingDialogOpen(true);
                            }}
                          >
                            Book Partnership
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No applications yet.</Typography>
              )}
            </Box>
          )}
          {tabValue === 2 && castingCall && (
            <InvitedCreators
              invitedCreators={castingCall.invited_creators || []}
              castingCall={castingCall}
              refetchData={refetch}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant='text'>
          Close
        </Button>
      </DialogActions>

      {/* Booking Dialog */}
      {castingCall && castingCall.platforms && (
        <BookingDialog
          open={bookingDialogOpen}
          onClose={() => setBookingDialogOpen(false)}
          submitBooking={submitBooking}
          creator={selectedCreator}
          castingCall={castingCall}
        />
      )}


    </Dialog>
  );
};

export default CastingCallDialog;
