import React from "react";
import { Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import Slider from "react-slick";
import testimonial1 from "../../Components/globalAssets/clientspfp/CUSTOM-LOADER-CMG-compressed.jpg";
import testimonial2 from "../../Components/globalAssets/clientspfp/L-2311-1601650775-2816.jpg";
import testimonial3 from "../../Components/globalAssets/clientspfp/ONErpm-logo.webp";
import testimonial4 from "../../Components/globalAssets/clientspfp/SONY_MUSIC__logo.jpg";
import testimonial5 from "../../Components/globalAssets/clientspfp/Temu_logo.svg";
import testimonial6 from "../../Components/globalAssets/clientspfp/aithor_ai_logo.jpg";
import testimonial7 from "../../Components/globalAssets/clientspfp/almo.jpg";
import testimonial8 from "../../Components/globalAssets/clientspfp/artist_publishing_group_logo.jpg";
import testimonial9 from "../../Components/globalAssets/clientspfp/ifnluienmedia.png";

const ClientsSection = React.forwardRef((props, ref) => {
  const theme = useTheme();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientNames = [
    "CMG",
    "Interscope Records",
    "ONErpm",
    "Sony Music",
    "Temu",
    "Aithor AI",
    "Alamo",
    "Artist Publishing Group",
    "Influence Media"
  ];

  return (
    <Box sx={{
      paddingInline: 4,
      paddingBlock: 12,
      backgroundColor: "#7b2a2a",
    }}>
      <Typography variant="h3" gutterBottom align="center" color="white">
        Our Clients
      </Typography>
      <Typography variant="h6" align="center" color="white" style={{ marginBottom: "2rem" }}>
        We are proud to work with these distinguished partners.
      </Typography>
      <Box
        sx={{
          maxWidth: '60rem',
          marginInline: 'auto'
        }}
      >
        <Slider {...sliderSettings}>
          {[testimonial1, testimonial2, testimonial3, testimonial4, testimonial5, testimonial6, testimonial7, testimonial8, testimonial9].map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                margin: "10px", // Added margin to create space between items
              }}
            >
              <img
                src={testimonial}
                alt={clientNames[index]}
                style={{
                  width: 140,
                  height: 140,
                  objectFit: "contain",
                  borderRadius: "50%",
                  border: "3px solid #fff",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                }}
              />
              <Typography variant="h6" style={{ marginTop: "15px", color: "#fff", fontWeight: "bold" }}>
                {clientNames[index]}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
});

export default ClientsSection;
