import React, {useState} from 'react'
import { useMutation, useQuery } from 'react-query'
import API from '../../../API'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Paper, TextField, Typography } from '@mui/material'

export default function AgencyCampaignConversation({ conversationId, isOpen, onClose, creatorUsername }) {

    const [message, setMessage] = useState('')

    const { data: messages, isLoading: loadingMessages, error: errorMessages, refetch: refetchMessages } = useQuery({
        queryKey: ['conversations', conversationId],
        queryFn: () => API.agency.getConversation(conversationId),
        enabled: !!conversationId,
        refetchOnWindowFocus: false
    })

    const {mutate: sendMessage, isLoading: loadingSendMessage, error: errorSendMessage} = useMutation(
        {
            mutationFn: (input) => API.conversations.sendMessage(conversationId, input),
            onSuccess: (data) => {
                refetchMessages();
                setMessage('');
            }
        }
    )

    const HandleSendMessage = () => {
        if(!loadingSendMessage && !!message)
            sendMessage({message, fake_creator: creatorUsername}); 
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                Conversation
            </DialogTitle>
            <DialogContent dividers>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    {loadingMessages && <Typography>Loading messages...</Typography>}
                    {!!errorMessages && <Typography>Error loading messages</Typography>}
                    {(!messages || messages.length === 0) && !errorMessages && !loadingMessages && <Typography>No messages</Typography>}
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ minHeight: "300px", maxHeight: "600px", overflowY: "auto" }}
                        style={{ flex: 1 }}>
                        {!!messages && messages.map((msg, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: !!msg.creator_id ? 'flex-end' : 'flex-start',
                                    mb: 2,
                                }}
                            >
                                <Paper
                                    sx={{
                                        maxWidth: '70%',
                                        overflowX: 'hidden',
                                        overflowWrap: 'break-word',
                                        p: 2,
                                        borderRadius: 2,
                                        backgroundColor: !!msg.creator_id ? 'background.paper' : 'action.disabledBackground',
                                        textAlign: 'left',
                                    }}
                                    elevation={2}
                                >
                                    <Typography variant="body1">
                                        {msg.message}
                                    </Typography>
                                    <Typography variant="caption" sx={{ display: 'block', mt: 1 }} color={msg.creator_id ? 'primary.main' : 'text.secondary'} >
                                        {new Date(msg.created_at).toLocaleTimeString()}
                                    </Typography>
                                </Paper>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap:1, height:'4.4em' }}>
                        <TextField
                            label="Type a message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            fullWidth
                            variant="outlined"
                            disabled={loadingMessages || loadingSendMessage}
                            error={!!errorSendMessage}
                            helperText={!!errorSendMessage ? (errorSendMessage.response?.data?.error || errorSendMessage.message) : null}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ flex: 0.25, height: 56 }}
                            disabled={loadingMessages || loadingSendMessage || !message}
                            onClick={HandleSendMessage}
                        >
                            {loadingSendMessage ? <CircularProgress size={24} color="inherit" /> : "Send"}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
