import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    TextField,
    IconButton,
    TableFooter,
    Checkbox,
    Select,
    MenuItem,
    Tooltip,
    Button,
    CircularProgress,
    Typography
} from "@mui/material";
import { StyledTableRow } from "../../../../Utils/styledcell";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import { TimelineStatus } from "../../../../Utils/constants";

function formatDateToYYYYMMDD(dateTime) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(dateTime);
    return formattedDate.replace(/\//g, '-');
}

const TimelineEntry = ({ event, onEditField }) => (
    <StyledTableRow
        sx={
            (theme) => ({
                ...(event.status === 'complete' && event.ogStatus !== 'complete' ? { bgcolor: `${theme.palette.success.main} !important` } : {}),
                ...(event.ogStatus === 'complete' ? {
                    border: `2px solid ${theme.palette.success.main}`
                } : {})
            })
        }
    >
        <TableCell>
            <TextField
                value={event.objective}
                id="Objective-picker"
                fullWidth
                disabled
            />
            <TextField
                value={event.notes}
                id="Notes-picker"
                fullWidth
                minRows={2}
                multiline
                onChange={(e) => onEditField(event.index, 'notes', e.target.value)}
                disabled={event.ogStatus === 'complete'}
            />
            <Typography variant="caption" color="textSecondary">
                Status: {event.status}
            </Typography>
        </TableCell>
        <TableCell>
            <TextField
                id="date-picker"
                fullWidth
                variant="outlined"
                value={formatDateToYYYYMMDD(event.projected_date)}
                disabled
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </TableCell>
        <TableCell align="right">
            <Tooltip title='Mark as Completed'>
                <Button
                    variant="contained"
                    color={event.status === 'complete' ? 'success' : 'primary'}
                    onClick={() => onEditField(event.index, 'status', event.status !== 'complete' ? 'complete' : TimelineStatus[1])}
                    disabled={event.ogStatus === 'complete'}
                    startIcon={event.status === 'complete' ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                    size="large"
                >
                    {event.status === 'complete' ? 'Completed' : 'Mark as Completed'}
                </Button>
            </Tooltip>
        </TableCell>
    </StyledTableRow>
);

const CreatorTimeline = ({ timelineEvents, onEditField, onSaveChanges, isSavingChanges }) => {
    if (!timelineEvents) {
        return null;
    }

    const currentTimeline = timelineEvents.map((event, index) => ({ ...event, index }));
    currentTimeline.sort((a, b) => new Date(a.projected_date) - new Date(b.projected_date));

    return (
        <Box>
            <TableContainer component={Paper} elevation={2}>
                <Typography variant="h4" gutterBottom>
                    To Do List:
                </Typography>
                <Table aria-label="assets details">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell sx={{ minWidth: '20em' }}>Objective and Notes</TableCell>
                            <TableCell sx={{ minWidth: '12em' }}>Date</TableCell>
                            <TableCell>Completed</TableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {currentTimeline.map((event) => (
                            <TimelineEntry key={event.id} event={event} onEditField={onEditField} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3} align="right">
                                <Button
                                    variant="contained"
                                    startIcon={
                                        isSavingChanges ? (
                                            <CircularProgress size={'1em'} />
                                        ) : (
                                            <SaveIcon />
                                        )
                                    }
                                    onClick={onSaveChanges}
                                    disabled={isSavingChanges}
                                >
                                    Save changes
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CreatorTimeline;
