import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Badge,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery, useMutation } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { useNavigate } from 'react-router-dom';
import CreatorCampaignPitchedDialog from './creatorcomponents/creatorCampaignPitchedDialog';
import PartnershipConversationDialog from './partnershipcomps/creatorpartnershipconversationdialog';
import { getOfferFromCampaign, getHeadersFromKeys } from '../../../Utils/constants';

// Helper function to determine if a campaign is pitched
const CheckIsPitched = (campaign, username) => {
  const creator = campaign.creators.find(c => c.id === username);
  return (
    (creator?.status === undefined || creator?.status === 'Pitched') &&
    campaign.campaign_status === 'Launched'
  );
};

const CreatorCollaborations = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const token = creatorToken?.token;

  const [collaborations, setCollaborations] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('All');
  const [currentCampaign, setCurrentCampaign] = useState(-1);
  const [currentPartnership, setCurrentPartnership] = useState(null);
  const [openPitch, setOpenPitch] = useState(false);
  const [openPartnershipDialog, setOpenPartnershipDialog] = useState(false);

  const navigate = useNavigate();

  // Fetch Campaigns
  const fetchCampaigns = async () => {
    if (!token) return [];
    const response = await API.creatorConnect.campaigns(token);
    // Ensure the response is an array
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.data)) { // Adjust based on actual API response
      return response.data;
    } else {
      console.error('Unexpected campaigns response:', response);
      return [];
    }
  };

  // Fetch Partnerships
  const fetchPartnerships = async () => {
    const response = await API.creatorConnect.getPartnerships();
    // Ensure the response is an array
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.data)) { // Adjust based on actual API response
      return response.data;
    } else {
      console.error('Unexpected partnerships response:', response);
      return [];
    }
  };

  // Fetch Casting Calls
  const fetchCastingCalls = async () => {
    const response = await API.castingCalls.creatorFetch();
    // Ensure the response is an array
    if (Array.isArray(response)) {
      return response;
    } else if (response && Array.isArray(response.casting_calls)) { // Adjust based on actual API response
      return response.casting_calls;
    } else {
      console.error('Unexpected casting calls response:', response);
      return [];
    }
  };

  // React Query hooks
  const {
    isLoading: campaignsLoading,
    error: campaignsError,
    data: campaigns,
    refetch: refetchCampaigns,
  } = useQuery(['campaigns', 'creator', username], fetchCampaigns, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: partnershipsLoading,
    error: partnershipsError,
    data: partnerships,
    refetch: refetchPartnerships,
  } = useQuery(['creator', 'partnerships'], fetchPartnerships, {
    initialData: [],
    keepPreviousData: false,
  });

  const {
    isLoading: castingCallsLoading,
    error: castingCallsError,
    data: castingCalls,
    refetch: refetchCastingCalls,
  } = useQuery(['castingCalls', 'creator'], fetchCastingCalls, {
    initialData: [],
    keepPreviousData: false,
  });

  // Combine all collaborations
  useEffect(() => {
    console.log('Campaigns:', campaigns);
    console.log('Partnerships:', partnerships);
    console.log('Casting Calls:', castingCalls);

    if (Array.isArray(campaigns) && Array.isArray(partnerships) && Array.isArray(castingCalls)) {
      const campaignsWithType = campaigns.map(campaign => ({
        ...campaign,
        type: 'campaign',
      }));

      const partnershipsWithType = partnerships.map(partnership => ({
        ...partnership,
        type: 'partnership',
      }));

      const castingCallsWithType = castingCalls.map(castingCall => ({
        ...castingCall,
        type: 'casting_call',
        isApplied: castingCall.status === 'applied', // Adjust based on actual status
      }));

      const combinedCollaborations = [
        ...campaignsWithType,
        ...partnershipsWithType,
        ...castingCallsWithType,
      ];

      setCollaborations(combinedCollaborations);
    } else {
      console.error('One of the data sets is not an array:', {
        campaigns: Array.isArray(campaigns),
        partnerships: Array.isArray(partnerships),
        castingCalls: Array.isArray(castingCalls),
      });
    }
  }, [campaigns, partnerships, castingCalls]);

  // Refetch data when dialogs close
  useEffect(() => {
    if (!openPitch) {
      refetchCampaigns();
    }
  }, [openPitch, refetchCampaigns]);

  useEffect(() => {
    if (!openPartnershipDialog) {
      refetchPartnerships();
    }
  }, [openPartnershipDialog, refetchPartnerships]);

  useEffect(() => {
    refetchCastingCalls();
  }, [refetchCastingCalls]);

  const loading = campaignsLoading || partnershipsLoading || castingCallsLoading;
  const error = campaignsError || partnershipsError || castingCallsError;

  // Filter collaborations based on currentFilter
  const filteredCollaborations = useMemo(() => {
    return collaborations.filter(item => {
      let isValidSelection = true;

      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        let isAcceptedCreator =
          creator?.status !== undefined &&
          creator.status !== 'Declined' &&
          creator.status !== 'Dropped' &&
          creator.status !== 'Pitched';

        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.campaign_status !== 'Archived' &&
              item.campaign_status !== 'Draft' &&
              item.campaign_status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection =
              isAcceptedCreator && item.campaign_status === 'Launched';
            break;
          case 'Offered':
            isValidSelection =
              (creator?.status === undefined || creator?.status === 'Pitched') &&
              item.campaign_status === 'Launched';
            break;
          case 'Completed':
            isValidSelection =
              isAcceptedCreator && item.campaign_status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'partnership') {
        switch (currentFilter) {
          case 'All':
            isValidSelection =
              item.status !== 'Archived' &&
              item.status !== 'Draft' &&
              item.status !== 'DELETED';
            break;
          case 'Current':
            isValidSelection = item.status === 'Active';
            break;
          case 'Offered':
            isValidSelection = item.status === 'pending' || item.status === 'Draft';
            break;
          case 'Completed':
            isValidSelection = item.status === 'Completed';
            break;
          default:
            isValidSelection = true;
        }
      } else if (item.type === 'casting_call') {
        switch (currentFilter) {
          case 'All':
            isValidSelection = item.status !== 'closed';
            break;
          case 'Current':
            isValidSelection = item.status === 'active' || item.isApplied;
            break;
          case 'Offered':
            isValidSelection = item.status === 'open';
            break;
          case 'Completed':
            isValidSelection = item.status === 'completed';
            break;
          default:
            isValidSelection = true;
        }
      }

      return isValidSelection;
    });
  }, [collaborations, currentFilter, username]);

  // Sort collaborations: prioritize partnerships pending, then casting calls applied, etc.
  const sortedCollaborations = useMemo(() => {
    return filteredCollaborations.slice().sort((a, b) => {
      const getPriority = item => {
        if (item.type === 'partnership') {
          if (item.status === 'pending' || item.status === 'Draft') return 1;
          return 3;
        }
        if (item.type === 'casting_call') {
          if (item.isApplied) return 2;
          if (item.status === 'open') return 4;
          return 5;
        }
        if (item.type === 'campaign') {
          return 6;
        }
        return 7;
      };
      const priorityA = getPriority(a);
      const priorityB = getPriority(b);
      if (priorityA !== priorityB) return priorityA - priorityB;
      return 0;
    });
  }, [filteredCollaborations]);

  // Count of offered collaborations for badge
  const offeredCount = useMemo(() => {
    return collaborations.reduce((count, item) => {
      if (item.type === 'campaign') {
        const creator = item.creators.find(c => c.id === username);
        if (
          (creator?.status === undefined || creator?.status === 'Pitched') &&
          item.campaign_status === 'Launched'
        ) {
          count += 1;
        }
      } else if (item.type === 'partnership') {
        if (item.status === 'pending' || item.status === 'Draft') {
          count += 1;
        }
      } else if (item.type === 'casting_call') {
        if (item.status === 'open') {
          count += 1;
        }
      }
      return count;
    }, 0);
  }, [collaborations, username]);

  // Handle item click based on type
const handleItemClick = (item) => {
  if (item.type === 'campaign') {
    const isPitch = CheckIsPitched(item, username);
    if (isPitch) {
      setCurrentCampaign(item.id);
      setOpenPitch(true);
    } else {
      navigate(`/creatorconnect/campaigns/${item.id}`);
    }
  } else if (item.type === 'partnership') {
    // Navigate directly to the partnership page
    navigate(`/creatorconnect/partnerships/${item.id}`);
  } else if (item.type === 'casting_call') {
    if (item.isApplied) {
      navigate(`/creatorconnect/casting-calls/${item.id}/applied`);
    } else {
      navigate(`/creatorconnect/casting-calls/${item.id}`);
    }
  }
};

  // Close dialogs
  const handleClosePitch = () => {
    setOpenPitch(false);
    setCurrentCampaign(-1);
  };

  const handleClosePartnershipDialog = () => {
    setOpenPartnershipDialog(false);
    setCurrentPartnership(null);
  };

  // Determine button label based on type and filter
  const getButtonLabel = item => {
    if (item.type === 'campaign') {
      if (currentFilter !== 'Offered') {
        return 'Manage Campaign';
      } else {
        return 'See Pitch';
      }
    } else if (item.type === 'partnership') {
      return 'Manage Partnership';
    } else if (item.type === 'casting_call') {
      return item.isApplied ? 'View Application' : 'View Details';
    }
    return 'Action';
  };

  // Share handler (to be implemented)
  const handleShare = (item) => {
    // Implement share functionality here
    alert(`Sharing ${item.type} - ${item.name || item.id}`);
  };

  // Loading and error states
  if (loading)
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Typography sx={{ color: 'error', p: 2 }}>
        Error fetching collaborations: {error.message}
      </Typography>
    );

  return (
    <Box>
      {/* Filter Tabs */}
      <Paper elevation={2} square>
        <Tabs
          value={currentFilter}
          onChange={(event, value) => setCurrentFilter(value)}
          centered
        >
          <Tab label="All" value="All" />
          <Tab label="Current" value="Current" />
          <Tab
            label={
              <Badge
                badgeContent={offeredCount}
                color="secondary"
                overlap="rectangular"
                sx={{ padding: 1 }}
              >
                Offered
              </Badge>
            }
            value="Offered"
          />
          <Tab label="Completed" value="Completed" />
        </Tabs>
      </Paper>

      {/* Header */}
      <Typography variant="h6" gutterBottom sx={{ p: 2, marginBlock: 1 }}>
        Your Collaborations
      </Typography>

      {/* Collaborations Grid */}
      <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
        {sortedCollaborations && sortedCollaborations.length > 0 ? (
          sortedCollaborations.map(item => (
            <Grid item xs={12} sm={6} md={3} key={`${item.type}-${item.id}`}>
              <Card
                sx={{
                  opacity:
                    item.type === 'casting_call' && item.isApplied && currentFilter === 'Current'
                      ? 0.6
                      : 1,
                  cursor: 'pointer',
                }}
                onClick={() => handleItemClick(item)}
              >
                <CardHeader
                  title={
                    item.type === 'casting_call'
                      ? `Casting Call #${item.id}`
                      : item.name || `#${item.id}`
                  }
                  titleTypographyProps={{ sx: { textTransform: 'capitalize' } }}
                  subheader={
                    item.type === 'campaign'
                      ? 'Campaign'
                      : item.type === 'partnership'
                      ? 'Partnership'
                      : 'Casting Call'
                  }
                />
                <CardContent>
                  {/* Details based on type */}
                  {item.type === 'campaign' && (
                    <>
                      <Typography variant="body2">
                        Offer: {getOfferFromCampaign(item, username)}
                      </Typography>
                      <Typography variant="body2">Status: {item.campaign_status}</Typography>
                    </>
                  )}
                  {item.type === 'partnership' && (
                    <>
                      <Typography color="textSecondary">{item.description}</Typography>
                      <Typography color="textSecondary">Status: {item.status}</Typography>
                    </>
                  )}
                  {item.type === 'casting_call' && (
                    <>
                      <Typography variant="body2">
                        Budget: ${item.budget_min} - ${item.budget_max}
                      </Typography>
                      <Typography variant="body2">
                        Platforms: {item.platforms?.join(', ') || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        Promotion Types:{' '}
                        {item.promotion_types
                          ? getHeadersFromKeys(item.promotion_types).join(', ')
                          : 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        Status: {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                      </Typography>
                    </>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleItemClick(item);
                    }}
                  >
                    {getButtonLabel(item)}
                  </Button>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShare(item);
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center">No collaborations available</Typography>
          </Grid>
        )}
      </Grid>

      {/* Dialogs */}
      <CreatorCampaignPitchedDialog
        openDialog={openPitch}
        handleCloseDialog={handleClosePitch}
        campaignId={currentCampaign}
      />
      {currentPartnership && (
        <PartnershipConversationDialog
          openDialog={openPartnershipDialog}
          handleCloseDialog={handleClosePartnershipDialog}
          partnership={currentPartnership}
        />
      )}
    </Box>
  );
};

export default CreatorCollaborations;
