import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Container,
  Toolbar,
  ThemeProvider,
} from '@mui/material';
import HomeHeader from '../../Components/HomeHeader';
import HomeFooter from '../../Components/HomeFooter';
import { globalStyles } from '../../Utils/Styles';
import { StyledDivider as Divider } from '../../Components/LightDivider';
import client from '../../API';
import { lightTheme } from '../../Utils/baseTheme';

import { useNavigate } from 'react-router-dom';

const rainbowAnimation = {
  '@keyframes rainbow-border': {
    '0%': { borderColor: '#ff0000' },
    '16%': { borderColor: '#ff9900' },
    '33%': { borderColor: '#33ff00' },
    '50%': { borderColor: '#00ffcc' },
    '66%': { borderColor: '#0066ff' },
    '83%': { borderColor: '#cc00ff' },
    '100%': { borderColor: '#ff0000' },
  },
};

const animatedButtonStyle = {
  border: '2px solid',
  borderRadius: '8px',
  animation: 'rainbow-border 3s linear infinite',
  ...rainbowAnimation,
};

const PayoutPage = () => {
  const navigate = useNavigate();
  const { payoutId } = useParams();
  const [payout, setPayout] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayout = async () => {
      try {
        const data = await client.payouts.get(payoutId);
        setPayout(data.payout);
      } catch (error) {
        console.error('Error fetching payout:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayout();
  }, [payoutId]);

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!payout) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Payout not found.</Typography>
      </Box>
    );
  }

  const formattedDate = (date) => new Date(date).toLocaleDateString();
  const getAmount = (amount) => amount || 0;

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HomeHeader />
        <Toolbar />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              ...globalStyles.wholeHeightMinusToolbar,
              py: 2,
              maxWidth: 'md',
            }}
          >
            <Box id="payout-content" sx={{ mb: 4 }}>
              <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  sx={{ fontWeight: 'bold', mb: 2 }}
                >
                  Payout Information
                </Typography>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Congrats! {payout.creator_id}, you got paid ${getAmount(payout.amount).toFixed(2)}.
                  </Typography>

                  {payout.status === 'Pending' && (
                    <Typography variant="body1" align="center">
                      You can claim the money today for 5% or you can wait 30 days for the full amount to hit your Blitz account.
                    </Typography>
                  )}
                </Box>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Payout Details
                  </Typography>

                  <Typography variant="body1">
                    Creator Name: {payout.creator_id}
                  </Typography>
                  <Typography variant="body1">
                    Amount: ${getAmount(payout.amount).toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Payout Date: {formattedDate(payout.payout_date)}
                  </Typography>
                  <Typography variant="body1">
                    Status: {payout.status}
                  </Typography>
                  {payout.notes && (
                    <Typography variant="body1">
                      Notes: {payout.notes}
                    </Typography>
                  )}
                </Box>

                <Divider sx={{ my: 4 }} />

                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  {payout.has_creator_user ? (
                    <Button
                      variant="contained"
                      sx={{ ...animatedButtonStyle, mt: 2 }}
                      onClick={() => navigate('/creatorconnect/login')}
                    >
                      Login
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ ...animatedButtonStyle, mt: 2 }}
                      onClick={() => navigate(`/creatorconnect/${payout.creator_id}`)}
                    >
                      Claim your free Blitz account
                    </Button>
                  )}
                </Box>

                {/* Added Payment Instructions */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="body1" align="center">
                    To accept this payment, login or claim your Blitz account and navigate to the Money page. Once there, you should be able to select how fast you want to get paid!
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Container>
        </Box>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default PayoutPage;
