import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Button,
  Tabs,
  Tab,
  Alert,
  Paper,
  Backdrop,
  CircularProgress,
  Pagination,
  CardActionArea,
  CardActions,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Delete as DeleteIcon } from "@mui/icons-material";
import client from "../../../../API";
import CastingCallDialog from "./castingcallsdialog";
import { platPromoToHead, platPromoToKey } from "../../../../Utils/constants";
import CreateCallDialog from "./CreateCallDialog";
import { globalStyles } from "../../../../Utils/Styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const dummyPhotoLinks = [
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/287262.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/wp2468668.jpg",
  "https://storage.googleapis.com/blitzccmc.appspot.com/1/eev9l01j44ab1.jpg",
];

const rowsPerPage = 12;

const CastingCalls = () => {
  const [tabValue, setTabValue] = useState("all");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCastingCallId, setSelectedCastingCallId] = useState(null);
  const [open, setOpen] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const { data: castingCalls, isFetching: loadingList, refetch: fetchCastingCalls, error: errorList } = useQuery({
    queryKey: ['castingCalls', 'list', { page, tabValue }],
    queryFn: () => client.castingCalls.list({ page, rows: rowsPerPage, status: tabValue }),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (castingCalls) {
      setRowCount(castingCalls.total);
    }
  }, [castingCalls]);

  useEffect(() => {
    if (!!errorList) {
      alert(`An error ocurred while obtaining Casting Calls: ${errorList?.response?.data?.error || errorList.message}`);
      console.log(errorList);
    }
  }, [errorList])

  const { mutate: updateStatus, isLoading: loadingUpdateState } = useMutation(
    ({ id, status }) => client.castingCalls.updateStatus(id, status),
    {
      onSuccess: () => {
        fetchCastingCalls();
      },
      onError: (error) => {
        console.error("Error updating casting call status:", error);
        alert(`An error ocurred while updating Casting Call: ${error?.response?.data?.error || error.message}`);
      },
    }
  );

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleOpen = () => {
    const hasOpenCastingCall = castingCalls?.casting_calls.some(
      (call) => call.status === "ACTIVE"
    );
    if (hasOpenCastingCall) {
      setBannerVisible(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDelete = (castingCallId) => {
    updateStatus({ id: castingCallId, status: "DELETED" });
  };

  const handleComplete = (castingCallId) => {
    updateStatus({ id: castingCallId, status: "COMPLETED" });
  };


  const handleDialogOpen = (castingCallId) => {
    setSelectedCastingCallId(castingCallId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCastingCallId(null);
  };

  return (
    <Box sx={{ ...globalStyles.wholeHeightMinusToolbar, width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={loadingList||loadingUpdateState}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        sx={{ textAlign: "center", marginBlock: 2, marginInlineStart: 2 }}
      >
        Casting Calls
      </Typography>

      {bannerVisible && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          You may not have more than one open casting call. To create a new one,
          you must delete or archive the old one.
        </Alert>
      )}

      <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginInline: 2, alignSelf: 'flex-start' }}>
        Create a Casting Call
      </Button>

      <CreateCallDialog open={open} handleClose={handleClose} fetchCastingCalls={fetchCastingCalls}></CreateCallDialog>

      <Paper elevation={4} square sx={{ marginBlock: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="All" value="all" />
          <Tab label="Active" value="active" />
          <Tab label="Completed" value="completed" />
        </Tabs>
      </Paper>


      <Box sx={{ marginInline: 2, flex: 1, marginBlockEnd:2 }}>
        <Grid container spacing={2} sx={{alignContent:'flex-start' }}>
          {(!!castingCalls) && castingCalls.casting_calls.map((call) => (
            <Grid item xs={12} sm={6} md={4} key={call.id}>
              <Card onClick={() => handleDialogOpen(call.id)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={dummyPhotoLinks[Math.round((call.date_created.getTime() / 1000) % dummyPhotoLinks.length)]}
                    alt="Casting Call Image"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      {call.platforms.join(", ")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {call.brief}
                    </Typography>
                    <Typography variant="body2">
                      Budget: ${call.budget_min || 0} - ${call.budget_max || 0}
                    </Typography>

                  </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(call.id);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                    {tabValue != 'completed' && <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleComplete(call.id);
                      }}
                      color={call.status == 'COMPLETED' ? "success" : 'default'}
                    >
                      {call.status == 'COMPLETED' ? <CheckCircleIcon></CheckCircleIcon> : <CheckCircleOutlineIcon></CheckCircleOutlineIcon>}
                    </IconButton>}
                  </CardActions>

              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Paper sx={{ paddingInline: 4, paddingBlock: 1, display: 'flex', justifyContent: 'center' }} square elevation={2}>
        <Pagination
          count={rowCount === 0 ? 1 : Math.ceil(rowCount / rowsPerPage)}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}>
        </Pagination>
      </Paper>

      <CastingCallDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        castingCallId={selectedCastingCallId}
      />
    </Box>
  );
};

export default CastingCalls;
