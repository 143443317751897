import React, { useEffect, useState } from 'react';
import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import client from "../API";
import routes from '../Config/routes';
import BlitzHeader from '../Components/BlitzHeader';
import { useCreatorAuth } from '../Hooks/creator-use-auth';

const CreatorRedirect = () => {
  const navigate = useNavigate();

  const { login } = useCreatorAuth();

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const [searchParams] = useSearchParams();

  const redirectStripe = () => {
    const redirectUri = `https://www.useblitz.co/creatorconnect/start/stripe`;
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(redirectUri)}`;
  }

  console.log(searchParams);

  const GoToStart = () => {
    console.log('Going to start!');
    navigate(routes.creatorLogin);
  }
  const GoToEdit = () => {
    navigate(routes.creatorConnectEdit);
  }

  async function SendGoogleCode(code, state) {
    console.log('data:',code,state);
    if (code && state) {
      try {
        const data = await client.creatorConnect.postGoogle({ code, state });
        if (data.redirect === 'stripe') {
          {
            login(data.loginData);
            openDialog("Success", "Account created succesfully. You will now be redirected to Stripe to set up BlitzPay", redirectStripe, redirectStripe, "Ok", null);
          }
        }
        if (data.redirect === 'editstripe') {
          {
            openDialog("Success", "Partner Google Account set succesfully. You will now be redirected to Stripe to set up BlitzPay", redirectStripe, redirectStripe, "Ok", null);
          }
        }
        if (data.redirect === 'login') {
          {
            openDialog("Success", "Account created succesfully.", GoToStart, GoToStart, "Ok", null);
          }
        }
        if (data.redirect === 'edit') {
          {
            openDialog("Success", "Partner Google Account set succesfully.", GoToEdit, GoToEdit, "Ok", null);
          }
        }
      }
      catch (error) {
        console.log(error)
        openDialog("Error", <>Error processing your request<br/>{error.response?.data?.error || error.message}</>, () => void 0, () => () => void 0, 'Ok', null);
      }
    }
    else {
      openDialog("Error", "Unrecovereable error. Your Google authorization was lost. Try again", () => void 0, () => () => void 0, 'Ok', null);
    }
  }

  useEffect(() => {
    SendGoogleCode(searchParams.get('code'),searchParams.get('state'));
  }, [searchParams]);



  return (
    <>
      <BlitzHeader></BlitzHeader>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <Box sx={{ p: 2, backgroundColor: '#1a1a1a', color: '#f5f5f5', minHeight: '100vh' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Creator Connect
        </Typography>
      </Box>
    </>
  );
};

export default CreatorRedirect;
