import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import useIsDesktop from "../../../Hooks/useIsDesktop";
import API from "../../../API";
import { CloseRounded } from "@mui/icons-material";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

const AgencyPartnershipDialog = ({
  openDialog,
  handleCloseDialog,
  partnership,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useIsDesktop();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={isLoading ? "sm" : "md"}
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
        PaperProps={{ elevation: 1, sx: { boxShadow: 24 } }}
      >
        {(isLoading) && (
          <DialogContent style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <CircularProgress />
          </DialogContent>
        )}

        {!isLoading && (
          <>
            <Paper square elevation={2}>
              <DialogTitle>
                Partnership Info
              </DialogTitle>
            </Paper>

            <DialogContent dividers={true}>
              <Typography variant="h6">Partnership: {partnership?.name}</Typography>
              <Typography variant="subtitle1">Ideal due date: {partnership?.finish_date?.toDateString() || 'N/A'}</Typography>
              <Typography variant="subtitle1">Offer: {partnership?.total_sum?.toFixed(2) || 'N/A'}</Typography>
              <Typography variant="subtitle1">Brief: 
                <Typography variant="body2" component='span'> {partnership?.description}</Typography></Typography>

            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState}></AlertDialog>
    </>
  );
};

export default AgencyPartnershipDialog;
