import { createSvgIcon } from "@mui/material";

const TwitchIcon = createSvgIcon(
    <svg fill="currentColor" width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path d="M80,32,48,112V416h96v64h64l64-64h80L464,304V32ZM416,288l-64,64H256l-64,64V352H112V80H416Z"></path>
            <rect x="320" y="143" width="48" height="129"></rect><rect x="208" y="143" width="48" height="129"></rect>
        </g>
    </svg>
    ,
    'Twitch',
);

export default TwitchIcon

