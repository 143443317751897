// DealIOPublic.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Toolbar,
  ThemeProvider,
} from '@mui/material';
import HomeHeader from '../../Components/HomeHeader';
import HomeFooter from '../../Components/HomeFooter';
import { globalStyles } from '../../Utils/Styles';
import { StyledDivider as Divider } from '../../Components/LightDivider';
import API from '../../API';
import { lightTheme } from '../../Utils/baseTheme';

const DealIOPublic = () => {
  const { dealId } = useParams();
  const [dealIO, setDealIO] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDealIO = async () => {
      try {
        const data = await API.dealsIO.fetch(dealId);
        setDealIO(data);
      } catch (error) {
        console.error('Error fetching Deal IO:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDealIO();
  }, [dealId]);

  const formattedDate = (date) => (date ? new Date(date).toLocaleDateString() : 'N/A');

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!dealIO) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>Deal IO not found.</Typography>
      </Box>
    );
  }

  const { creator_vendor_id, amount_due, amount_paid, amount_of_months, deliverables } = dealIO;

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundImage: 'linear-gradient(to bottom, #ffffff, #e0e0e0)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HomeHeader />
        <Toolbar />
        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Container
            sx={{
              ...globalStyles.wholeHeightMinusToolbar,
              py: 2,
              maxWidth: 'md',
            }}
          >
            <Box id="dealio-content" sx={{ mb: 4 }}>
              <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
                {/* Header */}
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  sx={{ fontWeight: 'bold', mb: 2 }}
                >
                  Deal IO
                </Typography>

                <Typography variant="body1" align="center">
                  Deal IO ID: {dealId}
                </Typography>

                {/* Warning Banner */}
                <Paper
                  elevation={2}
                  sx={{ p: 2, mb: 4, backgroundColor: '#fff3cd', margin: 2 }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ color: '#856404', textAlign: 'center' }}
                  >
                    ⚠️ Deals and payments must happen within the Blitz platform. Going
                    outside the Blitz platform could result in banishment from the
                    platform and losing deals from other partnerships.
                  </Typography>
                </Paper>

                <Divider sx={{ my: 4 }} />

                {/* Deal IO Details */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="body1">
                    Vendor ID: {creator_vendor_id}
                  </Typography>
                  <Typography variant="body1">
                    Amount Due: ${parseFloat(amount_due).toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Amount Paid: ${parseFloat(amount_paid).toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Amount of Months: {amount_of_months}
                  </Typography>
                </Box>

                <Divider sx={{ my: 4 }} />

                {/* Deliverables Table */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    Deliverables
                  </Typography>
                  {deliverables && deliverables.length > 0 ? (
                    <TableContainer component={Paper} elevation={1}>
                      <Table>
                        <TableHead sx={{ bgcolor: '#000' }}>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}>Work Info</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Amount</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Due Date</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Completion Date</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Paid</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deliverables.map((deliverable) => (
                            <TableRow key={deliverable.id}>
                              <TableCell>{deliverable.work_info}</TableCell>
                              <TableCell>
                                ${parseFloat(deliverable.amount).toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {formattedDate(deliverable.due_date)}
                              </TableCell>
                              <TableCell>
                                {formattedDate(deliverable.completion_date)}
                              </TableCell>
                              <TableCell>{deliverable.status}</TableCell>
                              <TableCell>{deliverable.paid ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography>No deliverables available.</Typography>
                  )}
                </Box>

                <Divider sx={{ my: 4 }} />

                {/* Terms or additional information */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                    Terms
                  </Typography>
                  <Typography variant="body1">
                    {/* Include any terms or notes as required */}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Container>
        </Box>
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};

export default DealIOPublic;
