import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import ZapFeed from './zapfeed/zapfeed';
import { useQuery } from 'react-query';
import API from '../../../API';
import routes from './../../../Config/routes';
import useIsDesktop from '../../../Hooks/useIsDesktop';
import CountUp from 'react-countup';
import '../../styles.css'

const CreatorStart = () => {
  const navigate = useNavigate();
  const { creatorToken } = useCreatorAuth();
  const [openUnreadMessagesDialog, setOpenUnreadMessagesDialog] = useState(false);
  const isDesktop = useIsDesktop();

  if (!creatorToken) return <>Loading</>;

  // Fetching data for action cards
  const {
    isLoading: isLoadingDashboard,
    error: errorDashboard,
    data: dashboard,
  } = useQuery(['creator', creatorToken.creator_user.username, 'dashboard'], API.creatorConnect.dashboard, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  if (isLoadingDashboard)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    );

  if (errorDashboard)
    return <Typography sx={{ color: 'error' }}>Error getting dashboar data: {errorDashboard?.response?.data?.error || errorDashboard.message}</Typography>;

  // Calculating total earnings and status breakdown
  const totalEarnings = dashboard?.payouts?.reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const pendingEarnings =
    dashboard?.payouts?.filter((payout) => payout.status === 'Pending').reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const approvedEarnings =
    dashboard?.payouts?.filter((payout) => payout.status === 'Approved').reduce((sum, payout) => sum + payout.amount, 0) || 0;

  // Filtering campaigns and partnerships
  const filteredCampaigns = dashboard.campaigns.filter((campaign) => {
    const creator = campaign.creators.find((c) => c.id === creatorToken.creator_user.username);
    return (
      campaign.campaign_status !== 'Archived' &&
      campaign.campaign_status !== 'Draft' &&
      campaign.campaign_status !== 'DELETED' &&
      creator?.status !== 'Declined' &&
      creator?.status !== 'Dropped'
    );
  });

  const filteredPartnerships = dashboard.partnerships.filter((partnership) => {
    return partnership.status !== 'Archived' && partnership.status !== 'Draft' && partnership.status !== 'DELETED';
  });

  // Calculating total deals
  const totalDeals = filteredCampaigns.length + filteredPartnerships.length;

  const handleUnreadMessagesClick = () => {
    setOpenUnreadMessagesDialog(true);
  };

  const handleUnreadMessagesClose = () => {
    setOpenUnreadMessagesDialog(false);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      <Box
        sx={{
          paddingBlockStart: 4,
          paddingInline: { xs: 2, md: 8 },
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {/* New Earnings Section */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h4" component="h1">
            Your Blitz Earnings
          </Typography>
          <Typography variant="h3" component="div">
            $
            <CountUp end={totalEarnings} duration={2} decimals={2} />
          </Typography>
          <Typography variant="h6" component="div">
            Pending: $
            <CountUp end={pendingEarnings} duration={2} decimals={2} />
          </Typography>
          <Typography variant="h6" component="div">
            Approved: $
            <CountUp end={approvedEarnings} duration={2} decimals={2} />
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Contact us to learn how to earn more money through Blitz
          </Typography>
        
        </Box>

        {/* Invoice Someone Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button
            variant="outlined"
            className="rainbow-button" // Apply the animated rainbow style
            onClick={() => handleNavigate(routes.creatorConnectPayoutsInvoicing)}
          >
            Invoice Someone
          </Button>
        </Box>

        {/* Rest of the content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            gap: 2,
            mt: 4,
          }}
        >
          <Box>
            <ZapFeed />
          </Box>
          <Box sx={{ flex: 1, minWidth: { xs: 'auto', sm: '25em' } }}>
            <Typography variant="h6" gutterBottom>
              Quick Access
            </Typography>

            <Grid container spacing={2} sx={{ padding: 0, marginBottom: 2 }}>
              {/* Total Earnings Card */}
              <Grid item xs={12} sm={6}>
                <Card
                  elevation={2}
                  sx={{
                    border: '1px solid',
                    borderColor: 'grey.300',
                    ':hover': { boxShadow: 6, transform: 'scale(1.02)' },
                    transition: 'transform 0.2s',
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Total Earnings</Typography>
                    <Typography variant="body1">Total: ${totalEarnings.toFixed(2)}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Pending: ${pendingEarnings.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Approved: ${approvedEarnings.toFixed(2)}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() => handleNavigate(routes.creatorConnectPayoutsInvoicing)}
                    >
                      View Earnings
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Total Deals Card */}
              <Grid item xs={12} sm={6}>
                <Card
                  elevation={2}
                  sx={{
                    border: '1px solid',
                    borderColor: 'grey.300',
                    ':hover': { boxShadow: 6, transform: 'scale(1.02)' },
                    transition: 'transform 0.2s',
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Total Deals</Typography>
                    <Typography variant="body1">Total: {totalDeals}</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorCollabs)}>
                      View Collaborations
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Unread Messages Card */}
              <Grid item xs={12}>
                <Card
                  elevation={2}
                  sx={{
                    border: '1px solid',
                    borderColor: 'grey.300',
                    ':hover': { boxShadow: 6, transform: 'scale(1.02)' },
                    transition: 'transform 0.2s',
                    backgroundColor: '#ffebee',
                  }}
                >
                  <CardContent>
                    <Badge badgeContent={dashboard?.unreadMessages?.length || 0} color="secondary">
                      <Typography variant="h6">Unread Messages</Typography>
                    </Badge>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {dashboard?.unreadMessages?.length
                        ? `You have ${unreadMessages.length} unread messages.`
                        : 'You have no unread messages.'}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={handleUnreadMessagesClick}
                      disabled={dashboard?.unreadMessages?.length === 0}
                    >
                      View Unread Messages
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Other Cards */}
              {[
                { title: 'Pitch', route: routes.creatorConnectPitch },
                {
                  title: 'Mediakit',
                  route: `https://www.useblitz.co/creators/${creatorToken.creator_user.username}`,
                  external: true,
                },
                { title: 'Deals & Emails', route: routes.creatorConnectDealsEmails },
                { title: 'More Options', route: routes.creatorConnectMore },
              ].map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    elevation={2}
                    sx={{
                      border: '1px solid',
                      borderColor: 'grey.300',
                      ':hover': { boxShadow: 6, transform: 'scale(1.02)' },
                      transition: 'transform 0.2s',
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6">{card.title}</Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {card.title === 'Mediakit'
                          ? 'Manage your media and content assets.'
                          : `Learn more about ${card.title.toLowerCase()}.`}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={() => {
                          if (card.external) {
                            window.location.href = card.route;
                          } else {
                            handleNavigate(card.route);
                          }
                        }}
                      >
                        {card.title === 'Mediakit' ? 'View Mediakit' : `Go to ${card.title}`}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Unread Messages Dialog */}
      <Dialog open={openUnreadMessagesDialog} onClose={handleUnreadMessagesClose} fullWidth maxWidth="sm">
        <DialogTitle>Unread Messages</DialogTitle>
        <DialogContent>
          <List>
            {dashboard?.unreadMessages?.map((campaign) => (
              <Box key={campaign.campaign_id}>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  {campaign.campaign_name}
                </Typography>
                {campaign.messages.map((message) => (
                  <ListItem key={message.message_id} onClick={() => handleNavigate(routes.creatorCollabs)}>
                    <ListItemText
                      primary={message.text}
                      secondary={`Received on ${new Date(message.created_at).toLocaleString()}`}
                    />
                  </ListItem>
                ))}
              </Box>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreatorStart;
