import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Paper, Button, CircularProgress, Tabs, Tab, Badge, IconButton, Grid, Card, CardContent, CardActions, CardHeader } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../Hooks/creator-use-auth';
import { useNavigate } from 'react-router-dom';
import API from '../../API';

const CreatorDeals = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const token = creatorToken?.token;

  const [currentFilter, setCurrentFilter] = useState('active');
  const navigate = useNavigate();

  const fetchDeals = async () => {
    if (!token) return [];
    return API.dealsIO.creatorFetch().then(res => res.data);
  };

  const { isLoading, error, data: deals, refetch: refetchDeals } = useQuery(
    ['deals', 'creator', username],
    fetchDeals,
    {
      initialData: [],
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    refetchDeals();
  }, [refetchDeals]);
  const filteredDeals = useMemo(() => deals.filter((deal) => {
    switch (currentFilter) {
      case 'active':
        return deal.amount_due > deal.amount_paid;
      case 'completed':
        return deal.amount_due <= deal.amount_paid;
      default:
        return true;
    }
  }), [deals, currentFilter]);

  const activeCount = useMemo(() => deals.reduce((count, deal) => deal.amount_due > deal.amount_paid ? count + 1 : count, 0), [deals]);

  if (isLoading) return <CircularProgress sx={{ color: 'white' }} />;
  if (error) return <Typography sx={{ color: 'error' }}>Error fetching deals: {(error.response && error.response.data) ? error.response.data.error : error.message}</Typography>;

  const handleClickDeal = (dealId) => {
    console.log(`Navigating to deal ${dealId}`);
    navigate(`/deals/${dealId}`);
  };

  return (
    <Box>
      <Paper elevation={2} square>
        <Tabs value={currentFilter} onChange={(event, value) => setCurrentFilter(value)} centered>
          <Tab label="Active" value='active'></Tab>
          <Tab label={<Badge badgeContent={activeCount} color='secondary' overlap='rectangular' sx={{ padding: 1 }}>Completed</Badge>} value='completed'></Tab>
        </Tabs>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ p: 2, marginBlock: 1 }}>
        Your Deals
      </Typography>
      <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
        {filteredDeals && filteredDeals.length > 0 ? filteredDeals.map(deal => (
          <Grid item xs={12} sm={6} md={3} key={deal.id}>
            <Card>
              <CardHeader title={deal.name} titleTypographyProps={{ sx: { textTransform: 'capitalize' } }} subheader={`Amount Due: $${deal.amount_due}`} />
              <CardContent>
                <Typography variant="body2">Amount Paid: ${deal.amount_paid}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={() => handleClickDeal(deal.id)}>
                  Manage Deal
                </Button>
                <IconButton onClick={() => {/* handle share */ }}>
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        )) : (
          <Grid item xs={12}>
            <Typography align="center">No deals available</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CreatorDeals;
