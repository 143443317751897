import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'

export default function StatsCard({ data, label, icon}) {

    return (
        <Card variant='outlined' sx={{padding:0, margin:0}}>
            <CardContent>
                <Typography variant='subtitle2' color={'text.secondary'}>{icon} {label}</Typography>
                <Typography variant='h4'>{data}</Typography>
            </CardContent>
        </Card>
    )
}
