import { Box, Card, CardContent, CardMedia, FormControlLabel, Grid, IconButton, MenuItem, Switch, TextField, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { RateField } from '../../../../Components/RateField';
import { platformRatesListFromCreator } from '../../../../Utils/constants';

export default function CreatorDetailsCard({ creator, index, handlePriceChange, handleOptionChange, onRemove, triedNext }) {
    const promotions = platformRatesListFromCreator(creator);

    return (
        <Grid item xs={12} sm={6} md={6}>
            <Card>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 300 }}
                        image={creator.pfphref || '/default_profile.png'}
                        alt={creator.creator}
                    />
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            {creator.creator}
                        </Typography>
                        <TextField
                            error={triedNext && !creator.rateKey}
                            helperText={(triedNext && !creator.rateKey) ? 'Select a promotion for this creator' : ''}
                            value={creator.rateKey}
                            onChange={(e) =>
                                handleOptionChange(
                                    index,
                                    'rateKey',
                                    e.target.value
                                )
                            }
                            label='Promotion Type'
                            select
                            fullWidth
                        >
                            <MenuItem value=''>None</MenuItem>
                            {promotions.map((promo) => (<MenuItem key={`${creator.creator}-${promo.rateKey}`} value={promo.rateKey}>{promo.label}</MenuItem>))}
                        </TextField>
                        <RateField
                            error={triedNext && (!creator.basePrice || creator.basePrice == '0')}
                            helperText={(triedNext&&(!creator.basePrice  || creator.basePrice == '0')) ? 'Enter a rate for this creator' : ''}
                            label="Base Price"
                            variant="outlined"
                            fullWidth
                            value={creator.basePrice}
                            onChange={(value) =>
                                handlePriceChange(index, value)
                            }
                            sx={{ marginTop: 1 }}
                        />
                        {false && <FormControlLabel
                            control={
                                <Switch
                                    checked={creator.crossPost}
                                    onChange={(e) =>
                                        handleOptionChange(
                                            index,
                                            'crossPost',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Cross-Post"
                        />}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={creator.usageRights}
                                    onChange={(e) =>
                                        handleOptionChange(
                                            index,
                                            'usageRights',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Usage Rights"
                        />
                        <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                            Adjusted Price: ${creator.adjustedPrice.toFixed(2)}
                        </Typography>
                    </CardContent>
                    <IconButton edge="end" sx={{alignSelf:'flex-end', marginRight:1}} onClick={() => onRemove(creator.creator)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Card>
        </Grid>
    )
}
