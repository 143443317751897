import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography
} from '@mui/material';
import client from '../../../../../API';

const UpdateDialog = ({ open, onClose, userData }) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailPreview, setEmailPreview] = useState(''); // Added for HTML preview
  const [isSending, setIsSending] = useState(false);

  const handleGeneratePreview = () => {
    const formattedBody = emailBody
      .replace(/\n/g, '<br>') // Convert line breaks to HTML
      .replace(/{first_name}/g, 'User'); // Replace placeholders with a default value for preview
    setEmailPreview(formattedBody);
  };

  const handleSendUpdate = async () => {
    setIsSending(true);
    const updates = userData.map((user) => ({
      email: user.email,
      subject: emailSubject.replace(/{first_name}/g, user.first_name || 'User'),
      body: emailBody.replace(/{first_name}/g, user.first_name || 'User')
    }));

    try {
      const result = await client.crm.update(updates);
      console.log('Updates sent:', result);

      // Optionally update CRM contacts
      await client.crm.update_contacts({ updates, note: `Last email - ${emailSubject}` });

      onClose();
    } catch (error) {
      console.error('Failed to send updates:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Update</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Selected Users:
        </Typography>
        {userData.length > 0 ? (
          <Typography variant="body2" component="div">
            {userData.map((user) => (
              <div key={user.id}>{user.email}</div>
            ))}
          </Typography>
        ) : (
          <Typography>No users selected.</Typography>
        )}
        <TextField
          label="Email Subject"
          variant="outlined"
          fullWidth
          margin="normal"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
        />
        <TextField
          label="Email Body"
          variant="outlined"
          multiline
          rows={10}
          fullWidth
          margin="normal"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
          onBlur={handleGeneratePreview} // Generate preview when input loses focus
        />
        <Typography variant="subtitle1" gutterBottom>
          Email Preview (HTML):
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: emailPreview }}
          style={{
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#f9f9f9',
            maxHeight: '200px',
            overflowY: 'auto'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSending}>
          Cancel
        </Button>
        <Button onClick={handleSendUpdate} color="primary" variant="contained" disabled={isSending}>
          {isSending ? <CircularProgress size={24} /> : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDialog;
