import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Backdrop, Box, CircularProgress, Divider, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Pagination, Paper, Skeleton, TextField, Typography } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { useQuery } from 'react-query';
import API from '../API';
import { platforms, platformToFollowing, sortCreators } from '../Utils/constants';
import {
    Instagram as InstagramIcon,
    YouTube as YouTubeIcon,
    Twitter as TwitterIcon,
} from "@mui/icons-material";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import CastIcon from '@mui/icons-material/Cast';
import TikTokIcon from "./Icons/TikTok";
import TwitchIcon from "./Icons/Twitch";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const socialIcons = {
    instagram_link: <InstagramIcon fontSize='inherit' />,
    youtube_link: <YouTubeIcon fontSize='inherit' />,
    x_link: <TwitterIcon fontSize='inherit' />,
    twitch_link: <TwitchIcon fontSize='inherit' ></TwitchIcon>,
    podcast_link: <PodcastsIcon fontSize='inherit' ></PodcastsIcon>,
    kick_link: <CastIcon fontSize='inherit' ></CastIcon>,
    tiktok_link: <TikTokIcon fontSize='inherit' />,
};

function CreatorEntry({ creator, selectedCreators, disabledCreators, onClick=(creator)=>0 }) {
    const totalFollowers = platforms.reduce((prev, platform) => {
        return prev + parseInt(creator[platformToFollowing[platform]] || '0', 10)
    }, 0);

    return (
        <ListItem
            sx={{ backgroundColor: selectedCreators.has(creator.creator) ? 'action.selected' : (disabledCreators.has(creator.creator) ? 'action.disabledBackground' : 'transparent') }}
            alignItems='flex-start'
            disablePadding
            secondaryAction={
                <IconButton
                    edge='end'
                    aria-label="Link to Creator"
                    sx={{ paddingBlock: 0, paddingInline: 0.5 }}
                    component="a"
                    href={`https://www.useblitz.co/creators/${creator.creator}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <OpenInNewIcon />
                </IconButton>
            }
        >
            <ListItemButton
                alignItems='flex-start'
                onClick={()=>onClick(creator)}
            >
                <ListItemAvatar>
                    <Avatar src={creator.pfphref || undefined}>
                        {!creator.pfphref && creator.creator[0]}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={creator.creator}
                    secondary={
                        <>
                            Followers: {totalFollowers.toLocaleString()}<br />
                            {Object.keys(socialIcons).map((platform) =>
                                creator[platform] && (
                                    <IconButton
                                        sx={{ paddingBlock: 0, paddingInline: 0.5 }}
                                        key={platform}
                                        component="a"
                                        href={creator[platform]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={platform}
                                    >
                                        {socialIcons[platform]}
                                    </IconButton>
                                )
                            )}
                        </>
                    } secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
            </ListItemButton>
        </ListItem>
    )
}

function EmptyCreator({ props }) {
    return (
        <ListItemButton alignItems='flex-start'>
            <ListItemAvatar>
                <Skeleton variant="circular">
                    <Avatar>
                    </Avatar>
                </Skeleton>
            </ListItemAvatar>
            <ListItemText primary={<Skeleton animation='wave'></Skeleton>} secondary={<Skeleton animation='wave' sx={{ fontSize: '2em' }}></Skeleton>} secondaryTypographyProps={{ sx: { textWrap: 'nowrap' } }} />
        </ListItemButton>
    )
}


export default function MiniCreatorSearch({ onCreatorPick = (creator) => 0, selectedCreators = new Set(), disabledCreators = new Set(), itemsPerPage = 20, searchSettings = {} }) {
    const [page, setPage] = useState(0);
    const [currentSearch, setCurrentSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const { data: creators, isLoading } = useQuery({
        queryKey: ['creators'],
        queryFn: async () => sortCreators(await API.creators.list({ ...searchSettings })),
        refetchOnWindowFocus: false
    });

    //Debounces search bar to make it more performant
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setCurrentSearch(searchInput);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
    }, [searchInput]);

    const filteredCreators = useMemo(
        () => !creators ? [] : creators.filter((creator) => {
            return currentSearch === "" || Object.values(creator).some((val) => typeof val === "string" && val.toLowerCase().includes(currentSearch.toLowerCase()));
        }),
        [currentSearch, creators]
    );

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Paper sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
                elevation={1}>
                <Box sx={{ padding: 2 }}>
                    <TextField
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        variant='outlined'
                        fullWidth size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <SearchRounded>
                                    </SearchRounded>
                                </InputAdornment>)
                        }}>
                    </TextField>
                </Box>
                <Divider></Divider>
                <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'clip' }}>
                    <Box>
                        <List dense>
                            {!!creators && filteredCreators.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage, filteredCreators.length)).map((creator) => (
                                <CreatorEntry key={creator.creator}
                                    creator={creator}
                                    selectedCreators={selectedCreators}
                                    disabledCreators={disabledCreators}
                                    onClick={(creator)=>onCreatorPick(creator)}
                                    >
                                </CreatorEntry>
                            ))}
                            {isLoading && [0, 1, 2, 4, 5, 6, 7].map((index) => (<EmptyCreator key={index}></EmptyCreator>))}
                        </List>
                    </Box>
                </Box>
                <Divider></Divider>
                <Box sx={{ paddingBlock: 2 }}>
                    <Pagination
                        sx={{ marginInline: 'auto', maxWidth: 'fit-content' }}
                        siblingCount={0}
                        count={Math.floor(filteredCreators.length / itemsPerPage)} page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}>
                    </Pagination>
                </Box>
            </Paper>
        </Box>
    )
}
