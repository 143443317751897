import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import client from '../../API';
import useAuth from '../../Hooks/use-auth';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';

const DealIODetail = () => {
  const { dealId } = useParams(); // Get the deal IO ID from the route parameters
  const [dealIO, setDealIO] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null); // For menu
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareEmails, setShareEmails] = useState('');
  const [selectedDeliverables, setSelectedDeliverables] = useState([]);
  const [showMessageCapAlert, setShowMessageCapAlert] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deliverableToApprove, setDeliverableToApprove] = useState(null);

  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();

  // Fetch the Deal IO by ID
  const { data, isLoading: isFetching, error, refetch } = useQuery(
    ['dealIO', dealId],
    () => client.dealsIO.fetch(dealId),
    {
      onSuccess: (data) => {
        setDealIO(data);
        setLoading(false);
      },
      onError: (error) => {
        console.error('Error fetching Deal IO:', error);
        setLoading(false);
      },
    }
  );

  // Mutation to update the Deal IO
  const { mutate: updateDealIO, isLoading: isUpdating } = useMutation(
    (updatedData) => client.dealsIO.update(dealId, updatedData),
    {
      onSuccess: () => {
        alert('Deal IO updated successfully!');
        refetch();
      },
      onError: (error) => {
        console.error('Error updating Deal IO:', error);
        alert('Error updating Deal IO: ' + error.message);
      },
    }
  );

  // Mutation to share the Deal IO
  const { mutate: shareDealIO, isLoading: isSharing } = useMutation(
    (input) => client.dealsIO.share(dealId, input),
    {
      onSuccess: () => {
        alert('Deal IO shared successfully!');
        setShareDialogOpen(false);
        setShareEmails('');
        refetch();
      },
      onError: (error) => {
        console.error('Error sharing Deal IO:', error);
        alert('Error sharing Deal IO: ' + error.message);
      },
    }
  );

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareClick = () => {
    setShareDialogOpen(true);
    handleMenuClose();
  };

  const handleShareSubmit = () => {
    const emails = shareEmails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '');
    if (emails.length === 0) {
      alert('Please enter at least one email address');
      return;
    }
    shareDealIO({ shared_with: emails });
  };

  const handleDeliverableCheckboxChange = (deliverableId) => {
    const selectedIndex = selectedDeliverables.indexOf(deliverableId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDeliverables, deliverableId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDeliverables.slice(1));
    } else if (selectedIndex === selectedDeliverables.length - 1) {
      newSelected = newSelected.concat(selectedDeliverables.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDeliverables.slice(0, selectedIndex),
        selectedDeliverables.slice(selectedIndex + 1)
      );
    }

    setSelectedDeliverables(newSelected);
  };

  const handleApproveClick = (deliverable) => {
    setDeliverableToApprove(deliverable);
    setConfirmDialogOpen(true);
  };

  const handleDeliverableStatusChange = (deliverableId, newStatus) => {
    if (!dealIO || !dealIO.deliverables) return;
    const updatedDeliverables = dealIO.deliverables.map((d) => {
      if (d.id === deliverableId) {
        return { ...d, status: newStatus };
      }
      return d;
    });
    updateDealIO({ deliverables: updatedDeliverables });
  };

  if (loading || isFetching) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !dealIO) {
    return (
      <Typography
        sx={{
          width: '100vw',
          height: '100vh',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Error loading Deal IO.
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Header */}
      <Paper
        sx={{ position: 'sticky', top: 0, zIndex: 2, padding: 2 }}
        elevation={4}
        square
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
              Deal IO with {dealIO.creator_vendor_id}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleShareClick} color="inherit">
              <ShareIcon />
            </IconButton>
            <IconButton onClick={handleMenuOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleShareClick}>Share Deal IO</MenuItem>
          </Menu>
        </Box>
      </Paper>

      {/* Warning Banner */}
      <Paper
        elevation={2}
        sx={{ p: 2, mb: 4, backgroundColor: '#fff3cd', margin: 2 }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: '#856404', textAlign: 'center' }}
        >
          ⚠️ Deals and payments must happen within the Blitz platform. Going
          outside the Blitz platform could result in banishment from the
          platform and losing deals from other partnerships.
        </Typography>
      </Paper>

      {/* Deal IO Details */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">
          Vendor ID: {dealIO.creator_vendor_id}
        </Typography>
        <Typography variant="subtitle1">
          Amount Due: ${parseFloat(dealIO.amount_due).toFixed(2)}
        </Typography>
        <Typography variant="subtitle1">
          Amount Paid: ${parseFloat(dealIO.amount_paid).toFixed(2)}
        </Typography>
        <Typography variant="subtitle1">
          Amount of Months: {dealIO.amount_of_months}
        </Typography>
      </Box>

      {/* Deliverables */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Deliverables</Typography>
        {dealIO.deliverables && dealIO.deliverables.length > 0 ? (
          dealIO.deliverables.map((deliverable) => {
            const isApproved = deliverable.status === 'Approved';
            const isPaid = deliverable.paid;

            return (
              <Paper key={deliverable.id} sx={{ p: 2, mt: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography variant="subtitle1">
                      Work Info: {deliverable.work_info}
                    </Typography>
                    <Typography variant="body2">
                      Amount: ${parseFloat(deliverable.amount).toFixed(2)}
                    </Typography>
                    <Typography variant="body2">
                      Due Date: {deliverable.due_date}
                    </Typography>
                    <Typography variant="body2">
                      Completion Date: {deliverable.completion_date || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      Status: {deliverable.status}
                    </Typography>
                    <Typography variant="body2">
                      Paid: {deliverable.paid ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isApproved}
                          disabled={isPaid}
                          onChange={(e) => {
                            if (e.target.checked) {
                              // Show confirmation dialog before approving
                              handleApproveClick(deliverable);
                            } else {
                              // If unchecking, no confirmation needed
                              handleDeliverableStatusChange(deliverable.id, 'Pending');
                            }
                          }}
                        />
                      }
                      label="Approve"
                    />
                  </Box>
                </Box>
              </Paper>
            );
          })
        ) : (
          <Typography>No deliverables available.</Typography>
        )}
      </Box>

      {/* Confirmation Dialog for Approval */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirm Approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this deliverable and send payment of 
            ${deliverableToApprove ? parseFloat(deliverableToApprove.amount).toFixed(2) : '...'} 
            to the vendor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (deliverableToApprove) {
                handleDeliverableStatusChange(deliverableToApprove.id, 'Approved');
              }
              setConfirmDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Share Deal IO Dialog */}
      <Dialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      >
        <DialogTitle>Share Deal IO</DialogTitle>
        <DialogContent>
          {dealIO.shared_with && dealIO.shared_with.length > 0 ? (
            <>
              <Typography variant="subtitle1">Already shared with:</Typography>
              <ul>
                {dealIO.shared_with.map((email) => (
                  <li key={email}>{email}</li>
                ))}
              </ul>
            </>
          ) : (
            <Typography variant="subtitle1">
              Nobody has been shared this yet! Invite your first colleague
              today.
            </Typography>
          )}
          <DialogContentText sx={{ mt: 2 }}>
            Enter the email addresses of the users you want to share this Deal
            IO with. Separate multiple emails with commas.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="emails"
            label="Email Addresses"
            type="email"
            fullWidth
            variant="standard"
            value={shareEmails}
            onChange={(e) => setShareEmails(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleShareSubmit}
            disabled={isSharing}
          >
            {isSharing ? (
              <CircularProgress size={24} />
            ) : (
              'Share'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for message cap alert */}
      <Snackbar
        open={showMessageCapAlert}
        autoHideDuration={6000}
        onClose={() => setShowMessageCapAlert(false)}
      >
        <Alert
          onClose={() => setShowMessageCapAlert(false)}
          severity="warning"
          sx={{ width: '100%' }}
        >
          You have reached the maximum number of messages without a response.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DealIODetail;
